import { type Blockchain } from './types';

export type BlockchainFilter = Blockchain | 'all';

export default function blockchainFilterReducer(
  state: BlockchainFilter = 'all',
  action: {
    type: 'SET_BLOCKCHAIN_FILTER';
    payload: { selectedBlockchain: BlockchainFilter };
  },
) {
  switch (action.type) {
    case 'SET_BLOCKCHAIN_FILTER': {
      return action.payload.selectedBlockchain;
    }
    default:
      return state;
  }
}
