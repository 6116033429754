import type { Placement } from '@floating-ui/react';

import { Select } from '../_core/select';
import { useAppDispatch } from '../store/react';
import { AccountAvatar } from '../vault/accountAvatar';
import { setSelectedAccount } from '../vault/redux';
import type { InMemoryAccountJSON } from '../vault/types';
import * as styles from './currentAccountSelect.module.css';
import { Account } from './types';

interface Props {
  accounts: InMemoryAccountJSON[];
  placement?: Placement;
  selectedAccountId: string;
}

export function CurrentAccountSelect({
  accounts,
  placement,
  selectedAccountId,
}: Props) {
  const dispatch = useAppDispatch();

  const accountOptions = accounts.map(accountJSON => {
    const account = Account.fromInMemoryJSON(accountJSON).assertOk();

    return {
      account,
      value: account.id,
      label: account.name,
    };
  });

  return (
    <Select
      items={accountOptions}
      placement={placement}
      value={selectedAccountId}
      variant="defaultSmall"
      renderButtonLabel={({ account, label }) => (
        <span className={styles.accountButton}>
          <AccountAvatar
            account={account}
            className={styles.accountButtonAvatar}
            size="small"
          />

          <span className={styles.accountButtonLabel}>{label}</span>
        </span>
      )}
      renderPlaceholder={text => (
        <span className={styles.accountButtonLabel}>{text}</span>
      )}
      renderOptionLabel={({ account, label }) => (
        <span className={styles.accountButton}>
          <AccountAvatar account={account} size="small" />
          <span className={styles.accountButtonLabel}>{label}</span>
        </span>
      )}
      onChange={value => {
        void dispatch(setSelectedAccount(value));
      }}
    />
  );
}
