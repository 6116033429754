import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { FormControl } from '../../_core/formControl';
import { InputShell } from '../../_core/input';
import type { Account } from '../../accounts/types';
import { AccountAvatar } from '../../vault/accountAvatar';
import * as styles from './accountNameInput.module.css';

interface Props {
  account: Account;
  error?: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AccountNameInput = forwardRef<HTMLInputElement, Props>(
  function AccountNameInput(
    { account, error, name, value, disabled, onBlur, onChange },
    ref,
  ) {
    const { i18n } = useLingui();

    return (
      <FormControl error={error} label={t(i18n)`Set account name`}>
        <InputShell>
          {({ inputClassName, inputId, rootClassName }) => (
            <div className={clsx(styles.root, rootClassName)}>
              <AccountAvatar account={account} className={styles.avatar} />

              <input
                ref={ref}
                className={clsx(styles.input, inputClassName)}
                id={inputId}
                name={name}
                value={value}
                placeholder={t(i18n)`Name required`}
                disabled={disabled}
                onBlur={onBlur}
                onChange={onChange}
              />
            </div>
          )}
        </InputShell>
      </FormControl>
    );
  },
);
