import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LinkButton } from '../../_core/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogHeading,
} from '../../_core/dialog';
import { Maybe, None, Some } from '../../_core/maybe';
import { KeeperMobileAccount } from '../../accounts/models/keeperMobileAccount';
import { useAppSelector } from '../../store/react';
import { AccountAvatar } from '../../vault/accountAvatar';
import { GetKeeperMessage } from './getKeeperMessage';
import * as styles from './importAccount.module.css';
import {
  OptionButton,
  OptionButtonLink,
  OptionGroup,
  OptionGroupSeparator,
} from './options';
import { AddAccountShell } from './shell';

export function ImportAccountPage() {
  const { i18n } = useLingui();

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const next = params.get('next');
  const nextParams = new URLSearchParams();

  if (next) {
    nextParams.set('next', next);
  }

  const nextSearch = nextParams.toString();

  const vault = useAppSelector(state => state.vault);

  const existingMobileAccount = useMemo(() => {
    return Maybe.fromNullable(
      vault.state === 'unlocked' ? vault.accounts : null,
    ).flatMapSome(accounts =>
      Maybe.findMap(accounts, account =>
        account.type === 'keeper-mobile' ? Some(account) : None,
      ),
    );
  }, [vault]);

  const [isOneMobileAccountAlertOpen, setOneMobileAccountAlertOpen] =
    useState(false);

  return (
    <>
      <AddAccountShell backPath="/add-account" heading={t(i18n)`Add Account`}>
        {existingMobileAccount
          .mapSome(account => (
            <OneMobileAccountAlert
              account={KeeperMobileAccount.fromInMemoryJSON(account).assertOk()}
              isOpen={isOneMobileAccountAlertOpen}
              onIsOpenChange={setOneMobileAccountAlertOpen}
            />
          ))
          .toNullable()}

        <p className={styles.description}>
          <Trans>Choose a convenient method to import your account</Trans>
        </p>

        <OptionGroupSeparator>
          <Trans>connect existing one</Trans>
        </OptionGroupSeparator>

        <OptionGroup>
          <OptionButton
            iconSrc={
              new URL('./keeperMobileIcon.svg', import.meta.url).pathname
            }
            text={t(i18n)`Mobile App`}
            onClick={async () => {
              if (existingMobileAccount.isSome) {
                setOneMobileAccountAlertOpen(true);
                return;
              }

              navigate({
                pathname: '/add-account/import/mobile',
                search: nextSearch,
              });
            }}
          />

          <OptionButtonLink
            iconSrc={new URL('./keeperIcon.svg', import.meta.url).pathname}
            text={t(i18n)`Extension`}
            to={{
              pathname: '/add-account/import/extension',
              search: nextSearch,
            }}
          />
        </OptionGroup>

        <OptionGroupSeparator>
          <Trans>or import account</Trans>
        </OptionGroupSeparator>

        <OptionGroup>
          <OptionButtonLink
            iconSrc={new URL('./seedOrKeyIcon.svg', import.meta.url).pathname}
            text={t(i18n)`Secret Phrase`}
            to={{ pathname: '/add-account/seed-or-key', search: nextSearch }}
          />
        </OptionGroup>
      </AddAccountShell>

      <GetKeeperMessage />
    </>
  );
}

function OneMobileAccountAlert({
  account,
  isOpen,
  onIsOpenChange,
}: {
  account: KeeperMobileAccount;
  isOpen?: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
}) {
  const { i18n } = useLingui();

  return (
    <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogClose />
          <DialogHeading>{t(i18n)`Switch Mobile Account`}</DialogHeading>
        </DialogHeader>

        <div className={styles.alertPlate}>
          <p className={styles.alertText}>
            <Trans>
              Currently, only one mobile account can be added. Your connected
              Keeper Mobile account is:
            </Trans>
          </p>

          <div className={styles.accountItem}>
            <div
              className={clsx(
                styles.accountAvatarContainer,
                {
                  waves: styles.accountAvatarBadge_waves,
                  'keeper-extension': styles.accountAvatarBadge_waves,
                  'keeper-mobile': styles.accountAvatarBadge_waves,
                  ethereum: styles.accountAvatarBadge_ethereum,
                  multichain: undefined,
                }[account.type],
              )}
            >
              <AccountAvatar
                account={account}
                className={styles.accountAvatar}
              />
            </div>

            <h3 className={styles.accountTitle}>{account.name}</h3>
          </div>

          <p className={styles.alertText}>
            <Trans>
              Delete the current account first to add a new one on the Accounts
              page
            </Trans>
          </p>
        </div>

        <LinkButton to="/accounts" block text={t(i18n)`Go to Accounts`} />

        <button
          className={styles.alertCancelBtn}
          onClick={() => onIsOpenChange(false)}
        >
          {t(i18n)`Cancel`}
        </button>
      </DialogContent>
    </Dialog>
  );
}
