import { base16Encode } from '@keeper-wallet/waves-crypto';

import { Err, Ok } from '../../_core/result';

export class EthereumPrivateKey {
  #bytes: Uint8Array;

  private constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  static fromBytes(bytes: Uint8Array) {
    if (bytes.length !== 32) {
      return Err({ type: 'invalid-ethereum-private-key' });
    }

    return Ok(new EthereumPrivateKey(bytes));
  }

  get blockchain() {
    return 'ethereum' as const;
  }

  toString() {
    return `0x${base16Encode(this.#bytes)}`;
  }
}
