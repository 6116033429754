import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import BigNumber from '@waves/bignumber';
import { TRANSACTION_TYPE } from '@waves/ts-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { number, type } from 'superstruct';

import { AsyncValue } from '../_core/asyncValue';
import type { UnexpectedError } from '../_core/errors';
import { handleResponse } from '../_core/handleResponse';
import { Maybe, None, Some } from '../_core/maybe';
import {
  type BlockchainMoney,
  EthereumMoney,
  WavesMoney,
} from '../_core/money';
import { Err, Ok, Result } from '../_core/result';
import { useAccounts } from '../accounts/requireAccounts';
import { useSignatureRequest } from '../accounts/signatureRequest';
import { Account } from '../accounts/types';
import { track } from '../analytics';
import {
  type BlockchainAddress,
  EthereumAddress,
  type EthereumAddressError,
  WavesAddress,
  type WavesAddressError,
} from '../blockchain/address';
import {
  EthereumFee,
  type EthereumFeeEstimates,
} from '../blockchain/fee/ethereumFee';
import { getWavesFeeOptions, WavesFee } from '../blockchain/fee/wavesFee';
import {
  type BlockchainFee,
  BlockchainTransactionRequest,
  type BlockchainTransactionSendResponse,
} from '../blockchain/types';
import {
  type TransactionStatus,
  WatchTransactionStatus,
} from '../blockchain/watchTransactionStatus';
import { useCoingeckoUsdPrices } from '../cache/coingecko/usdPrices';
import { useDataServiceAssets } from '../cache/dataService/assets';
import { useDataServiceEthereumBalances } from '../cache/dataService/ethereumBalances';
import { useDataServiceEthereumTokens } from '../cache/dataService/ethereumTokenList';
import { useDataServiceUsdPrices } from '../cache/dataService/usdPrices';
import {
  useWavesAssetsDetails,
  WAVES_ASSET,
} from '../cache/wavesNode/assetsDetails';
import { useWavesBalances } from '../cache/wavesNode/balances';
import { useEntryContext } from '../entry';
import { ETHEREUM_ASSET } from '../ethereum/constants';
import { Container } from '../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { Network } from '../network/types';
import type { SendAssetsAssetSelectOption } from '../send/assetSelect';
import type {
  SendAssetsEthereumFeeOption,
  SendAssetsWavesFeeOption,
} from '../send/feeField';
import {
  SendAssets,
  type SendAssetsFormErrors,
  type SendAssetsFormValues,
} from '../send/sendAssets';
import { SendStatusDialog } from '../send/sendStatusDialog';
import { useAppSelector } from '../store/react';
import { resolveDomainAddress } from '../waves/domains';
import * as styles from './send.module.css';

const DEFAULT_FEE_IN_WAVES = '100000';
const SMART_ASSET_EXTRA_FEE = '400000';

export function SendAssetsPage() {
  const { ethereumNodeUrls, dataServiceUrl } = useEntryContext();
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const { chainId, nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  const signatureRequest = useSignatureRequest();

  const [accountJSON] = useAccounts({ onlySelected: true });

  const account = useMemo(
    () => Account.fromInMemoryJSON(accountJSON).assertOk(),
    [accountJSON],
  );

  const ethereumAddress = useMemo(
    () => account.getEthereumAddress(),
    [account],
  );

  const wavesAddress = useMemo(
    () => account.getWavesAddress(chainId),
    [account, chainId],
  );

  const ethereumAddresses = useMemo(
    () =>
      ethereumAddress.match({
        Some: x => [x.toString()],
        None: () => [],
      }),
    [ethereumAddress],
  );

  const wavesAddresses = useMemo(
    () =>
      wavesAddress.match({
        Some: address => [address.toString()],
        None: () => [],
      }),
    [wavesAddress],
  );

  const wavesBalancesByAddress = useWavesBalances({
    addresses: wavesAddresses,
  });

  const wavesBalances = useMemo(
    () =>
      wavesAddress
        .match({
          None: () => AsyncValue.Ready(None),
          Some: address =>
            wavesBalancesByAddress.mapReady(balances =>
              Maybe.fromNullable(balances[address.toString()]).mapSome(
                balanceEntry => ({
                  byAssetId: balanceEntry.byAssetId,
                  list: balanceEntry.list,
                }),
              ),
            ),
        })
        .mapReady(maybeBalances =>
          maybeBalances.getOr(() => ({
            byAssetId: {},
            list: [],
          })),
        ),
    [wavesAddress, wavesBalancesByAddress],
  );

  const wavesAssetIds = useMemo(
    () =>
      wavesBalances.match({
        Pending: () => [],
        Ready: wavesBalancesValue =>
          wavesBalancesValue.list.map(assetBalance => assetBalance.assetId),
      }),
    [wavesBalances],
  );

  const ethereumTokens = useDataServiceEthereumTokens();

  const wavesAssetsDetails = useWavesAssetsDetails({
    assetIds: wavesAssetIds,
  });

  const dataServiceUsdPrices = useDataServiceUsdPrices({
    assetIds: wavesAssetIds,
  });

  const dataServiceAssets = useDataServiceAssets();

  const ethereumBalancesByAddress = useDataServiceEthereumBalances({
    addresses: ethereumAddresses,
  });

  const ethereumBalances = useMemo(
    () =>
      ethereumAddress
        .match({
          None: () => AsyncValue.Ready(None),
          Some: ethereumAddressValue =>
            ethereumBalancesByAddress.mapReady(balances =>
              Maybe.fromNullable(
                balances[ethereumAddressValue.toString()],
              ).mapSome(x => ({
                byAssetAddress: x.byAssetAddress,
                list: x.list,
              })),
            ),
        })
        .mapReady(x => x.getOr(() => ({ byAssetAddress: {}, list: [] }))),
    [ethereumAddress, ethereumBalancesByAddress],
  );

  const ethereumAssetAddresses = useMemo(
    () =>
      ethereumBalances.match({
        Pending: () => [],
        Ready: balances =>
          balances.list.map(assetBalance => assetBalance.address),
      }),
    [ethereumBalances],
  );

  const coingeckoUsdPrices = useCoingeckoUsdPrices({
    addresses: ethereumAssetAddresses,
  });

  const assetSelectOptions = useMemo(
    () =>
      AsyncValue.allRecord({
        ethereumBalances,
        ethereumTokens,
        wavesAssetsDetails,
        wavesBalances,
      }).mapReady(x => {
        const allBalances = [
          ...x.ethereumBalances.list,
          ...x.wavesBalances.list,
        ];

        const options: SendAssetsAssetSelectOption[] = [];

        for (const balance of allBalances) {
          switch (balance.blockchain) {
            case 'ethereum': {
              const asset = x.ethereumTokens[balance.address];

              if (asset) {
                const tokens = EthereumMoney.fromBalance(
                  balance,
                  asset,
                ).getTokens();

                options.push({
                  asset,
                  label: asset.symbol,
                  logo: Some(asset.iconUrl),
                  tokens,
                  usd: coingeckoUsdPrices
                    .getReady()
                    .mapSome(usdPrices =>
                      Maybe.fromNullable(usdPrices[asset.address]),
                    )
                    .flatMapSome(usdPrice =>
                      usdPrice.mapSome(usdPriceValue =>
                        tokens.mul(usdPriceValue),
                      ),
                    ),
                  value: `ethereum-${asset.address}`,
                });
              }

              break;
            }
            case 'waves': {
              const asset = x.wavesAssetsDetails[balance.assetId];

              if (asset) {
                const tokens = WavesMoney.fromBalance(
                  balance,
                  asset,
                ).getTokens();

                const dataServiceAsset = dataServiceAssets
                  .getReady()
                  .flatMapSome(dataServiceAssetsValue =>
                    Maybe.fromNullable(dataServiceAssetsValue[asset.assetId]),
                  );

                options.push({
                  asset,
                  label: dataServiceAsset
                    .flatMapSome(dataServiceAssetValue =>
                      Maybe.fromNullable(dataServiceAssetValue.ticker),
                    )
                    .match({
                      None: () => asset.name,
                      Some: ticker => ticker,
                    }),
                  logo: dataServiceAsset.flatMapSome(dataServiceAssetValue =>
                    Maybe.fromNullable(dataServiceAssetValue.url),
                  ),
                  tokens,
                  usd: dataServiceUsdPrices
                    .getReady()
                    .flatMapSome(usdPrices =>
                      Maybe.fromNullable(usdPrices[asset.assetId]),
                    )
                    .mapSome(usdPrice => tokens.mul(usdPrice)),
                  value: `waves-${asset.assetId}`,
                });
              }
              break;
            }
          }
        }

        return options.sort((a, b) => {
          const aUsd = a.usd.getOr(() => new BigNumber(0));
          const bUsd = b.usd.getOr(() => new BigNumber(0));

          return Number(bUsd.sub(aUsd));
        });
      }),
    [
      coingeckoUsdPrices,
      dataServiceAssets,
      dataServiceUsdPrices,
      ethereumBalances,
      ethereumTokens,
      wavesAssetsDetails,
      wavesBalances,
    ],
  );

  const [formErrorState, setFormErrorState] = useState<Maybe<string>>(None);

  const [values, setValues] = useState<SendAssetsFormValues>({
    amount: None,
    asset: None,
    feeOptionId: None,
    recipient: None,
  });

  const asset = useMemo(
    () =>
      Maybe.all([assetSelectOptions.getReady(), values.asset])
        .flatMapSome(([assetSelectOptionsValue, assetValue]) =>
          Maybe.findMap(assetSelectOptionsValue, option =>
            option.value === assetValue ? Some(option) : None,
          ),
        )
        .mapSome(option => option.asset),
    [assetSelectOptions, values.asset],
  );

  const [smartAccountFee, setSmartAccountFee] = useState<
    AsyncValue<WavesMoney>
  >(AsyncValue.Pending);
  useEffect(() => {
    wavesAddress.mapSome(address =>
      fetch(new URL(`/addresses/scriptInfo/${address}`, nodeUrl))
        .then(handleResponse(type({ extraFee: number() })))
        .then(
          ({ extraFee }) => new BigNumber(extraFee),
          () => new BigNumber(0),
        )
        .then(coins => {
          setSmartAccountFee(
            AsyncValue.Ready(WavesMoney.fromCoins(coins, WAVES_ASSET)),
          );
        }),
    );
  }, [nodeUrl, wavesAddress]);

  const smartTokenFee = useMemo(() => {
    return asset
      .mapSome(x =>
        x.blockchain === 'waves' && x.scripted
          ? WavesMoney.fromCoins(SMART_ASSET_EXTRA_FEE, WAVES_ASSET)
          : WavesMoney.fromCoins('0', WAVES_ASSET),
      )
      .getOr(() => WavesMoney.fromCoins('0', WAVES_ASSET));
  }, [asset]);

  const wavesFee = smartAccountFee.mapReady(smartAccountFeeValue =>
    WavesMoney.fromCoins(
      new BigNumber(DEFAULT_FEE_IN_WAVES)
        .add(smartAccountFeeValue.getCoins())
        .add(smartTokenFee.getCoins()),
      WAVES_ASSET,
    ),
  );

  const assetBalance = useMemo(
    () =>
      asset
        .mapSome((assetValue): AsyncValue<Maybe<BlockchainMoney>> => {
          switch (assetValue.blockchain) {
            case 'ethereum':
              return ethereumBalances
                .mapReady(balances =>
                  Maybe.fromNullable(
                    balances.byAssetAddress[assetValue.address],
                  ),
                )
                .mapReady(balance =>
                  balance.mapSome(balanceValue =>
                    EthereumMoney.fromBalance(balanceValue, assetValue),
                  ),
                );
            case 'waves':
              return wavesBalances
                .mapReady(balances =>
                  Maybe.fromNullable(balances.byAssetId[assetValue.assetId]),
                )
                .mapReady(balance =>
                  balance.mapSome(balanceValue =>
                    WavesMoney.fromBalance(balanceValue, assetValue),
                  ),
                );
          }
        })
        .flatMapSome(x => x.getReady())
        .flatMapSome(x => x),
    [asset, ethereumBalances, wavesBalances],
  );

  const assetBalanceUsd = assetBalance
    .mapSome(balance => {
      switch (balance.blockchain) {
        case 'ethereum':
          return coingeckoUsdPrices
            .mapReady(usdPrices =>
              Maybe.fromNullable(usdPrices[balance.asset.address]),
            )
            .mapReady(usdPrice =>
              usdPrice.mapSome(usdPriceValue =>
                balance.getTokens().mul(usdPriceValue),
              ),
            );
        case 'waves':
          return dataServiceUsdPrices
            .mapReady(usdPrices =>
              Maybe.fromNullable(usdPrices[balance.asset.assetId]),
            )
            .mapReady(usdPrice =>
              usdPrice.mapSome(usdPriceValue =>
                balance.getTokens().mul(usdPriceValue),
              ),
            );
      }
    })
    .flatMapSome(x => x.getReady())
    .flatMapSome(x => x);

  const blockchain = useMemo(() => asset.mapSome(x => x.blockchain), [asset]);

  const parsedRecipientAddress = useMemo(
    () =>
      Maybe.all([blockchain, values.recipient]).mapSome(
        ([blockchainValue, recipientValue]): Result<
          BlockchainAddress,
          EthereumAddressError | WavesAddressError | 'invalid-chain-id'
        > =>
          blockchainValue === 'ethereum'
            ? EthereumAddress.fromString(recipientValue)
            : WavesAddress.fromString(recipientValue).flatMapOk(address =>
                address.chainId === chainId
                  ? Ok(address)
                  : Err('invalid-chain-id'),
              ),
      ),
    [blockchain, chainId, values.recipient],
  );

  const [resolvedRecipientAddress, setResolvedRecipientAddress] =
    useState<Maybe<BlockchainAddress>>(None);
  const [recipientError, setRecipientError] = useState<Maybe<string>>(None);
  const [isResolvingRecipientAddress, setIsResolvingRecipientAddress] =
    useState(false);
  useEffect(() => {
    setResolvedRecipientAddress(None);
    setRecipientError(None);

    return Maybe.all([
      blockchain,
      parsedRecipientAddress,
      values.recipient,
    ]).match({
      None: () => undefined,
      Some: ([blockchainValue, parseResult, recipientValue]) =>
        parseResult.match({
          Ok: () => undefined,
          Err: () => {
            if (blockchainValue === 'waves' && network === Network.Mainnet) {
              const timeout = setTimeout(() => {
                setIsResolvingRecipientAddress(true);

                void resolveDomainAddress(recipientValue, network).then(
                  address => {
                    address
                      .mapErr(err => {
                        switch (err) {
                          case 'no-such-domain':
                            return t(i18n)`Invalid address or domain`;
                          case 'failed-to-resolve-a-domain':
                            return t(i18n)`Failed to resolve domain`;
                        }
                      })
                      .match({
                        Ok: value => {
                          setResolvedRecipientAddress(Some(value));
                        },
                        Err: err => {
                          setRecipientError(Some(err));
                        },
                      });

                    setIsResolvingRecipientAddress(false);
                  },
                );
              }, 500);

              return () => {
                clearTimeout(timeout);
              };
            }

            setRecipientError(Some(t(i18n)`Invalid address`));
            return;
          },
        }),
    });
  }, [blockchain, i18n, network, parsedRecipientAddress, values.recipient]);

  const recipientAddress = useMemo(
    () =>
      resolvedRecipientAddress.or(() =>
        parsedRecipientAddress.flatMapSome(x => x.getOk()),
      ),
    [parsedRecipientAddress, resolvedRecipientAddress],
  );

  const amountMoney = useMemo(
    () =>
      Maybe.all([values.amount, asset]).flatMapSome(
        ([amountValue, assetValue]) => {
          const amount = new BigNumber(amountValue.value);

          return amount.isFinite()
            ? Some(
                assetValue.blockchain === 'ethereum'
                  ? EthereumMoney.fromTokens(amountValue.value, assetValue)
                  : WavesMoney.fromTokens(amountValue.value, assetValue),
              )
            : None;
        },
      ),
    [asset, values.amount],
  );

  const amountInUsd = amountMoney.flatMapSome(amountMoneyValue =>
    amountMoneyValue.blockchain === 'waves'
      ? dataServiceUsdPrices
          .mapReady(usdPrices =>
            Maybe.fromNullable(usdPrices[amountMoneyValue.asset.assetId]),
          )
          .getReady()
          .flatMapSome(x =>
            x.mapSome(usdPerToken =>
              amountMoneyValue.getTokens().mul(usdPerToken),
            ),
          )
      : None,
  );

  const [ethereumFeeEstimates, setEthereumFeeEstimates] = useState<
    AsyncValue<Result<EthereumFeeEstimates, string>>
  >(AsyncValue.Pending);

  interface EthereumFeeOption extends SendAssetsEthereumFeeOption {
    fee: EthereumFee;
  }

  interface WavesFeeOption extends SendAssetsWavesFeeOption {
    fee: WavesFee;
  }

  const feeData = blockchain.mapSome<
    AsyncValue<
      Result<
        | {
            blockchain: 'ethereum';
            options: EthereumFeeOption[];
            selectedOption: EthereumFeeOption;
          }
        | {
            blockchain: 'waves';
            options: WavesFeeOption[];
            selectedOption: WavesFeeOption;
          },
        string
      >
    >
  >(blockchainValue => {
    switch (blockchainValue) {
      case 'ethereum':
        return ethereumFeeEstimates.mapReady(ethereumFeeEstimatesResult =>
          ethereumFeeEstimatesResult.mapOk(estimates => {
            function createFeeOption(
              kind: keyof EthereumFeeEstimates,
            ): EthereumFeeOption {
              const fee = estimates[kind];
              const money = fee.toMoney();

              return {
                blockchain: 'ethereum',
                fee,
                kind,
                id: `ethereum-${kind}`,
                usdAmount: coingeckoUsdPrices
                  .getReady()
                  .flatMapSome(usdPrices =>
                    Maybe.fromNullable(usdPrices[money.asset.address]),
                  )
                  .match({
                    None: () => undefined,
                    Some: usdPrice => money.getTokens().mul(usdPrice),
                  }),
                weiAmount: money.getCoins(),
              } as const;
            }

            const optionSafe = createFeeOption('safe');
            const optionNormal = createFeeOption('normal');
            const optionFast = createFeeOption('fast');

            const options = [optionSafe, optionNormal, optionFast];

            return {
              blockchain: 'ethereum',
              options,
              selectedOption: values.feeOptionId
                .flatMapSome(feeOptionId =>
                  Maybe.fromNullable(options.find(o => o.id === feeOptionId)),
                )
                .getOr(() => optionNormal),
            };
          }),
        );
      case 'waves':
        return AsyncValue.allRecord({
          wavesAssetsDetails,
          wavesBalances,
          wavesFee,
        }).mapReady(x => {
          const dataServiceAssetsValue = dataServiceAssets
            .getReady()
            .getOr(() => ({}));

          const usdPrices = dataServiceUsdPrices.getReady().getOr(() => ({}));

          const feeOptions = getWavesFeeOptions({
            assets: x.wavesAssetsDetails,
            balances: x.wavesBalances.list,
            initialFee: x.wavesFee,
            txType: TRANSACTION_TYPE.TRANSFER,
            usdPrices,
          });

          const feeOptionsWithFallback =
            feeOptions.length === 0 ? [new WavesFee(x.wavesFee)] : feeOptions;

          const options = feeOptionsWithFallback
            .map((fee): WavesFeeOption => {
              const money = fee.toMoney();

              return {
                blockchain: 'waves',
                fee,
                assetId: money.asset.assetId,
                assetLabel:
                  dataServiceAssetsValue[money.asset.assetId]?.ticker ??
                  money.asset.name,
                assetTokens: money.getTokens(),
                id: `waves-${money.asset.assetId}`,
                logo: dataServiceAssetsValue[money.asset.assetId]?.url,
                usdAmount: Maybe.fromNullable(
                  usdPrices[money.asset.assetId],
                ).match({
                  None: () => undefined,
                  Some: usdPrice => money.getTokens().mul(usdPrice),
                }),
              };
            })
            .sort((a, b) => {
              const aUsd = a.usdAmount ?? new BigNumber(0);
              const bUsd = b.usdAmount ?? new BigNumber(0);

              return Number(aUsd.sub(bUsd));
            });

          return Result.Ok({
            blockchain: 'waves',
            options,
            selectedOption: values.feeOptionId
              .flatMapSome(feeOptionId =>
                Maybe.fromNullable(options.find(o => o.id === feeOptionId)),
              )
              .getOr(() => options[0]),
          });
        });
    }
  });

  const selectedFee = feeData.flatMapSome(feeDataAsync =>
    feeDataAsync
      .getReady()
      .flatMapSome(x => x.getOk())
      .mapSome(result => result.selectedOption.fee),
  );

  const maxAmountInAssetTokens = Maybe.all([
    assetBalance,
    selectedFee.mapSome(x => x.toMoney()),
  ]).mapSome(([assetBalanceValue, feeMoneyValue]) => {
    const balanceMinusFee =
      (assetBalanceValue.asset.blockchain === 'waves' &&
        feeMoneyValue.asset.blockchain === 'waves' &&
        assetBalanceValue.asset.assetId === feeMoneyValue.asset.assetId) ||
      (assetBalanceValue.asset.blockchain === 'ethereum' &&
        feeMoneyValue.asset.blockchain === 'ethereum' &&
        assetBalanceValue.asset.address === feeMoneyValue.asset.address)
        ? assetBalanceValue.getTokens().sub(feeMoneyValue.getTokens())
        : assetBalanceValue.getTokens();

    return BigNumber.max(0, balanceMinusFee);
  });

  const amount = Maybe.all([amountMoney, maxAmountInAssetTokens]).mapSome(
    ([amountMoneyValue, maxAmountInAssetValue]): Result<
      BlockchainMoney,
      string
    > => {
      const tokens = amountMoneyValue.getTokens();

      return tokens.lte(0)
        ? Err(t(i18n)`Amount must be greater than 0`)
        : tokens.gt(maxAmountInAssetValue)
        ? Err(t(i18n)`Insufficient funds`)
        : Ok(
            amountMoneyValue.asset.blockchain === 'ethereum'
              ? EthereumMoney.fromTokens(tokens, amountMoneyValue.asset)
              : WavesMoney.fromTokens(tokens, amountMoneyValue.asset),
          );
    },
  );

  useEffect(() => {
    return Maybe.all([
      asset,
      ethereumAddress,
      ethereumTokens
        .mapReady(tokens => Maybe.fromNullable(tokens[ETHEREUM_ASSET.address]))
        .getReady()
        .flatMapSome(x => x),
      recipientAddress,
      signatureRequest.sendTransactionRequest,
    ]).match({
      None: () => undefined,
      Some: ([
        assetValue,
        ethereumAddressValue,
        feeAssetValue,
        recipientAddressValue,
      ]) => {
        if (
          recipientAddressValue.blockchain !== 'ethereum' ||
          assetValue.blockchain !== 'ethereum'
        )
          return;

        const updateEthereumFeeOptions = () => {
          void EthereumFee.getEstimates({
            amount: EthereumMoney.fromCoins(
              Maybe.all([assetBalance, amountMoney]).match({
                Some: ([assetBalanceValue, amountValue]) =>
                  BigNumber.min(
                    assetBalanceValue.getCoins(),
                    amountValue.getCoins(),
                  ),
                None: () => new BigNumber(0),
              }),
              assetValue,
            ),
            dataServiceUrl,
            ethereumNodeUrls,
            feeAsset: feeAssetValue,
            network,
            recipient: recipientAddressValue,
            sender: ethereumAddressValue,
          }).then(estimates => {
            setEthereumFeeEstimates(
              AsyncValue.Ready(
                estimates.mapErr(() => t(i18n)`Failed to estimate gas`),
              ),
            );
          });
        };

        let interval: ReturnType<typeof setInterval> | undefined;

        const timeout = setTimeout(() => {
          updateEthereumFeeOptions();
          interval = setInterval(updateEthereumFeeOptions, 5000);
        }, 500);

        return () => {
          clearTimeout(timeout);
          clearInterval(interval);
        };
      },
    });
  }, [
    amountMoney,
    asset,
    assetBalance,
    dataServiceUrl,
    ethereumAddress,
    ethereumNodeUrls,
    ethereumTokens,
    i18n,
    network,
    recipientAddress,
    signatureRequest.sendTransactionRequest,
  ]);

  const errors: SendAssetsFormErrors = {
    amount: amount.flatMapSome(x => x.getErr()),
    recipient: recipientError,
  };

  const feeAssetBalance = selectedFee.flatMapSome(
    (selectedFeeValue): Maybe<BlockchainMoney> => {
      const money = selectedFeeValue.toMoney();
      const feeAsset = money.asset;

      switch (feeAsset.blockchain) {
        case 'ethereum':
          return ethereumBalances
            .getReady()
            .flatMapSome(ethereumBalancesValue =>
              Maybe.fromNullable(
                ethereumBalancesValue.byAssetAddress[feeAsset.address],
              ),
            )
            .mapSome(balance => EthereumMoney.fromBalance(balance, feeAsset));
        case 'waves':
          return wavesBalances
            .getReady()
            .flatMapSome(wavesBalancesValue =>
              Maybe.fromNullable(
                wavesBalancesValue.byAssetId[feeAsset.assetId],
              ),
            )
            .mapSome(balance => WavesMoney.fromBalance(balance, feeAsset));
      }
    },
  );

  const selectedFeeOrError = Maybe.all([feeAssetBalance, selectedFee]).mapSome(
    ([balance, selectedFeeValue]): Result<BlockchainFee, string> =>
      balance.getCoins().gte(selectedFeeValue.toMoney().getCoins())
        ? Ok(selectedFeeValue)
        : Err(t(i18n)`Insufficient funds to pay the network fee`),
  );

  const transactionRequest = Maybe.all([
    amount.flatMapSome(x => x.getOk()),
    selectedFeeOrError.flatMapSome(x => x.getOk()),
    recipientAddress,
  ]).flatMapSome(([amountValue, selectedFeeValue, recipientAddressValue]) =>
    BlockchainTransactionRequest.createTransfer({
      account,
      amount: amountValue,
      fee: selectedFeeValue,
      network,
      recipient: recipientAddressValue,
    }),
  );

  const formError = selectedFeeOrError
    .flatMapSome(x => x.getErr())
    .or(() => formErrorState);

  const [broadcastResult, setBroadcastResult] = useState<
    Maybe<{
      request: BlockchainTransactionRequest;
      sendResult: Result<BlockchainTransactionSendResponse, UnexpectedError>;
      timestamp: number;
    }>
  >(None);

  const onTransactionStatusChange = useCallback(
    (status: TransactionStatus) => {
      broadcastResult.mapSome(({ request, sendResult, timestamp }) => {
        sendResult.match({
          Err: () => undefined,
          Ok: sendResultValue => {
            switch (status.type) {
              case 'applicationFailed':
              case 'transactionLost':
              case 'applicationSuccess':
                track({
                  eventType: 'transaction status received',
                  afterSeconds: (performance.now() - timestamp) / 1_000,
                  transactionId:
                    sendResultValue.blockchain === 'waves'
                      ? sendResultValue.id
                      : sendResultValue.hash,
                  transactionStatus: status.type,
                  walletType: request.account.type,
                });
                break;
            }
          },
        });
      });
    },
    [broadcastResult],
  );

  return (
    <Container>
      <h1 className={styles.sendPageTitle}>
        <Trans>Send Assets</Trans>
      </h1>

      <>
        <SendAssets
          amountInUsd={amountInUsd}
          assetBalanceTokens={assetBalance.mapSome(x => x.getTokens())}
          assetBalanceUsd={assetBalanceUsd}
          assetDecimals={asset.mapSome(x => x.decimals)}
          assetSelectOptions={assetSelectOptions}
          errors={errors}
          feeFieldData={feeData}
          formError={formError}
          isResolvingRecipientAddress={isResolvingRecipientAddress}
          maxAmountInAssetTokens={maxAmountInAssetTokens}
          resolvedRecipientAddress={resolvedRecipientAddress}
          values={values}
          onChange={(fieldName, newValue) => {
            setValues(prevValues =>
              prevValues[fieldName] === newValue
                ? prevValues
                : {
                    ...prevValues,
                    [fieldName]: newValue,
                  },
            );
          }}
          onSubmit={Maybe.all([
            transactionRequest,
            signatureRequest.sendTransactionRequest,
          ]).mapSome(
            ([transactionRequestValue, sendTransferTransaction]) =>
              async () => {
                setFormErrorState(None);

                const transferResult = await sendTransferTransaction(
                  transactionRequestValue,
                );

                transferResult.match({
                  Err: err => {
                    setBroadcastResult(
                      Some({
                        request: transactionRequestValue,
                        sendResult: Err(err),
                        timestamp: performance.now(),
                      }),
                    );
                  },
                  Ok: sendResponse => {
                    sendResponse.match({
                      None: () => undefined,
                      Some: sendResponseValue => {
                        track({
                          eventType: 'broadcast transaction',
                          type: 'send assets',
                          blockchain: sendResponseValue.blockchain,
                          transactionId:
                            sendResponseValue.blockchain === 'waves'
                              ? sendResponseValue.id
                              : sendResponseValue.hash,
                          walletType: transactionRequestValue.account.type,
                        });

                        setValues({
                          amount: None,
                          asset: None,
                          feeOptionId: None,
                          recipient: None,
                        });

                        setBroadcastResult(
                          Some({
                            request: transactionRequestValue,
                            sendResult: Ok(sendResponseValue),
                            timestamp: performance.now(),
                          }),
                        );
                      },
                    });
                  },
                });
              },
          )}
        />

        {broadcastResult
          .mapSome(({ sendResult, request, timestamp }) => (
            <WatchTransactionStatus
              network={request.input.network}
              sendResult={sendResult}
              onStatusChange={onTransactionStatusChange}
            >
              {transactionStatus => (
                <SendStatusDialog
                  dataServiceAssets={dataServiceAssets}
                  dataServiceUsdPrices={dataServiceUsdPrices}
                  coingeckoUsdPrices={coingeckoUsdPrices}
                  isOpen
                  request={request}
                  sendResult={sendResult}
                  transactionStatus={transactionStatus}
                  onIsOpenChange={open => {
                    if (open) return;
                    setBroadcastResult(None);

                    if (transactionStatus.type !== 'pending') return;

                    track({
                      eventType: 'pending send status dialog closed',
                      afterSeconds: (performance.now() - timestamp) / 1_000,
                    });
                  }}
                />
              )}
            </WatchTransactionStatus>
          ))
          .toOptional()}
      </>
    </Container>
  );
}
