import { type Infer, object, string, type } from 'superstruct';

import { handleResponse } from '../_core/handleResponse';

export const GasOracelFeeData = type({
  FastGasPrice: string(),
  LastBlock: string(),
  ProposeGasPrice: string(),
  SafeGasPrice: string(),
  gasUsedRatio: string(),
  suggestBaseFee: string(),
});

export type GasOracleFeeData = Infer<typeof GasOracelFeeData>;

export function getGasOracleFeeData(
  dataServiceUrl: string,
): Promise<GasOracleFeeData> {
  return fetch(new URL('/api/v1/ethereum/gasoracle', dataServiceUrl))
    .then(handleResponse(object({ result: GasOracelFeeData })))
    .then(({ result }) => result);
}
