// extracted by mini-css-extract-plugin
export var accountAvatar = "ENiGayArEnCExtNutvJx";
export var accountAvatarBadge_ethereum = "ELILN84C1nSuBTdrpi29";
export var accountAvatarBadge_waves = "jaH6ewHUZnF1H8e3ewIk";
export var accountAvatarContainer = "SFbxd9lFB32u51ag8tNl";
export var accountItem = "YkTRn5W16jybmwngvjpW";
export var accountTitle = "x1Wh3zVz4pC2AmYs5CY_";
export var alertCancelBtn = "QeHejwkc4ixnRjsJOsuE";
export var alertPlate = "Mp0Xccmgb5fNk7gaqTIv";
export var alertText = "WzDHwD1ACZ8jLl1aInJT";
export var description = "PKAWDqE__EBJxhII3EAv";