import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import clsx from 'clsx';
import { createContext, useContext, useMemo } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { AsyncValue } from '../../_core/asyncValue';
import { formatUsdPrice } from '../../_core/formatUsdPrice';
import { Maybe, None, Some } from '../../_core/maybe';
import { EthereumMoney, WavesMoney } from '../../_core/money';
import { isNotNull } from '../../_core/predicates';
import { useAccounts } from '../../accounts/requireAccounts';
import { Account } from '../../accounts/types';
import { BlockchainSelect } from '../../blockchain/blockchainSelect';
import { isBlockchainEqual } from '../../blockchain/types';
import {
  type CoingeckoUsdPrices,
  useCoingeckoUsdPrices,
} from '../../cache/coingecko/usdPrices';
import { useDataServiceEthereumBalances } from '../../cache/dataService/ethereumBalances';
import { useDataServiceEthereumTokens } from '../../cache/dataService/ethereumTokenList';
import { useDataServiceLeasesInfo } from '../../cache/dataService/leasesInfo';
import {
  type DataServiceProduct,
  useDataServiceProducts,
} from '../../cache/dataService/products';
import {
  type DataServiceUsdPrices,
  useDataServiceUsdPrices,
} from '../../cache/dataService/usdPrices';
import {
  useWavesAssetsDetails,
  type WavesAsset,
} from '../../cache/wavesNode/assetsDetails';
import { useWavesBalances } from '../../cache/wavesNode/balances';
import { useWavesActiveLeases } from '../../cache/wavesNode/leases';
import { PageHeader } from '../../investments/pageHeader';
import {
  calculateProductTotalWorth,
  type Investment,
  mapDataServiceProductToInvestment,
  mapWavesLeasingToInvestment,
  mergeWavesLeasesByRecipient,
} from '../../investments/utils';
import { Container } from '../../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { useAppSelector } from '../../store/react';
import * as styles from './portfolio.module.css';

interface PortfolioPageContextType {
  assets: AsyncValue<Partial<Record<string, WavesAsset>>>;
  wavesMoneys: AsyncValue<WavesMoney[]>;
  ethereumMoneys: AsyncValue<EthereumMoney[]>;
  investments: AsyncValue<Investment[]>;
  wavesUsdPrices: AsyncValue<DataServiceUsdPrices>;
  ethereumUsdPrices: AsyncValue<CoingeckoUsdPrices>;
  walletWorth: AsyncValue<BigNumber>;
  investmentsWorth: AsyncValue<BigNumber>;
}

const PortfolioPageContext = createContext<PortfolioPageContextType>({
  assets: AsyncValue.Pending,
  wavesMoneys: AsyncValue.Pending,
  ethereumMoneys: AsyncValue.Pending,
  investments: AsyncValue.Pending,
  wavesUsdPrices: AsyncValue.Pending,
  ethereumUsdPrices: AsyncValue.Pending,
  walletWorth: AsyncValue.Pending,
  investmentsWorth: AsyncValue.Pending,
});

export function usePortfolioPageContext() {
  return useContext(PortfolioPageContext);
}

export function PortfolioPage() {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const blockchainFilter = useAppSelector(state => state.blockchainFilter);

  const [selectedAccountJSON] = useAccounts({ onlySelected: true });
  const selectedAccount = useMemo(
    () => Account.fromInMemoryJSON(selectedAccountJSON).assertOk(),
    [selectedAccountJSON],
  );

  const filteredPublicKeys = useMemo(() => {
    const keys = selectedAccount.getPublicKeys();

    if (blockchainFilter === 'all') {
      return keys;
    }

    return keys.filter(({ blockchain }) => blockchain === blockchainFilter);
  }, [blockchainFilter, selectedAccount]);

  const wavesAddresses = useMemo(
    () =>
      filteredPublicKeys.filter(isBlockchainEqual('waves')).map(publicKey =>
        publicKey
          .getAddress({
            chainId: WAVES_NETWORK_CONFIGS[network].chainId,
          })
          .toString(),
      ),
    [filteredPublicKeys, network],
  );

  const activeLeases = useWavesActiveLeases({
    addresses: wavesAddresses,
    only: 'outgoing',
  });

  const leasesInfo = useDataServiceLeasesInfo();

  const mergedLeases = useMemo(() => {
    return activeLeases.mapReady(activeLeasesValue => {
      const allActiveLeases = Object.values(activeLeasesValue)
        .flat()
        .filter(isNotNull);

      return mergeWavesLeasesByRecipient(allActiveLeases);
    });
  }, [activeLeases]);

  const wavesBalances = useWavesBalances({
    addresses: wavesAddresses,
  });

  const dataServiceProducts = useDataServiceProducts({
    addresses: wavesAddresses,
  });

  const assetIds = useMemo(() => {
    return AsyncValue.allRecord({
      wavesBalances,
      dataServiceProducts,
    })
      .mapReady(x => {
        const assetIdsSet = new Set<string>();

        for (const address of wavesAddresses) {
          const accountBalances = x.wavesBalances[address];
          const accountProducts = x.dataServiceProducts[address];

          if (accountBalances) {
            for (const balance of accountBalances.list) {
              if (balance) {
                assetIdsSet.add(balance.assetId);
              }
            }
          }

          if (accountProducts) {
            for (const product of accountProducts) {
              for (const amount of product.amounts) {
                assetIdsSet.add(amount.asset_id);
              }
            }
          }
        }

        return Array.from(assetIdsSet);
      })
      .getReady()
      .getOr(() => []);
  }, [wavesAddresses, dataServiceProducts, wavesBalances]);

  const wavesAssetsDetails = useWavesAssetsDetails({
    assetIds,
  });

  const wavesUsdPrices = useDataServiceUsdPrices({
    assetIds,
  });

  const mergedProducts = useMemo((): AsyncValue<DataServiceProduct[]> => {
    return AsyncValue.allRecord({
      dataServiceProducts,
      wavesAssetsDetails,
    }).mapReady(x => {
      const result: Record<string, DataServiceProduct> = {};

      for (const address of wavesAddresses) {
        const accountProducts = x.dataServiceProducts[address];
        if (!accountProducts) continue;

        for (const product of accountProducts) {
          const productAmounts: WavesMoney[] = [];

          for (const amount of product.amounts) {
            const assetDetails = x.wavesAssetsDetails[amount.asset_id];
            if (!assetDetails) continue;

            productAmounts.push(
              WavesMoney.fromCoins(amount.coins, assetDetails),
            );
          }

          if (result[product.product_id] == null) {
            result[product.product_id] = {
              ...product,
              amounts: productAmounts,
            };
          } else {
            const allAmounts = [
              ...result[product.product_id].amounts,
              ...productAmounts,
            ];

            const totalAmounts = Object.values(
              allAmounts.reduce<Record<string, WavesMoney>>(
                (amountsAcc, amount) => {
                  const { assetId } = amount.asset;

                  if (amountsAcc[assetId] == null) {
                    amountsAcc[assetId] = amount;
                  } else {
                    amountsAcc[assetId] = WavesMoney.fromTokens(
                      amountsAcc[assetId].getTokens().add(amount.getTokens()),
                      amount.asset,
                    );
                  }

                  return amountsAcc;
                },
                {},
              ),
            );

            result[product.product_id].amounts = totalAmounts;
          }
        }
      }

      return Object.values(result);
    });
  }, [wavesAddresses, dataServiceProducts, wavesAssetsDetails]);

  const investments = useMemo(() => {
    return AsyncValue.allRecord({
      mergedProducts,
      leasesInfo,
    }).mapReady(x => {
      return [
        ...x.mergedProducts.map(mapDataServiceProductToInvestment),
        ...mergedLeases
          .getReady()
          .getOr(() => [])
          .map(leasing => mapWavesLeasingToInvestment(leasing, x.leasesInfo)),
      ];
    });
  }, [leasesInfo, mergedProducts, mergedLeases]);

  const wavesMoneys = useMemo(() => {
    return AsyncValue.allRecord({
      wavesBalances,
      wavesAssetsDetails,
    }).mapReady(x => {
      const mergedBalancesByAssetIds: Record<string, WavesMoney> = {};

      for (const address of wavesAddresses) {
        const accountBalance = x.wavesBalances[address];
        if (!accountBalance) continue;

        for (const { assetId, balance } of accountBalance.list) {
          const asset = x.wavesAssetsDetails[assetId];
          if (!asset) continue;

          const balanceMoney = WavesMoney.fromCoins(balance, asset);

          if (!mergedBalancesByAssetIds[assetId]) {
            mergedBalancesByAssetIds[assetId] = balanceMoney;
          } else {
            mergedBalancesByAssetIds[assetId] = WavesMoney.fromCoins(
              mergedBalancesByAssetIds[assetId]
                .getCoins()
                .add(balanceMoney.getCoins()),
              asset,
            );
          }
        }
      }

      return Object.values(mergedBalancesByAssetIds);
    });
  }, [wavesAddresses, wavesAssetsDetails, wavesBalances]);

  const ethereumAddress = useMemo(() => {
    return Maybe.findMap(filteredPublicKeys, publicKey =>
      publicKey.blockchain === 'ethereum'
        ? Some(publicKey.getAddress().toString())
        : None,
    );
  }, [filteredPublicKeys]);

  const ethereumBalancesByAddress = useDataServiceEthereumBalances({
    addresses: useMemo(
      () =>
        ethereumAddress.match({
          Some: x => [x],
          None: () => [],
        }),
      [ethereumAddress],
    ),
  });

  const ethereumBalance = useMemo(() => {
    return ethereumBalancesByAddress.mapReady(
      ethereumBalancesByAddressValue => {
        const address = ethereumAddress.toOptional();

        return address
          ? ethereumBalancesByAddressValue[address]?.byAssetAddress ?? {}
          : {};
      },
    );
  }, [ethereumAddress, ethereumBalancesByAddress]);

  const ethereumAssetIds = useMemo(
    () => ethereumBalance.getReady().mapSome(x => Object.keys(x)),
    [ethereumBalance],
  );

  const ethereumUsdPrices = useCoingeckoUsdPrices({
    addresses: ethereumAssetIds.getOr(() => []),
  });

  const ethereumTokens = useDataServiceEthereumTokens();

  const ethereumMoneys = useMemo(
    () =>
      AsyncValue.allRecord({ ethereumTokens, ethereumBalance }).mapReady(x => {
        return Maybe.mapArray(
          Maybe.filterMap(Object.values(x.ethereumBalance), Maybe.fromNullable),
          balance =>
            Maybe.fromNullable(x.ethereumTokens[balance.address]).mapSome(
              assetValue =>
                EthereumMoney.fromCoins(balance.balance, assetValue),
            ),
        ).getOr(() => []);
      }),
    [ethereumBalance, ethereumTokens],
  );

  const ethereumWalletWorth = AsyncValue.allRecord({
    ethereumUsdPrices,
    ethereumMoneys,
  }).mapReady(x => {
    return x.ethereumMoneys.reduce((acc, ethereumMoney) => {
      const usdPrice = new BigNumber(
        x.ethereumUsdPrices[ethereumMoney.asset.address] ?? '0',
      );

      return acc.add(ethereumMoney.getTokens().mul(usdPrice));
    }, new BigNumber(0));
  });

  const wavesWalletWorth = AsyncValue.allRecord({
    wavesMoneys,
    wavesUsdPrices,
  }).mapReady(x =>
    x.wavesMoneys.reduce(
      (acc, balance) =>
        acc.add(
          balance
            .getTokens()
            .mul(x.wavesUsdPrices[balance.asset.assetId] ?? '0'),
        ),
      new BigNumber(0),
    ),
  );

  const walletWorth = useMemo(() => {
    return AsyncValue.allRecord({
      ethereumWalletWorth,
      wavesWalletWorth,
    }).mapReady(x => x.ethereumWalletWorth.add(x.wavesWalletWorth));
  }, [ethereumWalletWorth, wavesWalletWorth]);

  const investmentsWorth = AsyncValue.allRecord({
    mergedLeases,
    investments,
    wavesUsdPrices,
  }).mapReady(x =>
    x.investments.reduce(
      (acc, investment) =>
        acc.add(calculateProductTotalWorth(x.wavesUsdPrices, investment)),
      new BigNumber(0),
    ),
  );

  const contextValue = useMemo(
    (): PortfolioPageContextType => ({
      assets: wavesAssetsDetails,
      wavesMoneys,
      ethereumMoneys,
      investments,
      wavesUsdPrices,
      ethereumUsdPrices,
      walletWorth,
      investmentsWorth,
    }),
    [
      wavesAssetsDetails,
      wavesMoneys,
      ethereumMoneys,
      investments,
      wavesUsdPrices,
      ethereumUsdPrices,
      walletWorth,
      investmentsWorth,
    ],
  );

  return (
    <>
      <PageHeader
        className={styles.header}
        caption={<BlockchainSelect />}
        heading={AsyncValue.allRecord({
          investmentsWorth,
          walletWorth,
        }).mapReady(x => formatUsdPrice(x.walletWorth.add(x.investmentsWorth)))}
      >
        <ul className={styles.menu}>
          <TabLink end label={t(i18n)`Wallet`} to="" />
          <TabLink label={t(i18n)`Investments`} to="investments" />
          <TabLink label={t(i18n)`NFTs`} to="nfts" />
        </ul>
      </PageHeader>

      <Container className={styles.content}>
        <PortfolioPageContext.Provider value={contextValue}>
          <Outlet />
        </PortfolioPageContext.Provider>
      </Container>
    </>
  );
}

interface TabLinkProps {
  end?: boolean;
  label: string;
  to: string;
}

function TabLink({ end, label, to }: TabLinkProps) {
  return (
    <li>
      <NavLink
        className={({ isActive, isPending }) =>
          clsx(styles.menuItem, {
            [styles.menuItem_active]: isActive,
            [styles.menuItem_pending]: isPending,
          })
        }
        end={end}
        to={to}
      >
        {label}
      </NavLink>
    </li>
  );
}
