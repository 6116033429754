import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { pipe, subscribe } from 'wonka';

import { Button } from '../_core/button';
import type { MetaDescriptor } from '../_core/meta';
import type { AccumulatedRpcRequest } from '../_core/rpc';
import type { AppApi } from '../_core/rpcTypes';
import { Spinner } from '../_core/spinner';
import { isOldConnectedWavesAccount } from '../accounts/types';
import { useEntryContext } from '../entry';
import { useAppSelector } from '../store/react';
import * as styles from './requestWalletAccess.module.css';

export function requestWalletAccessMeta(): MetaDescriptor | void {
  const title = 'Request Wallet Access';

  return {
    title,
    'og:title': title,
  };
}

export function RequestWalletAccessPage() {
  const { client, embedContext } = useEntryContext();

  const vault = useAppSelector(state => state.vault);
  const accounts = vault.state === 'unlocked' ? vault.accounts : null;

  const [requestWalletAccessRequest, setRequestWalletAccessRequest] =
    useState<AccumulatedRpcRequest<AppApi, 'getPrivateAccountData'> | null>(
      null,
    );

  useEffect(() => {
    if (embedContext !== 'window.open') {
      const url = new URL(location.href);
      url.pathname = '/';
      url.search = '';
      url.hash = '';
      location.href = url.href;
      return;
    }

    if (!accounts || !client) return;

    const { unsubscribe } = pipe(
      client.accumulatedRequests.getPrivateAccountData,
      subscribe(request => {
        setRequestWalletAccessRequest(request);
      }),
    );

    return () => {
      unsubscribe();
    };
  }, [accounts, client, embedContext]);

  const oldConnectedWavesAccount = accounts?.filter(isOldConnectedWavesAccount);

  return (
    <div>
      {oldConnectedWavesAccount == null ||
      client == null ||
      requestWalletAccessRequest == null ? (
        <p className={styles.spinnerWrapper}>
          <Spinner size={32} />
        </p>
      ) : oldConnectedWavesAccount.length === 0 ? (
        <p className={styles.noAccountsMessage}>
          <Trans>You have no saved accounts</Trans>
        </p>
      ) : (
        <div>
          <ul className={styles.accountList}>
            {oldConnectedWavesAccount.map(account => (
              <li key={account.id}>
                <Button
                  text={account.name}
                  onClick={() => {
                    requestWalletAccessRequest.resolve(account);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
