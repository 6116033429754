import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { Fragment } from 'react';

import { AsyncValue } from '../_core/asyncValue';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Logo } from '../_core/logo';
import { isNotNull } from '../_core/predicates';
import { Skeleton } from '../_core/skeleton';
import { Table } from '../_core/table';
import { Tooltip } from '../_core/tooltip';
import type { DataServiceAssets } from '../cache/dataService/assets';
import type { DataServiceProtocol } from '../cache/dataService/protocols';
import type { DataServiceUsdPrices } from '../cache/dataService/usdPrices';
import * as styles from './investmentListDesktop.module.css';
import { ProductCell } from './productCell';
import {
  calculateProductTotalWorth,
  calculateProtocolTotalWorth,
  getProductTypeName,
  type Investment,
} from './utils';

interface Props {
  className?: string;
  dataServiceAssets: AsyncValue<DataServiceAssets>;
  investments: AsyncValue<Investment[]>;
  protocols: AsyncValue<DataServiceProtocol[]>;
  usdPrices: AsyncValue<DataServiceUsdPrices>;
}

export function InvestmentListDesktop({
  className,
  dataServiceAssets,
  investments,
  protocols,
  usdPrices,
}: Props) {
  const { i18n } = useLingui();

  const productsByProtocol = investments.mapReady(x =>
    x.reduce<Partial<Record<string, Record<string, Investment[]>>>>(
      (acc, product) => {
        const productsByType = (acc[product.protocol_id] ??= {});
        productsByType[product.type] ??= [];
        productsByType[product.type].push(product);

        return acc;
      },
      {},
    ),
  );

  return AsyncValue.allRecord({
    dataServiceAssets,
    investments,
    protocols,
    usdPrices,
    productsByProtocol,
  }).match({
    Pending: () => (
      <div className={className}>
        <div className={styles.vendorGroupHeaderSkeleton}>
          <Skeleton className={styles.vendorGroupNameSkeleton} />
          <Skeleton className={styles.vendorGroupTotalSkeleton} />
        </div>

        <div className={clsx(styles.card, styles.cardSkeleton)}>
          <Skeleton className={styles.productBadgelSkeleton} />
          <Skeleton className={styles.productSkeleton} />
        </div>
      </div>
    ),
    Ready: x => (
      <div className={className}>
        <div className={styles.vendorGroupList}>
          {x.protocols
            .map(protocol => {
              const productsByType = x.productsByProtocol[protocol.id];

              if (productsByType == null) {
                return null;
              }

              return {
                protocol,
                productsByType,
                protocolTotal: calculateProtocolTotalWorth(
                  x.usdPrices,
                  Object.values(productsByType).flat(),
                ),
              };
            })
            .filter(isNotNull)
            .sort((a, b) => {
              if (a.protocolTotal.gt(b.protocolTotal)) {
                return -1;
              }

              if (a.protocolTotal.lt(b.protocolTotal)) {
                return 1;
              }

              return a.protocol.name.localeCompare(b.protocol.name);
            })
            .map(({ protocol, productsByType, protocolTotal }) => (
              <Fragment key={protocol.id}>
                <section>
                  <div className={styles.vendorAnchor} id={protocol.id} />

                  <header className={styles.vendorGroupHeader}>
                    <img
                      className={styles.vendorGroupAvatar}
                      src={protocol.icon_url}
                      alt=""
                    />

                    <h2 className={styles.vendorGroupName}>{protocol.name}</h2>

                    <div className={styles.vendorGroupTotal}>
                      {formatUsdPrice(protocolTotal)}
                    </div>
                  </header>

                  <div className={styles.vendorProductGroups}>
                    {Object.entries(productsByType)
                      .map(
                        ([protocolType, productsOfType]) =>
                          [
                            protocolType,
                            productsOfType,
                            calculateProtocolTotalWorth(
                              x.usdPrices,
                              productsOfType,
                            ),
                          ] as const,
                      )
                      .sort(([, , a], [, , b]) => {
                        if (a.gt(b)) {
                          return -1;
                        }

                        if (a.lt(b)) {
                          return 1;
                        }

                        return 0;
                      })
                      .map(([productType, productsOfType]) => (
                        <article className={styles.card} key={productType}>
                          <h2 className={styles.productBadge}>
                            {getProductTypeName(i18n, productType)}
                          </h2>

                          <Table
                            columns={[
                              {
                                label: t(i18n)`Product`,
                                render: ({ name, icon_url, product_id }) => (
                                  <ProductCell
                                    name={name}
                                    logo={icon_url}
                                    id={product_id.toString()}
                                  />
                                ),
                              },
                              {
                                label: t(i18n)`Balance`,
                                align: 'right',
                                width: '30%',
                                render: ({ amounts }) =>
                                  amounts
                                    .sort((a, b) => {
                                      const aTokens = a.getTokens();
                                      const bTokens = b.getTokens();

                                      if (aTokens.gt(bTokens)) {
                                        return -1;
                                      }

                                      if (aTokens.lt(bTokens)) {
                                        return 1;
                                      }

                                      return a.asset.name.localeCompare(
                                        b.asset.name,
                                      );
                                    })
                                    .map(amount => {
                                      const asset =
                                        x.dataServiceAssets[
                                          amount.asset.assetId
                                        ];

                                      const assetDisplayName =
                                        asset?.ticker || amount.asset.name;

                                      return (
                                        <div
                                          key={amount.asset.assetId}
                                          className={styles.balanceAsset}
                                        >
                                          <span>
                                            {amount.getTokens().toFormat()}
                                          </span>
                                          <Tooltip
                                            content={assetDisplayName}
                                            placement="top"
                                          >
                                            {renderProps => (
                                              <div {...renderProps}>
                                                <Logo
                                                  name={assetDisplayName}
                                                  size={12}
                                                  logo={asset?.url}
                                                  objectId={
                                                    amount.asset.assetId
                                                  }
                                                />
                                              </div>
                                            )}
                                          </Tooltip>
                                        </div>
                                      );
                                    }),
                              },
                              {
                                label: t(i18n)`Total`,
                                align: 'right',
                                width: '30%',
                                render: product =>
                                  formatUsdPrice(product.totalWorth),
                              },
                            ]}
                            data={productsOfType
                              .map(product => ({
                                ...product,
                                totalWorth: calculateProductTotalWorth(
                                  x.usdPrices,
                                  product,
                                ),
                              }))
                              .sort((a, b) => {
                                if (a.totalWorth.gt(b.totalWorth)) {
                                  return -1;
                                }

                                if (a.totalWorth.lt(b.totalWorth)) {
                                  return 1;
                                }

                                return a.name.localeCompare(b.name);
                              })}
                          />
                        </article>
                      ))}
                  </div>
                </section>
              </Fragment>
            ))}
        </div>
      </div>
    ),
  });
}
