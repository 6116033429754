import { nanoid } from 'nanoid';
import invariant from 'tiny-invariant';

import { Err, Ok, type Result } from '../_core/result';
import { Account } from '../accounts/types';
import { createWalletProvider } from '../accounts/utils';
import {
  validateAccount,
  type ValidateAccountError,
  validateName,
  type ValidateNameError,
} from '../accounts/validators';
import { track } from '../analytics';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import type { AppThunkAction } from '../store/types';
import { decryptVault, encryptVault } from './storage';
import type { InMemoryAccountJSON, PersistedAccountJSON } from './types';

type VaultState = 'unknown' | 'empty' | 'locked' | 'unlocked';

export type Vault<T extends VaultState = VaultState> = Extract<
  | { state: 'unknown' }
  | { state: 'empty'; password: string | null }
  | { state: 'locked'; contents: number[] }
  | {
      state: 'unlocked';
      accounts: InMemoryAccountJSON[];
      password: string;
      selectedAccountId: string;
    },
  { state: T }
>;

export default function vaultReducer(
  state: Vault = { state: 'unknown' },
  action: { type: 'SET_VAULT'; payload: Vault },
): Vault {
  switch (action.type) {
    case 'SET_VAULT':
      return action.payload;
    default:
      return state;
  }
}

async function getUnlockedVaultState(
  vaultContents: Uint8Array,
  password: string,
): Promise<Vault<'unlocked'>> {
  const { accounts, selectedAccountId } = await decryptVault(
    vaultContents,
    password,
  );

  return {
    state: 'unlocked',
    accounts: await Promise.all(accounts.map(createInMemoryAccountJSON)),
    password,
    selectedAccountId,
  };
}

export function readVault(): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { useMainDb }) => {
    const vaultContents = await useMainDb(db =>
      db.transaction('vault').objectStore('vault').get(0),
    );

    const prevVault = getState().vault;

    dispatch({
      type: 'SET_VAULT',
      payload: vaultContents
        ? prevVault.state === 'unlocked'
          ? await getUnlockedVaultState(vaultContents, prevVault.password)
          : { state: 'locked', contents: Array.from(vaultContents) }
        : { state: 'empty', password: null },
    });
  };
}

export function setPasswordForEmptyVault(
  password: string,
): AppThunkAction<void> {
  return (dispatch, getState) => {
    const { vault } = getState();
    invariant(vault.state === 'empty');

    dispatch({
      type: 'SET_VAULT',
      payload: { state: 'empty', password },
    });
  };
}

function persistVault(
  vault: Vault<'empty' | 'unlocked'>,
): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { useMainDb }) => {
    const prevVault = getState().vault;

    dispatch({
      type: 'SET_VAULT',
      payload: vault,
    });

    try {
      if (vault.state === 'unlocked') {
        const contents = await encryptVault(
          {
            accounts: vault.accounts,
            selectedAccountId: vault.selectedAccountId,
          },
          vault.password,
        );

        await useMainDb(db =>
          db
            .transaction('vault', 'readwrite', { durability: 'strict' })
            .objectStore('vault')
            .put(contents, 0),
        );
      } else if (vault.state === 'empty') {
        await useMainDb(db =>
          db
            .transaction('vault', 'readwrite', { durability: 'strict' })
            .objectStore('vault')
            .delete(0),
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw new Error(`Unexpected vault state: ${(vault as any).state}`);
      }

      localStorage.setItem('updateVault', nanoid());
    } catch (err) {
      dispatch({
        type: 'SET_VAULT',
        payload: prevVault,
      });

      throw err;
    }
  };
}

async function createInMemoryAccountJSON(
  json: PersistedAccountJSON,
): Promise<InMemoryAccountJSON> {
  const account = await Account.fromPersistedJSON(json);

  return account.assertOk().toInMemoryJSON();
}

export function unlockVault(password: string): AppThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const { vault } = getState();
    invariant(vault.state === 'locked');

    dispatch({
      type: 'SET_VAULT',
      payload: await getUnlockedVaultState(
        new Uint8Array(vault.contents),
        password,
      ),
    });
  };
}

export function lockVault(): AppThunkAction<Promise<void>> {
  return async (dispatch, getState, { useMainDb }) => {
    const { vault } = getState();
    invariant(vault.state === 'unlocked');

    await useMainDb(async db => {
      const contents = await db.get('vault', 0);

      invariant(contents);

      dispatch({
        type: 'SET_VAULT',
        payload: {
          state: 'locked',
          contents: Array.from(contents),
        },
      });
    });
  };
}

export type AddAccountInput = { name: string } & (
  | { type: 'ethereum'; seed: string }
  | { type: 'keeper-extension'; publicKey: string }
  | { type: 'keeper-mobile'; publicKey: string }
  | { type: 'multichain'; seed: string }
  | { type: 'waves'; seed: string }
);

export function addAccount(
  input: AddAccountInput,
): AppThunkAction<
  Promise<Result<null, { type: 'password-is-needed' } | ValidateAccountError>>
> {
  return async (dispatch, getState) => {
    const { vault } = getState();
    invariant(vault.state === 'empty' || vault.state === 'unlocked');

    const id = nanoid();

    let newVault: Vault;

    const inputAccount = await createInMemoryAccountJSON({
      ...input,
      name: input.name.trim(),
      id,
    });
    const account = Account.fromInMemoryJSON(inputAccount).assertOk();

    if (vault.state === 'empty') {
      if (vault.password == null) {
        return Err({ type: 'password-is-needed' });
      }

      const result = validateAccount({ account, accounts: [] });
      if (result.isErr) return result;

      newVault = {
        state: 'unlocked',
        accounts: [inputAccount],
        password: vault.password,
        selectedAccountId: id,
      };
    } else {
      const result = validateAccount({
        account,
        accounts: vault.accounts.map(a =>
          Account.fromInMemoryJSON(a).assertOk(),
        ),
      });
      if (result.isErr) return result;

      newVault = {
        ...vault,
        accounts: vault.accounts.concat(inputAccount),
        selectedAccountId: id,
      };
    }

    await dispatch(persistVault(newVault));

    track({ eventType: 'add account', walletType: input.type });

    return Ok(null);
  };
}

export function deleteAccount(id: string): AppThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const { network, vault } = getState();
    invariant(vault.state === 'unlocked');

    const account = vault.accounts.find(x => x.id === id);
    if (account == null) return;

    // only one mobile account is supported right now, so we need to
    // disconnect the last walletconnect session when deleting the account.
    if (account.type === 'keeper-mobile') {
      const [{ Signer }, provider] = await Promise.all([
        import(
          /* webpackChunkName: "signer" */
          '@waves/signer'
        ),
        createWalletProvider(origin, account.type),
      ]);

      invariant(provider);

      const signer = new Signer({
        NODE_URL: WAVES_NETWORK_CONFIGS[network].nodeUrl,
      });
      void signer.setProvider(provider);

      await signer.logout();
    }

    const updatedAccounts = vault.accounts.filter(x => x.id !== id);

    const nextVault: Vault<'empty' | 'unlocked'> =
      updatedAccounts.length === 0
        ? { state: 'empty', password: null }
        : { ...vault, accounts: updatedAccounts };

    if (nextVault.state === 'unlocked') {
      const selectedAccount =
        nextVault.accounts.find(x => x.id === vault.selectedAccountId) ??
        nextVault.accounts[0];

      nextVault.selectedAccountId = selectedAccount.id;
    }

    await dispatch(persistVault(nextVault));

    track({
      eventType: 'delete account',
      walletType: account.type,
    });
  };
}

export function deleteAllAccounts(): AppThunkAction<Promise<void>> {
  return async dispatch => {
    await dispatch(persistVault({ state: 'empty', password: null }));
  };
}

export function renameAccount(
  id: string,
  name: string,
): AppThunkAction<Promise<Result<null, ValidateNameError>>> {
  return async (dispatch, getState) => {
    const trimmedName = name.trim();
    const { vault } = getState();
    invariant(vault.state === 'unlocked');

    const account = vault.accounts.find(x => x.id === id);
    if (account == null) throw new Error('Account not found');

    const inputAccount = { ...account, name: trimmedName };

    const result = validateName({
      name: trimmedName,
      accounts: vault.accounts.map(json =>
        Account.fromInMemoryJSON(json).assertOk(),
      ),
    });
    if (result.isErr) {
      return result;
    }

    await dispatch(
      persistVault({
        ...vault,
        accounts: vault.accounts.map(prevAccount => {
          if (prevAccount.id === account.id) return inputAccount;
          return prevAccount;
        }),
      }),
    );

    return Ok(null);
  };
}
export function setSelectedAccount(
  selectedAccountId: string,
): AppThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    const { vault } = getState();
    invariant(vault.state === 'unlocked');

    await dispatch(persistVault({ ...vault, selectedAccountId }));
  };
}
