import clsx from 'clsx';

import type { BlockchainAddress } from '../../blockchain/address';
import * as styles from './addressList.module.css';

interface Props {
  items: BlockchainAddress[];
}

export function AddressList({ items }: Props) {
  return (
    <ul className={styles.root}>
      {items.map((address, index) => (
        <li
          key={index}
          className={clsx(
            styles.item,
            {
              ethereum: styles.item_ethereum,
              waves: styles.item_waves,
            }[address.blockchain],
          )}
        >
          <span className={styles.text}>{address.toString()}</span>
        </li>
      ))}
    </ul>
  );
}
