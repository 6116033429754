import BigNumber from '@waves/bignumber';
import { JsonRpcProvider, type TransactionRequest } from 'ethers';

import { EthereumMoney } from '../../_core/money';
import { Result } from '../../_core/result';
import { getGasOracleFeeData } from '../../apis/dataService';
import { ETHEREUM_ASSET } from '../../ethereum/constants';
import { Network } from '../../network/types';
import type { EthereumAddress } from '../address';
import { encodeErc20TransferData } from '../transaction/ethereumTransaction';
import type { Asset } from '../types';

export interface EthereumFeeEstimates {
  safe: EthereumFee;
  normal: EthereumFee;
  fast: EthereumFee;
}

export class EthereumFee {
  readonly asset;
  readonly gasLimit;
  readonly maxFeePerGas;
  readonly maxPriorityFeePerGas;

  private constructor({
    asset,
    gasLimit,
    maxFeePerGas,
    maxPriorityFeePerGas,
  }: {
    asset: Asset<'ethereum'>;
    gasLimit: BigNumber;
    maxFeePerGas: BigNumber;
    maxPriorityFeePerGas: BigNumber;
  }) {
    this.asset = asset;
    this.gasLimit = gasLimit;
    this.maxFeePerGas = maxFeePerGas;
    this.maxPriorityFeePerGas = maxPriorityFeePerGas;
  }

  static async getEstimates({
    amount,
    dataServiceUrl,
    ethereumNodeUrls,
    feeAsset,
    network,
    recipient,
    sender,
  }: {
    amount: EthereumMoney;
    dataServiceUrl: string;
    ethereumNodeUrls: {
      [Network.Mainnet]: string;
      [Network.Testnet]: string;
    };
    feeAsset: Asset<'ethereum'>;
    network: Network;
    recipient: EthereumAddress;
    sender: EthereumAddress;
  }): Promise<Result<EthereumFeeEstimates, unknown>> {
    const request: TransactionRequest =
      amount.asset.address === ETHEREUM_ASSET.address
        ? {
            from: sender.toString(),
            to: recipient.toString(),
          }
        : {
            from: sender.toString(),
            to: amount.asset.address,
            data: encodeErc20TransferData({ amount, recipient }),
          };

    const jsonRpcProvider = new JsonRpcProvider(ethereumNodeUrls[network]);

    if (network === Network.Mainnet) {
      const [feeData, gasLimitResult] = await Promise.all([
        Result.fromPromise(getGasOracleFeeData(dataServiceUrl)),
        Result.fromPromise(jsonRpcProvider.estimateGas(request)),
      ]);

      return Result.all([feeData, gasLimitResult]).mapOk(
        ([feeDataValue, gasLimitResultValue]) => {
          const gasLimit = new BigNumber(String(gasLimitResultValue));

          const baseFeePerGas = new BigNumber(
            String(feeDataValue.suggestBaseFee),
          ).mul(new BigNumber(10).pow(9));

          return {
            safe: new EthereumFee({
              asset: feeAsset,
              gasLimit,
              maxFeePerGas: new BigNumber(
                String(feeDataValue.SafeGasPrice),
              ).mul(new BigNumber(10).pow(9)),
              maxPriorityFeePerGas: new BigNumber(
                String(feeDataValue.SafeGasPrice),
              )
                .mul(new BigNumber(10).pow(9))
                .sub(baseFeePerGas),
            }),
            normal: new EthereumFee({
              asset: feeAsset,
              gasLimit,
              maxFeePerGas: new BigNumber(
                String(feeDataValue.ProposeGasPrice),
              ).mul(new BigNumber(10).pow(9)),
              maxPriorityFeePerGas: new BigNumber(
                String(feeDataValue.ProposeGasPrice),
              )
                .mul(new BigNumber(10).pow(9))
                .sub(baseFeePerGas),
            }),
            fast: new EthereumFee({
              asset: feeAsset,
              gasLimit,
              maxFeePerGas: new BigNumber(
                String(feeDataValue.FastGasPrice),
              ).mul(new BigNumber(10).pow(9)),
              maxPriorityFeePerGas: new BigNumber(
                String(feeDataValue.FastGasPrice),
              )
                .mul(new BigNumber(10).pow(9))
                .sub(baseFeePerGas),
            }),
          };
        },
      );
    } else {
      const [feeData, gasLimitResult] = await Promise.all([
        Result.fromPromise(jsonRpcProvider.getFeeData()),
        Result.fromPromise(jsonRpcProvider.estimateGas(request)),
      ]);

      return Result.all([feeData, gasLimitResult]).mapOk(
        ([feeDataValue, gasLimitValue]) => ({
          safe: new EthereumFee({
            asset: feeAsset,
            gasLimit: new BigNumber(String(gasLimitValue)),
            maxFeePerGas: new BigNumber(String(feeDataValue.maxFeePerGas)),
            maxPriorityFeePerGas: new BigNumber(
              String(feeDataValue.maxPriorityFeePerGas),
            ),
          }),
          normal: new EthereumFee({
            asset: feeAsset,
            gasLimit: new BigNumber(String(gasLimitValue)),
            maxFeePerGas: new BigNumber(String(feeDataValue.maxFeePerGas)),
            maxPriorityFeePerGas: new BigNumber(
              String(feeDataValue.maxPriorityFeePerGas),
            ),
          }),
          fast: new EthereumFee({
            asset: feeAsset,
            gasLimit: new BigNumber(String(gasLimitValue)),
            maxFeePerGas: new BigNumber(String(feeDataValue.maxFeePerGas)),
            maxPriorityFeePerGas: new BigNumber(
              String(feeDataValue.maxPriorityFeePerGas),
            ),
          }),
        }),
      );
    }
  }

  get blockchain() {
    return 'ethereum' as const;
  }

  toMoney() {
    return EthereumMoney.fromCoins(
      new BigNumber(String(this.gasLimit)).mul(String(this.maxFeePerGas)),
      this.asset,
    );
  }
}
