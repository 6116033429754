import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { type AsyncValue } from '../_core/asyncValue';
import { Skeleton } from '../_core/skeleton';
import { ChevronRightIcon } from '../icons/chevronRight';
import * as styles from './widget.module.css';

export function DashboardWidget({
  children,
  heading,
  headerLink,
  className,
}: {
  children: React.ReactNode;
  heading: string;
  headerLink?: { label?: AsyncValue<string>; to: string };
  className?: string;
}) {
  return (
    <section className={clsx(className, styles.root)}>
      <header className={styles.header}>
        <h2 className={styles.heading}>{heading}</h2>

        {headerLink && (
          <Link className={styles.headerLink} to={headerLink.to}>
            {headerLink.label?.match({
              Pending: () => <Skeleton className={styles.headerLinkSkeleton} />,
              Ready: x => <span>{x}</span>,
            })}

            <ChevronRightIcon className={styles.headerLinkChevron} />
          </Link>
        )}
      </header>

      {children}
    </section>
  );
}

export function DashboardWidgetEmptyMessage({
  heading,
  description,
}: {
  heading: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <div className={styles.emptyContent}>
      <h2 className={styles.emptyContentHeading}>{heading}</h2>
      <p className={styles.emptyContentDescription}>{description}</p>
    </div>
  );
}
