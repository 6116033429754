import { type Maybe, None, Some } from '../_core/maybe';
import type { BlockchainMoney } from '../_core/money';
import type { EthereumAccount } from '../accounts/models/ethereumAccount';
import type { KeeperExtensionAccount } from '../accounts/models/keeperExtensionAccount';
import type { KeeperMobileAccount } from '../accounts/models/keeperMobileAccount';
import type { MultichainAccount } from '../accounts/models/multichainAccount';
import type { WavesAccount } from '../accounts/models/wavesAccount';
import type { Account, AccountType } from '../accounts/types';
import type { Network } from '../network/types';
import type { BlockchainAddress } from './address';
import type { EthereumFee } from './fee/ethereumFee';
import type { WavesFee } from './fee/wavesFee';
import type { EthereumTransactionInput } from './transaction/ethereumTransaction';
import type { WavesTransactionInput } from './transaction/wavesTransaction';

export type Blockchain = 'ethereum' | 'waves';

export type BlockchainFee = EthereumFee | WavesFee;

export type BlockchainAddressJSON<T extends Blockchain = Blockchain> = Extract<
  | { blockchain: 'ethereum'; address: number[] }
  | { blockchain: 'waves'; address: number[] },
  { blockchain: T }
>;

export type Asset<T extends Blockchain = Blockchain> = Extract<
  | {
      blockchain: 'ethereum';
      address: string;
      decimals: number;
      iconUrl: string;
      name: string;
      symbol: string;
    }
  | {
      blockchain: 'waves';
      assetId: string;
      decimals: number;
      description: string;
      issueHeight: number;
      issueTimestamp: number;
      issuer: string;
      minSponsoredAssetFee: string | null;
      name: string;
      originTransactionId: string;
      quantity: string;
      reissuable: boolean;
      scripted: boolean;
    },
  { blockchain: T }
>;

export type Balance<T extends Blockchain = Blockchain> = Extract<
  | {
      blockchain: 'ethereum';
      address: string;
      balance: string;
    }
  | {
      blockchain: 'waves';
      assetId: string;
      minSponsoredAssetFee: string | null;
      balance: string;
      sponsorBalance: string | null;
    },
  { blockchain: T }
>;

export type BlockchainPublicKeyJSON<T extends Blockchain = Blockchain> =
  Extract<
    | { blockchain: 'ethereum'; publicKey: number[] }
    | { blockchain: 'waves'; publicKey: number[] },
    { blockchain: T }
  >;

export function isBlockchainEqual<T extends Blockchain>(blockchain: T) {
  return <U extends { blockchain: Blockchain }>(
    t: U,
  ): t is Extract<U, { blockchain: T }> => t.blockchain === blockchain;
}

export type BlockchainTransactionInput =
  | EthereumTransactionInput
  | WavesTransactionInput;

export const BlockchainTransactionInput = {
  create: (input: {
    amount: BlockchainMoney;
    fee: BlockchainFee;
    network: Network;
    recipient: BlockchainAddress;
  }): Maybe<BlockchainTransactionInput> => {
    if (
      input.amount.blockchain === 'ethereum' &&
      input.fee.blockchain === 'ethereum' &&
      input.recipient.blockchain === 'ethereum'
    ) {
      return Some({
        blockchain: 'ethereum',
        amount: input.amount,
        fee: input.fee,
        network: input.network,
        recipient: input.recipient,
      });
    }

    if (
      input.amount.blockchain === 'waves' &&
      input.fee.blockchain === 'waves' &&
      input.recipient.blockchain === 'waves'
    ) {
      return Some({
        blockchain: 'waves',
        type: 'transfer',
        amount: input.amount,
        fee: input.fee,
        network: input.network,
        recipient: input.recipient,
      });
    }

    return None;
  },
};

export type BlockchainTransactionRequest<T extends AccountType = AccountType> =
  Extract<
    | {
        accountType: 'ethereum';
        account: EthereumAccount;
        input: EthereumTransactionInput;
      }
    | {
        accountType: 'keeper-extension';
        account: KeeperExtensionAccount;
        input: WavesTransactionInput;
      }
    | {
        accountType: 'keeper-mobile';
        account: KeeperMobileAccount;
        input: WavesTransactionInput;
      }
    | {
        accountType: 'multichain';
        account: MultichainAccount;
        input: BlockchainTransactionInput;
      }
    | {
        accountType: 'waves';
        account: WavesAccount;
        input: WavesTransactionInput;
      },
    { accountType: T }
  >;

export const BlockchainTransactionRequest = {
  createTransfer: ({
    account,
    amount,
    fee,
    network,
    recipient,
  }: {
    account: Account;
    amount: BlockchainMoney;
    fee: BlockchainFee;
    network: Network;
    recipient: BlockchainAddress;
  }) => {
    return BlockchainTransactionInput.create({
      amount,
      fee,
      network,
      recipient,
    }).flatMapSome((input): Maybe<BlockchainTransactionRequest> => {
      switch (account.type) {
        case 'ethereum':
          return input.blockchain === 'ethereum'
            ? Some({ accountType: 'ethereum', account, input })
            : None;
        case 'keeper-extension':
          return input.blockchain === 'waves'
            ? Some({ accountType: account.type, account, input })
            : None;
        case 'keeper-mobile':
          return input.blockchain === 'waves'
            ? Some({ accountType: account.type, account, input })
            : None;
        case 'multichain':
          return Some({ accountType: account.type, account, input });
        case 'waves':
          return input.blockchain === 'waves'
            ? Some({ accountType: account.type, account, input })
            : None;
      }
    });
  },
};

export type BlockchainTransactionSendResponse<
  T extends Blockchain = Blockchain,
> = Extract<
  | { blockchain: 'ethereum'; hash: string }
  | { blockchain: 'waves'; id: string },
  { blockchain: T }
>;
