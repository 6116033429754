import { combineReducers } from 'redux';

import blockchainFilter from '../blockchain/redux';
import cache from '../cache/redux';
import network from '../network/redux';
import vault from '../vault/redux';

export const reducer = combineReducers({
  cache,
  network,
  vault,
  blockchainFilter,
});
