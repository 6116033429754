import {
  base16Decode,
  base16Encode,
  base58Decode,
  base58Encode,
  verifyAddress,
} from '@keeper-wallet/waves-crypto';
import { getAddress, isAddress } from 'ethers';

import { Err, Ok, Result } from '../_core/result';
import type { BlockchainAddressJSON } from './types';

export type BlockchainAddress = EthereumAddress | WavesAddress;

export const BlockchainAddress = {
  fromJSON(input: BlockchainAddressJSON) {
    switch (input.blockchain) {
      case 'ethereum':
        return EthereumAddress.fromJSON(input);
      case 'waves':
        return WavesAddress.fromJSON(input);
    }
  },
};

type Base16DecodingError = 'invalid-base-16';
type EthereumAddressValidationError = 'invalid-ethereum-address';

export type EthereumAddressError =
  | Base16DecodingError
  | EthereumAddressValidationError;

export class EthereumAddress {
  #bytes: Uint8Array;

  private constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  static fromBytes(
    bytes: Uint8Array,
  ): Result<EthereumAddress, EthereumAddressValidationError> {
    return isAddress(base16Encode(bytes))
      ? Ok(new EthereumAddress(bytes))
      : Err('invalid-ethereum-address');
  }

  static fromJSON(
    input: BlockchainAddressJSON<'ethereum'>,
  ): Result<EthereumAddress, never> {
    return Ok(new EthereumAddress(new Uint8Array(input.address)));
  }

  static fromString(
    input: string,
  ): Result<EthereumAddress, EthereumAddressError> {
    return Result.try(() => base16Decode(input)).match<
      Result<EthereumAddress, EthereumAddressError>
    >({
      Err: () => Err('invalid-base-16'),
      Ok: EthereumAddress.fromBytes,
    });
  }

  get blockchain() {
    return 'ethereum' as const;
  }

  toJSON(): BlockchainAddressJSON<'ethereum'> {
    return {
      blockchain: this.blockchain,
      address: Array.from(this.#bytes),
    };
  }

  toString(): string {
    return getAddress(`0x${base16Encode(this.#bytes)}`);
  }
}

type WavesAddressValidationError = 'invalid-waves-address';
type Base58DecodingError = 'invalid-base-58';

export type WavesAddressError =
  | WavesAddressValidationError
  | Base58DecodingError;

export class WavesAddress {
  #bytes: Uint8Array;

  private constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  static fromBytes(
    bytes: Uint8Array,
  ): Result<WavesAddress, WavesAddressValidationError> {
    return verifyAddress(bytes)
      ? Ok(new WavesAddress(bytes))
      : Err('invalid-waves-address');
  }

  static fromJSON(
    input: BlockchainAddressJSON<'waves'>,
  ): Result<WavesAddress, never> {
    return Ok(new WavesAddress(new Uint8Array(input.address)));
  }

  static fromString(input: string) {
    return Result.try(() => base58Decode(input)).match<
      Result<WavesAddress, Base58DecodingError | WavesAddressValidationError>
    >({
      Err: () => Err('invalid-base-58'),
      Ok: WavesAddress.fromBytes,
    });
  }

  get blockchain() {
    return 'waves' as const;
  }

  get chainId() {
    return this.#bytes[1];
  }

  toJSON(): BlockchainAddressJSON<'waves'> {
    return {
      blockchain: this.blockchain,
      address: Array.from(this.#bytes),
    };
  }

  toString(): string {
    return base58Encode(this.#bytes);
  }
}
