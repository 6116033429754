import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import { useMemo } from 'react';

import { AsyncValue } from '../_core/asyncValue';
import { CoingeckoBadge } from '../_core/coingeckoBadge';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Maybe, None, Some } from '../_core/maybe';
import { EthereumMoney, WavesMoney } from '../_core/money';
import { isNotNull } from '../_core/predicates';
import { useAccounts } from '../accounts/requireAccounts';
import { Account } from '../accounts/types';
import { BlockchainSelect } from '../blockchain/blockchainSelect';
import { isBlockchainEqual } from '../blockchain/types';
import { useCoingeckoUsdPrices } from '../cache/coingecko/usdPrices';
import { useDataServiceAssets } from '../cache/dataService/assets';
import { useDataServiceEthereumBalances } from '../cache/dataService/ethereumBalances';
import { useDataServiceEthereumTokens } from '../cache/dataService/ethereumTokenList';
import { useDataServiceLeasesInfo } from '../cache/dataService/leasesInfo';
import { useDataServiceProducts } from '../cache/dataService/products';
import {
  type DataServiceProtocol,
  useDataServiceProtocols,
} from '../cache/dataService/protocols';
import { useDataServiceUsdPrices } from '../cache/dataService/usdPrices';
import {
  useWavesAssetsDetails,
  WAVES_ASSET,
  type WavesAsset,
} from '../cache/wavesNode/assetsDetails';
import { useWavesBalances } from '../cache/wavesNode/balances';
import { useWavesActiveLeases } from '../cache/wavesNode/leases';
import { useWavesNfts } from '../cache/wavesNode/nfts';
import { RecentAssets } from '../dashboard/recentAssets';
import {
  TopInvestments,
  type TopInvestmentsItem,
} from '../dashboard/topInvestments';
import { TopNfts } from '../dashboard/topNfts';
import { ETHEREUM_ASSET } from '../ethereum/constants';
import {
  InvestmentsOverview,
  type InvestmentsOverviewItem,
} from '../investments/investmentsOverview';
import { PageHeader } from '../investments/pageHeader';
import {
  type Investment,
  mapDataServiceProductToInvestment,
  mapWavesLeasingToInvestment,
  mergeWavesLeasesByRecipient,
} from '../investments/utils';
import { Container } from '../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import * as styles from './dashboard.module.css';

const TOP_NFT_COUNT = 4;

export function DashboardPage() {
  const network = useAppSelector(state => state.network);
  const blockchainFilter = useAppSelector(state => state.blockchainFilter);

  const dataServiceAssets = useDataServiceAssets();

  const [selectedAccountJSON] = useAccounts({ onlySelected: true });
  const selectedAccount = useMemo(
    () => Account.fromInMemoryJSON(selectedAccountJSON).assertOk(),
    [selectedAccountJSON],
  );

  const filteredPublicKeys = useMemo(() => {
    const keys = selectedAccount.getPublicKeys();

    if (blockchainFilter === 'all') {
      return keys;
    }

    return keys.filter(({ blockchain }) => blockchain === blockchainFilter);
  }, [blockchainFilter, selectedAccount]);

  const wavesAddresses = useMemo(
    () =>
      filteredPublicKeys.filter(isBlockchainEqual('waves')).map(publicKey =>
        publicKey
          .getAddress({
            chainId: WAVES_NETWORK_CONFIGS[network].chainId,
          })
          .toString(),
      ),
    [network, filteredPublicKeys],
  );

  const wavesBalances = useWavesBalances({
    addresses: wavesAddresses,
  });

  const dataServiceProducts = useDataServiceProducts({
    addresses: wavesAddresses,
  });

  const activeLeases = useWavesActiveLeases({
    addresses: wavesAddresses,
    only: 'outgoing',
  });

  const leasesInfo = useDataServiceLeasesInfo();

  const assetIds = useMemo(() => {
    return AsyncValue.allRecord({
      wavesBalances,
      dataServiceProducts,
    })
      .mapReady(x => {
        const assetIdsSet = new Set<string>();

        for (const address of wavesAddresses) {
          const accountBalances = x.wavesBalances[address];
          const accountProducts = x.dataServiceProducts[address];

          if (accountBalances) {
            for (const balance of accountBalances.list) {
              if (balance) {
                assetIdsSet.add(balance.assetId);
              }
            }
          }

          if (accountProducts) {
            for (const product of accountProducts) {
              for (const amount of product.amounts) {
                assetIdsSet.add(amount.asset_id);
              }
            }
          }
        }

        return Array.from(assetIdsSet);
      })
      .getReady()
      .getOr(() => []);
  }, [wavesAddresses, dataServiceProducts, wavesBalances]);

  const wavesAssetsDetails = useWavesAssetsDetails({
    assetIds,
  });

  const wavesUsdPrices = useDataServiceUsdPrices({
    assetIds,
  });

  const wavesMoneys = useMemo(() => {
    return AsyncValue.allRecord({
      wavesBalances,
      wavesAssetsDetails,
    }).mapReady(x => {
      const mergedBalancesByAssetIds: Record<string, WavesMoney> = {};

      for (const address of wavesAddresses) {
        const accountBalance = x.wavesBalances[address];
        if (!accountBalance) continue;

        for (const { assetId, balance } of accountBalance.list) {
          const asset = x.wavesAssetsDetails[assetId];
          if (!asset) continue;

          const balanceMoney = WavesMoney.fromCoins(balance, asset);

          if (!mergedBalancesByAssetIds[assetId]) {
            mergedBalancesByAssetIds[assetId] = balanceMoney;
          } else {
            mergedBalancesByAssetIds[assetId] = WavesMoney.fromCoins(
              mergedBalancesByAssetIds[assetId]
                .getCoins()
                .add(balanceMoney.getCoins()),
              asset,
            );
          }
        }
      }

      return Object.values(mergedBalancesByAssetIds);
    });
  }, [wavesAddresses, wavesAssetsDetails, wavesBalances]);

  const ethereumAddress = useMemo(() => {
    return Maybe.findMap(filteredPublicKeys, publicKey =>
      publicKey.blockchain === 'ethereum'
        ? Some(publicKey.getAddress().toString())
        : None,
    );
  }, [filteredPublicKeys]);

  const ethereumBalancesByAddress = useDataServiceEthereumBalances({
    addresses: useMemo(
      () =>
        ethereumAddress.match({
          Some: x => [x],
          None: () => [],
        }),
      [ethereumAddress],
    ),
  });

  const ethereumBalance = useMemo(() => {
    return ethereumBalancesByAddress.mapReady(
      ethereumBalancesByAddressValue => {
        const address = ethereumAddress.toOptional();

        return address
          ? ethereumBalancesByAddressValue[address]?.byAssetAddress ?? {}
          : {};
      },
    );
  }, [ethereumAddress, ethereumBalancesByAddress]);

  const ethereumAssetIds = useMemo(
    () => ethereumBalance.getReady().mapSome(x => Object.keys(x)),
    [ethereumBalance],
  );

  const ethereumUsdPrices = useCoingeckoUsdPrices({
    addresses: ethereumAssetIds.getOr(() => []),
  });

  const ethereumTokens = useDataServiceEthereumTokens();

  const ethereumMoneys = useMemo(
    () =>
      AsyncValue.allRecord({ ethereumTokens, ethereumBalance }).mapReady(x => {
        return Maybe.mapArray(
          Maybe.filterMap(Object.values(x.ethereumBalance), Maybe.fromNullable),
          balance =>
            Maybe.fromNullable(x.ethereumTokens[balance.address]).mapSome(
              assetValue =>
                EthereumMoney.fromCoins(balance.balance, assetValue),
            ),
        ).getOr(() => []);
      }),
    [ethereumBalance, ethereumTokens],
  );

  const ethereumWalletWorth = AsyncValue.allRecord({
    ethereumUsdPrices,
    ethereumMoneys,
  }).mapReady(x => {
    return x.ethereumMoneys.reduce((acc, ethereumMoney) => {
      const usdPrice = new BigNumber(
        x.ethereumUsdPrices[ethereumMoney.asset.address] ?? '0',
      );

      return acc.add(ethereumMoney.getTokens().mul(usdPrice));
    }, new BigNumber(0));
  });

  const wavesWalletWorth = AsyncValue.allRecord({
    wavesMoneys,
    wavesUsdPrices,
  }).mapReady(x =>
    x.wavesMoneys.reduce(
      (acc, balance) =>
        acc.add(
          balance
            .getTokens()
            .mul(x.wavesUsdPrices[balance.asset.assetId] ?? '0'),
        ),
      new BigNumber(0),
    ),
  );

  const walletWorth = useMemo(() => {
    return AsyncValue.allRecord({
      ethereumWalletWorth,
      wavesWalletWorth,
    }).mapReady(x => x.ethereumWalletWorth.add(x.wavesWalletWorth));
  }, [ethereumWalletWorth, wavesWalletWorth]);

  const ethereumAssetItems = AsyncValue.allRecord({
    ethereumUsdPrices,
    ethereumMoneys,
  }).mapReady(x => {
    return x.ethereumMoneys.map(ethereumMoney => {
      const { asset } = ethereumMoney;

      const usdPrice = new BigNumber(x.ethereumUsdPrices[asset.address] ?? '0');

      const hasPrice = usdPrice.gt(0);

      return {
        blockchain: asset.blockchain,
        id: asset.address,
        ticker: asset.symbol,
        logo: asset.iconUrl,
        price: hasPrice ? usdPrice : undefined,
        available: ethereumMoney.getTokens(),
        worth: hasPrice ? ethereumMoney.getTokens().mul(usdPrice) : undefined,
      };
    });
  });

  const wavesAssetItems = AsyncValue.allRecord({
    wavesMoneys,
    dataServiceAssets,
    wavesUsdPrices,
  }).mapReady(x =>
    x.wavesMoneys.map(balance => {
      const usdPrice = new BigNumber(
        x.wavesUsdPrices[balance.asset.assetId] ?? '0',
      );

      const hasPrice = usdPrice.gt(0);

      const dataServiceAsset = x.dataServiceAssets[balance.asset.assetId];

      return {
        blockchain: balance.asset.blockchain,
        id: balance.asset.assetId,
        ticker: dataServiceAsset?.ticker || balance.asset.name,
        logo: dataServiceAsset?.url,
        price: hasPrice ? usdPrice : undefined,
        priceChange: undefined,
        available: balance.getTokens(),
        worth: hasPrice ? balance.getTokens().mul(usdPrice) : undefined,
      };
    }),
  );

  const assetBalances = useMemo(() => {
    return AsyncValue.allRecord({
      ethereumAssetItems,
      wavesAssetItems,
    }).mapReady(x =>
      [...x.ethereumAssetItems, ...x.wavesAssetItems].sort((a, b) => {
        const aWorth = new BigNumber(a.worth ?? 0);
        const bWorth = new BigNumber(b.worth ?? 0);

        if (a.id === WAVES_ASSET.assetId && b.id === ETHEREUM_ASSET.address) {
          return 1;
        }

        if ([ETHEREUM_ASSET.address, WAVES_ASSET.assetId].includes(a.id)) {
          return -1;
        }

        if ([ETHEREUM_ASSET.address, WAVES_ASSET.assetId].includes(b.id)) {
          return 1;
        }

        if (aWorth.gt(bWorth)) {
          return -1;
        }

        if (aWorth.lt(bWorth)) {
          return 1;
        }

        return a.ticker.localeCompare(b.ticker);
      }),
    );
  }, [ethereumAssetItems, wavesAssetItems]);

  const dataServiceProtocols = useDataServiceProtocols();

  const investmentsByAddress = useMemo(() => {
    return AsyncValue.allRecord({
      dataServiceProducts,
      wavesAssetsDetails,
      leasesInfo,
    }).mapReady(x => {
      const investments: Record<string, Investment[]> = {};

      for (const address of wavesAddresses) {
        const products = x.dataServiceProducts[address];
        const leases = activeLeases
          .getReady()
          .mapSome(activeLeasesValue => activeLeasesValue[address])
          .getOr(() => []);

        investments[address] = [
          ...(products ?? []).map(product =>
            mapDataServiceProductToInvestment({
              ...product,
              amounts: product.amounts
                .map(amount => {
                  const asset = x.wavesAssetsDetails[amount.asset_id];
                  if (!asset) return;

                  return WavesMoney.fromCoins(amount.coins, asset);
                })
                .filter(isNotNull),
            }),
          ),
          ...mergeWavesLeasesByRecipient(leases ?? []).map(leasing =>
            mapWavesLeasingToInvestment(leasing, x.leasesInfo),
          ),
        ];
      }

      return investments;
    });
  }, [
    wavesAddresses,
    dataServiceProducts,
    activeLeases,
    wavesAssetsDetails,
    leasesInfo,
  ]);

  const topProducts = useMemo(() => {
    return AsyncValue.allRecord({
      investmentsByAddress,
      dataServiceProtocols,
      wavesUsdPrices,
    }).mapReady(x => {
      const itemMap = new Map<string, TopInvestmentsItem>();

      for (const address of wavesAddresses) {
        const investments = x.investmentsByAddress[address];

        for (const investment of investments) {
          const protocol =
            x.dataServiceProtocols.byProtocolId[investment.protocol_id];
          if (!protocol) continue;

          let totalWorth = new BigNumber(0);

          for (const amount of investment.amounts) {
            totalWorth = totalWorth.add(
              amount
                .getTokens()
                .mul(x.wavesUsdPrices[amount.asset.assetId] ?? '0'),
            );
          }

          const existingItem = itemMap.get(investment.product_id);

          if (existingItem) {
            existingItem.totalWorth = existingItem.totalWorth.add(totalWorth);
          } else {
            itemMap.set(investment.product_id, {
              productName: investment.name,
              productLogo: investment.icon_url,
              productId: investment.product_id,
              productType: investment.type,
              protocolId: protocol.id,
              protocolName: protocol.name,
              protocolLogo: protocol.icon_url,
              totalWorth,
            });
          }
        }
      }

      return Array.from(itemMap.values()).sort((a, b) => {
        if (a.totalWorth.gt(b.totalWorth)) {
          return -1;
        }

        if (a.totalWorth.lt(b.totalWorth)) {
          return 1;
        }

        return a.productName.localeCompare(b.productName);
      });
    });
  }, [
    wavesAddresses,
    dataServiceProtocols,
    investmentsByAddress,
    wavesUsdPrices,
  ]);

  const protocolsInvestments = useMemo<
    AsyncValue<InvestmentsOverviewItem[]>
  >(() => {
    return AsyncValue.allRecord({
      investmentsByAddress,
      dataServiceProtocols,
      wavesAssetsDetails,
      wavesUsdPrices,
    }).mapReady(x => {
      const worthByProtocol = new Map<DataServiceProtocol, BigNumber>();

      for (const address of wavesAddresses) {
        const investments = x.investmentsByAddress[address];

        for (const product of investments) {
          const protocol =
            x.dataServiceProtocols.byProtocolId[product.protocol_id];
          if (!protocol) continue;

          let worth = worthByProtocol.get(protocol) ?? new BigNumber(0);

          for (const amount of product.amounts) {
            const assetDetails = x.wavesAssetsDetails[amount.asset.assetId];
            if (!assetDetails) continue;

            worth = worth.add(
              amount
                .getTokens()
                .mul(x.wavesUsdPrices[amount.asset.assetId] ?? '0'),
            );
          }

          worthByProtocol.set(protocol, worth);
        }
      }

      return Array.from(worthByProtocol.entries(), ([protocol, worth]) => ({
        id: protocol.id,
        name: protocol.name,
        logo: protocol.icon_url,
        value: worth,
        link: `/portfolio/investments#${protocol.id}`,
      }));
    });
  }, [
    wavesAddresses,
    dataServiceProtocols,
    investmentsByAddress,
    wavesAssetsDetails,
    wavesUsdPrices,
  ]);

  const netWorth = AsyncValue.allRecord({
    protocolsInvestments,
    activeLeases,
    walletWorth,
  }).mapReady(x =>
    x.walletWorth.add(
      x.protocolsInvestments.reduce(
        (acc, { value }) => acc.add(value),
        new BigNumber(0),
      ),
    ),
  );

  const wavesNfts = useWavesNfts({
    addresses: wavesAddresses,
    limit: TOP_NFT_COUNT,
  });

  const topNfts = useMemo(() => {
    return wavesNfts.mapReady(wavesNftsValue => {
      const nftArrays: WavesAsset[][] = [];
      for (const address of wavesAddresses) {
        const nfts = wavesNftsValue[address];
        if (!nfts) continue;

        nftArrays.push(nfts);
      }

      const maxLen = Math.max(...nftArrays.map(nftArray => nftArray.length));

      const result: WavesAsset[] = [];

      outer: for (let i = 0; i < maxLen; i++) {
        for (const nfts of nftArrays) {
          const nft = nfts.at(i);

          if (nft) {
            result.push(nft);

            if (result.length === TOP_NFT_COUNT) break outer;
          }
        }
      }

      return result;
    });
  }, [wavesAddresses, wavesNfts]);

  const asyncValues = AsyncValue.allRecord({
    topProducts,
    walletWorth,
    protocolsInvestments,
    topNfts,
    assetBalances,
  });

  return (
    <>
      <PageHeader
        caption={<BlockchainSelect />}
        heading={AsyncValue.allRecord({
          asyncValues,
          activeLeases,
          netWorth,
        }).mapReady(x => formatUsdPrice(x.netWorth))}
        className={styles.header}
      />

      <Container className={styles.content}>
        <ProtocolsOverviewSection
          protocolsInvestments={protocolsInvestments}
          walletWorth={walletWorth}
        />

        <div className={styles.widgetsContainer}>
          <RecentAssets
            className={styles.column1}
            items={asyncValues.mapReady(x => x.assetBalances)}
          />

          <TopInvestments
            className={styles.column2}
            items={asyncValues.mapReady(x => x.topProducts)}
          />

          {asyncValues.getReady().match({
            Some: x => x.topNfts.length > 0,
            None: () => true,
          }) && (
            <TopNfts
              className={styles.column1}
              items={asyncValues.mapReady(x =>
                x.topNfts.map(nft => ({
                  id: nft.assetId,
                  issuerLabel: nft.issuer,
                  label: nft.name,
                })),
              )}
            />
          )}
        </div>

        <CoingeckoBadge />
      </Container>
    </>
  );
}

function ProtocolsOverviewSection(asyncProps: {
  walletWorth: AsyncValue<BigNumber>;
  protocolsInvestments: AsyncValue<InvestmentsOverviewItem[]>;
}) {
  const { i18n } = useLingui();

  const asyncValue = AsyncValue.allRecord(asyncProps);

  if (
    asyncValue
      .getReady()
      .mapSome(value => value.protocolsInvestments.length === 0)
      .toOptional()
  ) {
    return null;
  }

  return (
    <section className={styles.investmentsOverview}>
      <h2 className={styles.investmentsOverviewHeading}>
        {t(i18n)`Protocols overview`}
      </h2>

      <InvestmentsOverview
        investments={asyncValue.mapReady(
          ({ walletWorth, protocolsInvestments }) => [
            {
              id: 'wallet',
              name: t(i18n)`Wallet`,
              value: walletWorth,
              logo: new URL('../investments/images/wallet.svg', import.meta.url)
                .pathname,
              pinned: true,
              link: `/portfolio`,
            },
            ...protocolsInvestments,
          ],
        )}
      />
    </section>
  );
}
