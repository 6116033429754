import * as serializeJavascript from 'serialize-javascript';

import { type AnalyticsConfig } from './analytics';
import { type SentryConfig } from './entry';
import { type Network } from './network/types';
import { type AppState } from './store/types';

type BrowserGlobals = Partial<{
  __ENTRY_CONTEXT__: {
    analyticsConfig: AnalyticsConfig | undefined;
    css: string[];
    dataServiceUrl: string;
    ethereumNodeUrls: {
      [Network.Mainnet]: string;
      [Network.Testnet]: string;
    };
    js: string[];
    nonce: string;
    sentryConfig: SentryConfig | undefined;
  };
  __INITIAL_REDUX_STATE__: AppState;
}>;

declare global {
  interface Window extends BrowserGlobals {}
}

interface Props<K extends keyof BrowserGlobals> {
  name: K;
  nonce: string;
  value: BrowserGlobals[K];
}

export function BrowserGlobal<K extends keyof BrowserGlobals>({
  name,
  nonce,
  value,
}: Props<K>) {
  return (
    <script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `${name}=${serializeJavascript(value, { isJSON: true })}`,
      }}
      nonce={nonce}
      suppressHydrationWarning
    />
  );
}
