import { createSvgIcon } from './_base';

export const LockIcon = createSvgIcon(
  '0 0 24 24',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 3.75C10.2051 3.75 8.75 5.20507 8.75 7V10.25H15.25V7C15.25 5.20507 13.7949 3.75 12 3.75ZM16.75 10.25V7C16.75 4.37665 14.6234 2.25 12 2.25C9.37665 2.25 7.25 4.37665 7.25 7V10.25H5C4.58579 10.25 4.25 10.5858 4.25 11V21C4.25 21.4142 4.58579 21.75 5 21.75H19C19.4142 21.75 19.75 21.4142 19.75 21V11C19.75 10.5858 19.4142 10.25 19 10.25H16.75ZM5.75 11.75V20.25H18.25V11.75H5.75Z"
    fill="#272732"
  />,
);
