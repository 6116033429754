import * as styles from './seedWordList.module.css';

interface Props {
  words: string[];
}

export function SeedWordList({ words }: Props) {
  return (
    <div className={styles.root}>
      {words.map((word, index) => (
        <div key={index} className={styles.item}>
          <span className={styles.itemIndex}>{index + 1}</span>
          <span>{word}</span>
        </div>
      ))}
    </div>
  );
}
