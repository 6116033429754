import clsx from 'clsx';
import { forwardRef } from 'react';
import invariant from 'tiny-invariant';

import { useFormControlContext } from './formControl';
import * as styles from './input.module.css';

interface InputShellProps {
  children: (childrenProps: {
    inputClassName: string;
    inputId: string | undefined;
    rootClassName: string;
    textAreaClassName: string;
  }) => React.ReactElement;
  hasError?: boolean;
}

export function InputShell({
  children,
  hasError: hasErrorProp,
}: InputShellProps) {
  const formControlContext = useFormControlContext();

  let hasError: boolean;
  if (formControlContext) {
    invariant(
      hasErrorProp === undefined,
      '`hasError` prop is passed to an `Input` component wrapped to `FormControl`.\n Use `error` prop on `FormControl` instead',
    );
    hasError = Boolean(formControlContext.error || formControlContext.hasError);
  } else {
    hasError = hasErrorProp === true;
  }

  return children({
    inputClassName: styles.input,
    inputId: formControlContext?.id,
    rootClassName: clsx(styles.root, { [styles.root_error]: hasError }),
    textAreaClassName: styles.textarea,
  });
}

type InputProps = Pick<
  React.ComponentProps<'input'>,
  | 'autoFocus'
  | 'className'
  | 'defaultValue'
  | 'disabled'
  | 'maxLength'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'placeholder'
  | 'value'
> & {
  autoComplete?: 'current-password' | 'new-password' | 'off';
  hasError?: boolean;
  type?: 'email' | 'number' | 'password';
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, hasError, ...props },
  ref,
) {
  return (
    <InputShell hasError={hasError}>
      {({ inputClassName, inputId, rootClassName }) => (
        <input
          ref={ref}
          {...props}
          className={clsx(className, rootClassName, inputClassName)}
          id={inputId}
        />
      )}
    </InputShell>
  );
});

type TextAreaProps = Pick<
  React.ComponentProps<'textarea'>,
  | 'autoFocus'
  | 'className'
  | 'defaultValue'
  | 'disabled'
  | 'maxLength'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'placeholder'
  | 'readOnly'
  | 'rows'
  | 'value'
> & {
  hasError?: boolean;
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea({ className, hasError, ...props }, ref) {
    return (
      <InputShell hasError={hasError}>
        {({ inputClassName, inputId, rootClassName, textAreaClassName }) => (
          <textarea
            ref={ref}
            {...props}
            className={clsx(
              className,
              rootClassName,
              inputClassName,
              textAreaClassName,
            )}
            id={inputId}
          />
        )}
      </InputShell>
    );
  },
);
