import { Trans } from '@lingui/macro';

import * as styles from './getKeeperMessage.module.css';

export function GetKeeperMessage() {
  return (
    <p className={styles.root}>
      <Trans>
        Don&apos;t have Keeper yet? Get it on our{' '}
        <a
          className={styles.link}
          rel="noopener"
          href="https://keeper-wallet.app/#get-keeper"
          target="_blank"
        >
          website
        </a>
        .
      </Trans>
    </p>
  );
}
