import { Trans } from '@lingui/macro';
import { Link, useSearchParams } from 'react-router-dom';

import { Container } from '../../layout/layout';
import * as styles from './addAccountIndex.module.css';
import { GetKeeperMessage } from './getKeeperMessage';

export function AddAccountIndexPage() {
  const [params] = useSearchParams();
  const next = params.get('next');
  const nextParams = new URLSearchParams();

  if (next) {
    nextParams.set('next', next);
  }

  const nextSearch = nextParams.toString();

  return (
    <Container>
      <h1 className={styles.heading}>
        <Trans>Add Account</Trans>
      </h1>

      <p className={styles.subheading}>
        <Trans>
          You can either create a new Multi-Coin Account
          <br className={styles.subheadingBr} />
          or connect any of the available Keeper ecosystem accounts, and much
          more
        </Trans>
      </p>

      <ul className={styles.introButtonList}>
        <li>
          <Link
            className={styles.introButton}
            to={{ pathname: '/add-account/create', search: nextSearch }}
          >
            <div className={styles.introButtonLogoContainer}>
              <img
                className={styles.introButtonLogo}
                src={new URL('./keeperIcon.svg', import.meta.url).pathname}
                alt=""
              />
            </div>

            <h2 className={styles.introButtonLabel}>
              <Trans>Create new account</Trans>
            </h2>

            <p className={styles.introButtonDescription}>
              <Trans>Set up your own Multi-Coin Account with Keeper</Trans>
            </p>
          </Link>
        </li>

        <li>
          <Link
            className={styles.introButton}
            to={{ pathname: '/add-account/import', search: nextSearch }}
          >
            <div className={styles.introButtonLogoContainer}>
              <img
                className={styles.introButtonLogo}
                src={new URL('./importIcon.svg', import.meta.url).pathname}
                alt=""
              />
            </div>

            <h2 className={styles.introButtonLabel}>
              <Trans>Add existing one</Trans>
            </h2>

            <p className={styles.introButtonDescription}>
              <Trans>
                Connect or import any Keeper account or choose from other
                options
              </Trans>
            </p>
          </Link>
        </li>
      </ul>

      <GetKeeperMessage />
    </Container>
  );
}
