import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useState } from 'react';

import { Button } from '../_core/button';
import * as styles from './confirmAccount.module.css';

export function ConfirmAccount({
  seed,
  seedIndicesAfterShuffle,
  indicesToCheck,
  onConfirm,
}: {
  seed: string;
  seedIndicesAfterShuffle: number[];
  indicesToCheck: number[];
  onConfirm: () => void;
}) {
  const { i18n } = useLingui();

  const wordsList = seed.split(' ');

  const [selectedIndices, setSelectedIndices] = useState<Array<number | null>>([
    null,
    null,
    null,
  ]);

  function selectWord(wordIndex: number) {
    setSelectedIndices(prevIndices => {
      setErrMessage('');
      const indexToUpdate = prevIndices.indexOf(null);

      if (selectedIndices.includes(wordIndex)) return prevIndices;
      if (indexToUpdate === -1) return prevIndices;

      const indices = [...prevIndices];
      indices[indexToUpdate] = wordIndex;

      return indices;
    });
  }

  function clearWord(index: number) {
    setSelectedIndices(prevIndices => {
      const indices = [...prevIndices];
      indices[index] = null;

      return indices;
    });
  }

  const isAllWordsSelected = selectedIndices.every(i => i != null);

  const [errMessage, setErrMessage] = useState('');

  return (
    <>
      <p className={styles.helpText}>
        <Trans>Choose three words from the list in the correct order</Trans>
      </p>

      <div className={styles.wordsToCheck}>
        {indicesToCheck.map((wordIndexToCheck, index) => {
          const wordIndex = selectedIndices[index];
          const word = wordIndex != null ? wordsList[wordIndex] : undefined;

          return (
            <Pill
              key={index}
              id={wordIndexToCheck + 1}
              word={word}
              isSelected={word != null}
              hasError={Boolean(errMessage)}
              onClick={() => clearWord(index)}
            />
          );
        })}
      </div>

      <div className={styles.wordsList}>
        {seedIndicesAfterShuffle.map((wordIndex, index) => (
          <Pill
            key={index}
            word={wordsList[wordIndex]}
            isHidden={selectedIndices.includes(wordIndex)}
            onClick={() => selectWord(wordIndex)}
          />
        ))}
      </div>

      <form
        onSubmit={e => {
          e.preventDefault();

          const wordsToCheck = indicesToCheck.map(i => wordsList[i]).join(' ');
          const selectedWords = selectedIndices
            .map(i => (i != null && wordsList[i]) ?? '')
            .join(' ');

          if (selectedWords !== wordsToCheck) {
            setErrMessage(t(i18n)`Invalid order`);
            setSelectedIndices([null, null, null]);
            return;
          }

          onConfirm();
        }}
      >
        {errMessage && <p className={styles.errorText}>{errMessage}</p>}

        <Button
          disabled={!isAllWordsSelected}
          block
          text={t(i18n)`Continue`}
          type="submit"
        />
      </form>
    </>
  );
}

function Pill({
  id,
  word,
  isSelected,
  isHidden,
  hasError,
  onClick,
}: {
  id?: number;
  word?: string;
  isSelected?: boolean;
  isHidden?: boolean;
  hasError?: boolean;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className={clsx(styles.pill, {
        [styles.pillWithIndex]: id != null,
        [styles.pillError]: hasError,
        [styles.pillSelected]: isSelected,
        [styles.pillHidden]: isHidden,
      })}
      onClick={onClick}
    >
      {id && <span className={styles.pillIndex}>{id}</span>}
      <span>{word}</span>
    </button>
  );
}
