import { Trans } from '@lingui/macro';

import { Select } from '../_core/select';
import { useAppDispatch, useAppSelector } from '../store/react';
import * as styles from './blockchainSelect.module.css';

export function BlockchainSelect() {
  const dispatch = useAppDispatch();

  const blockchainFilter = useAppSelector(state => state.blockchainFilter);

  return (
    <Select
      variant="text"
      value={blockchainFilter}
      items={(['all', 'ethereum', 'waves'] as const).map(blockchain => ({
        label: blockchain,
        value: blockchain,
      }))}
      renderButtonLabel={option => {
        switch (option.value) {
          case 'ethereum': {
            return (
              <div className={styles.blockchainButton}>
                <div className={styles.blockchainOptionIcon}>
                  <img
                    src={
                      new URL('../icons/ethereum.svg', import.meta.url).pathname
                    }
                    alt=""
                  />
                </div>
                <span>
                  <span className={styles.blockchainName}>
                    <Trans>Ethereum</Trans>
                  </span>{' '}
                  worth
                </span>
              </div>
            );
          }
          case 'waves': {
            return (
              <div className={styles.blockchainButton}>
                <div className={styles.blockchainOptionIcon}>
                  <img
                    src={
                      new URL('../icons/waves.svg', import.meta.url).pathname
                    }
                    alt=""
                  />
                </div>
                <span>
                  <span className={styles.blockchainName}>
                    <Trans>Waves</Trans>
                  </span>{' '}
                  worth
                </span>
              </div>
            );
          }
          case 'all': {
            return (
              <div className={styles.blockchainButton}>
                <span>
                  <span className={styles.blockchainName}>
                    <Trans>All networks</Trans>
                  </span>{' '}
                  worth
                </span>
              </div>
            );
          }
        }
      }}
      renderOptionLabel={option => {
        switch (option.value) {
          case 'ethereum': {
            return (
              <div className={styles.blockchainOption}>
                <div className={styles.blockchainOptionIcon}>
                  <img
                    src={
                      new URL('../icons/ethereum.svg', import.meta.url).pathname
                    }
                    alt=""
                  />
                </div>
                <span>
                  <Trans>Ethereum</Trans>
                </span>
              </div>
            );
          }
          case 'waves': {
            return (
              <div className={styles.blockchainOption}>
                <div className={styles.blockchainOptionIcon}>
                  <img
                    src={
                      new URL('../icons/waves.svg', import.meta.url).pathname
                    }
                    alt=""
                  />
                </div>
                <span>
                  <Trans>Waves</Trans>
                </span>
              </div>
            );
          }
          case 'all': {
            return (
              <div className={styles.blockchainOption}>
                <span>
                  <Trans>All networks</Trans>
                </span>
              </div>
            );
          }
        }
      }}
      onChange={selectedBlockchain => {
        dispatch({
          type: 'SET_BLOCKCHAIN_FILTER',
          payload: {
            selectedBlockchain,
          },
        });
      }}
    />
  );
}
