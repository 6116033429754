import { type AsyncValue } from '../_core/asyncValue';
import { Skeleton } from '../_core/skeleton';
import * as styles from './tabPanelHeader.module.css';

interface Props {
  caption?: string;
  heading: AsyncValue<React.ReactNode>;
}

export function TabPanelHeader({ caption, heading }: Props) {
  return (
    <header className={styles.root}>
      {caption && <h2 className={styles.caption}>{caption}</h2>}

      {heading.match({
        Pending: () => <Skeleton className={styles.headingSkeleton} />,
        Ready: x =>
          x == null ? null : <div className={styles.heading}>{x}</div>,
      })}
    </header>
  );
}
