import { type AppThunkAction } from '../store/types';
import { Network } from './types';
import { isValidNetworkValue } from './utils';

const NETWORK_COOKIE_NAME = 'network';

export default function networkReducer(
  state = Network.Mainnet,
  action: { type: 'SET_NETWORK'; payload: Network },
): Network {
  switch (action.type) {
    case 'SET_NETWORK':
      return action.payload;
    default:
      return state;
  }
}

export function restoreNetworkFromCookies(): AppThunkAction<void> {
  return (dispatch, _getState, { cookies }) => {
    const network = cookies.get(NETWORK_COOKIE_NAME);

    if (!isValidNetworkValue(network)) return;

    dispatch({
      type: 'SET_NETWORK',
      payload: network,
    });
  };
}

export function setNetwork(
  newNetwork: Network,
  { saveToCookies = true }: { saveToCookies?: boolean } = {},
): AppThunkAction<void> {
  return (dispatch, _getState, { cookies }) => {
    if (saveToCookies) {
      cookies.set(NETWORK_COOKIE_NAME, newNetwork, {
        maxAge: 365 * 24 * 60 * 60,
      });
    }

    dispatch({
      type: 'SET_NETWORK',
      payload: newNetwork,
    });
  };
}
