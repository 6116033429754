import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';

import { CopyIcon } from '../icons/copy';
import * as styles from './copyButton.module.css';
import { CopyToClipboard } from './copyToClipboard';
import { Tooltip } from './tooltip';

interface CopyButtonProps {
  block?: boolean;
  children?: React.ReactNode;
  className?: string;
  textToCopy: string;
}

export function CopyButton({
  block,
  children,
  className,
  textToCopy,
}: CopyButtonProps) {
  const { i18n } = useLingui();

  return (
    <CopyToClipboard>
      {({ copy, isCopied }) => (
        <Tooltip
          content={isCopied ? t(i18n)`Copied` : t(i18n)`Copy`}
          placement="top"
        >
          {({ ref, onMouseEnter, onMouseLeave, onTouchStart }) => (
            <button
              ref={ref}
              className={clsx(className, styles.root, {
                [styles.root_block]: block,
              })}
              type="button"
              onClick={() => {
                void copy(textToCopy);
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onTouchStart={onTouchStart}
            >
              <span className={styles.text}>{children}</span>
              <CopyIcon className={styles.icon} />
            </button>
          )}
        </Tooltip>
      )}
    </CopyToClipboard>
  );
}
