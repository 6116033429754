import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, type Infer, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { isAbortError } from '../../_core/errors';
import { handleResponse } from '../../_core/handleResponse';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const DataServiceProtocol = type({
  id: string(),
  name: string(),
  icon_url: string(),
  url: string(),
});

export type DataServiceProtocol = Infer<typeof DataServiceProtocol>;

const reducer: Reducer<
  DataServiceProtocol[] | null,
  {
    type: 'UPDATE_DATA_SERVICE_PROTOCOLS';
    payload: DataServiceProtocol[];
  }
> = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_PROTOCOLS':
      return action.payload;
    default:
      return state;
  }
};

export default reducer;

interface DataServiceProtocols {
  byProtocolId: Partial<Record<string, DataServiceProtocol>>;
  list: DataServiceProtocol[];
}

export function useDataServiceProtocols(): AsyncValue<DataServiceProtocols> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    if (network !== Network.Mainnet) return;

    const abortController = new AbortController();

    const signal = abortController.signal;

    fetch(new URL('/api/v1/protocols', dataServiceUrl), { signal })
      .then(handleResponse(array(DataServiceProtocol)))
      .then(
        protocols => {
          dispatch({
            type: 'UPDATE_DATA_SERVICE_PROTOCOLS',
            payload: protocols,
          });
        },
        err => {
          if (isAbortError(err)) return;

          throw err;
        },
      );

    return () => {
      abortController.abort();
    };
  }, [dataServiceUrl, dispatch, network]);

  const protocols = useAppSelector(state => state.cache.dataService.protocols);

  return useMemo(
    () =>
      network === Network.Mainnet
        ? protocols
          ? AsyncValue.Ready({
              byProtocolId: Object.fromEntries(
                protocols.map(protocol => [protocol.id, protocol]),
              ),
              list: protocols,
            })
          : AsyncValue.Pending
        : AsyncValue.Ready({
            byProtocolId: {},
            list: [],
          }),
    [network, protocols],
  );
}
