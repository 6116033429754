import { HDNodeWallet, JsonRpcProvider } from 'ethers';

import { handleUnexpectedError } from '../../_core/errors';
import { Some } from '../../_core/maybe';
import { Ok, Result } from '../../_core/result';
import { sendWavesTransaction } from '../../apis/wavesNode';
import {
  BlockchainPublicKey,
  EthereumPublicKey,
  WavesPublicKey,
} from '../../blockchain/publicKey';
import { EthereumTransaction } from '../../blockchain/transaction/ethereumTransaction';
import { WavesTransaction } from '../../blockchain/transaction/wavesTransaction';
import type {
  BlockchainTransactionInput,
  BlockchainTransactionSendResponse,
} from '../../blockchain/types';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import type { Network } from '../../network/types';
import {
  createPrivateKey,
  createPublicKey,
  signBytes,
} from '../../waves/ed25519';
import type { Account } from '../types';
import { AbstractAccount } from './abstractAccount';
import { MultichainSeed } from './multichainSeed';

export class MultichainAccount extends AbstractAccount {
  #publicKeys;
  #seed;

  private constructor({
    id,
    name,
    publicKeys,
    seed,
  }: {
    id: string;
    name: string;
    publicKeys: BlockchainPublicKey[];
    seed: MultichainSeed;
  }) {
    super({ id, name });
    this.#publicKeys = publicKeys;
    this.#seed = seed;
  }

  static fromInMemoryJSON(
    json: ReturnType<MultichainAccount['toInMemoryJSON']>,
  ) {
    return Result.all([
      MultichainSeed.fromString(json.seed),
      Result.all(json.publicKeys.map(BlockchainPublicKey.fromJSON)),
    ]).mapOk(
      ([seed, publicKeys]) =>
        new MultichainAccount({
          id: json.id,
          name: json.name,
          publicKeys,
          seed,
        }),
    );
  }

  static async fromPersistedJSON(
    json: ReturnType<MultichainAccount['toPersistedJSON']>,
  ) {
    return Result.all([
      MultichainSeed.fromString(json.seed),
      EthereumPublicKey.fromSeed(json.seed),
      WavesPublicKey.fromBytes(
        await createPublicKey(createPrivateKey(json.seed)),
      ),
    ]).mapOk(
      ([seed, ethereumPublicKey, wavesPublicKey]) =>
        new MultichainAccount({
          id: json.id,
          name: json.name,
          publicKeys: [ethereumPublicKey, wavesPublicKey],
          seed,
        }),
    );
  }

  static fromSeed(seed: MultichainSeed) {
    return MultichainAccount.fromPersistedJSON({
      type: 'multichain',
      id: '',
      name: '',
      seed: seed.toString(),
    });
  }

  get type() {
    return 'multichain' as const;
  }

  get seed() {
    return this.#seed.toString();
  }

  getSeed() {
    return Some(this.#seed);
  }

  getPrivateKeys() {
    return [
      this.#seed.computeEthereumPrivateKey(),
      this.#seed.computeWavesPrivateKey(),
    ];
  }

  getPublicKeys() {
    return this.#publicKeys;
  }

  isDuplicateOf(other: Account): boolean {
    return (
      this.id !== other.id &&
      this.type === other.type &&
      this.#seed.toString() === other.#seed.toString()
    );
  }

  toInMemoryJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      seed: this.#seed.toString(),
      publicKeys: this.getPublicKeys().map(publicKey => publicKey.toJSON()),
    };
  }

  toPersistedJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      seed: this.#seed.toString(),
    };
  }

  async sendTransaction(
    input: BlockchainTransactionInput,
    ethereumNodeUrls: {
      [Network.Mainnet]: string;
      [Network.Testnet]: string;
    },
  ) {
    try {
      switch (input.blockchain) {
        case 'ethereum': {
          const wallet = HDNodeWallet.fromPhrase(this.#seed.toString()).connect(
            new JsonRpcProvider(ethereumNodeUrls[input.network]),
          );

          const txResponse = await wallet.sendTransaction(
            EthereumTransaction.fromInput(
              input,
              this.getEthereumAddress().assertSome(),
            ).toTransactionRequest(),
          );

          const response: BlockchainTransactionSendResponse<'ethereum'> = {
            blockchain: 'ethereum',
            hash: txResponse.hash,
          };

          return Ok(Some(response));
        }
        case 'waves': {
          const transaction = WavesTransaction.fromInput(
            input,
            this.getWavesPublicKey().assertSome(),
          );

          const privateKey = this.#seed.computeWavesPrivateKey().toBytes();
          transaction.addSignature(
            await signBytes(privateKey, transaction.toBytes()),
          );

          const { nodeUrl } = WAVES_NETWORK_CONFIGS[input.network];
          const response = await sendWavesTransaction(transaction, { nodeUrl });

          return Ok(Some(response));
        }
      }
    } catch (err) {
      return handleUnexpectedError(err, {
        context: `Send ${input.blockchain} transaction using Multichain Account`,
      });
    }
  }
}
