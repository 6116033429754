import { deepEqual } from 'fast-equals';
import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, type Infer, nullable, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { pollWhileUserIsActive } from '../../_core/polling';
import type { Balance } from '../../blockchain/types';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import type { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';
import { WAVES_ASSET } from './assetsDetails';

const WavesAssetBalance = type({
  assetId: string(),
  balance: string(),
  minSponsoredAssetFee: nullable(string()),
  sponsorBalance: nullable(string()),
});

type WavesAssetBalance = Infer<typeof WavesAssetBalance>;

const WavesBalanceDetails = type({
  address: string(),
  regular: string(),
  generating: string(),
  available: string(),
  effective: string(),
});

type WavesBalanceDetails = Infer<typeof WavesBalanceDetails>;

type BalancesState = Partial<
  Record<
    Network,
    Partial<{
      [address: string]: {
        assetBalances: WavesAssetBalance[];
        wavesBalance: WavesBalanceDetails;
      };
    }>
  >
>;

const reducer: Reducer<
  BalancesState,
  {
    type: 'UPDATE_WAVES_BALANCES';
    payload: {
      address: string;
      assetBalances: WavesAssetBalance[];
      network: Network;
      wavesBalance: WavesBalanceDetails;
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_WAVES_BALANCES': {
      const { address, assetBalances, network, wavesBalance } = action.payload;

      const balanceEntry = {
        assetBalances,
        wavesBalance,
      };

      const prevBalanceEntry = state[network]?.[address];

      if (prevBalanceEntry && deepEqual(balanceEntry, prevBalanceEntry)) {
        return state;
      }

      return {
        ...state,
        [network]: {
          ...state[network],
          [address]: balanceEntry,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;

interface WavesBalanceEntry {
  wavesBalance: WavesBalanceDetails;
  byAssetId: Partial<{
    [assetId: string]: Balance<'waves'>;
  }>;
  list: Array<Balance<'waves'>>;
}

type WavesBalances = Partial<{
  [address: string]: WavesBalanceEntry;
}>;

export function useWavesBalances({
  addresses,
}: {
  addresses: string[];
}): AsyncValue<WavesBalances> {
  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);
  const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  useEffect(() => {
    return pollWhileUserIsActive(5000, async signal => {
      await Promise.all(
        addresses.map(address =>
          Promise.all([
            fetch(new URL(`assets/balance/${address}`, nodeUrl), {
              headers: {
                accept: 'application/json; large-significand-format=string',
              },
              signal,
            }).then(
              handleResponse(
                type({
                  address: string(),
                  balances: array(WavesAssetBalance),
                }),
              ),
            ),
            fetch(new URL(`addresses/balance/details/${address}`, nodeUrl), {
              headers: {
                accept: 'application/json; large-significand-format=string',
              },
              signal,
            }).then(handleResponse(WavesBalanceDetails)),
          ]).then(([{ balances: assetBalances }, wavesBalance]) => {
            dispatch({
              type: 'UPDATE_WAVES_BALANCES',
              payload: {
                address,
                assetBalances,
                network,
                wavesBalance,
              },
            });
          }),
        ),
      );
    });
  }, [addresses, dispatch, network, nodeUrl]);

  const balances = useAppSelector(state => state.cache.waves.balances[network]);

  return useMemo(() => {
    const result: WavesBalances = {};

    for (const address of addresses) {
      const addressBalance = balances?.[address];
      if (!addressBalance) return AsyncValue.Pending;

      const assetBalances = addressBalance.assetBalances
        .concat([
          {
            assetId: WAVES_ASSET.assetId,
            balance: addressBalance.wavesBalance.available,
            minSponsoredAssetFee: WAVES_ASSET.minSponsoredAssetFee,
            sponsorBalance: addressBalance.wavesBalance.available,
          },
        ])
        .map(
          (balance): Balance<'waves'> => ({
            ...balance,
            blockchain: 'waves',
          }),
        );

      result[address] = {
        wavesBalance: addressBalance.wavesBalance,
        byAssetId: Object.fromEntries(
          assetBalances.map(balance => [balance.assetId, balance]),
        ),
        list: assetBalances,
      };
    }

    return AsyncValue.Ready(result);
  }, [addresses, balances]);
}
