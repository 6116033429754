export class Maybe<T> {
  #state;

  private constructor(
    state: Readonly<{ isSome: false } | { isSome: true; value: Readonly<T> }>,
  ) {
    this.#state = state;
  }

  static None = new Maybe<never>({ isSome: false });

  static Some<T>(value: T) {
    return new Maybe<T>({ isSome: true, value });
  }

  static fromNullable<T>(value: T | null | undefined) {
    return value == null ? None : Some(value);
  }

  static all<T extends ReadonlyArray<Maybe<unknown>> | []>(
    xs: T,
  ): Maybe<{
    [I in keyof T]: T[I] extends Maybe<infer U> ? U : never;
  }> {
    const values: unknown[] = [];

    for (const x of xs) {
      if (!x.#state.isSome) return None;
      values.push(x.#state.value);
    }

    return Some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values as any,
    );
  }

  static allRecord<T extends Readonly<Record<string, Maybe<unknown>>>>(
    xs: T,
  ): Maybe<{ [K in keyof T]: T[K] extends Maybe<infer U> ? U : never }> {
    const result: Record<string, unknown> = {};

    for (const [key, x] of Object.entries(xs)) {
      if (!x.#state.isSome) return None;
      result[key] = x.#state.value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Some(result as any);
  }

  static findMap<T, U>(xs: Iterable<T>, f: (x: T) => Maybe<U>) {
    for (const x of xs) {
      const y = f(x);
      if (y.#state.isSome) return y;
    }

    return None;
  }

  static filterMap<T, U>(xs: Iterable<T>, f: (x: T) => Maybe<U>): U[] {
    const result: U[] = [];

    for (const x of xs) {
      f(x).match({
        Some: value => {
          result.push(value);
        },
        None: () => undefined,
      });
    }

    return result;
  }

  static mapArray<T, U>(xs: T[], f: (x: T) => Maybe<U>): Maybe<U[]> {
    const ys: U[] = [];

    for (const x of xs) {
      const y = f(x);
      if (!y.#state.isSome) return None;
      ys.push(y.#state.value);
    }

    return Some(ys);
  }

  get isNone() {
    return !this.#state.isSome;
  }

  get isSome() {
    return this.#state.isSome;
  }

  match<U>(arms: { Some: (value: T) => U; None: () => U }): U {
    return this.#state.isSome ? arms.Some(this.#state.value) : arms.None();
  }

  mapSome<U>(f: (value: T) => U): Maybe<U> {
    return this.match({
      Some: value => Some(f(value)),
      None: () => None,
    });
  }

  flatMapSome<U>(f: (value: T) => Maybe<U>): Maybe<U> {
    return this.match({
      Some: value => f(value),
      None: () => None,
    });
  }

  or(d: () => Maybe<T>): Maybe<T> {
    return this.match({
      Some,
      None: d,
    });
  }

  getOr(d: () => T): T {
    return this.match({
      Some: value => value,
      None: d,
    });
  }

  toNullable(): T | null {
    return this.match({
      Some: value => value,
      None: () => null,
    });
  }

  toOptional(): T | undefined {
    return this.match({
      Some: value => value,
      None: () => undefined,
    });
  }

  assertSome(message = 'Assertion failed'): T {
    return this.match({
      Some: value => value,
      None: () => {
        throw new Error(message);
      },
    });
  }
}

export const Some = Maybe.Some;
export const None = Maybe.None;
