// extracted by mini-css-extract-plugin
export var backButton = "gUeVbDqNx3vWpuEsRJfQ";
export var blockchainLabel = "l4YOwdH0mFFGmH2rP7yF";
export var blockchainLabelIcon = "RB47l1moVbnWDzyKawFY";
export var card = "J87o5MjJHEBbm_aBkSh2";
export var cardDescription = "HlcoA0mEvwnOlbtLjTaw";
export var cardSection = "I7wpcWizaPYohTcKEcFA";
export var cardTitle = "iZuLHcQSpypORO2lpYZx";
export var editButton = "WJenqND70oyEAyydMTYq";
export var header = "WCcaIJI8FMm0U5vIDB6o";
export var modalBottomButton = "Tnuefv9Dt7k8nOjRxaYO";
export var name = "FbTWZl2mN32b3uS0iB8X";
export var passwordModalAttentionBox = "h5BPY3VMAkSbijryKFDf";
export var passwordModalAttentionBoxBody = "SPPXLK34gp_OrhJ1dGot";
export var passwordModalAttentionBoxHeader = "WvFoMyBsidmGEXKhdKsC";
export var passwordModalEnterPasswordText = "fo06Mbvg11sHsvghJ5sa";
export var privateInfoKeyList = "cxLvwvROUCC2gC8NiAQP";
export var privateInfoSubheading = "ymLDL5KYfUNdYtLC34WI";
export var publicInfoItem = "gjXzYYxE9SbO4CfG4RCL";
export var publicInfoItemList = "hJEMt5HWr7HLekK5vmkb";
export var publicInfoItemName = "p2k3KCd_iqRomhNhR3sJ";
export var publicInfoSubheading = "egcWjm4s2n2fDBbVOCPo";
export var revealButton = "LVET_aqtAHONfHoEo4K2";
export var revealButtonIcon = "nHtXfDCLox5Duu2xFbZw";
export var revealModalCopyButton = "sWbRuUfD50NkOYJeCWvW";
export var revealModalCopyButtonWrapper = "ApaT_1TDfGzWNAQmw3_m";
export var revealModalDescription = "gwy86LtdmBNq2uEZiRpI";
export var revealModalRevealableContent = "gotcLB9ZvtDRW0vzNs3Q";
export var root = "TPuv2_NhIcbGZI1qg1eF";
export var separator = "whmor_RGCUhyen2En3IG";