import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AsyncValue } from '../../_core/asyncValue';
import { useDataServiceAssets } from '../../cache/dataService/assets';
import { useDataServiceProtocols } from '../../cache/dataService/protocols';
import { PortfolioInvestments } from '../../investments/investments';
import { Container } from '../../layout/layout';
import { usePortfolioPageContext } from './portfolio';
import * as styles from './portfolio.module.css';

export function InvestmentsPage() {
  const { i18n } = useLingui();
  const { investments, wavesUsdPrices, investmentsWorth } =
    usePortfolioPageContext();

  const dataServiceAssets = useDataServiceAssets();

  const dataServiceProtocols = useDataServiceProtocols();

  const asyncValues = AsyncValue.allRecord({
    investments,
    dataServiceAssets,
    dataServiceProtocols,
  });

  return (
    <Container>
      {asyncValues
        .mapReady(x => x.investments.length === 0)
        .getReady()
        .toNullable() ? (
        <h2 className={styles.emptyMessage}>{t(i18n)`No Investments yet`}</h2>
      ) : (
        <PortfolioInvestments
          dataServiceAssets={dataServiceAssets}
          dataServiceProtocols={dataServiceProtocols.mapReady(x => x.list)}
          investments={investments}
          usdPrices={wavesUsdPrices}
          totalWorth={investmentsWorth}
        />
      )}
    </Container>
  );
}
