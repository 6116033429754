function getIsUserActive() {
  return typeof document === 'undefined' ? true : document.hasFocus();
}

function ensureUserIsActive() {
  return new Promise<void>(resolve => {
    if (getIsUserActive()) {
      resolve();

      return;
    }
    const onFocusChange = () => {
      resolve();
      window.removeEventListener('focus', onFocusChange);
    };
    window.addEventListener('focus', onFocusChange);
  });
}

function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function pollWhileUserIsActive(
  ms: number,
  fn: (abortSignal: AbortSignal) => Promise<void>,
) {
  const abortController = new AbortController();
  let timeout: ReturnType<typeof setTimeout>;

  async function update() {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      await fn(abortController.signal).catch(() => {});

      if (abortController.signal.aborted) break;

      await wait(ms);

      await ensureUserIsActive();
    }
  }

  void update();

  return () => {
    clearTimeout(timeout);
    abortController.abort();
  };
}
