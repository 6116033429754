import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { number, record, string } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { ETHEREUM_ASSET } from '../../ethereum/constants';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const COINGECKO_API = 'https://api.coingecko.com';

export type CoingeckoUsdPrices = Partial<{
  [address: string]: number;
}>;

const reducer: Reducer<
  CoingeckoUsdPrices,
  { type: 'UPDATE_COINGECKO_RATES'; payload: CoingeckoUsdPrices }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_COINGECKO_RATES':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;

export function useCoingeckoUsdPrices({
  addresses,
}: {
  addresses: string[];
}): AsyncValue<CoingeckoUsdPrices> {
  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    if (network !== Network.Mainnet || addresses.length === 0) return;

    const contractAddresses = addresses.filter(
      address => address !== ETHEREUM_ASSET.address,
    );

    const CoingeckoResponse = record(string(), record(string(), number()));

    void Promise.all([
      contractAddresses.length !== 0
        ? fetch(
            new URL(
              // query params must be passed without encoding
              `/api/v3/simple/token_price/ethereum?contract_addresses=${contractAddresses.join(
                ',',
              )}&vs_currencies=usd`,
              COINGECKO_API,
            ),
          ).then(handleResponse(CoingeckoResponse))
        : undefined,

      addresses.includes(ETHEREUM_ASSET.address)
        ? fetch(
            new URL(
              `/api/v3/simple/price?ids=ethereum&vs_currencies=usd`,
              COINGECKO_API,
            ),
          ).then(handleResponse(CoingeckoResponse))
        : undefined,
    ]).then(([otherTokenPrices, ethereumPrice]) => {
      const rates = {
        ...otherTokenPrices,
      };

      if (ethereumPrice) {
        rates[ETHEREUM_ASSET.address] = ethereumPrice.ethereum;
      }

      dispatch({
        type: 'UPDATE_COINGECKO_RATES',
        payload: Object.fromEntries(
          Object.keys(rates).map(address => [address, rates[address]?.usd]),
        ),
      });
    });
  }, [addresses, dispatch, network]);

  const allUsdPrices = useAppSelector(state => state.cache.coingecko.usdPrices);

  return useMemo(() => {
    if (network !== Network.Mainnet) {
      return AsyncValue.Ready({});
    }

    const result: CoingeckoUsdPrices = {};

    for (const address of addresses) {
      if (!(address in allUsdPrices)) return AsyncValue.Pending;
      const usdPrice = allUsdPrices[address];
      result[address] = usdPrice;
    }

    return AsyncValue.Ready(result);
  }, [addresses, allUsdPrices, network]);
}
