import { combineReducers } from 'redux';

import assetsDetails from './assetsDetails';
import balances from './balances';
import leases from './leases';
import nfts from './nfts';

export default combineReducers({
  assetsDetails,
  balances,
  leases,
  nfts,
});
