import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CreateAccount } from '../../accounts/createAccount';
import { useCreateAccountPageContext } from './createAccount';
import { AddAccountShell } from './shell';

export function CreateAccountIndexPage() {
  const { i18n } = useLingui();

  const { seed, generateRandomSeed, shuffleSeedIndices } =
    useCreateAccountPageContext();

  useEffect(() => {
    if (seed) {
      shuffleSeedIndices();
      return;
    }

    generateRandomSeed();
  }, [seed, generateRandomSeed, shuffleSeedIndices]);

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const next = params.get('next');
  const nextParams = new URLSearchParams();

  if (next) {
    nextParams.set('next', next);
  }

  const nextSearch = nextParams.toString();

  return (
    <AddAccountShell
      backPath="/add-account"
      heading={t(i18n)`Back up secret phrase`}
    >
      {seed && (
        <CreateAccount
          seed={seed}
          onSubmit={() =>
            navigate({
              pathname: '/add-account/create/confirm',
              search: nextSearch,
            })
          }
        />
      )}
    </AddAccountShell>
  );
}
