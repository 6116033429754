import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AsyncValue } from '../_core/asyncValue';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { None, Some } from '../_core/maybe';
import { isNotNull } from '../_core/predicates';
import { useMatchesMedia } from '../_core/useMatchesMedia';
import type { DataServiceAssets } from '../cache/dataService/assets';
import type { DataServiceProtocol } from '../cache/dataService/protocols';
import type { DataServiceUsdPrices } from '../cache/dataService/usdPrices';
import { TabPanelHeader } from '../portfolio/tabPanelHeader';
import { InvestmentListDesktop } from './investmentListDesktop';
import { InvestmentListMobile } from './investmentListMobile';
import * as styles from './investments.module.css';
import { InvestmentsOverview } from './investmentsOverview';
import { type Investment } from './utils';

interface Props {
  dataServiceAssets: AsyncValue<DataServiceAssets>;
  dataServiceProtocols: AsyncValue<DataServiceProtocol[]>;
  investments: AsyncValue<Investment[]>;
  usdPrices: AsyncValue<DataServiceUsdPrices>;
  totalWorth: AsyncValue<BigNumber>;
}

export function PortfolioInvestments({
  dataServiceAssets,
  dataServiceProtocols,
  investments,
  usdPrices,
  totalWorth,
}: Props) {
  const { i18n } = useLingui();

  const isMobile = useMatchesMedia('(max-width: 768px)');

  const productsByProtocol = investments.mapReady(investmentsValue =>
    investmentsValue.reduce(
      (acc, product) => {
        acc[product.protocol_id] ??= [];
        acc[product.protocol_id].push(product);

        return acc;
      },
      {} as Record<string, Investment[]>,
    ),
  );

  const protocolsInvestments = AsyncValue.allRecord({
    dataServiceProtocols,
    productsByProtocol,
    usdPrices,
  }).mapReady(x =>
    x.dataServiceProtocols
      .map(protocol => {
        const protocolProducts = x.productsByProtocol[protocol.id];

        return (
          protocolProducts && {
            protocol,
            protocolProducts,
          }
        );
      })
      .filter(isNotNull)
      .map(({ protocol, protocolProducts }) => ({
        id: protocol.id,
        name: protocol.name,
        logo: protocol.icon_url,
        link: `#${protocol.id}`,
        value: protocolProducts.reduce((protocolProductsSum, { amounts }) => {
          const amountsSum = amounts.reduce(
            (productAmountsSum, amount) =>
              productAmountsSum.add(
                amount
                  .getTokens()
                  .mul(x.usdPrices[amount.asset.assetId] ?? '0'),
              ),
            new BigNumber(0),
          );

          return protocolProductsSum.add(amountsSum);
        }, new BigNumber(0)),
      })),
  );

  const location = useLocation();

  useEffect(() => {
    const scrollToId = location.hash.replace('#', '');

    if (protocolsInvestments.isPending || !scrollToId) {
      return;
    }

    const element = document.getElementById(scrollToId);

    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top });
    }
  }, [
    protocolsInvestments.isPending,
    isMobile,
    location.pathname,
    location.key,
    location.hash,
  ]);

  return (
    <div>
      <TabPanelHeader
        caption={t(i18n)`Investments worth`}
        heading={totalWorth.mapReady(x => formatUsdPrice(x))}
      />

      {protocolsInvestments
        .match({
          Pending: () => Some(AsyncValue.Pending),
          Ready: x => (x.length > 0 ? Some(AsyncValue.Ready(x)) : None),
        })
        .mapSome(x => <InvestmentsOverview investments={x} />)
        .toNullable()}

      {isMobile == null ? null : isMobile ? (
        <InvestmentListMobile
          className={styles.investmentListMobile}
          dataServiceAssets={dataServiceAssets}
          investments={investments}
          protocols={dataServiceProtocols}
          usdPrices={usdPrices}
        />
      ) : (
        <InvestmentListDesktop
          className={styles.investmentListDesktop}
          dataServiceAssets={dataServiceAssets}
          investments={investments}
          protocols={dataServiceProtocols}
          usdPrices={usdPrices}
        />
      )}
    </div>
  );
}
