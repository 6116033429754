import { createSvgIcon } from './_base';

export const PasteIcon = createSvgIcon(
  '0 0 25 24',
  <>
    <path
      fillRule="evenodd"
      d="M18.5 3.5h-12A.5.5 0 0 0 6 4v16a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5ZM6.5 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-12Z"
    />

    <path
      fillRule="evenodd"
      d="M14.5 3h-4a.5.5 0 0 0-.5.5V4a.5.5 0 0 0 .5.5h4A.5.5 0 0 0 15 4v-.5a.5.5 0 0 0-.5-.5Zm-4-1c-1.105 0-2 .395-2 1.5V4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-.5c0-1.105-.895-1.5-2-1.5h-4Z"
    />

    <path d="M7.5 9.75A.75.75 0 0 1 8.25 9h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Z" />
  </>,
);
