import { plural, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';

import { type AsyncValue } from '../_core/asyncValue';
import { Skeleton } from '../_core/skeleton';
import * as styles from './nftCollections.module.css';
import { TabPanelHeader } from './tabPanelHeader';

function NftIssuerCard({
  collectionId,
  itemCount,
  label,
  src,
}: {
  collectionId: string;
  itemCount: number;
  label: string;
  src?: string;
}) {
  const { i18n } = useLingui();

  return (
    <Link to={`/portfolio/nfts/${collectionId}`}>
      <figure className={styles.cardListItem}>
        <img
          className={styles.cardImage}
          src={
            src ??
            new URL('../nfts/nftPlaceholder.svg', import.meta.url).pathname
          }
          alt=""
        />

        <figcaption className={styles.cardCaption}>{label}</figcaption>

        <div className={styles.cardItemCount}>
          {t(i18n)({
            message: plural(itemCount, {
              one: '# item',
              other: '# items',
            }),
          })}
        </div>
      </figure>
    </Link>
  );
}

interface Props {
  collections: AsyncValue<
    Array<{
      id: string;
      itemCount: number;
      label: string;
      src?: string;
    }>
  >;
}

export function PortfolioNftCollections({ collections }: Props) {
  const { i18n } = useLingui();

  const totalItems = collections.mapReady(x =>
    x.map(collection => collection.itemCount).reduce((sum, n) => sum + n, 0),
  );

  return (
    <div>
      <TabPanelHeader
        heading={totalItems.mapReady(totalItemsValue =>
          t(i18n)({
            message: plural(totalItemsValue, {
              one: '# item',
              other: '# items',
            }),
          }),
        )}
      />

      <div className={styles.cardList}>
        {collections.match({
          Pending: () =>
            new Array(3)
              .fill(undefined)
              .map((_, index) => (
                <Skeleton key={index} className={styles.cardSkeleton} />
              )),
          Ready: collectionsValue =>
            collectionsValue.map(collection => (
              <NftIssuerCard
                key={collection.id}
                collectionId={collection.id}
                itemCount={collection.itemCount}
                label={collection.label}
                src={collection.src}
              />
            )),
        })}
      </div>
    </div>
  );
}
