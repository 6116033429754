import { captureException } from '@sentry/core';

import { Err, type Result } from './result';

interface ErrorLike {
  message: string;
}

export function isErrorLike(err: unknown): err is ErrorLike {
  return (
    typeof err === 'object' &&
    err !== null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (err as any).message === 'string'
  );
}

export type UnexpectedError = {
  type: 'unexpected-error';
  message: string | undefined;
};

export function handleUnexpectedError(
  err: unknown,
  { context }: { context: string },
): Result<never, UnexpectedError> {
  const error = new Error(`Unexpected error happened in context: ${context}`, {
    cause: err,
  });

  // eslint-disable-next-line no-console
  console.error(error);
  captureException(error);

  return Err({
    type: 'unexpected-error',
    message: isErrorLike(err) ? err.message : undefined,
  });
}

export function isAbortError(err: unknown) {
  return err instanceof DOMException && err.name === 'AbortError';
}

export function isKeeperInvalidNetworkError(err: unknown) {
  return (
    isErrorLike(err) &&
    /Invalid connect options. Signer connect (.*?) not equals keeper connect (.*?)/.test(
      err.message,
    )
  );
}

export function isKeeperNotInstalledError(err: unknown) {
  return isErrorLike(err) && /WavesKeeper is not installed/.test(err.message);
}

export function isKeeperNoAccountsError(err: unknown) {
  return isErrorLike(err) && /Add Keeper Wallet account/.test(err.message);
}

export function isKeeperMobileUserBusyError(err: unknown) {
  if (!isErrorLike(err)) {
    return false;
  }

  return /Details: Provider internal error: User is busy. This is not error of signer./.test(
    err.message,
  );
}

export function isUserRejectionError(err: unknown) {
  if (!isErrorLike(err)) {
    return false;
  }

  // Keeper
  if (err.message === 'User denied message') {
    return true;
  }

  const match = err.message.match(
    /Details: Provider internal error: (.*). This is not error of signer./,
  );

  return (
    match != null &&
    [
      'User denied message', // Keeper
      'User rejected.', // Keeper Mobile
      'User cancelled', // Keeper Mobile
      'Disconnected', // Keeper Mobile
    ].includes(match[1])
  );
}
