import {
  base16Decode,
  base16Encode,
  base58Decode,
  base58Encode,
  createAddress,
  createPrivateKey,
  createPublicKey,
  utf8Encode,
} from '@keeper-wallet/waves-crypto';
import { computeAddress, HDNodeWallet } from 'ethers';

import { Err, Ok, Result } from '../_core/result';
import { EthereumAddress, WavesAddress } from './address';
import type { BlockchainPublicKeyJSON } from './types';

export type BlockchainPublicKey = EthereumPublicKey | WavesPublicKey;

export const BlockchainPublicKey = {
  fromJSON(
    json: BlockchainPublicKeyJSON,
  ): Result<BlockchainPublicKey, unknown> {
    switch (json.blockchain) {
      case 'ethereum':
        return EthereumPublicKey.fromJSON(json);
      case 'waves':
        return WavesPublicKey.fromJSON(json);
    }
  },

  fromString(
    blockchain: BlockchainPublicKey['blockchain'],
    string: string,
  ): Result<BlockchainPublicKey, unknown> {
    switch (blockchain) {
      case 'ethereum':
        return EthereumPublicKey.fromString(string);
      case 'waves':
        return WavesPublicKey.fromString(string);
    }
  },
};

export class EthereumPublicKey {
  #bytes: Uint8Array;

  private constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  static fromBytes(bytes: Uint8Array): Result<EthereumPublicKey, unknown> {
    return Ok(new EthereumPublicKey(bytes));
  }

  static fromJSON(
    json: BlockchainPublicKeyJSON<'ethereum'>,
  ): Result<EthereumPublicKey, unknown> {
    return Ok(new EthereumPublicKey(new Uint8Array(json.publicKey)));
  }

  static fromSeed(seed: string): Result<EthereumPublicKey, unknown> {
    return Result.try(() =>
      base16Decode(HDNodeWallet.fromPhrase(seed).publicKey),
    ).mapOk(bytes => new EthereumPublicKey(bytes));
  }

  static fromString(string: string): Result<EthereumPublicKey, unknown> {
    return Result.try(() => base16Decode(string)).mapOk(
      bytes => new EthereumPublicKey(bytes),
    );
  }

  get blockchain() {
    return 'ethereum' as const;
  }

  toJSON(): BlockchainPublicKeyJSON<'ethereum'> {
    return {
      blockchain: this.blockchain,
      publicKey: Array.from(this.#bytes),
    };
  }

  toString(): string {
    return `0x${base16Encode(this.#bytes)}`;
  }

  getAddress() {
    return EthereumAddress.fromBytes(
      base16Decode(computeAddress(this.toString())),
    ).assertOk();
  }
}

export class WavesPublicKey {
  #bytes: Uint8Array;

  private constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  static fromBytes(bytes: Uint8Array): Result<WavesPublicKey, unknown> {
    return Ok(new WavesPublicKey(bytes));
  }

  static fromJSON(
    json: BlockchainPublicKeyJSON<'waves'>,
  ): Result<WavesPublicKey, unknown> {
    return Ok(new WavesPublicKey(new Uint8Array(json.publicKey)));
  }

  static async fromSeed(
    seed: string,
  ): Promise<Result<WavesPublicKey, unknown>> {
    const wavesPublicKeyBytesResult = await Result.fromPromise(
      createPrivateKey(utf8Encode(seed)).then(createPublicKey),
    );

    return wavesPublicKeyBytesResult.flatMapOk(bytes =>
      WavesPublicKey.fromBytes(bytes),
    );
  }

  static fromString(string: string): Result<WavesPublicKey, unknown> {
    return Result.try(() => base58Decode(string))
      .flatMapOk(bytes => {
        if (bytes.length !== 32) {
          return Err('invalid-public-key');
        }
        return Ok(bytes);
      })
      .mapOk(bytes => new WavesPublicKey(bytes));
  }

  get blockchain() {
    return 'waves' as const;
  }

  toJSON(): BlockchainPublicKeyJSON<'waves'> {
    return {
      blockchain: this.blockchain,
      publicKey: Array.from(this.#bytes),
    };
  }

  toString(): string {
    return base58Encode(this.#bytes);
  }

  getAddress({ chainId }: { chainId: number }) {
    return WavesAddress.fromBytes(
      createAddress(this.#bytes, chainId),
    ).assertOk();
  }
}
