import { AssetLogo } from '../_core/assetLogo';
import * as styles from './assetCell.module.css';

export function AssetCell({
  blockchain,
  logo,
  ticker,
  id,
}: {
  blockchain: 'ethereum' | 'waves';
  logo?: string;
  ticker: string;
  id: string;
}) {
  return (
    <div className={styles.assetCell}>
      <AssetLogo id={id} ticker={ticker} logo={logo} blockchain={blockchain} />

      <span className={styles.assetCellTicker}>{ticker}</span>
    </div>
  );
}
