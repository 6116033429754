import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import {
  array,
  boolean,
  type Infer,
  nullable,
  number,
  string,
  type,
} from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { isAbortError } from '../../_core/errors';
import { handleResponse } from '../../_core/handleResponse';
import { isNotNull } from '../../_core/predicates';
import type { Asset } from '../../blockchain/types';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';
import type { AppThunkAction } from '../../store/types';
import type { UpdateWavesNftsAction } from './nfts';

export const WavesAsset = type({
  assetId: string(),
  name: string(),
  decimals: number(),
  description: string(),
  issueHeight: number(),
  issueTimestamp: number(),
  issuer: string(),
  quantity: string(),
  reissuable: boolean(),
  scripted: boolean(),
  minSponsoredAssetFee: nullable(string()),
  originTransactionId: string(),
});

export type WavesAsset = Infer<typeof WavesAsset>;

export const WAVES_ASSET: Asset<'waves'> = {
  blockchain: 'waves',
  assetId: 'WAVES',
  name: 'Waves',
  decimals: 8,
  description: '',
  issueHeight: 0,
  issueTimestamp: new Date('2016-04-11T21:00:00.000Z').getTime(),
  issuer: '',
  quantity: '10000000000000000',
  reissuable: false,
  scripted: false,
  minSponsoredAssetFee: '100000',
  originTransactionId: '',
};

export type WavesAssetsDetails = Partial<{
  [assetId: string]: Asset<'waves'>;
}>;

const reducer: Reducer<
  Record<Network, WavesAssetsDetails>,
  | {
      type: 'UPDATE_WAVES_ASSETS';
      payload: {
        assetsDetails: WavesAssetsDetails;
        network: Network;
      };
    }
  | UpdateWavesNftsAction
> = (
  state = {
    [Network.Mainnet]: { WAVES: WAVES_ASSET },
    [Network.Testnet]: { WAVES: WAVES_ASSET },
  },
  action,
) => {
  switch (action.type) {
    case 'UPDATE_WAVES_ASSETS':
      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          ...action.payload.assetsDetails,
        },
      };
    case 'UPDATE_WAVES_NFTS':
      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          ...Object.fromEntries(
            action.payload.nfts.map(nft => [nft.assetId, nft]),
          ),
        },
      };
    default:
      return state;
  }
};

export default reducer;

export function fetchWavesAssetsDetailsAction({
  assetIds,
  network,
  signal,
}: {
  assetIds: Array<string | null | undefined>;
  network: Network;
  signal?: AbortSignal;
}): AppThunkAction<Promise<void>> {
  return async dispatch => {
    if (assetIds.length === 0) return;

    const assetIdsToFetch = Array.from(new Set(assetIds))
      .filter(isNotNull)
      .filter(id => id !== 'WAVES');

    const maxAssetsPerRequest = 100;
    const assetsDetails: WavesAssetsDetails = {};

    for (let i = 0; i < assetIdsToFetch.length; i += maxAssetsPerRequest) {
      const assetsDetailsBatch = await fetch(
        new URL('assets/details', WAVES_NETWORK_CONFIGS[network].nodeUrl),
        {
          method: 'POST',
          headers: {
            accept: 'application/json; large-significand-format=string',
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            ids: assetIdsToFetch.slice(i, i + maxAssetsPerRequest),
          }),
          signal,
        },
      ).then(handleResponse(array(WavesAsset)));

      assetsDetailsBatch.forEach(assetDetails => {
        assetsDetails[assetDetails.assetId] = {
          ...assetDetails,
          blockchain: 'waves',
        };
      });
    }

    dispatch({
      type: 'UPDATE_WAVES_ASSETS',
      payload: {
        assetsDetails,
        network,
      },
    });
  };
}

export function useWavesAssetsDetails({
  assetIds,
}: {
  assetIds: string[];
}): AsyncValue<WavesAssetsDetails> {
  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    const abortController = new AbortController();

    dispatch(
      fetchWavesAssetsDetailsAction({
        assetIds,
        network,
        signal: abortController.signal,
      }),
    ).catch(err => {
      if (isAbortError(err)) return;

      throw err;
    });

    return () => {
      abortController.abort();
    };
  }, [assetIds, dispatch, network]);

  const assetsDetails = useAppSelector(
    state => state.cache.waves.assetsDetails[network],
  );

  return useMemo(() => {
    const result: WavesAssetsDetails = {};

    for (const assetId of assetIds) {
      const assetDetails = assetsDetails[assetId];
      if (!assetDetails) return AsyncValue.Pending;

      result[assetId] = assetDetails;
    }

    return AsyncValue.Ready(result);
  }, [assetIds, assetsDetails]);
}
