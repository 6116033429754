import type { Signer } from '@waves/signer';

import {
  handleUnexpectedError,
  isKeeperInvalidNetworkError,
  isKeeperNoAccountsError,
  isKeeperNotInstalledError,
  isUserRejectionError,
} from '../../_core/errors';
import { None, Some } from '../../_core/maybe';
import { Err, Ok, type ResultErr } from '../../_core/result';
import { WavesPublicKey } from '../../blockchain/publicKey';
import {
  WavesTransaction,
  type WavesTransactionInput,
} from '../../blockchain/transaction/wavesTransaction';
import type { BlockchainTransactionSendResponse } from '../../blockchain/types';
import type { Account } from '../types';
import { AbstractAccount } from './abstractAccount';

export class KeeperExtensionAccount extends AbstractAccount {
  #publicKey;

  private constructor({
    id,
    name,
    publicKey,
  }: {
    id: string;
    name: string;
    publicKey: WavesPublicKey;
  }) {
    super({ id, name });
    this.#publicKey = publicKey;
  }

  static fromInMemoryJSON(
    json: ReturnType<KeeperExtensionAccount['toInMemoryJSON']>,
  ) {
    return WavesPublicKey.fromString(json.publicKey).mapOk(
      publicKey =>
        new KeeperExtensionAccount({
          id: json.id,
          name: json.name,
          publicKey,
        }),
    );
  }

  static fromPersistedJSON(
    json: ReturnType<KeeperExtensionAccount['toPersistedJSON']>,
  ) {
    return WavesPublicKey.fromString(json.publicKey).mapOk(
      publicKey =>
        new KeeperExtensionAccount({
          id: json.id,
          name: json.name,
          publicKey,
        }),
    );
  }

  static fromPublicKey(publicKey: WavesPublicKey) {
    return new KeeperExtensionAccount({ id: '', name: '', publicKey });
  }

  get type() {
    return 'keeper-extension' as const;
  }

  getSeed() {
    return None;
  }

  getPrivateKeys() {
    return [];
  }

  getPublicKeys() {
    return [this.#publicKey];
  }

  isDuplicateOf(other: Account): boolean {
    return (
      this.id !== other.id &&
      this.type === other.type &&
      this.#publicKey.toString() === other.#publicKey.toString()
    );
  }

  toInMemoryJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      publicKey: this.#publicKey.toString(),
    };
  }

  toPersistedJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      publicKey: this.#publicKey.toString(),
    };
  }

  async sendTransaction(
    input: WavesTransactionInput,
    getSigner: (accountType: 'keeper-extension') => Promise<Signer>,
  ) {
    try {
      const signer = await getSigner('keeper-extension');
      const { publicKey } = await signer.login();

      if (publicKey !== this.#publicKey.toString()) {
        return Err({ type: 'keeper-extension-account-is-wrong' });
      }

      const [tx] = await WavesTransaction.fromInput(
        input,
        this.#publicKey,
      ).broadcastWithSigner(signer);

      const response: BlockchainTransactionSendResponse<'waves'> = {
        blockchain: 'waves',
        id: tx.id,
      };

      return Ok(Some(response));
    } catch (err) {
      if (isUserRejectionError(err)) return Ok(None);

      if (isKeeperInvalidNetworkError(err)) {
        return Err({ type: 'keeper-extension-network-is-wrong' });
      }

      if (isKeeperNotInstalledError(err)) {
        return Err({ type: 'keeper-extension-is-not-installed' });
      }

      if (isKeeperNoAccountsError(err)) {
        return Err({ type: 'keeper-extension-has-no-accounts' });
      }

      return handleUnexpectedError(err, {
        context: 'Send transaction using Keeper Extension',
      });
    }
  }
}

export type KeeperExtensionAccountSendTransactionError = ResultErr<
  Awaited<ReturnType<KeeperExtensionAccount['sendTransaction']>>
>;
