import type { Router } from '@remix-run/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { matchRoutes } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { type MetaDescriptor } from './_core/meta';
import { type AccumulatedRpcRequests } from './_core/rpc';
import { type AppApi, type ClientApi } from './_core/rpcTypes';
import { type AnalyticsConfig } from './analytics';
import { type Network } from './network/types';
import { type AppRouteObject } from './routes';
import { type AppState } from './store/types';

export type EmbedContext = 'iframe' | 'window.open' | null;

export interface EntryClient {
  api: ClientApi;
  accumulatedRequests: AccumulatedRpcRequests<AppApi>;
}

export interface SentryConfig {
  dsn: string;
  environment: string;
  release: string;
}

interface EntryContext {
  analyticsConfig: AnalyticsConfig | undefined;
  client: EntryClient | null;
  css: string[];
  dataServiceUrl: string;
  embedContext: EmbedContext;
  ethereumNodeUrls: {
    [Network.Mainnet]: string;
    [Network.Testnet]: string;
  };
  initialReduxState: AppState;
  js: string[];
  meta: MetaDescriptor | undefined;
  nonce: string;
  origin: string;
  sentryConfig: SentryConfig | undefined;
}

const EntryContext = createContext<EntryContext | null>(null);

interface EntryProps {
  children: React.ReactNode;
  client: EntryClient | null;
  css: string[];
  dataServiceUrl: string;
  embedContext: EmbedContext;
  ethereumNodeUrls: {
    [Network.Mainnet]: string;
    [Network.Testnet]: string;
  };
  initialReduxState: AppState;
  js: string[];
  nonce: string;
  origin: string;
  router: Router;
  routes: AppRouteObject[];
  sentryConfig: SentryConfig | undefined;
  analyticsConfig: AnalyticsConfig | undefined;
}

export function Entry({
  children,
  client,
  css,
  dataServiceUrl,
  embedContext,
  ethereumNodeUrls,
  initialReduxState,
  js,
  nonce,
  origin,
  router,
  routes,
  sentryConfig,
  analyticsConfig,
}: EntryProps) {
  const [{ errors, loaderData, location }, setRouterState] = useState(
    router.state,
  );

  useEffect(() => router.subscribe(setRouterState), [router]);

  const meta = useMemo(() => {
    const matches = matchRoutes(routes, location);
    let hadError = false;

    return matches?.reduce<MetaDescriptor>((acc, { params, route }) => {
      if (!hadError && route.meta) {
        let routeMeta: MetaDescriptor | void;

        const error = errors?.[route.id];

        if (error) {
          hadError = true;
        }

        if (typeof route.meta === 'function') {
          routeMeta = route.meta({
            data: loaderData?.[route.id],
            error,
            location,
            params,
          });
        } else {
          routeMeta = route.meta;
        }

        return Object.assign(acc, routeMeta);
      }

      return acc;
    }, {});
  }, [errors, loaderData, location, routes]);

  const value = useMemo(
    (): EntryContext => ({
      analyticsConfig,
      client,
      css,
      dataServiceUrl,
      embedContext,
      ethereumNodeUrls,
      initialReduxState,
      js,
      meta,
      nonce,
      origin,
      sentryConfig,
    }),
    [
      analyticsConfig,
      client,
      css,
      dataServiceUrl,
      embedContext,
      ethereumNodeUrls,
      initialReduxState,
      js,
      meta,
      nonce,
      origin,
      sentryConfig,
    ],
  );

  return (
    <EntryContext.Provider value={value}>{children}</EntryContext.Provider>
  );
}

export function useEntryContext() {
  const value = useContext(EntryContext);
  invariant(value, 'You must render EntryProvider higher in  the tree');
  return value;
}
