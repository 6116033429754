import type { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useEffect } from 'react';
import {
  type IndexRouteObject,
  type NonIndexRouteObject,
  Outlet,
} from 'react-router-dom';

import type { MetaDescriptor, MetaFunctionArgs } from './_core/meta';
import { RequireAccounts } from './accounts/requireAccounts';
import { Document } from './document';
import type { SentryConfig } from './entry';
import { Layout } from './layout/layout';
import { demoLoader, Demos } from './routes/_demos';
import { createErrorMeta, ErrorPage } from './routes/_error';
import { AccountDetailsPage } from './routes/accountDetails';
import { AccountsPage } from './routes/accounts';
import { AddAccountPage } from './routes/addAccount/addAccount';
import { AddAccountIndexPage } from './routes/addAccount/addAccountIndex';
import { ConfirmAccountPage } from './routes/addAccount/confirmAccountPage';
import { CreateAccountPage } from './routes/addAccount/createAccount';
import { CreateAccountIndexPage } from './routes/addAccount/createAccountIndexPage';
import { ImportAccountPage } from './routes/addAccount/importAccount';
import { ImportEthereumAccount } from './routes/addAccount/importEthereumAccount';
import { ImportKeeperExtensionAccount } from './routes/addAccount/importKeeperExtensionAccount';
import { ImportKeeperMobileAccount } from './routes/addAccount/importKeeperMobileAccount';
import { ImportMulticoinAccount } from './routes/addAccount/importMulticoinAccount';
import { ImportSeedOrKeyAccountPage } from './routes/addAccount/importSeedOrKeyAccount';
import { ImportWavesAccount } from './routes/addAccount/importWavesAccount';
import { RequireSeed } from './routes/addAccount/requireSeed';
import { SaveAccountPage } from './routes/addAccount/saveAccountPage';
import { DashboardPage } from './routes/dashboard';
import { ForgotPassword } from './routes/forgotPassword';
import { IframePage } from './routes/iframe';
import { createNftLoader, NftPage } from './routes/nft';
import { InvestmentsPage } from './routes/portfolio/investments';
import {
  NftsPage,
  PortfolioNftCollectionPage,
  PortfolioNftCollectionsPage,
} from './routes/portfolio/nfts';
import { PortfolioPage } from './routes/portfolio/portfolio';
import { WalletPage } from './routes/portfolio/wallet';
import {
  requestWalletAccessMeta,
  RequestWalletAccessPage,
} from './routes/requestWalletAccess';
import { SendAssetsPage } from './routes/send';
import { SwapAssetsPage } from './routes/swap';
import type { AppStore } from './store/types';

interface AppRouteParams {
  meta?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((args: MetaFunctionArgs<any>) => MetaDescriptor | void) | MetaDescriptor;
}

type AppIndexRouteInput = IndexRouteObject & AppRouteParams;

type AppNonIndexRouteInput = Omit<NonIndexRouteObject, 'children' | 'id'> & {
  children?: AppRouteInput[];
} & AppRouteParams;

type AppRouteInput = AppIndexRouteInput | AppNonIndexRouteInput;

export type AppRouteObject = AppRouteInput & {
  id: string;
};

function convertRoutes(
  routes: AppRouteInput[],
  parentPath: number[] = [],
): AppRouteObject[] {
  return routes.map((route, index) => {
    const treePath = [...parentPath, index];
    const id = treePath.join('-');

    return route.index
      ? { ...route, id }
      : {
          ...route,
          id,
          children: route.children
            ? convertRoutes(route.children, treePath)
            : undefined,
        };
  });
}

export function createRoutes({
  i18n,
  store,
  sentryConfig,
}: {
  i18n: I18n;
  store: AppStore;
  sentryConfig: SentryConfig | undefined;
}) {
  return convertRoutes([
    {
      meta: () => {
        const title = 'Keeper Wallet';

        const description = t(
          i18n,
        )`Your entry point to the Waves blockchain and Waves-powered web services`;

        return {
          'application-name': 'Keeper Wallet',
          title,
          description,
          'og:title': title,
          'og:description': description,
        };
      },
      element: (
        <Document>
          <Outlet />
        </Document>
      ),
      errorElement: (
        <Document>
          <ErrorPage />
        </Document>
      ),
      children: [
        ...(__DEV__
          ? [
              {
                path: '_demos/*',
                element: <Demos />,
                loader: demoLoader,
              },
            ]
          : []),
        {
          path: '/',
          meta: createErrorMeta({ i18n }),
          element: (
            <Layout>
              <Outlet />
            </Layout>
          ),
          errorElement: (
            <Layout>
              <ErrorPage />
            </Layout>
          ),
          children: [
            {
              index: true,
              element: (
                <RequireAccounts>
                  <DashboardPage />
                </RequireAccounts>
              ),
            },
            ...(sentryConfig?.environment !== 'production'
              ? [
                  {
                    path: '/debug-sentry',
                    children: [
                      {
                        index: true,
                        Component: () => {
                          return (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 12,
                                }}
                              >
                                <h1>Emit error on:</h1>
                                <p>
                                  <button
                                    onClick={() => {
                                      throw new Error(
                                        'Error from component event handler',
                                      );
                                    }}
                                  >
                                    Event handler
                                  </button>
                                </p>
                                <p>
                                  <a href="/debug-sentry/componentEffect">
                                    Component effect
                                  </a>
                                </p>
                                <p>
                                  <a href="/debug-sentry/reactRouterLoader">
                                    React-router loader
                                  </a>
                                </p>
                                <p>
                                  <a href="/debug-sentry/expressRouterHandler">
                                    Express router handler
                                  </a>
                                </p>
                              </div>
                            </>
                          );
                        },
                      },
                      {
                        path: 'componentEffect',
                        Component: () => {
                          useEffect(() => {
                            throw new Error('Error from component useEffect');
                          }, []);

                          return <h1>Emits error</h1>;
                        },
                      },
                      {
                        path: 'reactRouterLoader',
                        loader: () => {
                          throw new Error('Error from react-router loader');
                        },
                      },
                    ],
                  },
                ]
              : []),
            {
              path: 'portfolio',
              element: (
                <RequireAccounts>
                  <PortfolioPage />
                </RequireAccounts>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <RequireAccounts>
                      <WalletPage />
                    </RequireAccounts>
                  ),
                },
                {
                  path: 'investments',
                  element: (
                    <RequireAccounts>
                      <InvestmentsPage />
                    </RequireAccounts>
                  ),
                },
                {
                  path: 'nfts',
                  element: (
                    <RequireAccounts>
                      <NftsPage />
                    </RequireAccounts>
                  ),
                  children: [
                    {
                      index: true,
                      element: <PortfolioNftCollectionsPage />,
                    },
                    {
                      path: ':issuerId',
                      element: <PortfolioNftCollectionPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'iframe',
              element: <IframePage />,
            },
            {
              path: 'nfts/:id',
              loader: createNftLoader({ i18n, store }),
              element: <NftPage />,
            },
            {
              path: 'request-wallet-access',
              meta: requestWalletAccessMeta,
              element: <RequestWalletAccessPage />,
            },
            {
              path: 'accounts',
              element: (
                <RequireAccounts>
                  <AccountsPage />
                </RequireAccounts>
              ),
            },
            {
              path: 'accounts/:id',
              element: (
                <RequireAccounts>
                  <AccountDetailsPage />
                </RequireAccounts>
              ),
            },
            {
              path: 'add-account',
              meta: () => {
                const title = t(i18n)`Add account`;

                return { title, 'og:title': title };
              },
              element: <AddAccountPage />,
              children: [
                {
                  index: true,
                  element: <AddAccountIndexPage />,
                },
                {
                  path: 'create',
                  meta: () => {
                    const title = t(i18n)`Back up secret phrase`;

                    return { title, 'og:title': title };
                  },
                  element: <CreateAccountPage />,
                  children: [
                    {
                      index: true,
                      element: <CreateAccountIndexPage />,
                    },

                    {
                      path: 'confirm',
                      meta: () => {
                        const title = t(i18n)`Confirm backup`;

                        return { title, 'og:title': title };
                      },
                      element: (
                        <RequireSeed>
                          {({
                            seed,
                            seedIndicesAfterShuffle,
                            indicesToCheck,
                          }) => (
                            <ConfirmAccountPage
                              seed={seed}
                              seedIndicesAfterShuffle={seedIndicesAfterShuffle}
                              indicesToCheck={indicesToCheck}
                            />
                          )}
                        </RequireSeed>
                      ),
                    },
                    {
                      path: 'save',
                      meta: () => {
                        const title = t(i18n)`Account name`;

                        return { title, 'og:title': title };
                      },
                      element: (
                        <RequireSeed>
                          {({ seed }) => <SaveAccountPage seed={seed} />}
                        </RequireSeed>
                      ),
                    },
                  ],
                },
                {
                  path: 'import',
                  meta: () => {
                    const title = t(i18n)`Add an existing account`;

                    return { title, 'og:title': title };
                  },
                  children: [
                    {
                      index: true,
                      element: <ImportAccountPage />,
                    },
                    {
                      path: 'mobile',
                      meta: () => {
                        const title = t(i18n)`Connect Keeper Mobile`;

                        return { title, 'og:title': title };
                      },
                      element: <ImportKeeperMobileAccount />,
                    },
                    {
                      path: 'extension',
                      meta: () => {
                        const title = t(i18n)`Connect Keeper Extension`;

                        return { title, 'og:title': title };
                      },
                      element: <ImportKeeperExtensionAccount />,
                    },
                  ],
                },
                {
                  path: 'seed-or-key',
                  meta: () => {
                    const title = t(i18n)`Choose account type`;

                    return { title, 'og:title': title };
                  },
                  element: <ImportSeedOrKeyAccountPage />,
                },
                {
                  path: 'multicoin',
                  meta: () => {
                    const title = t(i18n)`Import Multi-Coin Account`;

                    return { title, 'og:title': title };
                  },
                  element: <ImportMulticoinAccount />,
                },
                {
                  path: 'ethereum',
                  meta: () => {
                    const title = t(i18n)`Import Ethereum Account`;

                    return { title, 'og:title': title };
                  },
                  element: <ImportEthereumAccount />,
                },
                {
                  path: 'waves',
                  meta: () => {
                    const title = t(i18n)`Import Waves Account`;

                    return { title, 'og:title': title };
                  },
                  element: <ImportWavesAccount />,
                },
              ],
            },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'swap',
              element: (
                <RequireAccounts>
                  <SwapAssetsPage />
                </RequireAccounts>
              ),
            },
            {
              path: 'send',
              element: (
                <RequireAccounts>
                  <SendAssetsPage />
                </RequireAccounts>
              ),
            },
            {
              path: '*',
              loader: () => {
                throw new Response(t(i18n)`Page Not Found`, { status: 404 });
              },
              element: null,
            },
          ],
        },
      ],
    },
  ]);
}
