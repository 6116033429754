import { createContext, useContext, useId, useMemo } from 'react';

import { FormHelperText } from './formHelperText';
import { FormLabel } from './formLabel';

interface FormControlContextValue {
  error: string | undefined;
  hasError: boolean | undefined;
  id: string;
}

const FormControlContext = createContext<FormControlContextValue | null>(null);

export function useFormControlContext() {
  return useContext(FormControlContext);
}

interface Props {
  children: React.ReactNode;
  label?: string;
  helperText?: string;
  error?: string;
  hasError?: boolean;
  className?: string;
  id?: string;
}

export function FormControlProvider({
  children,
  error,
  hasError,
  id: idProp,
}: {
  children: React.ReactNode;
  error?: string;
  hasError?: boolean;
  id?: string;
}) {
  const id = useId();

  const contextValue = useMemo(
    (): FormControlContextValue => ({
      error,
      hasError,
      id: idProp ?? id,
    }),
    [error, hasError, idProp, id],
  );

  return (
    <FormControlContext.Provider value={contextValue}>
      {children}
    </FormControlContext.Provider>
  );
}

export function FormControl({
  children,
  label,
  helperText,
  error,
  hasError,
  className,
  id,
}: Props) {
  return (
    <FormControlProvider error={error} hasError={hasError} id={id}>
      <div className={className}>
        {label && <FormLabel>{label}</FormLabel>}

        {children}

        {error ? (
          <FormHelperText hasError>{error}</FormHelperText>
        ) : (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </div>
    </FormControlProvider>
  );
}
