import type { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useMemo } from 'react';
import {
  type LoaderFunctionArgs,
  useLoaderData,
  useParams,
} from 'react-router-dom';
import { number, type } from 'superstruct';
import invariant from 'tiny-invariant';

import { isErrorLike } from '../_core/errors';
import {
  fetchWavesAssetsDetailsAction,
  useWavesAssetsDetails,
} from '../cache/wavesNode/assetsDetails';
import { selectWavesNftById } from '../cache/wavesNode/nfts';
import { Container } from '../layout/layout';
import { NftDetailView } from '../nfts/nftDetailView';
import type { AppStore } from '../store/types';

export function createNftLoader({
  i18n,
  store,
}: {
  i18n: I18n;
  store: AppStore;
}) {
  return async ({ request, params }: LoaderFunctionArgs) => {
    invariant(params.id);

    const { network } = store.getState();

    const select = selectWavesNftById({ id: params.id, network });

    let assetDetails = select(store.getState());

    function throw404(): never {
      throw new Response(t(i18n)`Nft Not Found`, { status: 404 });
    }

    if (!assetDetails) {
      try {
        await store.dispatch(
          fetchWavesAssetsDetailsAction({
            assetIds: [params.id],
            network,
            signal: request.signal,
          }),
        );
      } catch (err) {
        if (isErrorLike(err)) {
          let parsed: { error: number } | undefined;

          try {
            parsed = type({ error: number() }).mask(JSON.parse(err.message));
          } catch {
            // ignore
          }

          if (parsed && parsed.error === 314) {
            throw404();
          }
        }

        throw err;
      }
    }

    assetDetails = select(store.getState());

    if (!assetDetails) {
      throw404();
    }

    return {
      assetDetails,
    };
  };
}

type LoaderData = Awaited<ReturnType<ReturnType<typeof createNftLoader>>>;

export function NftPage() {
  const loaderData = useLoaderData() as LoaderData;
  const params = useParams();
  const nftAssetId = params.id;
  invariant(nftAssetId);

  const assetIds = useMemo(() => [nftAssetId], [nftAssetId]);

  const wavesAssetDetails = useWavesAssetsDetails({
    assetIds,
  });

  const nftAssetDetails = wavesAssetDetails.match({
    Pending: () => loaderData.assetDetails,
    Ready: x => x[nftAssetId] ?? loaderData.assetDetails,
  });

  return (
    <Container>
      <NftDetailView
        collectionTitle={nftAssetDetails.issuer}
        creator={nftAssetDetails.issuer}
        description={nftAssetDetails.description}
        title={nftAssetDetails.name}
        viewOnLinks={[
          {
            iconUrl: new URL(
              '../nfts/viewOnIcons/explorer.svg',
              import.meta.url,
            ).pathname,
            label: 'Explorer',
            href: `https://wavesexplorer.com/assets/${nftAssetDetails.assetId}`,
          },
        ]}
      />
    </Container>
  );
}
