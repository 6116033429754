import { Link, useSearchParams } from 'react-router-dom';

import { ChevronLeftIcon } from '../../icons/chevronLeft';
import * as styles from './shell.module.css';

interface Props {
  backPath: string;
  children: React.ReactNode;
  heading: string;
}

export function AddAccountShell({ backPath, children, heading }: Props) {
  const [params] = useSearchParams();
  const next = params.get('next');
  const nextParams = new URLSearchParams();

  if (next) {
    nextParams.set('next', next);
  }

  const nextSearch = nextParams.toString();

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Link
          className={styles.backButton}
          to={{ pathname: backPath, search: nextSearch }}
        >
          <ChevronLeftIcon />
        </Link>

        <h1 className={styles.heading}>{heading}</h1>
      </header>

      <div className={styles.children}>{children}</div>
    </div>
  );
}
