import { useCallback, useEffect, useState } from 'react';

interface ChildProps {
  copy: (text: string) => Promise<void>;
  isCopied: boolean;
}

interface Props {
  children: (childProps: ChildProps) => React.ReactElement;
}

export function CopyToClipboard({ children }: Props) {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  const copy = useCallback(async (text: string) => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  }, []);

  return children({
    copy,
    isCopied,
  });
}
