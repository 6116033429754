import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo, useState } from 'react';

import { SegmentedControl } from '../_core/segmentedControl';
import { useAccounts } from '../accounts/requireAccounts';
import { Account } from '../accounts/types';
import { Container } from '../layout/layout';
import { SwapAssets } from '../swap/swapAssets';
import * as styles from './swap.module.css';

export function SwapAssetsPage() {
  const { i18n } = useLingui();
  const [selectedAccountJSON] = useAccounts({ onlySelected: true });

  const selectedAccount = useMemo(
    () => Account.fromInMemoryJSON(selectedAccountJSON).assertOk(),
    [selectedAccountJSON],
  );

  const [selectedBlockchain, setSelectedBlockchain] = useState<
    'ethereum' | 'waves'
  >('waves');

  return (
    <Container>
      <div className={styles.root}>
        <h1 className={styles.swapPageTitle}>
          <Trans>Swap Assets</Trans>
        </h1>

        <div className={styles.blockchainSwitcher}>
          <SegmentedControl
            options={[
              { id: 'waves', label: 'Waves' },
              { id: 'ethereum', label: 'Ethereum' },
            ]}
            selectedOptionId={selectedBlockchain}
            onChange={setSelectedBlockchain}
          />
        </div>

        <div
          style={{
            display: selectedBlockchain === 'waves' ? undefined : 'none',
          }}
        >
          {selectedAccount.type === 'ethereum' ? (
            <div className={styles.message}>
              {t(i18n)`Swaps in Waves are not available for selected account`}
            </div>
          ) : (
            <SwapAssets account={selectedAccount} />
          )}
        </div>

        <div
          style={{
            display: selectedBlockchain === 'ethereum' ? undefined : 'none',
          }}
        >
          <div className={styles.message}>
            {t(i18n)`Token swap with the Ethereum network coming soon`}
          </div>
        </div>
      </div>
    </Container>
  );
}
