import { base58Decode, base58Encode } from '@keeper-wallet/waves-crypto';

import { Err, Ok } from '../../_core/result';

export class WavesPrivateKey {
  #bytes: Uint8Array;

  private constructor(bytes: Uint8Array) {
    this.#bytes = bytes;
  }

  static fromBytes(bytes: Uint8Array) {
    if (bytes.length !== 32) {
      return Err({ type: 'invalid-waves-private-key' });
    }

    return Ok(new WavesPrivateKey(bytes));
  }

  static fromJSON(json: ReturnType<WavesPrivateKey['toJSON']>) {
    return WavesPrivateKey.fromBytes(new Uint8Array(json.privateKey));
  }

  static fromString(string: string) {
    return WavesPrivateKey.fromBytes(base58Decode(string));
  }

  get blockchain() {
    return 'waves' as const;
  }

  toBytes() {
    return this.#bytes;
  }

  toJSON() {
    return {
      blockchain: this.blockchain,
      privateKey: Array.from(this.#bytes),
    };
  }

  toString() {
    return base58Encode(this.#bytes);
  }
}
