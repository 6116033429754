import clsx from 'clsx';
import { useState } from 'react';

import { EyeIcon } from '../icons/eye';
import { Button } from './button';
import * as styles from './revealableBox.module.css';

interface Props {
  children: React.ReactNode;
  revealButtonText: string;
}

export function RevealableBox({ children, revealButtonText }: Props) {
  const [isRevealed, setIsRevealed] = useState(false);

  return (
    <div className={styles.root}>
      {children}

      <div
        className={clsx(styles.backdrop, {
          [styles.backdropHidden]: isRevealed,
        })}
      >
        <Button
          className={styles.showButton}
          size="small"
          startIcon={<EyeIcon />}
          text={revealButtonText}
          variant="outlined"
          onClick={() => setIsRevealed(true)}
        />
      </div>
    </div>
  );
}
