import { base16Decode } from '@keeper-wallet/waves-crypto';
import { HDNodeWallet, Mnemonic } from 'ethers';

import { Err, Ok, type Result } from '../../_core/result';
import { EthereumPrivateKey } from '../../blockchain/privateKey/ethereumPrivateKey';

export type Bip39SeedError = {
  type: 'seed-is-invalid';
};

type MnemonicWordCount = 12 | 15 | 18 | 21 | 24;

function getWordCountFromMnemonic(
  mnemonic: Mnemonic,
): MnemonicWordCount | undefined {
  const entropyBytes = base16Decode(mnemonic.entropy);

  switch (entropyBytes.length) {
    case 16:
      return 12;
    case 20:
      return 15;
    case 24:
      return 18;
    case 28:
      return 21;
    case 32:
      return 24;
    default:
      return undefined;
  }
}

export class Bip39Seed {
  #value;

  private constructor(
    value: string,
    readonly wordCount: MnemonicWordCount,
  ) {
    this.#value = value;
  }

  static fromString(input: string): Result<Bip39Seed, Bip39SeedError> {
    const normalized = input.trim().replace(/\s+/g, ' ');

    if (!Mnemonic.isValidMnemonic(normalized)) {
      return Err({ type: 'seed-is-invalid' });
    }

    const wordCount = getWordCountFromMnemonic(Mnemonic.fromPhrase(normalized));

    if (wordCount == null) {
      return Err({ type: 'seed-is-invalid' });
    }

    return Ok(new Bip39Seed(normalized, wordCount));
  }

  computeEthereumPrivateKey() {
    return EthereumPrivateKey.fromBytes(
      base16Decode(HDNodeWallet.fromPhrase(this.#value).privateKey),
    ).assertOk();
  }

  toString() {
    return this.#value;
  }

  toWords() {
    return this.#value.split(' ');
  }
}
