import { combineReducers } from 'redux';

import assets from './assets';
import ethereumBalances from './ethereumBalances';
import ethereumTokenList from './ethereumTokenList';
import leasesInfo from './leasesInfo';
import products from './products';
import protocols from './protocols';
import usdPrices from './usdPrices';

export default combineReducers({
  assets,
  ethereumBalances,
  ethereumTokenList,
  leasesInfo,
  products,
  protocols,
  usdPrices,
});
