import clsx from 'clsx';

import { type AsyncValue } from '../_core/asyncValue';
import { Skeleton } from '../_core/skeleton';
import { Container } from '../layout/layout';
import * as styles from './pageHeader.module.css';

interface Props {
  className?: string;
  caption?: React.ReactNode;
  heading: AsyncValue<React.ReactNode>;
  children?: React.ReactNode;
}

export function PageHeader({ className, children, caption, heading }: Props) {
  return (
    <div className={clsx(className, styles.pageHeader)}>
      <Container>
        <div className={styles.pageHeaderTitle}>{caption}</div>

        {heading.match({
          Pending: () => <Skeleton className={styles.skeleton} />,
          Ready: headingValue => (
            <h1 className={styles.pageHeaderValue}>{headingValue}</h1>
          ),
        })}

        {children}
      </Container>
    </div>
  );
}
