import { Contract, toBigInt } from 'ethers';

import type { EthereumMoney } from '../../_core/money';
import { ETHEREUM_ASSET } from '../../ethereum/constants';
import type { Network } from '../../network/types';
import type { EthereumAddress } from '../address';
import type { EthereumFee } from '../fee/ethereumFee';

export interface EthereumTransactionInput {
  readonly blockchain: 'ethereum';
  readonly amount: EthereumMoney;
  readonly fee: EthereumFee;
  readonly network: Network;
  readonly recipient: EthereumAddress;
}

export function encodeErc20TransferData({
  amount,
  recipient,
}: {
  amount: EthereumMoney;
  recipient: EthereumAddress;
}) {
  return new Contract(amount.asset.address, [
    'function transfer(address to, uint amount)',
  ]).interface.encodeFunctionData('transfer', [
    recipient.toString(),
    toBigInt(amount.getCoins().toString()),
  ]);
}

export class EthereumTransaction {
  #input;
  #from;

  private constructor(input: EthereumTransactionInput, from: EthereumAddress) {
    this.#input = input;
    this.#from = from;
  }

  static fromInput(input: EthereumTransactionInput, from: EthereumAddress) {
    return new EthereumTransaction(input, from);
  }

  toTransactionRequest() {
    if (this.#input.amount.asset.address === ETHEREUM_ASSET.address) {
      return {
        from: this.#from.toString(),
        to: this.#input.recipient.toString(),
        value: this.#input.amount.getCoins().toString(),

        gasLimit: toBigInt(this.#input.fee.gasLimit.toString()),
        maxFeePerGas: toBigInt(this.#input.fee.maxFeePerGas.toString()),
        maxPriorityFeePerGas: toBigInt(
          this.#input.fee.maxPriorityFeePerGas.toString(),
        ),
      };
    }

    const assetAddress = this.#input.amount.asset.address;

    return {
      from: this.#from.toString(),
      to: assetAddress,
      value: 0,

      data: encodeErc20TransferData({
        amount: this.#input.amount,
        recipient: this.#input.recipient,
      }),

      gasLimit: toBigInt(this.#input.fee.gasLimit.toString()),
      maxFeePerGas: toBigInt(this.#input.fee.maxFeePerGas.toString()),
      maxPriorityFeePerGas: toBigInt(
        this.#input.fee.maxPriorityFeePerGas.toString(),
      ),
    };
  }
}
