import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Button, LinkButton } from '../_core/button';
import { FormControl } from '../_core/formControl';
import { FormLabel } from '../_core/formLabel';
import { Input } from '../_core/input';
import { ChevronLeftIcon } from '../icons/chevronLeft';
import { WarningIcon } from '../icons/warning';
import { useAppDispatch } from '../store/react';
import { deleteAllAccounts } from '../vault/redux';
import * as styles from './forgotPassword.module.css';

export function ForgotPassword() {
  const { i18n } = useLingui();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const nextUrl = params.get('next') ?? '/';

  const {
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<{ phrase: string }>();

  const confirmationPhraseTranslated = t(i18n)({
    message: 'DELETE MY ACCOUNTS',
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to={nextUrl} className={styles.backButton}>
          <ChevronLeftIcon />
        </Link>
        <h1 className={styles.heading}>
          <Trans>Forgot password</Trans>
        </h1>
      </div>

      <form
        className={styles.form}
        onSubmit={handleSubmit(async () => {
          await dispatch(deleteAllAccounts());
          navigate(nextUrl);
        })}
      >
        <h2 className={styles.formHeading}>
          <Trans>Attention!</Trans>
        </h2>

        <p>
          <Trans>
            To reset the password, you need to delete all Keeper Wallet accounts
            and import them using the new password
          </Trans>
        </p>

        <div className={styles.alert}>
          <div className={styles.alertHeader}>
            <WarningIcon />
          </div>

          <p className={styles.alertContent}>
            <Trans>
              If you did not save a secret phrase or private key, you won&apos;t
              be able to restore access to your accounts
            </Trans>
          </p>
        </div>

        <p>
          <Trans>To proceed with the deletion, type</Trans> <br />
          <span style={{ userSelect: 'none' }}>“</span>
          {confirmationPhraseTranslated}
          <span style={{ userSelect: 'none' }}>”</span>
        </p>

        <FormControl className={styles.input}>
          <FormLabel>
            <Trans>Enter the phrase to delete</Trans>
          </FormLabel>
          <Input
            placeholder={confirmationPhraseTranslated}
            autoComplete="off"
            {...register('phrase', {
              validate: value => value === confirmationPhraseTranslated,
            })}
          />
        </FormControl>

        <Button
          type="submit"
          disabled={!isValid}
          error
          text={t(i18n)`Delete accounts`}
        />
        <LinkButton outlined to={nextUrl} text={t(i18n)`Cancel`} />
      </form>
    </div>
  );
}
