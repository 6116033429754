import {
  createPrivateKey,
  signBytes,
  utf8Encode,
} from '@keeper-wallet/waves-crypto';

import {
  handleUnexpectedError,
  type UnexpectedError,
} from '../../_core/errors';
import { type Maybe, Some } from '../../_core/maybe';
import { Ok, Result } from '../../_core/result';
import { sendWavesTransaction } from '../../apis/wavesNode';
import { WavesPrivateKey } from '../../blockchain/privateKey/wavesPrivateKey';
import { WavesPublicKey } from '../../blockchain/publicKey';
import {
  WavesTransaction,
  type WavesTransactionInput,
} from '../../blockchain/transaction/wavesTransaction';
import type { BlockchainTransactionSendResponse } from '../../blockchain/types';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import type { Account } from '../types';
import { AbstractAccount } from './abstractAccount';
import { WavesSeed } from './wavesSeed';

export class WavesAccount extends AbstractAccount {
  #privateKey;
  #publicKey;
  #seed;

  private constructor({
    id,
    name,
    privateKey,
    publicKey,
    seed,
  }: {
    id: string;
    name: string;
    privateKey: WavesPrivateKey;
    publicKey: WavesPublicKey;
    seed: WavesSeed;
  }) {
    super({ id, name });
    this.#privateKey = privateKey;
    this.#publicKey = publicKey;
    this.#seed = seed;
  }

  static fromInMemoryJSON(json: ReturnType<WavesAccount['toInMemoryJSON']>) {
    return Result.all([
      WavesSeed.fromString(json.seed),
      WavesPrivateKey.fromJSON(json.privateKey),
      WavesPublicKey.fromJSON(json.publicKey),
    ]).mapOk(
      ([seed, privateKey, publicKey]) =>
        new WavesAccount({
          id: json.id,
          name: json.name,
          privateKey,
          publicKey,
          seed,
        }),
    );
  }

  static async fromPersistedJSON(
    json: ReturnType<WavesAccount['toPersistedJSON']>,
  ) {
    const seed = WavesSeed.fromString(json.seed).assertOk();
    const privateKey = await seed.computeWavesPrivateKey();
    const publicKeyResult = await WavesPublicKey.fromSeed(json.seed);

    return publicKeyResult.mapOk(
      publicKey =>
        new WavesAccount({
          id: json.id,
          name: json.name,
          privateKey,
          publicKey,
          seed,
        }),
    );
  }

  static async fromSeed(seed: WavesSeed) {
    return WavesAccount.fromPersistedJSON({
      type: 'waves',
      id: '',
      name: '',
      seed: seed.toString(),
    });
  }

  get type() {
    return 'waves' as const;
  }

  getSeed() {
    return Some(this.#seed);
  }

  getPrivateKeys() {
    return [this.#privateKey];
  }

  getPublicKeys() {
    return [this.#publicKey];
  }

  isDuplicateOf(other: Account): boolean {
    return (
      this.id !== other.id &&
      this.type === other.type &&
      this.#seed.toString() === other.#seed.toString()
    );
  }

  get seed() {
    return this.#seed.toString();
  }

  toInMemoryJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      seed: this.#seed.toString(),
      privateKey: this.#privateKey.toJSON(),
      publicKey: this.#publicKey.toJSON(),
    };
  }

  toPersistedJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      seed: this.#seed.toString(),
    };
  }

  async sendTransaction(
    input: WavesTransactionInput,
  ): Promise<
    Result<Maybe<BlockchainTransactionSendResponse<'waves'>>, UnexpectedError>
  > {
    try {
      const privateKey = await createPrivateKey(
        utf8Encode(this.#seed.toString()),
      );
      const transaction = WavesTransaction.fromInput(input, this.#publicKey);

      transaction.addSignature(
        await signBytes(privateKey, transaction.toBytes()),
      );

      const { nodeUrl } = WAVES_NETWORK_CONFIGS[input.network];
      const response = await sendWavesTransaction(transaction, { nodeUrl });

      return Ok(Some(response));
    } catch (err) {
      return handleUnexpectedError(err, {
        context: `Send transaction using Waves Account`,
      });
    }
  }
}
