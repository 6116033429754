import type BigNumber from '@waves/bignumber';

import { AssetLogo } from '../_core/assetLogo';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import type { Maybe } from '../_core/maybe';
import { Select, type SelectOption } from '../_core/select';
import type { Asset } from '../blockchain/types';
import * as styles from './assetSelect.module.css';

export type SendAssetsAssetSelectOption = SelectOption & {
  asset: Asset;
  logo: Maybe<string>;
  tokens: BigNumber;
  usd: Maybe<BigNumber>;
};

interface Props {
  disabled?: boolean;
  isLoading?: boolean;
  items: SendAssetsAssetSelectOption[];
  placeholder?: string;
  value: string;
  onChange?: (value: string) => void;
}

export function SendAssetsAssetSelect({
  disabled,
  isLoading,
  items,
  placeholder,
  value,
  onChange,
}: Props) {
  return (
    <Select
      disabled={disabled}
      isLoading={isLoading}
      items={items}
      placeholder={placeholder}
      renderButtonLabel={option => (
        <div className={styles.button}>
          <AssetLogo
            blockchain={option.asset.blockchain}
            id={option.value}
            ticker={option.label}
            logo={option.logo.toOptional()}
          />

          {option.label}
        </div>
      )}
      renderOptionLabel={option => (
        <div className={styles.optionLabel}>
          <AssetLogo
            blockchain={option.asset.blockchain}
            id={option.value}
            ticker={option.label}
            logo={option.logo.toOptional()}
          />

          <div className={styles.optionLabelName}>{option.label}</div>

          <div className={styles.optionLabelAmount}>
            <div>{option.tokens.toFormat()}</div>

            {option.usd
              .mapSome(usdValue => (
                <div className={styles.optionLabelAmountUsd}>
                  {formatUsdPrice(usdValue)}
                </div>
              ))
              .toNullable()}
          </div>
        </div>
      )}
      value={value}
      onChange={onChange}
    />
  );
}
