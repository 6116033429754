import { createSvgIcon } from './_base';

export const MobileAppIcon = createSvgIcon(
  '0 0 22 22',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.25049 4.0213C2.25049 3.04304 3.0293 2.25 3.99002 2.25H8.27998C9.24069 2.25 10.0195 3.04304 10.0195 4.0213V8.38962C10.0195 9.36788 9.24069 10.1609 8.27998 10.1609H3.99001C3.0293 10.1609 2.25049 9.36787 2.25049 8.38961V4.0213ZM2.25049 13.6104C2.25049 12.6321 3.0293 11.8391 3.99002 11.8391H8.27998C9.24069 11.8391 10.0195 12.6321 10.0195 13.6104V17.9787C10.0195 18.957 9.24069 19.75 8.27998 19.75H3.99001C3.0293 19.75 2.25049 18.957 2.25049 17.9787V13.6104ZM13.7206 2.25C12.7599 2.25 11.9811 3.04304 11.9811 4.0213V8.38961C11.9811 9.36787 12.7599 10.1609 13.7206 10.1609H18.0106C18.9713 10.1609 19.7501 9.36788 19.7501 8.38962V4.0213C19.7501 3.04304 18.9713 2.25 18.0106 2.25H13.7206ZM11.9811 13.6104C11.9811 12.6321 12.7599 11.8391 13.7206 11.8391H15.4994C16.4601 11.8391 17.2389 12.6321 17.2389 13.6104V15.4216C17.2389 15.5075 17.2329 15.5919 17.2213 15.6745H18.508C19.1942 15.6745 19.7505 16.241 19.7505 16.9397V18.4846C19.7505 19.1834 19.1942 19.7498 18.508 19.7498H16.9908C16.3046 19.7498 15.7483 19.1834 15.7483 18.4846V17.1749C15.667 17.1868 15.5839 17.1929 15.4994 17.1929H13.7206C12.7599 17.1929 11.9811 16.3999 11.9811 15.4216V13.6104ZM5.19336 6.2056C5.19336 5.67602 5.61497 5.2467 6.13506 5.2467C6.65514 5.2467 7.07676 5.67602 7.07676 6.2056C7.07676 6.73519 6.65514 7.1645 6.13506 7.1645C5.61497 7.1645 5.19336 6.73519 5.19336 6.2056ZM5.19336 15.7943C5.19336 15.2647 5.61497 14.8354 6.13506 14.8354C6.65514 14.8354 7.07676 15.2647 7.07676 15.7943C7.07676 16.3239 6.65514 16.7532 6.13506 16.7532C5.61497 16.7532 5.19336 16.3239 5.19336 15.7943ZM15.866 5.2467C15.3459 5.2467 14.9243 5.67602 14.9243 6.2056C14.9243 6.73519 15.3459 7.1645 15.866 7.1645C16.3861 7.1645 16.8077 6.73519 16.8077 6.2056C16.8077 5.67602 16.3861 5.2467 15.866 5.2467Z"
    fill="#1F5AF6"
  />,
);
