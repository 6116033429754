import { Navigate } from 'react-router-dom';

import { useCreateAccountPageContext } from './createAccount';

export function RequireSeed({
  children,
}: {
  children: (props: {
    seed: string;
    seedIndicesAfterShuffle: number[];
    indicesToCheck: number[];
  }) => React.ReactElement;
}) {
  const { seed, seedIndicesAfterShuffle, indicesToCheck } =
    useCreateAccountPageContext();

  if (!seed || !seedIndicesAfterShuffle || !indicesToCheck) {
    return <Navigate to="/add-account" />;
  }

  return <>{children({ seed, seedIndicesAfterShuffle, indicesToCheck })}</>;
}
