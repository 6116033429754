import type { Asset } from '../blockchain/types';

export const ETHEREUM_ASSET: Asset<'ethereum'> = {
  blockchain: 'ethereum',
  address: '0x0000000000000000000000000000000000000000',
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
  iconUrl: new URL('../icons/ethereum.svg', import.meta.url).toString(),
};
