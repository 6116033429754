import { make, pipe, share, type Sink, type Source, subscribe } from 'wonka';

type TalkbackFn = (signal: 0 | 1) => void;

export interface Accumulator<T> {
  sink: (source: Source<T>) => void;
  source: Source<T>;
}

export function makeAccumulator<T>(): Accumulator<T> {
  const accumulated: T[] = [];
  let currentSink: Sink<T> | undefined;

  return {
    sink: source => {
      let talkback: TalkbackFn;

      source(signal => {
        if (signal === 0) return;

        if (signal.tag === 0) {
          talkback = signal[0];
          talkback(0);
        } else {
          const value = signal[0];

          if (currentSink) {
            currentSink(createPushSignal(value));
          } else {
            accumulated.push(value);
          }

          talkback(0);
        }
      });
    },
    source: share(sink => {
      currentSink = sink;

      sink(
        createStartSignal(signal => {
          if (signal === 0) {
            const value = accumulated.shift();

            if (value) {
              sink(createPushSignal(value));
            }
          } else {
            currentSink = undefined;
          }
        }),
      );
    }),
  };
}

function createSignal<T extends 0 | 1, V>(tagKind: T, value: V) {
  const wrappedTalkback: [V] = [value];
  return Object.assign(wrappedTalkback, { tag: tagKind });
}

export function createPushSignal<T>(value: T) {
  return createSignal(1, value);
}

export function createStartSignal(talkback: TalkbackFn) {
  return createSignal(0, talkback);
}

export function fromMessagePort(port: MessagePort) {
  return make(({ next }) => {
    function handleMessage(event: MessageEvent<unknown>) {
      next(event.data);
    }

    port.addEventListener('message', handleMessage);
    port.start();

    return () => {
      port.removeEventListener('message', handleMessage);
    };
  });
}

export function fromWindowEvent<K extends keyof WindowEventMap>(
  win: Window,
  type: K,
  options?: boolean | AddEventListenerOptions | undefined,
) {
  return make<WindowEventMap[K]>(({ next }) => {
    win.addEventListener(type, next, options);

    return () => {
      win.removeEventListener(type, next, options);
    };
  });
}

export function fromElementEvent<K extends keyof HTMLElementEventMap>(
  el: HTMLElement,
  type: K,
  options?: boolean | AddEventListenerOptions | undefined,
) {
  return make<HTMLElementEventMap[K]>(({ next }) => {
    el.addEventListener(type, next, options);

    return () => {
      el.removeEventListener(type, next, options);
    };
  });
}

export function toMessagePort(port: MessagePort) {
  return (source: Source<unknown>) => {
    return pipe(
      source,
      subscribe(message => {
        port.postMessage(message);
      }),
    );
  };
}
