import { Err, Ok, type Result } from '../../_core/result';
import { WavesPrivateKey } from '../../blockchain/privateKey/wavesPrivateKey';
import { createPrivateKey } from '../../waves/ed25519';
import { Bip39Seed, type Bip39SeedError } from './bip39Seed';

const WORD_COUNT = 24;

type MultichainSeedError =
  | Bip39SeedError
  | { type: 'invalid-word-count'; expectedCount: typeof WORD_COUNT };

export class MultichainSeed {
  #bip39Seed;

  private constructor(bip39Seed: Bip39Seed) {
    this.#bip39Seed = bip39Seed;
  }

  static fromString(
    input: string,
  ): Result<MultichainSeed, MultichainSeedError> {
    return Bip39Seed.fromString(input).flatMapOk(x => {
      if (x.wordCount !== WORD_COUNT) {
        return Err({ type: 'invalid-word-count', expectedCount: WORD_COUNT });
      }

      return Ok(new MultichainSeed(x));
    });
  }

  computeEthereumPrivateKey() {
    return this.#bip39Seed.computeEthereumPrivateKey();
  }

  computeWavesPrivateKey() {
    const bytes = createPrivateKey(this.#bip39Seed.toString());

    return WavesPrivateKey.fromBytes(bytes).assertOk();
  }

  toString() {
    return this.#bip39Seed.toString();
  }

  toWords() {
    return this.#bip39Seed.toWords();
  }
}
