// extracted by mini-css-extract-plugin
export var accountAvatar = "mRjummQhCNtS1vBY11uZ";
export var accountAvatarContainer = "BdQmv5SwfpR_Rlk6ZOj2";
export var accountBalance = "FOrclPiw2RfPnbq9wCJu";
export var accountBalanceSkeleton = "ulEkeEYCKlUCUD2b8ryQ";
export var accountList = "AE6P6xwdaJIbXQM0d6pi";
export var accountListItem = "Fo03Zn9KZgJCDQICJ0OA";
export var accountTitle = "DZbRdijRSgidmLJ5snhq";
export var accountsPageControls = "qeCa6yiQhbSyKwOUYOtp";
export var accountsPageHeader = "NalAznfcw4j_RlmrUQoI";
export var accountsPageTitle = "xJJncZ2Z0ma31Pr_AQaa";
export var accountsSpinnerWrapper = "IlPXmNSKaVixT53TS_8n";
export var chevronRightIcon = "FOhBjIieznfy41wuMhOR";
export var mobileAddAccount = "bQt0KSPfGjhJ4fY9CKHE";
export var mobileAddAccountIcon = "CwE5E_49AyVAXFreeTpk";
export var noAccountsMessage = "GSxs1iSjL76wSXHKI8S1";