import { plural, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';

import { type AsyncValue } from '../_core/asyncValue';
import { Skeleton } from '../_core/skeleton';
import { ChevronLeftIcon } from '../icons/chevronLeft';
import * as styles from './nftCollection.module.css';

function NftCard({
  id,
  label,
  src,
}: {
  id: string;
  label: string;
  src?: string;
}) {
  return (
    <Link to={`/nfts/${id}`}>
      <figure className={styles.cardListItem}>
        <img
          className={styles.cardImage}
          src={
            src ??
            new URL('../nfts/nftPlaceholder.svg', import.meta.url).pathname
          }
          alt=""
        />

        <figcaption className={styles.cardCaption}>{label}</figcaption>
      </figure>
    </Link>
  );
}

interface Props {
  collectionName: string;
  nfts: AsyncValue<
    Array<{
      id: string;
      label: string;
      src?: string;
    }>
  >;
}

export function PortfolioNftCollection({ collectionName, nfts }: Props) {
  const { i18n } = useLingui();

  return (
    <div>
      <div className={styles.header}>
        <Link to="/portfolio/nfts">
          <ChevronLeftIcon />
        </Link>

        {nfts.match({
          Pending: () => <Skeleton className={styles.headingSkeleton} />,
          Ready: nftsValue => (
            <span>
              {collectionName}{' '}
              <span className={styles.itemsCount}>
                {t(i18n)({
                  message: plural(nftsValue.length, {
                    one: '# item',
                    other: '# items',
                  }),
                })}
              </span>
            </span>
          ),
        })}
      </div>

      <div className={styles.cardList}>
        {nfts.match({
          Pending: () =>
            new Array(3)
              .fill(undefined)
              .map((_, index) => (
                <Skeleton key={index} className={styles.cardSkeleton} />
              )),
          Ready: nftsValue =>
            nftsValue.map(nft => (
              <NftCard
                key={nft.id}
                id={nft.id}
                label={nft.label}
                src={nft.src}
              />
            )),
        })}
      </div>
    </div>
  );
}
