import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, number, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { pollWhileUserIsActive } from '../../_core/polling';
import type { Asset } from '../../blockchain/types';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const reducer: Reducer<
  Partial<Record<Network, Array<Asset<'ethereum'>>>>,
  {
    type: 'UPDATE_DATA_SERVICE_ETHEREUM_TOKEN_LIST';
    payload: { network: Network; tokenList: Array<Asset<'ethereum'>> };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_ETHEREUM_TOKEN_LIST':
      return {
        ...state,
        [action.payload.network]: action.payload.tokenList,
      };
    default:
      return state;
  }
};

export default reducer;

type DataServiceEthereumTokens = Partial<Record<string, Asset<'ethereum'>>>;

export function useDataServiceEthereumTokens(): AsyncValue<DataServiceEthereumTokens> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    return pollWhileUserIsActive(1000 * 60 * 10, signal =>
      fetch(
        new URL(
          `/api/v1/${
            network === Network.Mainnet ? 'ethereum' : 'sepolia'
          }/tokenlist`,
          dataServiceUrl,
        ),
        { signal },
      )
        .then(
          handleResponse(
            array(
              type({
                address: string(),
                name: string(),
                symbol: string(),
                decimals: number(),
                iconUrl: string(),
              }),
            ),
          ),
        )
        .then(tokenList => {
          dispatch({
            type: 'UPDATE_DATA_SERVICE_ETHEREUM_TOKEN_LIST',
            payload: {
              network,
              tokenList: tokenList.map(asset => ({
                ...asset,
                blockchain: 'ethereum',
              })),
            },
          });
        }),
    );
  }, [dataServiceUrl, dispatch, network]);

  const tokenList = useAppSelector(
    state => state.cache.dataService.ethereumTokenList,
  );

  return useMemo(() => {
    const tokenListForNetwork = tokenList[network];

    return tokenListForNetwork
      ? AsyncValue.Ready(
          Object.fromEntries(
            tokenListForNetwork.map(token => [token.address, token]),
          ),
        )
      : AsyncValue.Pending;
  }, [network, tokenList]);
}
