import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button';
import { isUserRejectionError } from '../../_core/errors';
import { type Maybe, None, Some } from '../../_core/maybe';
import { Spinner } from '../../_core/spinner';
import { KeeperMobileAccount } from '../../accounts/models/keeperMobileAccount';
import { useAccountValidators } from '../../accounts/useAccountValidator';
import { useGetSigner } from '../../accounts/utils';
import { WavesPublicKey } from '../../blockchain/publicKey';
import { SadIcon } from '../../icons/sad';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { useAppDispatch, useAppSelector } from '../../store/react';
import { setSelectedAccount } from '../../vault/redux';
import { AccountNameInput } from './accountNameInput';
import { useAddAccountPageContext } from './addAccount';
import { AddressList } from './addressList';
import * as styles from './importKeeperMobileAccount.module.css';
import { AddAccountShell } from './shell';

interface ImportKeeperMobileInputs {
  name: string;
}

const defaultValues: ImportKeeperMobileInputs = {
  name: '',
};

export function ImportKeeperMobileAccount() {
  const { i18n } = useLingui();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  const navigate = useNavigate();

  const {
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const { tryToAddAnAccount } = useAddAccountPageContext();

  const [account, setAccount] = useState<Maybe<KeeperMobileAccount>>(None);
  const [rejectReason, setRejectReason] = useState('');

  const getSigner = useGetSigner({ network });

  const login = useCallback(async () => {
    try {
      const signer = await getSigner('keeper-mobile');
      // it is necessary to logout, because the provider keeps session after pairing
      await signer.logout();
      const { publicKey } = await signer.login();

      setAccount(
        Some(
          KeeperMobileAccount.fromPublicKey(
            WavesPublicKey.fromString(publicKey).assertOk(),
          ),
        ),
      );
    } catch (err) {
      if (isUserRejectionError(err)) {
        setRejectReason(t(i18n)`Rejected by user`);
        return;
      }
      // eslint-disable-next-line no-console
      console.error(err);
      setRejectReason(t(i18n)`Something went wrong`);
    }
  }, [getSigner, i18n]);

  const autoLoginRef = useRef<Promise<void>>();

  useEffect(() => {
    if (autoLoginRef.current != null) return;

    autoLoginRef.current = login();
  }, [i18n, login]);

  const { findDuplicate, validateName } = useAccountValidators();

  const existingAccount = useMemo(() => {
    return account.flatMapSome(newAccount => findDuplicate(newAccount));
  }, [account, findDuplicate]);

  return (
    <AddAccountShell
      backPath="/add-account/import"
      heading={t(i18n)`Connect Keeper Mobile`}
    >
      {rejectReason ? (
        <TryAgain
          reason={rejectReason}
          onRetry={() => {
            setRejectReason('');
            void login();
          }}
        />
      ) : account.isNone ? (
        <Loading message={t(i18n)`Approve request in Keeper Mobile App...`} />
      ) : (
        <form
          className={styles.form}
          onSubmit={handleSubmit(values =>
            tryToAddAnAccount({
              ...account.mapSome(x => x.toPersistedJSON()).assertSome(),
              name: values.name,
            }),
          )}
        >
          {account
            .mapSome(accountValue => (
              <>
                <p className={styles.helpText}>{t(
                  i18n,
                )`For start using Keeper please set account name`}</p>
                <div className={styles.nameInput}>
                  {existingAccount.match({
                    Some: acc => (
                      <AccountNameInput
                        key="existing"
                        disabled
                        account={accountValue}
                        value={acc.name}
                      />
                    ),
                    None: () => (
                      <AccountNameInput
                        account={accountValue}
                        error={errors.name?.message}
                        {...register('name', {
                          required: t(i18n)`Required`,
                          validate: value =>
                            validateName(value).getErr().toOptional(),
                        })}
                      />
                    ),
                  })}
                </div>
                <div className={styles.addressList}>
                  <AddressList
                    items={accountValue.getPublicKeys().map(publicKey =>
                      publicKey.getAddress({
                        chainId: WAVES_NETWORK_CONFIGS[network].chainId,
                      }),
                    )}
                  />
                </div>
                {existingAccount
                  .flatMapSome(x => (isSubmitting ? None : Some(x)))
                  .match({
                    Some: a => (
                      <>
                        <div className={styles.warningMessage}>
                          <Trans>
                            This account already imported. Make it active?
                          </Trans>
                        </div>

                        <div className={styles.actionButtonWrapper}>
                          <Button
                            block
                            text={t(i18n)`Make active`}
                            onClick={async () => {
                              await dispatch(setSelectedAccount(a.id));
                              navigate('/accounts');
                            }}
                          />
                        </div>
                      </>
                    ),
                    None: () => (
                      <div className={styles.actionButtonWrapper}>
                        <Button
                          block
                          disabled={!isValid || isSubmitting}
                          text={t(i18n)`Continue`}
                          type="submit"
                        />
                      </div>
                    ),
                  })}{' '}
              </>
            ))
            .toNullable()}
        </form>
      )}
    </AddAccountShell>
  );
}

function Loading({ message }: { message: string }) {
  return (
    <div className={styles.statusRoot}>
      <p className={styles.statusText}>{message}</p>

      <div className={styles.statusIconWrapper}>
        <Spinner size={24} />
      </div>
    </div>
  );
}

function TryAgain({
  reason,
  onRetry,
}: {
  reason: string;
  onRetry: () => void;
}) {
  const { i18n } = useLingui();

  return (
    <div className={styles.statusRoot}>
      <p className={styles.statusText}>{reason}</p>

      <div className={styles.statusIconWrapper}>
        <SadIcon className={styles.sadIcon} />
      </div>

      <button className={styles.cleanButton} onClick={() => onRetry()}>
        {t(i18n)`Try again`}
      </button>
    </div>
  );
}
