// extracted by mini-css-extract-plugin
export var amountHelperText = "_B2Y3MbZm0ieNeuQdtEP";
export var amountUseMaxButton = "d1hvr3URg1KCdkEDe6wJ";
export var assetHelperText = "MU342FjhyJK7wyKjLgpD";
export var card = "szaNoK7496J0uy4_s_1Q";
export var formError = "rTBxfqU5NqvKVbko0miN";
export var recipientInput = "deq93ykYASrhfQ5nAD3Q";
export var recipientLabel = "JoC8gJFdgWWDszAWoDmt";
export var recipientSpinner = "t74ryEyKw9zeT3TZIhha";
export var senderBalanceInTokensLabel = "YqdYwer1ors_W0czOe3A";
export var senderBalanceInUsdLabel = "tAks6VEBMf6RYn99hzBp";
export var senderLabel = "E4L48q2AZzmLXLC1Y4NA";
export var senderOptionAddress = "JwQaMyndGb51BaPHS12W";
export var senderOptionLabel = "uGpvEoGCfb8vhg79pTmc";
export var walletBalanceLabel = "V1MxJyC45aY2XDDI_rmq";