import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button } from '../_core/button';
import { CopyButton } from '../_core/copyButton';
import { CopyToClipboard } from '../_core/copyToClipboard';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogHeading,
} from '../_core/dialog';
import { FormControl } from '../_core/formControl';
import { Input, TextArea } from '../_core/input';
import { RevealableBox } from '../_core/revealableBox';
import type { EthereumPrivateKey } from '../blockchain/privateKey/ethereumPrivateKey';
import type { WavesPrivateKey } from '../blockchain/privateKey/wavesPrivateKey';
import type { Blockchain } from '../blockchain/types';
import { CheckIcon } from '../icons/checkIcon';
import { ChevronLeftIcon } from '../icons/chevronLeft';
import { CopyIcon } from '../icons/copy';
import { DeleteIcon } from '../icons/delete';
import { EditIcon } from '../icons/edit';
import { EyeIcon } from '../icons/eye';
import { WarningIcon } from '../icons/warning';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import type { Network } from '../network/types';
import type { InMemoryAccountJSON } from '../vault/types';
import * as styles from './details.module.css';
import type { Bip39Seed } from './models/bip39Seed';
import type { MultichainSeed } from './models/multichainSeed';
import type { WavesSeed } from './models/wavesSeed';
import { SeedWordList } from './seedWordList';
import type { Account } from './types';

interface Props {
  account: Account;
  network: Network;
  validateName: (name: string) => string | undefined;
  verifyPassword: (password: string) => boolean;
  onDelete: () => void;
  onRename: (newName: string) => void;
}

export function AccountDetails({
  account,
  network,
  validateName,
  verifyPassword,
  onDelete,
  onRename,
}: Props) {
  const { i18n } = useLingui();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <>
      <article className={styles.root}>
        <Header
          account={account}
          validateName={validateName}
          onRename={onRename}
        />

        <PublicInfo account={account} network={network} />

        <PrivateInfo account={account} verifyPassword={verifyPassword} />

        <Separator />

        <Button
          block
          error
          startIcon={<DeleteIcon />}
          text={t(i18n)`Delete account`}
          variant="outlined"
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </article>

      <DeleteAccountModal
        account={account.toInMemoryJSON()}
        isOpen={isDeleteModalOpen}
        onConfirm={onDelete}
        onIsOpenChange={setIsDeleteModalOpen}
      />
    </>
  );
}

function Header({
  account,
  validateName,
  onRename,
}: {
  account: Account;
  validateName: (name: string) => string | undefined;
  onRename: (newName: string) => void;
}) {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  return (
    <>
      <header className={styles.header}>
        <Link className={styles.backButton} to="/accounts">
          <ChevronLeftIcon />
        </Link>

        <h1 className={styles.name}>{account.name}</h1>

        <button
          className={styles.editButton}
          type="button"
          onClick={() => setIsRenameModalOpen(true)}
        >
          <EditIcon />
        </button>
      </header>

      <RenameAccountModal
        isOpen={isRenameModalOpen}
        validateName={validateName}
        onConfirm={onRename}
        onIsOpenChange={setIsRenameModalOpen}
      />
    </>
  );
}

function RenameAccountModal({
  isOpen,
  onIsOpenChange,
  validateName,
  onConfirm,
}: {
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
  validateName?: (name: string) => string | undefined;
  onConfirm: (newName: string) => void;
}) {
  const { i18n } = useLingui();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: { name: '' },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen, reset]);

  return (
    <div>
      <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogClose />
            <DialogHeading>{t(i18n)`Edit account name`}</DialogHeading>
          </DialogHeader>

          <form
            onSubmit={handleSubmit(values => {
              onConfirm(values.name);
              onIsOpenChange(false);
            })}
          >
            <FormControl
              label={t(i18n)`Enter new account name`}
              error={errors.name?.message}
            >
              <Input
                autoFocus
                {...register('name', {
                  required: t(i18n)`Required`,
                  validate: value => {
                    return validateName?.(value);
                  },
                })}
              />
            </FormControl>

            <Button
              block
              className={styles.modalBottomButton}
              disabled={!isValid}
              text={t(i18n)`Save`}
              type="submit"
            />
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function PublicInfo({
  account,
  network,
}: {
  account: Account;
  network: Network;
}) {
  const { i18n } = useLingui();

  const publicKeys = account.getPublicKeys();

  return (
    <section className={styles.card}>
      <header className={styles.cardSection}>
        <h2 className={styles.cardTitle}>{t(i18n)`Public info`}</h2>

        <p className={styles.cardDescription}>
          {t(i18n)`This information about your wallet is safe to share.`}
        </p>
      </header>

      <Separator />

      {publicKeys.map((publicKey, index) => {
        const address = publicKey.getAddress({
          chainId: WAVES_NETWORK_CONFIGS[network].chainId,
        });

        const addressString = address.toString();
        const publicKeyString = publicKey.toString();

        return (
          <Fragment key={index}>
            <section className={styles.cardSection}>
              <h3 className={styles.publicInfoSubheading}>
                <BlockchainLabel blockchain={publicKey.blockchain} />
              </h3>

              <ul className={styles.publicInfoItemList}>
                <li className={styles.publicInfoItem}>
                  <div className={styles.publicInfoItemName}>{t(
                    i18n,
                  )`Address`}</div>

                  <CopyButton block textToCopy={addressString}>
                    {addressString}
                  </CopyButton>
                </li>

                <li className={styles.publicInfoItem}>
                  <div className={styles.publicInfoItemName}>{t(
                    i18n,
                  )`Public key`}</div>

                  <CopyButton block textToCopy={publicKeyString}>
                    {publicKeyString}
                  </CopyButton>
                </li>
              </ul>
            </section>

            {index < publicKeys.length - 1 && <Separator />}
          </Fragment>
        );
      })}
    </section>
  );
}

function PrivateInfo({
  account,
  verifyPassword,
}: {
  account: Account;
  verifyPassword: (password: string) => boolean;
}) {
  const { i18n } = useLingui();

  const seed = account.getSeed();
  const privateKeys = account.getPrivateKeys();

  if (seed.isNone && privateKeys.length === 0) {
    return null;
  }

  return (
    <section className={styles.card}>
      <header className={styles.cardSection}>
        <h2 className={styles.cardTitle}>{t(i18n)`Private info`}</h2>

        <p className={styles.cardDescription}>
          {t(
            i18n,
          )`This information can be used to gain/restore access to your wallet. Do not share it with anyone else, or you might lose your account.`}
        </p>
      </header>

      <Separator />

      {seed.match({
        None: () => null,
        Some: seedValue => (
          <section className={styles.cardSection}>
            <h3 className={styles.privateInfoSubheading}>{t(i18n)`Back up`}</h3>

            <RevealSeedButton
              seed={seedValue}
              verifyPassword={verifyPassword}
            />
          </section>
        ),
      })}

      <Separator />

      <section className={styles.cardSection}>
        <h3 className={styles.privateInfoSubheading}>{t(i18n)`Private key`}</h3>

        <ul className={styles.privateInfoKeyList}>
          {privateKeys.map((privateKey, index) => (
            <Fragment key={index}>
              <li>
                <RevealPrivateKeyButton
                  privateKey={privateKey}
                  verifyPassword={verifyPassword}
                />
              </li>
            </Fragment>
          ))}
        </ul>
      </section>
    </section>
  );
}

function RevealButton({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <button className={styles.revealButton} type="button" onClick={onClick}>
      <div>{children}</div>

      <EyeIcon className={styles.revealButtonIcon} />
    </button>
  );
}

function RevealSeedButton({
  seed,
  verifyPassword,
}: {
  seed: Bip39Seed | MultichainSeed | WavesSeed;
  verifyPassword: (password: string) => boolean;
}) {
  const { i18n } = useLingui();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isSeedModalOpen, setIsSeedModalOpen] = useState(false);

  return (
    <>
      <RevealButton
        onClick={() => {
          setIsPasswordModalOpen(true);
        }}
      >{t(i18n)`Show Secret phrase`}</RevealButton>

      <PasswordModal
        attentionText={t(
          i18n,
        )`Secret phrase gives full access to your wallet. Keep it somewhere safe. Do not share it with anyone else, or you might lose your account.`}
        enterPasswordText={t(
          i18n,
        )`Enter your password to reveal the Secret phrase`}
        isOpen={isPasswordModalOpen}
        submitButtonText={t(i18n)`Reveal phrase`}
        verifyPassword={verifyPassword}
        onIsOpenChange={newIsOpen => {
          if (newIsOpen) return;

          setIsPasswordModalOpen(false);
        }}
        onValidPassword={() => {
          setIsPasswordModalOpen(false);
          setIsSeedModalOpen(true);
        }}
      />

      <RevealModal
        description={t(
          i18n,
        )`Before revealing the Secret phrase, make sure no one is looking at your screen.`}
        heading={t(i18n)`Secret phrase`}
        isOpen={isSeedModalOpen}
        revealButtonText={t(i18n)`Show phrase`}
        textToCopy={seed.toString()}
        onIsOpenChange={newIsOpen => {
          if (newIsOpen) return;

          setIsSeedModalOpen(false);
        }}
      >
        {'toWords' in seed ? (
          <SeedWordList words={seed.toWords()} />
        ) : (
          <TextArea readOnly rows={4} value={seed.toString()} />
        )}
      </RevealModal>
    </>
  );
}

function RevealPrivateKeyButton({
  privateKey,
  verifyPassword,
}: {
  privateKey: EthereumPrivateKey | WavesPrivateKey;
  verifyPassword: (password: string) => boolean;
}) {
  const { i18n } = useLingui();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPrivateKeyModalOpen, setIsPrivateKeyModalOpen] = useState(false);

  return (
    <>
      <RevealButton
        onClick={() => {
          setIsPasswordModalOpen(true);
        }}
      >
        <BlockchainLabel blockchain={privateKey.blockchain} />
      </RevealButton>

      <PasswordModal
        attentionText={t(
          i18n,
        )`Private key gives full access to your wallet. Keep it somewhere safe. Do not share it with anyone else, or you might lose your account.`}
        enterPasswordText={t(
          i18n,
        )`Enter your password to reveal the Private key`}
        isOpen={isPasswordModalOpen}
        submitButtonText={t(i18n)`Reveal private key`}
        verifyPassword={verifyPassword}
        onIsOpenChange={newIsOpen => {
          if (newIsOpen) return;

          setIsPasswordModalOpen(false);
        }}
        onValidPassword={() => {
          setIsPasswordModalOpen(false);
          setIsPrivateKeyModalOpen(true);
        }}
      />

      <RevealModal
        description={t(
          i18n,
        )`Before revealing the Private key, make sure no one is looking at your screen.`}
        heading={t(i18n)`Private key`}
        isOpen={isPrivateKeyModalOpen}
        revealButtonText={t(i18n)`Show Private key`}
        textToCopy={privateKey.toString()}
        onIsOpenChange={newIsOpen => {
          if (newIsOpen) return;

          setIsPrivateKeyModalOpen(false);
        }}
      >
        <TextArea readOnly rows={4} value={privateKey.toString()} />
      </RevealModal>
    </>
  );
}

function PasswordModal({
  attentionText,
  enterPasswordText,
  isOpen,
  submitButtonText,
  verifyPassword,
  onIsOpenChange,
  onValidPassword,
}: {
  attentionText: string;
  enterPasswordText: string;
  isOpen: boolean;
  submitButtonText: string;
  verifyPassword: (password: string) => boolean;
  onIsOpenChange: (newIsOpen: boolean) => void;
  onValidPassword: () => void;
}) {
  const { i18n } = useLingui();

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string>();

  useEffect(() => {
    if (isOpen) return;

    setPassword('');
    setPasswordError(undefined);
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogClose />

          <DialogHeading>{t(i18n)`Attention`}</DialogHeading>
        </DialogHeader>

        <div className={styles.passwordModalAttentionBox}>
          <header className={styles.passwordModalAttentionBoxHeader}>
            <WarningIcon />
          </header>

          <p className={styles.passwordModalAttentionBoxBody}>
            {attentionText}
          </p>
        </div>

        <p className={styles.passwordModalEnterPasswordText}>
          {enterPasswordText}
        </p>

        <form
          onSubmit={event => {
            event.preventDefault();

            if (verifyPassword(password)) {
              onValidPassword();
            } else {
              setPasswordError(t(i18n)`Wrong password`);
            }
          }}
        >
          <FormControl error={passwordError} label={t(i18n)`Enter password`}>
            <Input
              autoFocus
              autoComplete="current-password"
              name="password"
              type="password"
              value={password}
              onChange={event => {
                setPassword(event.currentTarget.value);
                setPasswordError(undefined);
              }}
            />
          </FormControl>

          <Button
            block
            className={styles.modalBottomButton}
            text={submitButtonText}
            type="submit"
          />
        </form>
      </DialogContent>
    </Dialog>
  );
}

function RevealModal({
  children,
  description,
  heading,
  isOpen,
  revealButtonText,
  textToCopy,
  onIsOpenChange,
}: {
  children: React.ReactNode;
  description: string;
  heading: string;
  isOpen: boolean;
  revealButtonText: string;
  textToCopy: string;
  onIsOpenChange: (newIsOpen: boolean) => void;
}) {
  const { i18n } = useLingui();

  return (
    <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogClose />

          <DialogHeading>{heading}</DialogHeading>
        </DialogHeader>

        <p className={styles.revealModalDescription}>{description}</p>

        <div className={styles.revealModalRevealableContent}>
          <RevealableBox revealButtonText={revealButtonText}>
            {children}
          </RevealableBox>
        </div>

        <div className={styles.revealModalCopyButtonWrapper}>
          <CopyToClipboard>
            {({ copy, isCopied }) => (
              <Button
                className={styles.revealModalCopyButton}
                size="small"
                startIcon={isCopied ? <CheckIcon /> : <CopyIcon />}
                text={t(i18n)`Copy to clipboard`}
                variant="text"
                onClick={() => {
                  void copy(textToCopy);
                }}
              />
            )}
          </CopyToClipboard>
        </div>

        <Button
          block
          className={styles.modalBottomButton}
          text={t(i18n)`Close`}
          variant="outlined"
          onClick={() => {
            onIsOpenChange(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function Separator() {
  return <hr className={styles.separator} />;
}

function BlockchainLabel({ blockchain }: { blockchain: Blockchain }) {
  const icon = {
    ethereum: new URL('../icons/ethereum.svg', import.meta.url).pathname,
    waves: new URL('../icons/waves.svg', import.meta.url).pathname,
  }[blockchain];

  const label = {
    ethereum: 'Ethereum',
    waves: 'Waves',
  }[blockchain];

  return (
    <div className={styles.blockchainLabel}>
      <img alt="" className={styles.blockchainLabelIcon} src={icon} />

      {label}
    </div>
  );
}

function DeleteAccountModal({
  account,
  isOpen,
  onIsOpenChange,
  onConfirm,
}: {
  account: InMemoryAccountJSON;
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
}) {
  const { i18n } = useLingui();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: { name: '' },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen, reset]);

  return (
    <div>
      <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogClose />
            <DialogHeading>{t(i18n)`Delete Your Account`}</DialogHeading>
            <DialogDescription>{t(
              i18n,
            )`Please note that your account will be deleted from this device!`}</DialogDescription>
          </DialogHeader>

          <form
            onSubmit={handleSubmit(() => {
              onConfirm();
              onIsOpenChange(false);
            })}
          >
            <FormControl
              label={t(i18n)`Type account name you want to delete`}
              error={errors.name?.message}
            >
              <Input
                autoFocus
                autoComplete="off"
                {...register('name', {
                  required: t(i18n)`Required`,
                  validate: value =>
                    value === account.name
                      ? undefined
                      : t(i18n)`Value does not match`,
                })}
              />
            </FormControl>

            <Button
              block
              className={styles.modalBottomButton}
              disabled={!isValid}
              error
              text={t(i18n)`Delete account`}
              type="submit"
            />
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
