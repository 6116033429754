import { createPrivateKey, utf8Encode } from '@keeper-wallet/waves-crypto';

import { Err, Ok } from '../../_core/result';
import { WavesPrivateKey } from '../../blockchain/privateKey/wavesPrivateKey';

const MIN_LENGTH = 24;

export class WavesSeed {
  #value;

  private constructor(value: string) {
    this.#value = value;
  }

  static fromString(input: string) {
    const trimmed = input.trim();

    if (trimmed.length < MIN_LENGTH) {
      return Err({
        type: 'seed-is-too-short',
        minLength: MIN_LENGTH,
      });
    }

    return Ok(new WavesSeed(trimmed));
  }

  async computeWavesPrivateKey() {
    const bytes = await createPrivateKey(utf8Encode(this.#value));

    return WavesPrivateKey.fromBytes(bytes).assertOk();
  }

  toString() {
    return this.#value;
  }
}
