import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '../../_core/button';
import { type Maybe, None, Some } from '../../_core/maybe';
import { MultichainAccount } from '../../accounts/models/multichainAccount';
import { MultichainSeed } from '../../accounts/models/multichainSeed';
import { useAccountValidators } from '../../accounts/useAccountValidator';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { useAppSelector } from '../../store/react';
import { AccountNameInput } from './accountNameInput';
import { useAddAccountPageContext } from './addAccount';
import { AddressList } from './addressList';
import * as styles from './saveAccountPage.module.css';
import { AddAccountShell } from './shell';

interface ImportMultichainAccountInputs {
  name: string;
}

const defaultValues: ImportMultichainAccountInputs = {
  name: '',
};

export function SaveAccountPage({ seed }: { seed: string }) {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const { tryToAddAnAccount } = useAddAccountPageContext();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<ImportMultichainAccountInputs>({
    defaultValues,
    mode: 'onChange',
  });

  const [account, setAccount] = useState<Maybe<MultichainAccount>>(None);
  useEffect(() => {
    void MultichainAccount.fromSeed(MultichainSeed.fromString(seed).assertOk())
      .then(x => x.assertOk())
      .then(Some)
      .then(setAccount);
  }, [seed]);

  const { validateName } = useAccountValidators();

  return (
    <AddAccountShell
      backPath="/add-account/create"
      heading={t(i18n)`Account name`}
    >
      <p className={styles.helpText}>
        <Trans>For start using Keeper please set account name</Trans>
      </p>

      <form
        onSubmit={handleSubmit(values =>
          tryToAddAnAccount({
            type: 'multichain',
            seed,
            name: values.name,
          }),
        )}
      >
        {account
          .mapSome(accountValue => (
            <>
              <AccountNameInput
                account={accountValue}
                error={errors.name?.message}
                {...register('name', {
                  required: t(i18n)`Required`,
                  validate: value => validateName(value).getErr().toOptional(),
                })}
              />

              <div className={styles.addressList}>
                <AddressList
                  items={accountValue.getPublicKeys().map(publicKey =>
                    publicKey.getAddress({
                      chainId: WAVES_NETWORK_CONFIGS[network].chainId,
                    }),
                  )}
                />
              </div>
            </>
          ))
          .toNullable()}

        <Button
          block
          disabled={!isValid}
          text={t(i18n)`Continue`}
          type="submit"
        />
      </form>
    </AddAccountShell>
  );
}
