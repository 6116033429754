import { Maybe } from '../_core/maybe';
import { Err, type Result } from '../_core/result';
import { WavesAddress } from '../blockchain/address';

export function resolveDomainAddress(
  domain: string,
  network: 'mainnet' | 'testnet',
): Promise<
  Result<WavesAddress, 'failed-to-resolve-a-domain' | 'no-such-domain'>
> {
  return import(
    /* webpackChunkName: "domains-client" */
    '@waves-domains/client'
  )
    .then(({ WavesDomainsClient }) =>
      new WavesDomainsClient({ network }).resolve(domain.toLowerCase()),
    )
    .then(
      resolvedAddress =>
        Maybe.fromNullable(resolvedAddress).match<
          Result<WavesAddress, 'failed-to-resolve-a-domain' | 'no-such-domain'>
        >({
          Some: x =>
            WavesAddress.fromString(x).mapErr(
              () => 'failed-to-resolve-a-domain',
            ),
          None: () => Err('no-such-domain'),
        }),
      () => Err('failed-to-resolve-a-domain'),
    );
}
