import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../_core/button';
import { FormControl } from '../../_core/formControl';
import { TextArea } from '../../_core/input';
import { type Maybe, None, Some } from '../../_core/maybe';
import { MultichainAccount } from '../../accounts/models/multichainAccount';
import { MultichainSeed } from '../../accounts/models/multichainSeed';
import { useAccountValidators } from '../../accounts/useAccountValidator';
import { PasteIcon } from '../../icons/paste';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { useAppDispatch, useAppSelector } from '../../store/react';
import { setSelectedAccount } from '../../vault/redux';
import { AccountNameInput } from './accountNameInput';
import { useAddAccountPageContext } from './addAccount';
import { AddressList } from './addressList';
import * as styles from './importMulticoinAccount.module.css';
import { AddAccountShell } from './shell';

interface ImportMulticoinAccountInputs {
  name: string;
  seed: string;
}

const defaultValues: ImportMulticoinAccountInputs = {
  name: '',
  seed: '',
};

export function ImportMulticoinAccount() {
  const { i18n } = useLingui();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const network = useAppSelector(state => state.network);

  const {
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    register,
    resetField,
    setValue,
    watch,
  } = useForm<ImportMulticoinAccountInputs>({
    defaultValues,
    mode: 'onChange',
  });

  const [canReadClipboard, setCanReadClipboard] = useState(false);

  useEffect(() => {
    setCanReadClipboard('readText' in navigator.clipboard);
  }, []);

  const { tryToAddAnAccount } = useAddAccountPageContext();

  const seed = watch('seed');

  const [account, setAccount] = useState<Maybe<MultichainAccount>>(None);
  useEffect(() => {
    MultichainSeed.fromString(seed).match({
      Err: () => {
        setAccount(None);
      },
      Ok: validSeed => {
        void MultichainAccount.fromSeed(validSeed).then(x => {
          setAccount(Some(x.assertOk()));
        });
      },
    });
  }, [i18n, network, seed]);

  const errorMessage = (errors.seed || errors.root)?.message;

  const { findDuplicate, validateName } = useAccountValidators();
  const existingAccount = useMemo(() => {
    return account.flatMapSome(newAccount => findDuplicate(newAccount));
  }, [account, findDuplicate]);

  useEffect(() => {
    resetField('name');
  }, [existingAccount, resetField]);

  return (
    <AddAccountShell
      backPath="/add-account/seed-or-key"
      heading={t(i18n)`Import Multi-Coin Account`}
    >
      <p className={styles.helpText}>
        <Trans>Paste or type your Secret phrase</Trans>
      </p>

      <form
        className={styles.root}
        onSubmit={handleSubmit(values =>
          account.match({
            None: () => undefined,
            Some: accountValue =>
              tryToAddAnAccount({
                type: 'multichain',
                name: values.name,
                seed: accountValue.seed,
              }),
          }),
        )}
      >
        <div>
          <FormControl hasError={Boolean(errors.seed)}>
            <TextArea
              autoFocus
              rows={5}
              {...register('seed', {
                required: t(i18n)`Secret phrase is required`,
                validate: value =>
                  MultichainSeed.fromString(value)
                    .getErr()
                    .mapSome(err => {
                      switch (err.type) {
                        case 'seed-is-invalid':
                          return t(i18n)`Secret phrase is invalid`;
                        case 'invalid-word-count':
                          return t(
                            i18n,
                          )`Secret phrase for multicoin account must be ${err.expectedCount} words long`;
                      }
                    })
                    .toOptional(),
              })}
            />
          </FormControl>

          {canReadClipboard && (
            <div className={styles.pasteButtonWrapper}>
              <Button
                block
                outlined
                size="small"
                startIcon={<PasteIcon />}
                text={t(i18n)`Paste`}
                onClick={async () => {
                  try {
                    setValue('seed', await navigator.clipboard.readText());
                  } catch {
                    // ignore
                  }
                }}
              />
            </div>
          )}
        </div>

        <div>
          {account
            .mapSome(accountValue => (
              <>
                <div className={styles.nameInput}>
                  {existingAccount.match({
                    Some: acc => (
                      <AccountNameInput
                        key="existing"
                        disabled
                        account={accountValue}
                        value={acc.name}
                      />
                    ),
                    None: () => (
                      <AccountNameInput
                        account={accountValue}
                        error={errors.name?.message}
                        {...register('name', {
                          required: t(i18n)`Required`,
                          validate: value =>
                            validateName(value).getErr().toOptional(),
                        })}
                      />
                    ),
                  })}
                </div>

                <div className={styles.addressList}>
                  <AddressList
                    items={accountValue.getPublicKeys().map(publicKey =>
                      publicKey.getAddress({
                        chainId: WAVES_NETWORK_CONFIGS[network].chainId,
                      }),
                    )}
                  />
                </div>
              </>
            ))
            .toNullable()}

          {existingAccount
            .flatMapSome(x => (isSubmitting ? None : Some(x)))
            .match({
              Some: acc => (
                <>
                  <div className={styles.warningMessage}>
                    <Trans>
                      This account already imported. Make it active?
                    </Trans>
                  </div>

                  <div className={styles.actionButtonWrapper}>
                    <Button
                      block
                      text={t(i18n)`Make active`}
                      onClick={async () => {
                        await dispatch(setSelectedAccount(acc.id));
                        navigate('/accounts');
                      }}
                    />
                  </div>
                </>
              ),
              None: () => (
                <>
                  {errorMessage && (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                  )}
                  <div className={styles.actionButtonWrapper}>
                    <Button
                      block
                      disabled={account.isNone || !isValid || isSubmitting}
                      text={t(i18n)`Continue`}
                      type="submit"
                    />
                  </div>
                </>
              ),
            })}
        </div>
      </form>
    </AddAccountShell>
  );
}
