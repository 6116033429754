import { createSvgIcon } from './_base';

export const SeedIcon = createSvgIcon(
  '0 0 22 22',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.4565 16.3545C10.5488 19.7357 14.6441 20.8578 17.2814 18.4984C19.7742 16.2683 19.2168 12.2525 16.2049 10.7419L15.626 10.4515V7.01795H17.2672C18.1552 7.01795 18.875 6.29811 18.875 5.41015C18.875 4.52219 18.1552 3.80236 17.2672 3.80236H15.626C15.626 2.94501 14.923 2.25 14.0657 2.25C13.1994 2.25 12.4891 2.95222 12.4891 3.81846V10.4515L11.9102 10.7419C9.84391 11.7782 8.77097 14.2325 9.4565 16.3545ZM12.7087 14.1476C13.3014 13.1855 14.9357 13.2256 15.4525 14.2149C16.0175 15.2961 15.2533 16.5539 14.0315 16.5539C12.8344 16.5539 12.0761 15.1743 12.7087 14.1476Z"
      fill="#1F5AF6"
    />
    <path
      d="M6.05516 9.18827C5.75506 9.68948 5.08147 9.80305 4.62611 9.4371C4.19706 9.0923 4.13718 8.45966 4.50993 8.05466C4.82304 7.71447 5.14347 7.38312 5.47121 7.06062C5.69569 6.8353 5.8369 6.6926 5.89483 6.63251C5.71379 6.60247 5.19603 6.47854 4.34155 6.26073C4.24014 6.23474 4.14443 6.20988 4.05442 6.18614C3.52862 6.04743 3.25596 5.50629 3.4232 4.98885C3.60757 4.41842 4.24222 4.12484 4.78036 4.38902C5.32952 4.65861 5.83859 4.94453 6.30759 5.24678C6.19899 4.48183 6.12433 3.80485 6.08359 3.21584C6.04675 2.6832 6.47971 2.25 7.01362 2.25C7.54753 2.25 7.97335 2.68289 7.92583 3.21468C7.87788 3.7513 7.79829 4.43618 7.68707 5.26931C7.8319 5.20923 8.14328 5.06277 8.62121 4.82994C8.89668 4.69978 9.16309 4.57767 9.42045 4.46361C9.95324 4.22748 10.5619 4.52868 10.7202 5.08953C10.8726 5.62934 10.5436 6.18758 9.99412 6.30001C9.44562 6.41223 8.85039 6.52306 8.20845 6.63251L9.5119 8.15343C9.85387 8.55738 9.76215 9.16083 9.32544 9.45984L9.30458 9.47412C8.85324 9.78315 8.23448 9.6441 7.95875 9.17169L7.00276 7.5338C6.71462 8.06292 6.39875 8.61441 6.05516 9.18827Z"
      fill="#1F5AF6"
    />
  </>,
);
