import { createContext, useContext, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';

import { AsyncValue } from '../_core/asyncValue';
import type { UnexpectedError } from '../_core/errors';
import { type Maybe, None, Some } from '../_core/maybe';
import { Err, Ok, type Result } from '../_core/result';
import type {
  BlockchainTransactionRequest,
  BlockchainTransactionSendResponse,
} from '../blockchain/types';
import { useEntryContext } from '../entry';
import { useAppSelector } from '../store/react';
import {
  SignatureRequestDialog,
  type SignatureRequestDialogData,
} from './signatureRequestDialog';
import { useGetSigner } from './utils';

interface SignatureRequestContextValue {
  sendTransactionRequest: Maybe<
    (
      request: BlockchainTransactionRequest,
    ) => Promise<
      Result<Maybe<BlockchainTransactionSendResponse>, UnexpectedError>
    >
  >;
}

const SignatureRequestContext =
  createContext<SignatureRequestContextValue | null>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export function useSignatureRequest() {
  const value = useContext(SignatureRequestContext);

  invariant(
    value,
    'useSignatureRequest() can only be used in a descendant of SignatureRequestProvider.',
  );

  return value;
}

export function SignatureRequestProvider({ children }: ProviderProps) {
  const { ethereumNodeUrls } = useEntryContext();

  const network = useAppSelector(state => state.network);
  const getSigner = useGetSigner({ network });

  const [dialogData, setDialogData] =
    useState<Maybe<SignatureRequestDialogData>>(None);
  const value = useMemo(
    (): SignatureRequestContextValue => ({
      sendTransactionRequest: dialogData.match({
        Some: () => None,
        None: () =>
          Some(async request => {
            async function confirmAndSend({
              send,
              setDialogState,
            }: {
              send: () => Promise<
                Result<
                  Maybe<BlockchainTransactionSendResponse>,
                  UnexpectedError
                >
              >;
              setDialogState: (callbacks: {
                onConfirm?: () => void;
                onReject?: () => void;
              }) => void;
            }) {
              const confirmed = await new Promise<boolean>(resolve => {
                setDialogState({
                  onConfirm: () => {
                    setDialogState({});
                    return resolve(true);
                  },
                  onReject: () => {
                    setDialogState({});
                    return resolve(false);
                  },
                });
              });

              if (!confirmed) {
                setDialogData(None);
                return Ok(None);
              }

              const response = await send();

              setDialogData(None);

              return response;
            }

            switch (request.accountType) {
              case 'ethereum': {
                return confirmAndSend({
                  setDialogState: ({ onConfirm, onReject }) => {
                    setDialogData(
                      Some({
                        connectedAccount: false,
                        request,
                        onClose: onReject,
                        onConfirm,
                      }),
                    );
                  },
                  send: () =>
                    request.account.sendTransaction(
                      request.input,
                      ethereumNodeUrls,
                    ),
                });
              }
              case 'multichain': {
                return confirmAndSend({
                  setDialogState: ({ onConfirm, onReject }) => {
                    setDialogData(
                      Some({
                        connectedAccount: false,
                        request,
                        onClose: onReject,
                        onConfirm,
                      }),
                    );
                  },
                  send: () =>
                    request.account.sendTransaction(
                      request.input,
                      ethereumNodeUrls,
                    ),
                });
              }
              case 'waves': {
                return confirmAndSend({
                  setDialogState: ({ onConfirm, onReject }) => {
                    setDialogData(
                      Some({
                        connectedAccount: false,
                        request,
                        onClose: onReject,
                        onConfirm,
                      }),
                    );
                  },
                  send: () => request.account.sendTransaction(request.input),
                });
              }
              case 'keeper-extension': {
                setDialogData(
                  Some({
                    connectedAccount: true,
                    confirmationError: AsyncValue.Pending,
                    request,
                  }),
                );

                const response = await request.account.sendTransaction(
                  request.input,
                  getSigner,
                );

                return response.match({
                  Ok: responseValue => {
                    setDialogData(None);
                    return Ok(responseValue);
                  },
                  Err: err => {
                    if (err.type === 'unexpected-error') {
                      setDialogData(None);
                      return Err(err);
                    }

                    setDialogData(prevState =>
                      prevState.mapSome(x => ({
                        ...x,
                        confirmationError: AsyncValue.Ready(err),
                        onClose: () => {
                          setDialogData(None);
                        },
                      })),
                    );

                    return Ok(None);
                  },
                });
              }
              case 'keeper-mobile': {
                setDialogData(
                  Some({
                    connectedAccount: true,
                    confirmationError: AsyncValue.Pending,
                    request,
                  }),
                );

                const response = await request.account.sendTransaction(
                  request.input,
                  getSigner,
                );

                return response.match({
                  Ok: responseValue => {
                    setDialogData(None);
                    return Ok(responseValue);
                  },
                  Err: err => {
                    if (err.type === 'unexpected-error') {
                      setDialogData(None);
                      return Err(err);
                    }

                    setDialogData(prevState =>
                      prevState.mapSome(x => ({
                        ...x,
                        confirmationError: AsyncValue.Ready(err),
                        onClose: () => {
                          setDialogData(None);
                        },
                      })),
                    );

                    return Ok(None);
                  },
                });
              }
            }
          }),
      }),
    }),
    [dialogData, ethereumNodeUrls, getSigner],
  );

  return (
    <SignatureRequestContext.Provider value={value}>
      {children}

      {dialogData
        .mapSome(dialogDataValue => (
          <SignatureRequestDialog data={dialogDataValue} />
        ))
        .toNullable()}
    </SignatureRequestContext.Provider>
  );
}
