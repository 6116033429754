import { JsonRpcProvider } from 'ethers';
import { useEffect, useState } from 'react';
import {
  array,
  enums,
  type Infer,
  literal,
  number,
  string,
  type,
  union,
} from 'superstruct';

import { isAbortError, type UnexpectedError } from '../_core/errors';
import { handleResponse } from '../_core/handleResponse';
import type { Result } from '../_core/result';
import { useEntryContext } from '../entry';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import type { Network } from '../network/types';
import type { BlockchainTransactionSendResponse } from './types';

export type TransactionStatus =
  | { type: 'pending' }
  | { type: 'applicationFailed' }
  | { type: 'transactionLost' }
  | { type: 'applicationSuccess' }
  | { type: 'broadcastError'; message: string | undefined };

interface Props {
  children: (transactionStatus: TransactionStatus) => React.ReactElement;
  network: Network;
  sendResult: Result<BlockchainTransactionSendResponse, UnexpectedError>;
  onStatusChange: (status: TransactionStatus) => void;
}

const WavesTransactionStatus = union([
  type({ status: literal('not_found'), id: string() }),
  type({ status: literal('unconfirmed'), id: string() }),
  type({
    status: literal('confirmed'),
    id: string(),
    height: number(),
    confirmations: number(),
    applicationStatus: enums(['succeeded', 'failed']),
    spentComplexity: number(),
  }),
]);

type WavesTransactionStatus = Infer<typeof WavesTransactionStatus>;

export function WatchTransactionStatus({
  children,
  network,
  sendResult,
  onStatusChange,
}: Props) {
  const { ethereumNodeUrls } = useEntryContext();
  const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(
    { type: 'pending' },
  );

  useEffect(() => {
    function updateTransactionStatus(value: TransactionStatus) {
      setTransactionStatus(value);
      onStatusChange(value);
    }

    return sendResult.match({
      Err: err => {
        updateTransactionStatus({
          type: 'broadcastError',
          message: err.message,
        });
      },
      Ok: sendResponse => {
        let statusRetryTimeout: ReturnType<typeof setTimeout> | undefined;
        let statusAttempts = 0;

        const abortController = new AbortController();
        abortController.signal.onabort = () => {
          clearTimeout(statusRetryTimeout);
        };

        switch (sendResponse.blockchain) {
          case 'ethereum': {
            const jsonRpcProvider = new JsonRpcProvider(
              ethereumNodeUrls[network],
            );

            void (async function fetchReceipt() {
              statusAttempts++;

              try {
                const receipt = await jsonRpcProvider.getTransactionReceipt(
                  sendResponse.hash,
                );

                if (abortController.signal.aborted) return;

                if (receipt) {
                  if (receipt.status) {
                    updateTransactionStatus({ type: 'applicationSuccess' });
                  } else {
                    updateTransactionStatus({ type: 'applicationFailed' });
                  }
                } else {
                  if (statusAttempts > 5) {
                    updateTransactionStatus({ type: 'transactionLost' });
                  } else {
                    statusRetryTimeout = setTimeout(() => fetchReceipt(), 5000);
                  }
                  return;
                }
              } catch (err) {
                if (isAbortError(err)) return;
                throw err;
              }
            })();
            break;
          }
          case 'waves': {
            void (async function fetchStatus(
              prevStatus: WavesTransactionStatus | null,
            ) {
              statusAttempts++;

              const url = new URL(`/transactions/status`, nodeUrl);
              url.searchParams.append('id', sendResponse.id);

              try {
                const [status] = await fetch(url, {
                  signal: abortController.signal,
                  headers: {
                    Accept: 'application/json; large-significand-format=string',
                  },
                }).then(handleResponse(array(WavesTransactionStatus)));

                if (
                  status.status === 'not_found' &&
                  prevStatus &&
                  prevStatus.status === 'unconfirmed'
                ) {
                  updateTransactionStatus({ type: 'transactionLost' });
                  return;
                }

                if (
                  status.status === 'unconfirmed' ||
                  status.status === 'not_found'
                ) {
                  if (statusAttempts > 5) {
                    updateTransactionStatus({ type: 'transactionLost' });
                  } else {
                    statusRetryTimeout = setTimeout(
                      () => fetchStatus(status),
                      5000,
                    );
                  }
                  return;
                }

                if (status.applicationStatus === 'failed') {
                  updateTransactionStatus({ type: 'applicationFailed' });
                  return;
                }

                updateTransactionStatus({ type: 'applicationSuccess' });
              } catch (err) {
                if (isAbortError(err)) return;

                throw err;
              }
            })(null);
            break;
          }
          default: {
            const exhaustivenessCheck: never = sendResponse;
            return exhaustivenessCheck;
          }
        }

        return () => {
          abortController.abort();
        };
      },
    });
  }, [ethereumNodeUrls, network, nodeUrl, onStatusChange, sendResult]);

  return children(transactionStatus);
}
