import { combineReducers } from 'redux';

import coingecko from './coingecko/_reducer';
import dataService from './dataService/_reducer';
import waves from './wavesNode/_reducer';

export default combineReducers({
  coingecko,
  dataService,
  waves,
});
