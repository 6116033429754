import {
  autoUpdate,
  type Boundary,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  hide,
  type Padding,
  type Placement,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { CheckIcon } from '../icons/checkIcon';
import { ChevronDownIcon } from '../icons/chevronDown';
import { useBoundaryContext } from './boundary';
import * as styles from './select.module.css';
import { Spinner } from './spinner';

export interface SelectOption {
  label: string;
  value: string;
}

const VARIANT_CLASSES = {
  default: styles.button_variant_default,
  defaultSmall: styles.button_variant_defaultSmall,
  text: styles.button_variant_text,
};

// Based on Select implementaion https://floating-ui.com/docs/useListNavigation#examples
export function Select<T extends SelectOption>({
  disabled,
  variant = 'default',
  autoWidth,
  placeholder,
  placement = 'bottom-start',
  isLoading,
  items,
  value,
  renderOptionLabel = (item: T) => item.label,
  renderButtonLabel = renderOptionLabel,
  renderPlaceholder = (text: string) => text,
  onChange,
}: {
  disabled?: boolean;
  variant?: keyof typeof VARIANT_CLASSES;
  autoWidth?: boolean;
  placeholder?: string;
  placement?: Placement;
  value?: string;
  isLoading?: boolean;
  items: T[];
  renderOptionLabel?: (item: T) => React.ReactElement | string | number;
  renderButtonLabel?: (item: T) => React.ReactElement | string | number;
  renderPlaceholder?: (text: string) => React.ReactElement | string | number;
  onChange?: (value: T['value']) => void;
}) {
  const { i18n } = useLingui();
  const boundaryContext = useBoundaryContext();

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const controllableIndex =
    value != null ? items.findIndex(i => i.value === value) : selectedIndex;

  function setControllableIndex(index: number) {
    setSelectedIndex(index);
    onChange?.(items[index].value);
  }

  const boundary: Boundary = boundaryContext?.rect ?? 'clippingAncestors';
  const padding: Padding = 10;

  const { x, y, strategy, refs, context, isPositioned, middlewareData } =
    useFloating({
      placement,
      open: isOpen,
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
      middleware: [
        flip({
          boundary,
          padding,
        }),
        size({
          boundary,
          padding,
          apply({ rects, elements, availableHeight, availableWidth }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
            });

            elements.floating.style.minWidth = `${rects.reference.width}px`;
            elements.floating.style.maxWidth = `${availableWidth}px`;
          },
        }),
        hide(),
      ],
    });

  useEffect(() => {
    if (!isPositioned || !isOpen || !middlewareData.hide?.referenceHidden) {
      return;
    }

    setIsOpen(false);
  }, [isOpen, isPositioned, middlewareData.hide?.referenceHidden]);

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(items.map(item => item.label));
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex: controllableIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex: controllableIndex,
    onMatch: isOpen ? setActiveIndex : setControllableIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [click, dismiss, role, listNav, typeahead],
  );

  const handleSelect = (index: number) => {
    setIsOpen(false);
    setControllableIndex(index);
  };

  const selectedItem =
    controllableIndex !== -1 ? items[controllableIndex] : undefined;

  return (
    <>
      <button
        disabled={disabled}
        type="button"
        tabIndex={0}
        ref={refs.setReference}
        className={clsx(styles.button, VARIANT_CLASSES[variant], {
          [styles.button_autoWidth]: autoWidth,
          [styles.placeholder]: selectedItem == null,
        })}
        {...getReferenceProps()}
      >
        <div className={styles.buttonLabel}>
          {selectedItem
            ? renderButtonLabel(selectedItem)
            : renderPlaceholder(placeholder || t(i18n)`Select...`)}
        </div>

        {isLoading ? (
          <Spinner className={styles.spinner} size={16} />
        ) : (
          <ChevronDownIcon className={styles.buttonIcon} />
        )}
      </button>

      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className={styles.floating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              {...getFloatingProps()}
            >
              {items.map((item, i) => (
                <div
                  key={item.value}
                  ref={node => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === controllableIndex && i === activeIndex}
                  className={clsx(styles.option, {
                    [styles.optionActive]: i === activeIndex,
                  })}
                  {...getItemProps({
                    onClick() {
                      handleSelect(i);
                    },
                    onKeyDown(event) {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        handleSelect(i);
                      }

                      if (event.key === ' ' && !isTypingRef.current) {
                        event.preventDefault();
                        handleSelect(i);
                      }
                    },
                  })}
                >
                  <div className={styles.optionLabel}>
                    {renderOptionLabel(item)}
                  </div>

                  {i === controllableIndex && (
                    <CheckIcon className={styles.optionSelectedIcon} />
                  )}
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
