import { HDNodeWallet, JsonRpcProvider } from 'ethers';

import {
  handleUnexpectedError,
  type UnexpectedError,
} from '../../_core/errors';
import { type Maybe, Some } from '../../_core/maybe';
import { Ok, Result } from '../../_core/result';
import { EthereumPublicKey } from '../../blockchain/publicKey';
import {
  EthereumTransaction,
  type EthereumTransactionInput,
} from '../../blockchain/transaction/ethereumTransaction';
import type { BlockchainTransactionSendResponse } from '../../blockchain/types';
import type { Network } from '../../network/types';
import type { Account } from '../types';
import { AbstractAccount } from './abstractAccount';
import { Bip39Seed } from './bip39Seed';

export class EthereumAccount extends AbstractAccount {
  #publicKey;
  #seed;

  private constructor({
    id,
    name,
    publicKey,
    seed,
  }: {
    id: string;
    name: string;
    publicKey: EthereumPublicKey;
    seed: Bip39Seed;
  }) {
    super({ id, name });
    this.#publicKey = publicKey;
    this.#seed = seed;
  }

  static fromInMemoryJSON(json: ReturnType<EthereumAccount['toInMemoryJSON']>) {
    return Result.all([
      Bip39Seed.fromString(json.seed),
      EthereumPublicKey.fromJSON(json.publicKey),
    ]).mapOk(
      ([seed, publicKey]) =>
        new EthereumAccount({
          id: json.id,
          name: json.name,
          publicKey,
          seed,
        }),
    );
  }

  static fromPersistedJSON(
    json: ReturnType<EthereumAccount['toPersistedJSON']>,
  ) {
    return Result.all([
      Bip39Seed.fromString(json.seed),
      EthereumPublicKey.fromSeed(json.seed),
    ]).mapOk(
      ([seed, publicKey]) =>
        new EthereumAccount({
          id: json.id,
          name: json.name,
          publicKey,
          seed,
        }),
    );
  }

  static fromSeed(seed: Bip39Seed) {
    return EthereumAccount.fromPersistedJSON({
      type: 'ethereum',
      id: '',
      name: '',
      seed: seed.toString(),
    });
  }

  get type() {
    return 'ethereum' as const;
  }

  get seed() {
    return this.#seed.toString();
  }

  getSeed() {
    return Some(this.#seed);
  }

  getPrivateKeys() {
    return [this.#seed.computeEthereumPrivateKey()];
  }

  getPublicKeys() {
    return [this.#publicKey];
  }

  isDuplicateOf(other: Account): boolean {
    return (
      this.id !== other.id &&
      this.type === other.type &&
      this.#seed.toString() === other.#seed.toString()
    );
  }

  toInMemoryJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      seed: this.#seed.toString(),
      publicKey: this.#publicKey.toJSON(),
    };
  }

  toPersistedJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      seed: this.#seed.toString(),
    };
  }

  async sendTransaction(
    input: EthereumTransactionInput,
    ethereumNodeUrls: {
      [Network.Mainnet]: string;
      [Network.Testnet]: string;
    },
  ): Promise<
    Result<
      Maybe<BlockchainTransactionSendResponse<'ethereum'>>,
      UnexpectedError
    >
  > {
    try {
      const wallet = HDNodeWallet.fromPhrase(this.#seed.toString()).connect(
        new JsonRpcProvider(ethereumNodeUrls[input.network]),
      );

      const txResponse = await wallet.sendTransaction(
        EthereumTransaction.fromInput(
          input,
          this.getEthereumAddress().assertSome(),
        ).toTransactionRequest(),
      );

      const response: BlockchainTransactionSendResponse<'ethereum'> = {
        blockchain: 'ethereum',
        hash: txResponse.hash,
      };

      return Ok(Some(response));
    } catch (err) {
      return handleUnexpectedError(err, {
        context: 'Send transaction using Ethereum Account',
      });
    }
  }
}
