import { createContext, useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import invariant from 'tiny-invariant';

import type { Vault } from '../vault/redux';
import { RequireUnlockedVault } from '../vault/requireUnlockedVault';

const AccountsContext = createContext<Extract<
  Vault,
  { state: 'unlocked' }
> | null>(null);

interface Props {
  children: React.ReactNode;
}

export function RequireAccounts({ children }: Props) {
  return (
    <RequireUnlockedVault>
      {vault => {
        if (vault.state === 'unlocked' && vault.accounts.length !== 0) {
          return (
            <AccountsContext.Provider value={vault}>
              {children}
            </AccountsContext.Provider>
          );
        }

        const searchParams = new URLSearchParams({
          next: location.pathname + location.search + location.hash,
        });

        return (
          <Navigate
            replace
            to={{ pathname: '/add-account', search: searchParams.toString() }}
          />
        );
      }}
    </RequireUnlockedVault>
  );
}

export function useAccounts({
  onlySelected = false,
}: { onlySelected?: boolean } = {}) {
  const vault = useContext(AccountsContext);

  invariant(
    vault,
    'No context provided: `useAccounts()` can only be used in a descendant of `<RequireAccounts>`.',
  );

  return useMemo(
    () =>
      onlySelected
        ? vault.accounts.filter(
            account => account.id === vault.selectedAccountId,
          )
        : vault.accounts,
    [onlySelected, vault.accounts, vault.selectedAccountId],
  );
}
