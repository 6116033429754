// extracted by mini-css-extract-plugin
export var errorText = "JQqrgVtW62Yd6zNMXgVe";
export var helpText = "vmQpbi_Ii4skSFvlUdAL";
export var pill = "P9rpJaFMOBKcLYD7veTt";
export var pillError = "Ew7DJ4Vlh585drEPslew";
export var pillHidden = "QQOYCMAbWpzMdLnNVeui";
export var pillIndex = "WSdgT9aV0REPJybsL6Ep";
export var pillSelected = "p9LMpVr4NYv5r0LtKjg0";
export var pillWithIndex = "FwpZsDso6hNj2FvrflXD";
export var wordsList = "sPE7mevjPefqsIfIxvyD";
export var wordsToCheck = "dNzJtfBGzYP9dZCVCtmA";