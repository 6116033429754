import { plural, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';

import { Err, Ok } from '../_core/result';
import { useAppSelector } from '../store/react';
import { Account } from './types';
import { findDuplicateAccount, validateName } from './validators';

export function useAccountValidators() {
  const { i18n } = useLingui();

  const vault = useAppSelector(state => state.vault);

  invariant(vault.state === 'unlocked' || vault.state === 'empty');

  return useMemo(() => {
    const accounts =
      vault.state === 'unlocked'
        ? vault.accounts.map(a => Account.fromInMemoryJSON(a).assertOk())
        : [];

    return {
      validateName: (name: string) =>
        validateName({ name, accounts }).match({
          Ok,
          Err: err => {
            switch (err.type) {
              case 'name-required':
                return Err(t(i18n)`Required`);
              case 'name-max-length-exceeded':
                return Err(
                  t(i18n)({
                    message: plural(err.maxLength, {
                      one: 'Maximum length is # character',
                      other: 'Maximum length is # characters',
                    }),
                  }),
                );
              case 'name-already-exists':
                return Err(t(i18n)`Name already exists`);
              default:
                return Err(t(i18n)`Unexpected error`);
            }
          },
        }),
      findDuplicate: (account: Account) =>
        findDuplicateAccount({ account, accounts }),
    };
  }, [i18n, vault]);
}
