import { object, string } from 'superstruct';

import { handleResponse } from '../_core/handleResponse';
import type { WavesTransaction } from '../blockchain/transaction/wavesTransaction';
import type { BlockchainTransactionSendResponse } from '../blockchain/types';

export async function sendWavesTransaction(
  transaction: WavesTransaction,
  { nodeUrl }: { nodeUrl: string },
) {
  const json = await fetch(new URL('transactions/broadcast', nodeUrl), {
    method: 'POST',
    headers: {
      accept: 'application/json; large-significand-format=string',
      'content-type': 'application/json; charset=utf-8',
    },
    body: transaction.stringify(),
  }).then(handleResponse(object({ id: string() })));

  const sendResponse: BlockchainTransactionSendResponse<'waves'> = {
    blockchain: 'waves',
    id: json.id,
  };

  return sendResponse;
}
