import clsx from 'clsx';

import * as styles from './segmentedControl.module.css';

interface SegmentedControlOption<Id extends string> {
  id: Id;
  label: string;
}

interface Props<Id extends string> {
  options: Array<SegmentedControlOption<Id>>;
  selectedOptionId: Id;
  onChange: (newSelectedOptionId: Id) => void;
}

export function SegmentedControl<Id extends string>({
  options,
  selectedOptionId,
  onChange,
}: Props<Id>) {
  return (
    <div className={styles.root}>
      {options.map(option => (
        <button
          key={option.id}
          className={clsx(styles.optionButton, {
            [styles.optionButton_selected]: option.id === selectedOptionId,
          })}
          type="button"
          onClick={() => {
            onChange(option.id);
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
