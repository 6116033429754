import { Logo } from '../_core/logo';
import * as styles from './assetLogo.module.css';

export function AssetLogo({
  blockchain,
  logo,
  ticker,
  id,
}: {
  blockchain?: 'ethereum' | 'waves';
  logo?: string;
  ticker: string;
  id?: string;
}) {
  return (
    <div className={styles.logoContainer}>
      <Logo logo={logo} name={ticker} objectId={id} />

      {blockchain && (
        <img
          alt=""
          className={styles.networkBadge}
          src={
            {
              ethereum: new URL('../icons/ethereum.svg', import.meta.url)
                .pathname,
              waves: new URL('../icons/waves.svg', import.meta.url).pathname,
            }[blockchain]
          }
        />
      )}
    </div>
  );
}
