import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { createContext, useContext, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { AsyncValue } from '../../_core/asyncValue';
import { None, Some } from '../../_core/maybe';
import { useAccounts } from '../../accounts/requireAccounts';
import { Account } from '../../accounts/types';
import { isBlockchainEqual } from '../../blockchain/types';
import type { WavesAsset } from '../../cache/wavesNode/assetsDetails';
import { useWavesNfts } from '../../cache/wavesNode/nfts';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { PortfolioNftCollection } from '../../portfolio/nftCollection';
import { PortfolioNftCollections } from '../../portfolio/nftCollections';
import { useAppSelector } from '../../store/react';
import * as styles from './portfolio.module.css';

interface NftsPageContextValue {
  nfts: AsyncValue<WavesAsset[]>;
}

const NftsPageContext = createContext<NftsPageContextValue>({
  nfts: AsyncValue.Pending,
});

export function NftsPage() {
  const network = useAppSelector(state => state.network);

  const selectedAccounts = useAccounts({ onlySelected: true });

  const wavesAddresses = useMemo(
    () =>
      selectedAccounts.flatMap(x =>
        Account.fromInMemoryJSON(x)
          .assertOk()
          .getPublicKeys()
          .filter(isBlockchainEqual('waves'))
          .map(publicKey =>
            publicKey
              .getAddress({
                chainId: WAVES_NETWORK_CONFIGS[network].chainId,
              })
              .toString(),
          ),
      ),
    [network, selectedAccounts],
  );

  const wavesNfts = useWavesNfts({
    addresses: wavesAddresses,
    limit: 1000,
  });

  const nfts = useMemo(() => {
    return wavesNfts.mapReady(wavesNftsValue => {
      const result: WavesAsset[] = [];
      for (const address of wavesAddresses) {
        const nftsOnAddress = wavesNftsValue[address];
        if (!nftsOnAddress) continue;

        result.push(...nftsOnAddress);
      }

      return result;
    });
  }, [wavesAddresses, wavesNfts]);

  const value = useMemo(
    (): NftsPageContextValue => ({
      nfts,
    }),
    [nfts],
  );

  return (
    <NftsPageContext.Provider value={value}>
      <Outlet />
    </NftsPageContext.Provider>
  );
}

export function PortfolioNftCollectionsPage() {
  const { i18n } = useLingui();
  const { nfts } = useContext(NftsPageContext);

  return nfts
    .match({
      Pending: () => Some(AsyncValue.Pending),
      Ready: x => (x.length === 0 ? None : Some(AsyncValue.Ready(x))),
    })
    .match({
      None: () => (
        <h2 className={styles.emptyMessage}>{t(i18n)`No NFTs yet`}</h2>
      ),
      Some: x => (
        <PortfolioNftCollections
          collections={x.mapReady(nftsValue =>
            Object.values(
              nftsValue.reduce(
                (acc, nft) => {
                  acc[nft.issuer] ??= {
                    id: nft.issuer,
                    itemCount: 0,
                    label: nft.issuer,
                  };

                  acc[nft.issuer].itemCount++;

                  return acc;
                },
                {} as Record<
                  string,
                  { id: string; itemCount: number; label: string; src?: string }
                >,
              ),
            ),
          )}
        />
      ),
    });
}

export function PortfolioNftCollectionPage() {
  const { i18n } = useLingui();
  const { nfts } = useContext(NftsPageContext);
  const { issuerId } = useParams();
  invariant(issuerId);

  const filteredNfts = nfts.mapReady(nftsValue =>
    nftsValue
      .filter(({ issuer }) => issuer === issuerId)
      .map(nft => ({
        id: nft.assetId,
        label: nft.name,
      })),
  );

  return filteredNfts
    .match({
      Pending: () => Some(AsyncValue.Pending),
      Ready: x => (x.length === 0 ? None : Some(AsyncValue.Ready(x))),
    })
    .match({
      None: () => (
        <h2 className={styles.emptyMessage}>{t(i18n)`No NFTs yet`}</h2>
      ),
      Some: x => <PortfolioNftCollection collectionName={issuerId} nfts={x} />,
    });
}
