import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ConfirmAccount } from '../../accounts/confirmAccount';
import { AddAccountShell } from './shell';

export function ConfirmAccountPage({
  seed,
  seedIndicesAfterShuffle,
  indicesToCheck,
}: {
  seed: string;
  seedIndicesAfterShuffle: number[];
  indicesToCheck: number[];
}) {
  const { i18n } = useLingui();

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const next = params.get('next');
  const nextParams = new URLSearchParams();

  if (next) {
    nextParams.set('next', next);
  }

  const nextSearch = nextParams.toString();

  return (
    <AddAccountShell
      backPath="/add-account/create"
      heading={t(i18n)`Confirm backup`}
    >
      <ConfirmAccount
        seed={seed}
        seedIndicesAfterShuffle={seedIndicesAfterShuffle}
        indicesToCheck={indicesToCheck}
        onConfirm={() =>
          navigate({
            pathname: '/add-account/create/save',
            search: nextSearch,
          })
        }
      />
    </AddAccountShell>
  );
}
