import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button } from '../_core/button';
import { FormControl } from '../_core/formControl';
import { Input } from '../_core/input';
import { useEntryContext } from '../entry';
import { useAppDispatch, useAppSelector } from '../store/react';
import { readVault, unlockVault, type Vault } from './redux';
import * as styles from './requireUnlockedVault.module.css';

interface PasswordFormProps {
  onPassword: (password: string) => Promise<void>;
}

interface PasswordFormInputs {
  password: string;
}

function PasswordForm({ onPassword }: PasswordFormProps) {
  const { i18n } = useLingui();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setError,
  } = useForm<PasswordFormInputs>();

  return (
    <>
      <h1 className={styles.passwordScreenHeading}>
        <Trans>Unlock Keeper</Trans>
      </h1>

      <form
        className={styles.passwordForm}
        onSubmit={handleSubmit(async values => {
          try {
            await onPassword(values.password);
          } catch (error) {
            setError('password', { message: t(i18n)`Wrong password` });
          }
        })}
      >
        <p className={styles.passwordFormDescription}>
          <Trans>Enter your password to unlock Keeper</Trans>
        </p>

        <FormControl
          error={errors.password?.message}
          label={t(i18n)`Enter password`}
        >
          <Input
            autoComplete="current-password"
            autoFocus
            type="password"
            {...register('password', {
              required: t(i18n)`Required`,
            })}
          />
        </FormControl>

        <Button text={t(i18n)`Unlock`} type="submit" disabled={!isValid} />

        <Link
          to={{
            pathname: '/forgot-password',
            search: new URLSearchParams({
              next: location.pathname + location.search + location.hash,
            }).toString(),
          }}
          className={styles.forgotPasswordLink}
        >
          <Trans>Forgot password?</Trans>
        </Link>
      </form>
    </>
  );
}

interface Props {
  children: (
    vault: Extract<Vault, { state: 'empty' | 'unlocked' }>,
  ) => React.ReactElement;
}

export function RequireUnlockedVault({ children }: Props) {
  const { embedContext } = useEntryContext();
  const dispatch = useAppDispatch();
  const vault = useAppSelector(state => state.vault);

  useEffect(() => {
    if (embedContext === 'iframe') return;

    void dispatch(readVault());

    function handleStorageChange(event: StorageEvent) {
      if (event.storageArea === localStorage && event.key === 'updateVault') {
        void dispatch(readVault());
      }
    }

    addEventListener('storage', handleStorageChange);

    return () => {
      removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, embedContext]);

  if (vault.state === 'unknown') return null;

  if (vault.state === 'locked') {
    return (
      <PasswordForm onPassword={password => dispatch(unlockVault(password))} />
    );
  }

  return children(vault);
}
