import { utf8Decode, utf8Encode } from '@keeper-wallet/waves-crypto';
import { literal, mask, object, string } from 'superstruct';
import invariant from 'tiny-invariant';

import type { PersistedAccountJSON } from './types';

const EthereumAccountPersistedJSON = object({
  type: literal('ethereum'),
  id: string(),
  name: string(),
  seed: string(),
});

const KeeperExtensionAccountPersistedJSON = object({
  type: literal('keeper-extension'),
  id: string(),
  name: string(),
  publicKey: string(),
});

const KeeperMobileAccountPersistedJSON = object({
  type: literal('keeper-mobile'),
  id: string(),
  name: string(),
  publicKey: string(),
});

const MultichainAccountPersistedJSON = object({
  type: literal('multichain'),
  id: string(),
  name: string(),
  seed: string(),
});

const WavesAccountPersistedJSON = object({
  type: literal('waves'),
  id: string(),
  name: string(),
  seed: string(),
});

interface PersistedVaultJSON {
  accounts: PersistedAccountJSON[];
  selectedAccountId: string;
}

const pbkdf2Params: Omit<Pbkdf2Params, 'salt'> = {
  name: 'PBKDF2',
  hash: 'SHA-512',
  iterations: 1200000,
};

export async function encryptVault(
  persistedVault: PersistedVaultJSON,
  password: string,
): Promise<Uint8Array> {
  const importedKey = await crypto.subtle.importKey(
    'raw',
    utf8Encode(password),
    'PBKDF2',
    false,
    ['deriveKey'],
  );

  const salt = crypto.getRandomValues(new Uint8Array(16));
  const derivedKey = await crypto.subtle.deriveKey(
    { ...pbkdf2Params, salt },
    importedKey,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt'],
  );

  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encryptedBuffer = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    derivedKey,
    utf8Encode(JSON.stringify(persistedVault)),
  );

  return Uint8Array.of(...salt, ...iv, ...new Uint8Array(encryptedBuffer));
}

function createPersistedVaultJSON(input: unknown): PersistedVaultJSON {
  invariant(typeof input === 'object' && input != null);

  invariant('accounts' in input);
  invariant(Array.isArray(input.accounts));

  const accounts: PersistedAccountJSON[] = input.accounts.map((x: unknown) => {
    invariant(typeof x === 'object' && x != null);
    invariant('type' in x);

    if (x.type === 'keeper-extension') {
      return mask(x, KeeperExtensionAccountPersistedJSON);
    } else if (x.type === 'keeper-mobile') {
      return mask(x, KeeperMobileAccountPersistedJSON);
    } else if (x.type === 'multichain') {
      return mask(x, MultichainAccountPersistedJSON);
    } else if (x.type === 'waves') {
      return mask(x, WavesAccountPersistedJSON);
    } else if (x.type === 'ethereum') {
      return mask(x, EthereumAccountPersistedJSON);
    } else {
      throw new Error(`Unexpected account type: ${x.type}`);
    }
  });

  invariant('selectedAccountId' in input);
  invariant(typeof input.selectedAccountId === 'string');

  const selectedAccountId = input.selectedAccountId;

  return {
    accounts,
    selectedAccountId,
  };
}

export async function decryptVault(
  data: Uint8Array,
  password: string,
): Promise<PersistedVaultJSON> {
  const importedKey = await crypto.subtle.importKey(
    'raw',
    utf8Encode(password),
    'PBKDF2',
    false,
    ['deriveKey'],
  );

  const derivedKey = await crypto.subtle.deriveKey(
    { ...pbkdf2Params, salt: data.subarray(0, 16) },
    importedKey,
    { name: 'AES-GCM', length: 256 },
    true,
    ['decrypt'],
  );

  const decryptedBuffer = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: data.subarray(16, 28) },
    derivedKey,
    data.subarray(28),
  );

  const decryptedJson: unknown = JSON.parse(
    utf8Decode(new Uint8Array(decryptedBuffer)),
  );

  return createPersistedVaultJSON(decryptedJson);
}
