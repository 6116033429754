import { Children } from 'react';
import { type To, useHref, useLinkClickHandler } from 'react-router-dom';

import { ChevronRightIcon } from '../../icons/chevronRight';
import * as styles from './options.module.css';

export function OptionGroup({ children }: { children: React.ReactNode }) {
  return (
    <ul className={styles.group}>
      {Children.map(children, child => (
        <li>{child}</li>
      ))}
    </ul>
  );
}

export function OptionGroupSeparator({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={styles.separator}>{children}</div>;
}

interface OptionButtonProps {
  iconSrc: string;
  text: string;
}

function OptionButtonContents({ iconSrc, text }: OptionButtonProps) {
  return (
    <>
      <img alt="" className={styles.buttonIcon} src={iconSrc} />
      <span className={styles.buttonText}>{text}</span>
      <ChevronRightIcon className={styles.buttonChevronIcon} />
    </>
  );
}

export function OptionButton({
  iconSrc,
  text,
  ...otherProps
}: OptionButtonProps & Pick<React.ComponentProps<'button'>, 'onClick'>) {
  return (
    <button className={styles.button} type="button" {...otherProps}>
      <OptionButtonContents iconSrc={iconSrc} text={text} />
    </button>
  );
}

export function OptionButtonLink({
  iconSrc,
  text,
  to,
}: OptionButtonProps & { to: To }) {
  const linkClickHandler = useLinkClickHandler(to);
  const href = useHref(to);

  return (
    <a className={styles.button} href={href} onClick={linkClickHandler}>
      <OptionButtonContents iconSrc={iconSrc} text={text} />
    </a>
  );
}
