import type { Signer } from '@waves/signer';

import {
  handleUnexpectedError,
  isKeeperMobileUserBusyError,
  isUserRejectionError,
} from '../../_core/errors';
import { None, Some } from '../../_core/maybe';
import { Err, Ok, type ResultErr } from '../../_core/result';
import { WavesPublicKey } from '../../blockchain/publicKey';
import {
  WavesTransaction,
  type WavesTransactionInput,
} from '../../blockchain/transaction/wavesTransaction';
import type { BlockchainTransactionSendResponse } from '../../blockchain/types';
import type { Account } from '../types';
import { AbstractAccount } from './abstractAccount';

export class KeeperMobileAccount extends AbstractAccount {
  #publicKey;

  private constructor({
    id,
    name,
    publicKey,
  }: {
    id: string;
    name: string;
    publicKey: WavesPublicKey;
  }) {
    super({ id, name });
    this.#publicKey = publicKey;
  }

  static fromInMemoryJSON(
    json: ReturnType<KeeperMobileAccount['toInMemoryJSON']>,
  ) {
    return WavesPublicKey.fromString(json.publicKey).mapOk(
      publicKey =>
        new KeeperMobileAccount({ id: json.id, name: json.name, publicKey }),
    );
  }

  static fromPersistedJSON(
    json: ReturnType<KeeperMobileAccount['toPersistedJSON']>,
  ) {
    return WavesPublicKey.fromString(json.publicKey).mapOk(
      publicKey =>
        new KeeperMobileAccount({ id: json.id, name: json.name, publicKey }),
    );
  }

  static fromPublicKey(publicKey: WavesPublicKey) {
    return new KeeperMobileAccount({ id: '', name: '', publicKey });
  }

  get type() {
    return 'keeper-mobile' as const;
  }

  getSeed() {
    return None;
  }

  getPrivateKeys() {
    return [];
  }

  getPublicKeys() {
    return [this.#publicKey];
  }

  isDuplicateOf(other: Account): boolean {
    return (
      this.id !== other.id &&
      this.type === other.type &&
      this.#publicKey.toString() === other.#publicKey.toString()
    );
  }

  toInMemoryJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      publicKey: this.#publicKey.toString(),
    };
  }

  toPersistedJSON() {
    return {
      type: this.type,
      id: this.id,
      name: this.name,
      publicKey: this.#publicKey.toString(),
    };
  }

  async sendTransaction(
    input: WavesTransactionInput,
    getSigner: (accountType: 'keeper-mobile') => Promise<Signer>,
  ) {
    try {
      const signer = await getSigner('keeper-mobile');
      const { publicKey } = await signer.login();

      if (publicKey !== this.#publicKey.toString()) {
        // NOTE: do not remove until KEEP-1713
        // This happens if session for selected account is invalidated
        // (e.g. you disconnected manually in mobile app) and you
        // pair (with QR) with an account that differs from the saved one
        await signer.logout();

        return Err({ type: 'keeper-mobile-account-is-wrong' });
      }

      const [tx] = await WavesTransaction.fromInput(
        input,
        this.#publicKey,
      ).broadcastWithSigner(signer);

      const response: BlockchainTransactionSendResponse<'waves'> = {
        blockchain: 'waves',
        id: tx.id,
      };

      return Ok(Some(response));
    } catch (err) {
      if (isUserRejectionError(err)) return Ok(None);

      if (isKeeperMobileUserBusyError(err)) {
        return Err({
          type: 'keeper-mobile-user-busy',
        });
      }

      return handleUnexpectedError(err, {
        context: 'Send transaction using Keeper Mobile',
      });
    }
  }
}

export type KeeperMobileAccountSendTransactionError = ResultErr<
  Awaited<ReturnType<KeeperMobileAccount['sendTransaction']>>
>;
