import clsx from 'clsx';
import invariant from 'tiny-invariant';

import { useFormControlContext } from './formControl';
import * as styles from './formLabel.module.css';

interface Props {
  children: React.ReactNode | undefined;
  htmlFor?: string;
  className?: string;
}

export function FormLabel({
  children,
  className,
  htmlFor,
  ...otherProps
}: Props) {
  const formControlContext = useFormControlContext();

  if (formControlContext) {
    invariant(
      htmlFor === undefined,
      '`htmlFor` prop is passed to an `FormLabel` component wrapped to `FormControl`.' +
        '\n Use `id` prop on `FormControl` instead',
    );
    htmlFor = formControlContext.id;
  }

  return children ? (
    <label
      htmlFor={htmlFor}
      className={clsx(styles.root, className)}
      {...otherProps}
    >
      {children}
    </label>
  ) : null;
}
