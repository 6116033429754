import { deepEqual } from 'fast-equals';
import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, type Infer, nullable, number, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import type { WavesMoney } from '../../_core/money';
import { pollWhileUserIsActive } from '../../_core/polling';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const DataServiceProductResponse = type({
  product_id: number(),
  protocol_id: string(),
  address: string(),
  type: string(),
  name: string(),
  url: string(),
  icon_url: nullable(string()),
  amounts: array(
    type({
      asset_id: string(),
      coins: number(),
    }),
  ),
});

type DataServiceProductResponse = Infer<typeof DataServiceProductResponse>;

export type DataServiceProduct = Omit<DataServiceProductResponse, 'amounts'> & {
  amounts: WavesMoney[];
};

type DataServiceProducts = Partial<{
  [address: string]: DataServiceProductResponse[];
}>;

const reducer: Reducer<
  DataServiceProducts,
  {
    type: 'UPDATE_DATA_SERVICE_PRODUCTS';
    payload: {
      addresses: string[];
      products: DataServiceProductResponse[];
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_PRODUCTS': {
      const receivedProducts: DataServiceProducts = {};

      action.payload.products.forEach(product => {
        const products = (receivedProducts[product.address] ??= []);

        products.push(product);
      });

      const newState = {
        ...state,
        ...Object.fromEntries(
          action.payload.addresses.map(address => [
            address,
            receivedProducts[address] ?? [],
          ]),
        ),
      };

      if (deepEqual(newState, state)) return state;

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;

export function useDataServiceProducts({
  addresses,
}: {
  addresses: string[];
}): AsyncValue<DataServiceProducts> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    if (network !== Network.Mainnet || addresses.length === 0) return;

    return pollWhileUserIsActive(5000, signal =>
      fetch(new URL('/api/v1/portfolio', dataServiceUrl), {
        method: 'POST',
        body: JSON.stringify({ addresses }),
        signal,
      })
        .then(handleResponse(array(DataServiceProductResponse)))
        .then(products => {
          dispatch({
            type: 'UPDATE_DATA_SERVICE_PRODUCTS',
            payload: {
              addresses,
              products: products.filter(product =>
                ['staking', 'liquidity_pool', 'lend'].some(
                  knownType => knownType === product.type,
                ),
              ),
            },
          });
        }),
    );
  }, [addresses, dataServiceUrl, dispatch, network]);

  const products = useAppSelector(state => state.cache.dataService.products);

  return useMemo(() => {
    if (network !== Network.Mainnet) return AsyncValue.Ready({});

    const result: DataServiceProducts = {};

    for (const address of addresses) {
      const productsForAddress = products[address];
      if (!productsForAddress) return AsyncValue.Pending;

      result[address] = productsForAddress;
    }

    return AsyncValue.Ready(result);
  }, [addresses, network, products]);
}
