import type { Result } from '../_core/result';
import type { InMemoryAccountJSON, PersistedAccountJSON } from '../vault/types';
import { EthereumAccount } from './models/ethereumAccount';
import { KeeperExtensionAccount } from './models/keeperExtensionAccount';
import { KeeperMobileAccount } from './models/keeperMobileAccount';
import { MultichainAccount } from './models/multichainAccount';
import { WavesAccount } from './models/wavesAccount';

export type Account =
  | EthereumAccount
  | KeeperExtensionAccount
  | KeeperMobileAccount
  | MultichainAccount
  | WavesAccount;

export type AccountType = Account['type'];

export const Account = {
  fromInMemoryJSON: (json: InMemoryAccountJSON): Result<Account, unknown> => {
    switch (json.type) {
      case 'ethereum':
        return EthereumAccount.fromInMemoryJSON(json);
      case 'keeper-extension':
        return KeeperExtensionAccount.fromInMemoryJSON(json);
      case 'keeper-mobile':
        return KeeperMobileAccount.fromInMemoryJSON(json);
      case 'multichain':
        return MultichainAccount.fromInMemoryJSON(json);
      case 'waves':
        return WavesAccount.fromInMemoryJSON(json);
    }
  },

  fromPersistedJSON: async (
    json: PersistedAccountJSON,
  ): Promise<Result<Account, unknown>> => {
    switch (json.type) {
      case 'ethereum':
        return EthereumAccount.fromPersistedJSON(json);
      case 'keeper-extension':
        return KeeperExtensionAccount.fromPersistedJSON(json);
      case 'keeper-mobile':
        return KeeperMobileAccount.fromPersistedJSON(json);
      case 'multichain':
        return MultichainAccount.fromPersistedJSON(json);
      case 'waves':
        return WavesAccount.fromPersistedJSON(json);
    }
  },
};

export type OldConnectedWavesAccount =
  | InMemoryAccountJSON<'keeper-extension'>
  | InMemoryAccountJSON<'keeper-mobile'>;

export function isOldConnectedWavesAccount<T extends InMemoryAccountJSON>(
  x: T,
): x is Extract<T, { type: 'keeper-extension' | 'keeper-mobile' }> {
  return x.type === 'keeper-extension' || x.type === 'keeper-mobile';
}
