import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, type Infer, number, object, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { pollWhileUserIsActive } from '../../_core/polling';
import type { Balance } from '../../blockchain/types';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const EthereumTokenBalance = object({
  address: string(),
  balance: string(),
});

type EthereumTokenBalance = Infer<typeof EthereumTokenBalance>;

const DataServiceEthereumBalanceResponse = type({
  nativeBalance: EthereumTokenBalance,
  tokenBalances: array(EthereumTokenBalance),
  updatedAt: number(),
});

type DataServiceEthereumBalanceResponse = Infer<
  typeof DataServiceEthereumBalanceResponse
>;

const reducer: Reducer<
  Partial<
    Record<
      Network,
      Partial<{ [address: string]: DataServiceEthereumBalanceResponse }>
    >
  >,
  {
    type: 'UPDATE_DATA_SERVICE_ETHEREUM_BALANCE';
    payload: {
      balance: DataServiceEthereumBalanceResponse;
      address: string;
      network: Network;
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_ETHEREUM_BALANCE':
      return {
        ...state,
        [action.payload.network]: {
          ...state[action.payload.network],
          [action.payload.address]: action.payload.balance,
        },
      };
    default:
      return state;
  }
};

export default reducer;

interface DataServiceEthereumBalanceEntry {
  byAssetAddress: Partial<Record<string, Balance<'ethereum'>>>;
  ethereumBalance: Balance<'ethereum'>;
  list: Array<Balance<'ethereum'>>;
}

type DataServiceEthereumBalances = Partial<{
  [address: string]: DataServiceEthereumBalanceEntry;
}>;

export function useDataServiceEthereumBalances({
  addresses,
}: {
  addresses: string[];
}): AsyncValue<DataServiceEthereumBalances> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    return pollWhileUserIsActive(5000, async signal => {
      await Promise.all(
        addresses.map(address =>
          fetch(
            new URL(
              `/api/v1/${
                network === Network.Mainnet ? 'ethereum' : 'sepolia'
              }/balances/${address}`,
              dataServiceUrl,
            ),
            { signal },
          )
            .then(handleResponse(DataServiceEthereumBalanceResponse))
            .then(balance => {
              dispatch({
                type: 'UPDATE_DATA_SERVICE_ETHEREUM_BALANCE',
                payload: {
                  balance,
                  address,
                  network,
                },
              });
            }),
        ),
      );
    });
  }, [addresses, dataServiceUrl, dispatch, network]);

  const ethereumBalances = useAppSelector(
    state => state.cache.dataService.ethereumBalances,
  );

  return useMemo(() => {
    const balances: DataServiceEthereumBalances = {};

    if (addresses.length === 0) return AsyncValue.Ready(balances);

    const balancesByAddress = ethereumBalances[network];

    if (!balancesByAddress) {
      return AsyncValue.Pending;
    }

    for (const address of addresses) {
      const addressBalance = balancesByAddress[address];

      if (!addressBalance) {
        return AsyncValue.Pending;
      }

      const byAssetAddress = Object.fromEntries(
        [addressBalance.nativeBalance, ...addressBalance.tokenBalances].map(
          asset => [
            asset.address,
            { ...asset, blockchain: 'ethereum' } as const,
          ],
        ),
      );

      balances[address] = {
        byAssetAddress,
        ethereumBalance: {
          ...addressBalance.nativeBalance,
          blockchain: 'ethereum',
        },
        list: Object.values(byAssetAddress),
      };
    }

    return AsyncValue.Ready(balances);
  }, [addresses, ethereumBalances, network]);
}
