// extracted by mini-css-extract-plugin
export var button = "MfApTSTgd_Of9vY0TyT5";
export var buttonIcon = "sum96VHNBmrYfZwnJ6lQ";
export var buttonLabel = "tV6PgdEiTQS0UGrqOw8k";
export var button_autoWidth = "NMJmePKe_Xd_neMaDdZO";
export var button_variant_default = "pIrhyRl3K3Ly7WVcuze3";
export var button_variant_defaultSmall = "Ow9HKsGaiTMvL2ZazYww";
export var button_variant_text = "Y6UCaxLEgqoVJ9HPJGiB";
export var floating = "OFDU90QGWFvc5cnDLt7N";
export var option = "T7m68BuyLiyE_YIhAtPQ";
export var optionActive = "Z47ZwWE7OzxQ7Kfbf4pk";
export var optionLabel = "Q0B40bKDTsGv_5Qwz4Wg";
export var optionSelectedIcon = "tVRmseBV6sM9hSmBZ_kw";
export var placeholder = "oDFlO9Gov6NbMMQvAnqp";
export var spinner = "PelyBKQuB1P33dv9OJK2";