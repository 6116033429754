import { Navigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { Maybe } from '../_core/maybe';
import { AccountDetails } from '../accounts/details';
import { useAccounts } from '../accounts/requireAccounts';
import { Account } from '../accounts/types';
import { useAccountValidators } from '../accounts/useAccountValidator';
import { useAppDispatch, useAppSelector } from '../store/react';
import { deleteAccount, renameAccount } from '../vault/redux';

export function AccountDetailsPage() {
  const params = useParams();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);
  const { validateName } = useAccountValidators();
  const accounts = useAccounts();

  const vault = useAppSelector(state => state.vault);
  invariant(vault.state === 'unlocked');

  return Maybe.fromNullable(accounts.find(account => account.id === params.id))
    .flatMapSome(account => Account.fromInMemoryJSON(account).getOk())
    .match({
      None: () => <Navigate to="/accounts" />,
      Some: account => (
        <AccountDetails
          account={account}
          network={network}
          validateName={name => validateName(name).getErr().toOptional()}
          verifyPassword={password => password === vault.password}
          onDelete={() => {
            void dispatch(deleteAccount(account.id));
          }}
          onRename={newName => {
            void dispatch(renameAccount(account.id, newName));
          }}
        />
      ),
    });
}
