import type { Provider, Signer } from '@waves/signer';
import { useCallback, useState } from 'react';
import invariant from 'tiny-invariant';

import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import type { Network } from '../network/types';
import type { AccountType, OldConnectedWavesAccount } from './types';

export async function createWalletProvider(
  origin: string,
  provider: OldConnectedWavesAccount['type'],
): Promise<Provider> {
  switch (provider) {
    case 'keeper-extension': {
      const { ProviderKeeper } = await import(
        /* webpackChunkName: "provider-keeper" */
        '@waves/provider-keeper'
      );

      return new ProviderKeeper();
    }
    case 'keeper-mobile': {
      const { ProviderKeeperMobile } = await import(
        /* webpackChunkName: "provider-keeper-mobile" */
        '@keeper-wallet/provider-keeper-mobile'
      );

      return new ProviderKeeperMobile({
        name: 'Keeper Web',
        icon: new URL('/icon-512x512.png', origin).href,
      });
    }
  }
}

export function importWavesSigner() {
  return import(
    /* webpackChunkName: "signer" */
    '@waves/signer'
  );
}

export function useGetSigner({ network }: { network: Network }) {
  const [signersMap] = useState(() => new Map<AccountType, Signer>());

  return useCallback(
    async (accountType: OldConnectedWavesAccount['type']) => {
      const cachedSigner = signersMap.get(accountType);

      if (cachedSigner) {
        return cachedSigner;
      }

      const [{ Signer }, provider] = await Promise.all([
        importWavesSigner(),
        createWalletProvider(origin, accountType),
      ]);

      invariant(provider, 'provider');

      const signer = new Signer({
        NODE_URL: WAVES_NETWORK_CONFIGS[network].nodeUrl,
      });

      await signer.setProvider(provider);

      signersMap.set(accountType, signer);

      return signer;
    },
    [network, signersMap],
  );
}
