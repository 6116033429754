import { deepEqual } from 'fast-equals';
import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, type Infer, nullable, number, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import type { WavesMoney } from '../../_core/money';
import { pollWhileUserIsActive } from '../../_core/polling';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import type { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const WavesLeasingResponse = type({
  id: string(),
  originTransactionId: string(),
  sender: string(),
  recipient: string(),
  amount: string(),
  height: number(),
  status: string(),
  cancelHeight: nullable(number()),
  cancelTransactionId: nullable(string()),
});

export type WavesLeasingResponse = Infer<typeof WavesLeasingResponse>;

export type WavesLeasing = Omit<WavesLeasingResponse, 'amount'> & {
  amount: WavesMoney;
};

type WavesLeases = Partial<{
  [address: string]: WavesLeasingResponse[];
}>;

const reducer: Reducer<
  Partial<Record<Network, WavesLeases>>,
  {
    type: 'UPDATE_WAVES_LEASES';
    payload: {
      address: string;
      leases: WavesLeasingResponse[];
      network: Network;
    };
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_WAVES_LEASES': {
      const { address, leases, network } = action.payload;

      const prevLeases = state[network]?.[address];

      if (prevLeases && deepEqual(prevLeases, leases)) return state;

      return {
        ...state,
        [network]: {
          ...state[network],
          [address]: leases,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;

export function useWavesActiveLeases({
  addresses,
  only,
}: {
  addresses: string[];
  only?: 'incoming' | 'outgoing';
}): AsyncValue<WavesLeases> {
  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);
  const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  useEffect(
    () =>
      pollWhileUserIsActive(5000, async signal => {
        await Promise.all(
          addresses.map(address =>
            fetch(new URL(`/leasing/active/${address}`, nodeUrl), {
              headers: {
                accept: 'application/json; large-significand-format=string',
              },
              signal,
            })
              .then(handleResponse(array(WavesLeasingResponse)))
              .then(leases => {
                dispatch({
                  type: 'UPDATE_WAVES_LEASES',
                  payload: {
                    address,
                    network,
                    leases,
                  },
                });
              }),
          ),
        );
      }),
    [addresses, dispatch, network, nodeUrl],
  );

  const leases = useAppSelector(state => state.cache.waves.leases);

  return useMemo(() => {
    const result: WavesLeases = {};

    for (const address of addresses) {
      const addressLeases = leases[network]?.[address];
      if (!addressLeases) return AsyncValue.Pending;

      result[address] =
        only === 'incoming'
          ? addressLeases.filter(leasing => leasing.recipient === address)
          : only === 'outgoing'
          ? addressLeases.filter(leasing => leasing.sender === address)
          : addressLeases;
    }

    return AsyncValue.Ready(result);
  }, [addresses, leases, network, only]);
}
