import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, LinkButton } from '../../_core/button';
import {
  isKeeperInvalidNetworkError,
  isKeeperNoAccountsError,
  isKeeperNotInstalledError,
  isUserRejectionError,
} from '../../_core/errors';
import { type Maybe, None, Some } from '../../_core/maybe';
import { Spinner } from '../../_core/spinner';
import { KeeperExtensionAccount } from '../../accounts/models/keeperExtensionAccount';
import { useAccountValidators } from '../../accounts/useAccountValidator';
import { useGetSigner } from '../../accounts/utils';
import { WavesPublicKey } from '../../blockchain/publicKey';
import { SadIcon } from '../../icons/sad';
import { WebIcon } from '../../icons/web';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';
import { setSelectedAccount } from '../../vault/redux';
import { AccountNameInput } from './accountNameInput';
import { useAddAccountPageContext } from './addAccount';
import { AddressList } from './addressList';
import * as styles from './importKeeperExtensionAccount.module.css';
import { AddAccountShell } from './shell';

interface ImportKeeperExtensionInputs {
  name: string;
}

const defaultValues: ImportKeeperExtensionInputs = {
  name: '',
};

export function ImportKeeperExtensionAccount() {
  const { i18n } = useLingui();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  const navigate = useNavigate();

  const {
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const { tryToAddAnAccount } = useAddAccountPageContext();

  const [account, setAccount] = useState<Maybe<KeeperExtensionAccount>>(None);
  const [rejectReason, setRejectReason] = useState('');

  const getSigner = useGetSigner({ network });
  const login = useCallback(async () => {
    try {
      const signer = await getSigner('keeper-extension');
      const { publicKey } = await signer.login();

      setAccount(
        Some(
          KeeperExtensionAccount.fromPublicKey(
            WavesPublicKey.fromString(publicKey).assertOk(),
          ),
        ),
      );
    } catch (err) {
      if (isUserRejectionError(err)) {
        setRejectReason(t(i18n)`Rejected by user`);
        return;
      }

      if (isKeeperInvalidNetworkError(err)) {
        setRejectReason(
          t(i18n)`Please, switch network to ${
            network === Network.Mainnet ? 'Mainnet' : 'Testnet'
          } in Keeper Wallet Extension and try again`,
        );
      } else if (isKeeperNotInstalledError(err)) {
        setRejectReason(t(i18n)`Keeper Wallet Extension is not installed`);
      } else if (isKeeperNoAccountsError(err)) {
        setRejectReason(
          t(i18n)`You don't have any accounts in Keeper Wallet Extension`,
        );
      } else {
        // eslint-disable-next-line no-console
        console.error(err);

        setRejectReason(t(i18n)`Something went wrong`);
      }
    }
  }, [getSigner, i18n, network]);

  const autoLoginRef = useRef<Promise<void>>();

  const [isInstalled, setIsInstalled] = useState<Maybe<boolean>>(None);

  useEffect(() => {
    void isKeeperInstalled().then(status => {
      setIsInstalled(Some(status));

      if (!status) return;

      if (autoLoginRef.current != null) return;

      autoLoginRef.current = login();
    });
  }, [i18n, login]);

  const { findDuplicate, validateName } = useAccountValidators();

  const existingAccount = useMemo(() => {
    return account.flatMapSome(newAccount => findDuplicate(newAccount));
  }, [account, findDuplicate]);

  return (
    <AddAccountShell
      backPath="/add-account/import"
      heading={t(i18n)`Connect Keeper Extension`}
    >
      {rejectReason ? (
        <TryAgain
          reason={rejectReason}
          onRetry={() => {
            setRejectReason('');
            void login();
          }}
        />
      ) : (
        isInstalled.match({
          None: () => (
            <Loading message={t(i18n)`Looking for Keeper Extension...`} />
          ),
          Some: isInstalledValue =>
            !isInstalledValue ? (
              <KeeperIsNotInstalled onRetry={() => navigate(0)} />
            ) : (
              account.match({
                None: () => (
                  <Loading
                    message={t(i18n)`Approve request in opened window...`}
                  />
                ),
                Some: accountValue => (
                  <form
                    className={styles.form}
                    onSubmit={handleSubmit(values =>
                      tryToAddAnAccount({
                        ...accountValue.toPersistedJSON(),
                        name: values.name,
                      }),
                    )}
                  >
                    <p className={styles.helpText}>{t(
                      i18n,
                    )`For start using Keeper please set account name`}</p>

                    <div className={styles.nameInput}>
                      {existingAccount.match({
                        Some: acc => (
                          <AccountNameInput
                            key="existing"
                            disabled
                            account={accountValue}
                            value={acc.name}
                          />
                        ),
                        None: () => (
                          <AccountNameInput
                            account={accountValue}
                            error={errors.name?.message}
                            {...register('name', {
                              required: t(i18n)`Required`,
                              validate: value =>
                                validateName(value).getErr().toOptional(),
                            })}
                          />
                        ),
                      })}
                    </div>

                    <div className={styles.addressList}>
                      <AddressList
                        items={accountValue.getPublicKeys().map(publicKey =>
                          publicKey.getAddress({
                            chainId: WAVES_NETWORK_CONFIGS[network].chainId,
                          }),
                        )}
                      />
                    </div>

                    {existingAccount
                      .flatMapSome(x => (isSubmitting ? None : Some(x)))
                      .match({
                        Some: acc => (
                          <>
                            <div className={styles.warningMessage}>
                              <Trans>
                                This account already imported. Make it active?
                              </Trans>
                            </div>

                            <div className={styles.actionButtonWrapper}>
                              <Button
                                block
                                text={t(i18n)`Make active`}
                                onClick={async () => {
                                  await dispatch(setSelectedAccount(acc.id));
                                  navigate('/accounts');
                                }}
                              />
                            </div>
                          </>
                        ),
                        None: () => (
                          <div className={styles.actionButtonWrapper}>
                            <Button
                              block
                              disabled={!isValid || isSubmitting}
                              text={t(i18n)`Continue`}
                              type="submit"
                            />
                          </div>
                        ),
                      })}
                  </form>
                ),
              })
            ),
        })
      )}
    </AddAccountShell>
  );
}

function Loading({ message }: { message: string }) {
  return (
    <div className={styles.statusRoot}>
      <p className={styles.statusText}>{message}</p>

      <div className={styles.statusIconWrapper}>
        <Spinner size={24} />
      </div>
    </div>
  );
}

function TryAgain({
  reason,
  onRetry,
}: {
  reason: string;
  onRetry: () => void;
}) {
  const { i18n } = useLingui();

  return (
    <div className={styles.statusRoot}>
      <p className={styles.statusText}>{reason}</p>

      <div className={styles.statusIconWrapper}>
        <SadIcon className={styles.sadIcon} />
      </div>

      <button className={styles.cleanButton} onClick={() => onRetry()}>
        {t(i18n)`Try again`}
      </button>
    </div>
  );
}

function KeeperIsNotInstalled({ onRetry }: { onRetry: () => void }) {
  const { i18n } = useLingui();

  return (
    <div className={styles.statusRoot}>
      <p className={styles.statusText}>{t(
        i18n,
      )`Keeper Extension is not installed`}</p>

      <div className={styles.statusIconWrapper}>
        <SadIcon className={styles.sadIcon} />
      </div>

      <p>{t(i18n)`Don’t have Keeper yet?`}</p>

      <div className={styles.getKeeperButtonWrapper}>
        <LinkButton
          block
          startIcon={<WebIcon />}
          text={t(i18n)`Get Keeper`}
          to="https://keeper-wallet.app/#get-keeper"
          target="_blank"
          rel="noopener"
        />
      </div>

      <p>{t(i18n)`or`}</p>

      <button className={styles.cleanButton} onClick={() => onRetry()}>
        {t(i18n)`Try to reload page`}
      </button>
    </div>
  );
}

async function isKeeperInstalled() {
  const poll = (
    resolve: (result: boolean) => void,
    reject: (...args: unknown[]) => void,
    attempt = 0,
    retries = 30,
    interval = 100,
  ) => {
    if (attempt > retries) return resolve(false);

    if (typeof KeeperWallet !== 'undefined') {
      return resolve(true);
    } else setTimeout(() => poll(resolve, reject, ++attempt), interval);
  };

  return new Promise(poll);
}
