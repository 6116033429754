import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Boundary } from '../_core/boundary';
import { CurrentAccountSelect } from '../accounts/currentAccountSelect';
import { BurgerIcon } from '../icons/burger';
import { LockIcon } from '../icons/lock';
import { useAppDispatch, useAppSelector } from '../store/react';
import { lockVault } from '../vault/redux';
import * as styles from './header.module.css';

interface Props {
  className?: string;
  openMobileMenu: () => void;
}

export function Header({ className, openMobileMenu }: Props) {
  const dispatch = useAppDispatch();
  const vault = useAppSelector(state => state.vault);

  return (
    <>
      <div className={clsx(styles.headerPlaceholder, className)} />

      <header className={styles.header}>
        <div className={styles.headerContainer}>
          <Boundary className={styles.headerLayout}>
            <div className={styles.mobileMenuTriggerContainer}>
              <button
                className={styles.mobileMenuTrigger}
                type="button"
                onClick={openMobileMenu}
              >
                <BurgerIcon />
              </button>
            </div>

            <div className={styles.logoContainer}>
              <Link className={styles.logo} to="/" title="Go to the home page">
                <img
                  src={new URL('./images/logo.svg', import.meta.url).pathname}
                  alt=""
                  width={37}
                  height={37}
                />
              </Link>

              <div className={styles.betaBadge}>Beta</div>
            </div>

            <div className={styles.rightControls}>
              {vault.state === 'unlocked' && (
                <>
                  <div className={styles.currentAccountSelectContainer}>
                    <CurrentAccountSelect
                      accounts={vault.accounts}
                      placement="bottom-end"
                      selectedAccountId={vault.selectedAccountId}
                    />
                  </div>
                  <div className={styles.divider} />
                  <button
                    className={styles.lockButton}
                    onClick={() => dispatch(lockVault())}
                  >
                    <LockIcon />
                  </button>
                </>
              )}
            </div>
          </Boundary>
        </div>
      </header>
    </>
  );
}
