import { type Maybe, None, Some } from './maybe';

export class Result<T, E> {
  #state;

  private constructor(
    state: { isOk: true; value: T } | { isOk: false; err: E },
  ) {
    this.#state = state;
  }

  static Ok<T>(value: T): Result<T, never> {
    return new Result({ isOk: true, value });
  }

  static Err<const E>(err: E): Result<never, E> {
    return new Result({ isOk: false, err });
  }

  static try<T>(f: () => T): Result<T, unknown> {
    try {
      return Ok(f());
    } catch (err) {
      return Err(err);
    }
  }

  static fromPromise<T>(promise: Promise<T>): Promise<Result<T, unknown>> {
    return promise.then(Ok, (err: unknown) => Err(err));
  }

  static all<T extends ReadonlyArray<Result<unknown, unknown>> | []>(
    xs: T,
  ): Result<
    { [I in keyof T]: T[I] extends Result<infer U, unknown> ? U : never },
    {
      [I in keyof T]: T[I] extends Result<unknown, infer F> ? F : never;
    }[number]
  > {
    const values: unknown[] = [];

    for (const x of xs) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!x.#state.isOk) return x as any;
      values.push(x.#state.value);
    }

    return Ok(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values as any,
    );
  }

  get isOk() {
    return this.#state.isOk;
  }

  get isErr() {
    return !this.#state.isOk;
  }

  match<U, F = U>(arms: { Ok: (value: T) => U; Err: (value: E) => F }): U | F {
    return this.#state.isOk
      ? arms.Ok(this.#state.value)
      : arms.Err(this.#state.err);
  }

  mapOk<U>(f: (value: T) => U): Result<U, E> {
    return this.match<Result<U, E>>({
      Err,
      Ok: value => Ok(f(value)),
    });
  }

  mapErr<F>(f: (err: E) => F): Result<T, F> {
    return this.match<Result<T, F>>({
      Ok,
      Err: err => Err(f(err)),
    });
  }

  flatMapOk<U, F>(f: (value: T) => Result<U, F>): Result<U, E | F> {
    return this.match<Result<U, E | F>>({
      Err,
      Ok: value => f(value),
    });
  }

  getOk(): Maybe<T> {
    return this.match({
      Ok: Some,
      Err: () => None,
    });
  }

  getErr(): Maybe<E> {
    return this.match({
      Ok: () => None,
      Err: Some,
    });
  }

  assertOk(): T {
    return this.match({
      Ok: value => value,
      Err: cause => {
        throw new Error('Assertion failed', { cause });
      },
    });
  }
}

export type ResultErr<T extends Result<unknown, unknown>> = T extends Result<
  unknown,
  infer E
>
  ? E
  : never;

export const Ok = Result.Ok;
export const Err = Result.Err;
