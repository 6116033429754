import { type I18n } from '@lingui/core';
import { defineMessage, t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ErrorResponse } from '@remix-run/router';
import { useEffect } from 'react';
import {
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from 'react-router-dom';

import { LinkButton } from '../_core/button';
import { type MetaFunctionArgs } from '../_core/meta';
import { RunningText } from '../_core/runningText';
import { Container } from '../layout/layout';

const errorMessage = defineMessage({
  comment: 'Title of the error page (e.g. 404)',
  message: 'Error: {message}',
});

function convertErrorToErrorResponse(i18n: I18n, error: unknown) {
  return isRouteErrorResponse(error)
    ? error
    : new ErrorResponse(
        500,
        'Internal Server Error',
        t(i18n)`Something went terribly wrong on our side.`,
      );
}

export function createErrorMeta({ i18n }: { i18n: I18n }) {
  return ({ error }: MetaFunctionArgs) => {
    if (!error) return;

    const response = convertErrorToErrorResponse(i18n, error);

    const title = i18n._(errorMessage, {
      message: response.data,
    });

    return {
      title,
      'og:title': title,
    };
  };
}

export function ErrorPage() {
  const { i18n } = useLingui();
  const error = useRouteError();
  const location = useLocation();
  const errorResponse = convertErrorToErrorResponse(i18n, error);

  useEffect(() => {
    if (!isRouteErrorResponse(error)) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [error]);

  return (
    <Container>
      <RunningText>
        <h1>{i18n._(errorMessage, { message: errorResponse.data })}</h1>

        {errorResponse.status >= 500 && (
          <>
            <Trans comment="Shown in case of an unexpected message, below generic error text">
              <p>
                Please try to click{' '}
                <LinkButton
                  text={t(i18n)`Reload`}
                  to={location.pathname + location.search + location.hash}
                />
              </p>

              <p>
                If it doesn&apos;t help, you&apos;d better try again a little
                later.
              </p>
            </Trans>
          </>
        )}
      </RunningText>
    </Container>
  );
}
