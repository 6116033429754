import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';

import { CopyButton } from '../_core/copyButton';
import * as styles from './nftDetailView.module.css';

interface Props {
  src?: string;
  title: string;
  collectionTitle: string;
  description: string;
  creator: string;
  viewOnLinks: Array<{
    label: string;
    iconUrl: string;
    href: string;
  }>;
}

export function NftDetailView({
  src,
  title,
  collectionTitle,
  description,
  creator,
  viewOnLinks,
}: Props) {
  const { i18n } = useLingui();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>{title}</h1>
      </div>

      <div className={styles.cardImageContainer}>
        <img
          className={styles.cardImage}
          src={src ?? new URL('./nftPlaceholder.svg', import.meta.url).pathname}
          alt=""
        />
      </div>

      <div className={styles.details}>
        <div className={styles.detailsItem}>
          <h3 className={styles.detailsItemTitle}>{t(i18n)`Creator`}</h3>

          <p
            className={clsx(styles.detailsItemContent, styles.addressContainer)}
          >
            <CopyButton textToCopy={creator}>{creator}</CopyButton>
          </p>
        </div>

        <div className={styles.detailsItem}>
          <h3 className={styles.detailsItemTitle}>{t(i18n)`Collection`}</h3>
          <p className={styles.detailsItemContent}>{collectionTitle}</p>
        </div>

        <div className={styles.detailsItem}>
          <h3 className={styles.detailsItemTitle}>{t(i18n)`View on`}</h3>

          <p className={styles.viewOnLinks}>
            {viewOnLinks.map(({ label, iconUrl, href }) => (
              <a
                key={href}
                href={href}
                target="_blank"
                rel="noreferrer"
                className={styles.viewOnLink}
              >
                <span className={styles.viewOnLinkImageContainer}>
                  <img src={iconUrl} alt="" />
                </span>

                <span>{label}</span>
              </a>
            ))}
          </p>
        </div>

        <div className={styles.detailsItem}>
          <h3 className={styles.detailsItemTitle}>{t(i18n)`Description`}</h3>
          <p className={styles.detailsItemContent}>{description}</p>
        </div>
      </div>
    </div>
  );
}
