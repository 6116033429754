// extracted by mini-css-extract-plugin
export var alert = "nDcBhLD__fi9IaHjDtPK";
export var alertContent = "F0fxGCsr7V0qub4cp6it";
export var alertHeader = "V6A5aFGmZJVJW4O3eQfZ";
export var backButton = "fE_QUlLypLtqMsSbY20i";
export var container = "DK0vYdBRYvryuD7Ps9iA";
export var form = "ohM3oUlthaIn7tdwhX0V";
export var formHeading = "FUv0adh3NHopwn66Jr2F";
export var header = "_wR1KkdurVrDXnFKk5nW";
export var heading = "tT2RzD7JcxyrJ6hv5dKU";
export var input = "pLNPG900_7xaVmI8lhxT";