// extracted by mini-css-extract-plugin
export var addressContainer = "pxu6m1fNCGXtsAmG6nIY";
export var cardImage = "IXbhXhZpiXP4S9DZx5wA";
export var cardImageContainer = "e1ru54e3I8qOMTQoekYG";
export var details = "A3t_hk4xfoeFA8zVz1Jj";
export var detailsItem = "gW_UkPNkxYlZkWYjOsFW";
export var detailsItemContent = "L9ECF8Z38__uU2ascvri";
export var detailsItemTitle = "USE0y0LBm55jqy_TzGo1";
export var header = "c2ot9n_fgSL5cwqRHC9r";
export var headerTitle = "VTKiAqYcB6UMXAocYGoy";
export var root = "GX6g3gERBNwEiJAERQ5g";
export var viewOnLink = "NSWPcpAff24X9b9pstTw";
export var viewOnLinkImageContainer = "L5Vsm9ERVlxjPGr6qdYr";
export var viewOnLinks = "Hq4IiwGma1MvQC5PtPJg";