import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { type ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { MediumIcon } from '../icons/medium';
import { TelegramIcon } from '../icons/telegram';
import { TwitterIcon } from '../icons/twitter';
import * as styles from './footer.module.css';

interface Props {
  className?: string;
}

function FooterMenu({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className={styles.footerMenu}>
      <h3 className={styles.footerMenu_title}>{title}</h3>

      <ul className={styles.footerMenu_list}>{children}</ul>
    </div>
  );
}

function FooterMenuItem({ children }: { children: ReactNode }) {
  return <li className={styles.footerMenu_item}>{children}</li>;
}

export function Footer({ className }: Props) {
  const { i18n } = useLingui();

  return (
    <footer className={clsx(styles.root, className)}>
      <div className={styles.logoContainer}>
        <Link
          className={styles.logo}
          to="/"
          title={t(i18n)`Go to the home page`}
        >
          <img
            src={new URL('./images/logo-mono.svg', import.meta.url).pathname}
            alt=""
            width={37}
            height={37}
          />
        </Link>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <a href="//keeper-wallet.app" target="_blank" rel="noopener">
            keeper-wallet.app
          </a>
        </div>

        <div className={styles.footerMenu_container}>
          <FooterMenu title={t(i18n)`News`}>
            <FooterMenuItem>
              <a
                href="https://twitter.com/KeeperWallet"
                rel="noopener"
                target="_blank"
              >
                <TwitterIcon />
              </a>
            </FooterMenuItem>

            <FooterMenuItem>
              <a
                href="https://medium.com/keeper-wallet"
                rel="noopener"
                target="_blank"
              >
                <MediumIcon />
              </a>
            </FooterMenuItem>

            <FooterMenuItem>
              <a
                href="https://t.me/KeeperWallet"
                rel="noopener"
                target="_blank"
              >
                <TelegramIcon />
              </a>
            </FooterMenuItem>
          </FooterMenu>

          <FooterMenu title={t(i18n)`Community`}>
            <FooterMenuItem>
              <a
                href="https://t.me/KeeperCommunity"
                rel="noopener"
                target="_blank"
              >
                <TelegramIcon />
              </a>
            </FooterMenuItem>
          </FooterMenu>

          <FooterMenu title={t(i18n)`Support`}>
            <FooterMenuItem>
              <a
                href="https://docs.keeper-wallet.app/"
                target="_blank"
                rel="noopener"
              >
                <Trans>Docs</Trans>
              </a>
            </FooterMenuItem>

            <FooterMenuItem>
              <a
                href="https://keeper-wallet.app/terms-of-use"
                target="_blank"
                rel="noopener"
              >
                <Trans>Term of use</Trans>
              </a>
            </FooterMenuItem>
          </FooterMenu>
        </div>
      </div>
    </footer>
  );
}
