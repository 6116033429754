import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSearchParams } from 'react-router-dom';

import { OptionButtonLink, OptionGroup, OptionGroupSeparator } from './options';
import { AddAccountShell } from './shell';

export function ImportSeedOrKeyAccountPage() {
  const { i18n } = useLingui();

  const [params] = useSearchParams();
  const next = params.get('next');
  const nextParams = new URLSearchParams();

  if (next) {
    nextParams.set('next', next);
  }

  const nextSearch = nextParams.toString();

  return (
    <AddAccountShell
      backPath="/add-account/import"
      heading={t(i18n)`Choose account type`}
    >
      <OptionGroup>
        <OptionButtonLink
          iconSrc={new URL('./keeperIcon.svg', import.meta.url).pathname}
          text={t(i18n)`Multi-Coin Account`}
          to={{ pathname: '/add-account/multicoin', search: nextSearch }}
        />
      </OptionGroup>

      <OptionGroupSeparator>
        <Trans>or</Trans>
      </OptionGroupSeparator>

      <OptionGroup>
        <OptionButtonLink
          iconSrc={
            new URL('../../icons/ethereum.svg', import.meta.url).pathname
          }
          text={t(i18n)`Ethereum`}
          to={{ pathname: '/add-account/ethereum', search: nextSearch }}
        />

        <OptionButtonLink
          iconSrc={new URL('../../icons/waves.svg', import.meta.url).pathname}
          text={t(i18n)`Waves`}
          to={{ pathname: '/add-account/waves', search: nextSearch }}
        />
      </OptionGroup>
    </AddAccountShell>
  );
}
