import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import BigNumber from '@waves/bignumber';
import clsx from 'clsx';
import { useState } from 'react';

import { AssetLogo } from '../_core/assetLogo';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogHeading,
} from '../_core/dialog';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Maybe } from '../_core/maybe';
import { type Result } from '../_core/result';
import type { EthereumFeeEstimates } from '../blockchain/fee/ethereumFee';
import { ChevronDownIcon } from '../icons/chevronDown';
import * as styles from './feeField.module.css';

export interface SendAssetsEthereumFeeOption {
  blockchain: 'ethereum';
  id: string;
  kind: keyof EthereumFeeEstimates;
  usdAmount: BigNumber | undefined;
  weiAmount: BigNumber;
}

export interface SendAssetsWavesFeeOption {
  blockchain: 'waves';
  assetId: string;
  assetLabel: string;
  assetTokens: BigNumber;
  id: string;
  logo: string | undefined;
  usdAmount: BigNumber | undefined;
}

export type SendAssetsFeeFieldData =
  | {
      blockchain: 'ethereum';
      options: SendAssetsEthereumFeeOption[];
      selectedOption: SendAssetsEthereumFeeOption;
    }
  | {
      blockchain: 'waves';
      options: SendAssetsWavesFeeOption[];
      selectedOption: SendAssetsWavesFeeOption;
    };

interface Props {
  data: Result<SendAssetsFeeFieldData, string>;
  onChange: (newId: string) => void;
}

export function SendAssetsFeeField({ data, onChange }: Props) {
  const { i18n } = useLingui();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div className={styles.root}>
      {t(i18n)`Network fee`}

      {data.match({
        Ok: dataValue => (
          <>
            {dataValue.options.length === 1 ? (
              <SelectedOption option={dataValue.selectedOption} />
            ) : (
              <button
                className={styles.button}
                type="button"
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                <SelectedOption option={dataValue.selectedOption} showChevron />
              </button>
            )}

            <Dialog isOpen={isDialogOpen} onIsOpenChange={setIsDialogOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogClose />
                  <DialogHeading>{t(i18n)`Network fee`}</DialogHeading>
                </DialogHeader>

                {(() => {
                  switch (dataValue.blockchain) {
                    case 'ethereum':
                      return (
                        <div>
                          <p className={styles.dialogCallToAction}>{t(
                            i18n,
                          )`Choose network fee you’re willing to pay`}</p>

                          <ul className={styles.dialogFeeOptionList}>
                            {dataValue.options.map(option => (
                              <EthereumFeeOption
                                key={option.id}
                                isSelected={
                                  option.id === dataValue.selectedOption.id
                                }
                                option={option}
                                onClick={() => {
                                  onChange(option.id);
                                  setIsDialogOpen(false);
                                }}
                              />
                            ))}
                          </ul>
                        </div>
                      );
                    case 'waves':
                      return (
                        <div>
                          <p className={styles.dialogCallToAction}>{t(
                            i18n,
                          )`Choose an network fee asset`}</p>

                          <ul className={styles.dialogFeeOptionList}>
                            {dataValue.options.map(option => (
                              <WavesFeeOption
                                key={option.id}
                                isSelected={
                                  option.id === dataValue.selectedOption.id
                                }
                                option={option}
                                onClick={() => {
                                  onChange(option.id);
                                  setIsDialogOpen(false);
                                }}
                              />
                            ))}
                          </ul>
                        </div>
                      );
                  }
                })()}
              </DialogContent>
            </Dialog>
          </>
        ),
        Err: message => <span className={styles.error}>{message}</span>,
      })}
    </div>
  );
}

function formatWeisAsGweis(weis: BigNumber) {
  return `${weis.div(new BigNumber(10).pow(9)).toFormat()} Gwei`;
}

function SelectedOption({
  option,
  showChevron,
}: {
  option: SendAssetsEthereumFeeOption | SendAssetsWavesFeeOption;
  showChevron?: boolean;
}) {
  return (
    <div className={styles.selectedValue}>
      <div className={styles.selectedValueUsd}>
        {Maybe.fromNullable(option.usdAmount).match({
          None: () => '$–',
          Some: usd => formatUsdPrice(usd),
        })}
      </div>

      <div className={styles.selectedValueTokens}>
        {(() => {
          switch (option.blockchain) {
            case 'ethereum':
              return <>{formatWeisAsGweis(option.weiAmount)}</>;
            case 'waves':
              return (
                <>
                  {option.assetTokens.toFormat()} {option.assetLabel}
                </>
              );
          }
        })()}
      </div>

      {showChevron && (
        <ChevronDownIcon className={styles.selectedValueChevron} />
      )}
    </div>
  );
}

function EthereumFeeOption({
  isSelected,
  option,
  onClick,
}: {
  isSelected: boolean;
  option: SendAssetsEthereumFeeOption;
  onClick: () => void;
}) {
  const { i18n } = useLingui();

  return (
    <button
      className={clsx(styles.dialogFeeOption, {
        [styles.dialogFeeOption_selected]: isSelected,
      })}
      onClick={onClick}
    >
      <div className={styles.dialogFeeOptionLabel}>
        {
          {
            safe: t(i18n)`Low`,
            normal: t(i18n)`Average`,
            fast: t(i18n)`High`,
          }[option.kind]
        }
      </div>

      <div className={styles.dialogFeeOptionValue}>
        <div className={styles.dialogFeeOptionValueBlockchain}>
          {formatWeisAsGweis(option.weiAmount)}
        </div>

        <div className={styles.dialogFeeOptionValueUsd}>
          {Maybe.fromNullable(option.usdAmount).match({
            None: () => '$-',
            Some: formatUsdPrice,
          })}
        </div>
      </div>
    </button>
  );
}

function WavesFeeOption({
  isSelected,
  option,
  onClick,
}: {
  isSelected: boolean;
  option: SendAssetsWavesFeeOption;
  onClick: () => void;
}) {
  return (
    <button
      className={clsx(styles.dialogFeeOption, {
        [styles.dialogFeeOption_selected]: isSelected,
      })}
      onClick={onClick}
    >
      <AssetLogo
        blockchain="waves"
        logo={option.logo}
        id={option.assetId}
        ticker={option.assetLabel}
      />

      <div className={styles.dialogFeeOptionLabel}>{option.assetLabel}</div>

      <div className={styles.dialogFeeOptionValue}>
        <div className={styles.dialogFeeOptionValueBlockchain}>
          {option.assetTokens.toFormat()}
        </div>

        <div className={styles.dialogFeeOptionValueUsd}>
          {Maybe.fromNullable(option.usdAmount).match({
            None: () => '$-',
            Some: formatUsdPrice,
          })}
        </div>
      </div>
    </button>
  );
}
