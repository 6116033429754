import { Maybe, None, Some } from '../../_core/maybe';
import type { BlockchainPrivateKey } from '../../blockchain/privateKey';
import type { BlockchainPublicKey } from '../../blockchain/publicKey';
import type { Account, AccountType } from '../types';
import type { Bip39Seed } from './bip39Seed';
import type { MultichainSeed } from './multichainSeed';
import type { WavesSeed } from './wavesSeed';

export abstract class AbstractAccount {
  #id: string;
  #name: string;

  constructor({ id, name }: { id: string; name: string }) {
    this.#id = id;
    this.#name = name;
  }

  get id() {
    return this.#id;
  }

  get name() {
    return this.#name;
  }

  abstract get type(): AccountType;
  abstract isDuplicateOf(other: Account): boolean;

  abstract getSeed(): Maybe<Bip39Seed | MultichainSeed | WavesSeed>;
  abstract getPrivateKeys(): BlockchainPrivateKey[];
  abstract getPublicKeys(): BlockchainPublicKey[];

  getEthereumPublicKey() {
    return Maybe.findMap(this.getPublicKeys(), x =>
      x.blockchain === 'ethereum' ? Some(x) : None,
    );
  }

  getWavesPublicKey() {
    return Maybe.findMap(this.getPublicKeys(), x =>
      x.blockchain === 'waves' ? Some(x) : None,
    );
  }

  getEthereumAddress() {
    return this.getEthereumPublicKey().mapSome(x => x.getAddress());
  }

  getWavesAddress(chainId: number) {
    return this.getWavesPublicKey().mapSome(x => x.getAddress({ chainId }));
  }
}
