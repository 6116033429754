import { Maybe } from '../_core/maybe';
import { Err, Ok, type Result } from '../_core/result';
import type { Account } from './types';

const NAME_MAX_LENGTH = 26;

export type ValidateNameError =
  | {
      type: 'name-required';
    }
  | {
      type: 'name-max-length-exceeded';
      maxLength: 26;
    }
  | {
      type: 'name-already-exists';
    };

export function validateName({
  name,
  accounts,
}: {
  name: string;
  accounts: Account[];
}): Result<null, ValidateNameError> {
  const trimmedName = name.trim();

  if (trimmedName.length === 0) {
    return Err({ type: 'name-required' });
  }
  if (trimmedName.length > 26) {
    return Err({
      type: 'name-max-length-exceeded',
      maxLength: NAME_MAX_LENGTH,
    });
  }
  if (accounts.some(a => a.name === trimmedName)) {
    return Err({ type: 'name-already-exists' });
  }

  return Ok(null);
}

export function findDuplicateAccount({
  account,
  accounts,
}: {
  account: Account;
  accounts: Account[];
}) {
  return Maybe.fromNullable(accounts.find(a => account.isDuplicateOf(a)));
}

export type ValidateAccountError =
  | ValidateNameError
  | {
      type: 'only-one-mobile-account-supported';
    }
  | {
      type: 'account-already-exists';
    };

export function validateAccount({
  account,
  accounts,
}: {
  account: Account;
  accounts: Account[];
}): Result<null, ValidateAccountError> {
  if (
    account.type === 'keeper-mobile' &&
    accounts.some(acc => acc.type === 'keeper-mobile')
  )
    return Err({ type: 'only-one-mobile-account-supported' });

  if (findDuplicateAccount({ account, accounts }).isSome)
    return Err({ type: 'account-already-exists' });

  return validateName({ name: account.name, accounts });
}
