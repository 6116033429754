import { None, Some } from './maybe';

export class AsyncValue<T> {
  #state;

  private constructor(
    state: Readonly<{ isReady: false } | { isReady: true; value: T }>,
  ) {
    this.#state = state;
  }

  static Pending = new AsyncValue<never>({ isReady: false });

  static Ready<T>(value: T) {
    return new AsyncValue<T>({ isReady: true, value });
  }

  static allRecord<T extends Readonly<Record<string, AsyncValue<unknown>>>>(
    xs: T,
  ): AsyncValue<{
    [K in keyof T]: T[K] extends AsyncValue<infer U> ? U : never;
  }> {
    const result: Record<string, unknown> = {};

    for (const [key, x] of Object.entries(xs)) {
      if (!x.#state.isReady) return AsyncValue.Pending;
      result[key] = x.#state.value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return AsyncValue.Ready(result as any);
  }

  get isPending() {
    return !this.#state.isReady;
  }

  get isReady() {
    return this.#state.isReady;
  }

  match<U>(arms: { Pending: () => U; Ready: (value: T) => U }): U {
    return this.#state.isReady ? arms.Ready(this.#state.value) : arms.Pending();
  }

  mapReady<U>(f: (value: T) => U): AsyncValue<U> {
    return this.match({
      Ready: value => AsyncValue.Ready(f(value)),
      Pending: () => AsyncValue.Pending,
    });
  }

  flatMapReady<U>(f: (value: T) => AsyncValue<U>): AsyncValue<U> {
    return this.match({
      Ready: value => f(value),
      Pending: () => AsyncValue.Pending,
    });
  }

  getReady() {
    return this.match({
      Pending: () => None,
      Ready: Some,
    });
  }
}
