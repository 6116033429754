// extracted by mini-css-extract-plugin
export var button = "HuO8ys6sv3a0jUHBBzEw";
export var dialogCallToAction = "kVeebiQtGXYaln1GvAgg";
export var dialogFeeOption = "hagcXWFkkdex5YAtKxQB";
export var dialogFeeOptionLabel = "tNMj42z6yYQdJlEhcO4Z";
export var dialogFeeOptionList = "mWZ25xgbniyywctyaHZ8";
export var dialogFeeOptionValue = "hYjZusA5XA008DhiMowV";
export var dialogFeeOptionValueBlockchain = "T645layPjCtO3_r9FPkf";
export var dialogFeeOptionValueUsd = "GfVCh8AThZqFh2jLPhSg";
export var dialogFeeOption_selected = "ujSLMmvTNe1FvqY89fwQ";
export var error = "Z_pD0PJYVSVa4HdVhv4o";
export var root = "zjjLlmrYXxehzk5Fo1P3";
export var selectedValue = "ZH7Jg7nLV_zRT_sVc_Vv";
export var selectedValueChevron = "rBi_NcdNyKk5qy1n7LgM";
export var selectedValueTokens = "CWbP2BmZFQ2UGmJILuZK";
export var selectedValueUsd = "PZuz9dUxgH6cBjX6uhFt";