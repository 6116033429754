import { Trans } from '@lingui/macro';
import clsx from 'clsx';

import * as styles from './coingeckoBadge.module.css';

export function CoingeckoBadge({ className }: { className?: string }) {
  return (
    <div className={clsx(className, styles.coingeckoBadge)}>
      <a
        className={styles.coingeckoBadgeLink}
        href="https://www.coingecko.com/"
        rel="noopener"
      >
        <Trans>Data from CoinGecko API</Trans>
      </a>
    </div>
  );
}
