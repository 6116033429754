import BigNumber from '@waves/bignumber';
import { TRANSACTION_TYPE, type TransactionType } from '@waves/ts-types';
import invariant from 'tiny-invariant';

import { WavesMoney } from '../../_core/money';
import type { Asset, Balance } from '../../blockchain/types';
import type { DataServiceUsdPrices } from '../../cache/dataService/usdPrices';
import {
  WAVES_ASSET,
  type WavesAssetsDetails,
} from '../../cache/wavesNode/assetsDetails';

export class WavesFee {
  #value;

  constructor(value: WavesMoney) {
    this.#value = value;
  }

  get blockchain() {
    return 'waves' as const;
  }

  toMoney() {
    return this.#value;
  }
}

function convertFeeToAsset(fee: WavesMoney, asset: Asset<'waves'>) {
  const minSponsoredFeeFrom = fee.asset.minSponsoredAssetFee;
  const minSponsoredFeeTo = asset.minSponsoredAssetFee;

  invariant(minSponsoredFeeFrom && minSponsoredFeeTo);

  return WavesMoney.fromCoins(
    fee
      .getCoins()
      .mul(minSponsoredFeeTo)
      .div(minSponsoredFeeFrom)
      .roundTo(0, 0),
    asset,
  );
}

export function getWavesFeeOptions({
  assets,
  balances,
  initialFee,
  txType,
  usdPrices,
}: {
  assets: WavesAssetsDetails;
  balances: Array<Balance<'waves'>>;
  initialFee: WavesMoney;
  txType: TransactionType;
  usdPrices: DataServiceUsdPrices;
}) {
  if (
    txType !== TRANSACTION_TYPE.TRANSFER &&
    txType !== TRANSACTION_TYPE.INVOKE_SCRIPT
  ) {
    return [];
  }

  const feeInWaves = convertFeeToAsset(initialFee, WAVES_ASSET);

  return balances
    .map(balance => ({
      asset: assets[balance.assetId],
      balance,
    }))
    .filter(
      (
        item,
      ): item is {
        asset: Asset<'waves'>;
        balance: Balance<'waves'>;
      } => item.balance.minSponsoredAssetFee != null && item.asset != null,
    )
    .map(({ asset, balance }) => ({
      balance,
      money: convertFeeToAsset(initialFee, asset),
    }))
    .filter(
      ({ balance, money }) =>
        new BigNumber(balance.sponsorBalance ?? 0).gte(feeInWaves.getCoins()) &&
        new BigNumber(balance.balance).gte(money.getCoins()),
    )
    .sort((a, b) => {
      const aUsdSum = a.money
        .getTokens()
        .mul(usdPrices[a.money.asset.assetId] || '0');

      const bUsdSum = b.money
        .getTokens()
        .mul(usdPrices[b.money.asset.assetId] || '0');

      if (aUsdSum.gt(bUsdSum)) {
        return 1;
      }

      if (aUsdSum.lt(bUsdSum)) {
        return -1;
      }

      const aSponsorBalance = new BigNumber(a.balance.sponsorBalance ?? 0);
      const bSponsorBalance = new BigNumber(b.balance.sponsorBalance ?? 0);

      if (aSponsorBalance.gt(bSponsorBalance)) {
        return -1;
      }

      if (aSponsorBalance.lt(bSponsorBalance)) {
        return 1;
      }

      return 0;
    })
    .map(x => new WavesFee(x.money));
}
