import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';

import { AssetLogo } from '../_core/assetLogo';
import { AsyncValue } from '../_core/asyncValue';
import { Button } from '../_core/button';
import {
  Dialog,
  DialogContextConsumer,
  DialogHeading,
  DialogRoot,
} from '../_core/dialog';
import type { UnexpectedError } from '../_core/errors';
import { Maybe } from '../_core/maybe';
import { WavesMoney } from '../_core/money';
import { Skeleton } from '../_core/skeleton';
import { Spinner } from '../_core/spinner';
import { ellipsis } from '../_core/utils';
import type { BlockchainAddress } from '../blockchain/address';
import type {
  BlockchainTransactionInput,
  BlockchainTransactionRequest,
} from '../blockchain/types';
import { useDataServiceAssets } from '../cache/dataService/assets';
import { useWavesAssetsDetails } from '../cache/wavesNode/assetsDetails';
import { CrossIcon } from '../icons/cross';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { Network } from '../network/types';
import { useAppSelector } from '../store/react';
import type { KeeperExtensionAccountSendTransactionError } from './models/keeperExtensionAccount';
import type { KeeperMobileAccountSendTransactionError } from './models/keeperMobileAccount';
import * as styles from './signatureRequestDialog.module.css';
import type { Account } from './types';

type ConnectedAccountSignatureError = Exclude<
  | KeeperExtensionAccountSendTransactionError
  | KeeperMobileAccountSendTransactionError,
  UnexpectedError
>;

export type SignatureRequestDialogData =
  | {
      connectedAccount: false;
      request: BlockchainTransactionRequest<
        'ethereum' | 'multichain' | 'waves'
      >;
      onClose?: () => void;
      onConfirm?: () => void;
    }
  | {
      connectedAccount: true;
      confirmationError: AsyncValue<ConnectedAccountSignatureError>;
      request: BlockchainTransactionRequest<
        'keeper-extension' | 'keeper-mobile'
      >;
      onClose?: () => void;
    };

interface Props {
  data: SignatureRequestDialogData;
}

export function SignatureRequestDialog({ data }: Props) {
  const { i18n } = useLingui();

  const txType =
    data.request.input.blockchain === 'waves' &&
    data.request.input.type === 'invokeScript'
      ? 'swap'
      : 'send';

  const headingText = {
    send: t(i18n)`Confirm sending`,
    swap: t(i18n)`Confirm swaping`,
  }[txType];

  const confirmButtonText = {
    send: t(i18n)`Send`,
    swap: t(i18n)`Swap`,
  }[txType];

  return (
    <Dialog
      isOpen
      onIsOpenChange={newIsOpen => {
        if (newIsOpen) return;

        data.onClose?.();
      }}
    >
      <DialogRoot className={styles.root}>
        <div className={styles.header}>
          <div className={styles.headerTop}>
            <DialogHeading>{headingText}</DialogHeading>

            {data.onClose && (
              <DialogContextConsumer>
                {({ setOpen }) => (
                  <button
                    className={styles.headerCloseButton}
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    <CrossIcon />
                  </button>
                )}
              </DialogContextConsumer>
            )}
          </div>

          <div className={styles.headerNetwork}>
            <img
              alt=""
              className={styles.headerNetworkIcon}
              src={
                {
                  ethereum: new URL('../icons/ethereum.svg', import.meta.url)
                    .pathname,
                  waves: new URL('../icons/waves.svg', import.meta.url)
                    .pathname,
                }[data.request.input.blockchain]
              }
            />

            {
              {
                ethereum: t(i18n)`Ethereum network`,
                waves: t(i18n)`Waves network`,
              }[data.request.input.blockchain]
            }
          </div>
        </div>

        <div className={styles.body}>
          <TxDetails
            account={data.request.account}
            input={data.request.input}
          />

          {data.connectedAccount ? (
            data.confirmationError.match({
              Pending: () => (
                <div className={styles.waitingConfirmationMessage}>
                  <p>
                    {
                      {
                        'keeper-extension': t(
                          i18n,
                        )`Confirm the request in opened Keeper Extension`,
                        'keeper-mobile': t(
                          i18n,
                        )`Open mobile app to confirm the request`,
                      }[data.request.accountType]
                    }
                  </p>

                  <Spinner size={24} />
                </div>
              ),
              Ready: err => (
                <div className={styles.confirmationError}>
                  <p className={styles.confirmationErrorMessage}>
                    {(() => {
                      switch (err.type) {
                        case 'keeper-extension-account-is-wrong':
                          return t(
                            i18n,
                          )`Please switch your account in Keeper Wallet Extension and try again`;
                        case 'keeper-extension-is-not-installed':
                          return t(
                            i18n,
                          )`Keeper Wallet Extension is not installed`;
                        case 'keeper-extension-network-is-wrong':
                          return t(i18n)`Please, switch network to ${
                            data.request.input.network === Network.Mainnet
                              ? 'Mainnet'
                              : 'Testnet'
                          } in Keeper Wallet Extension and try again`;
                        case 'keeper-extension-has-no-accounts':
                          return t(
                            i18n,
                          )`You don't have any accounts in Keeper Wallet Extension`;
                        case 'keeper-mobile-account-is-wrong':
                          return t(
                            i18n,
                          )`Please switch your account in Keeper Wallet Mobile and try again`;
                        case 'keeper-mobile-user-busy':
                          return t(
                            i18n,
                          )`Please confirm or cancel previous transaction request in Keeper Wallet Mobile and try again`;
                      }
                    })()}
                  </p>

                  {data.onClose && (
                    <Button
                      block
                      text={t(i18n)`Close`}
                      variant="outlined"
                      onClick={data.onClose}
                    />
                  )}
                </div>
              ),
            })
          ) : (
            <Button
              block
              disabled={!data.onConfirm}
              text={confirmButtonText}
              onClick={data.onConfirm}
            />
          )}
        </div>
      </DialogRoot>
    </Dialog>
  );
}

function TxDetails({
  account,
  input,
}: {
  account: Account;
  input: BlockchainTransactionInput;
}) {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const { chainId } = WAVES_NETWORK_CONFIGS[network];

  const dataServiceAssets = useDataServiceAssets();

  const wavesAssetIds = useMemo(() => {
    if (input.blockchain !== 'waves') return [];

    switch (input.type) {
      case 'invokeScript':
        return input.payments.map(p => p.assetId);
      case 'transfer':
        return [];
    }
  }, [input]);

  const wavesAssetsDetails = useWavesAssetsDetails({
    assetIds: wavesAssetIds,
  });

  switch (input.blockchain) {
    case 'ethereum': {
      return (
        <ul className={styles.txDetails}>
          <li className={styles.txDetailsItem}>
            <span className={styles.txDetailsItemLabel}>{t(
              i18n,
            )`Recipient`}</span>

            <span className={styles.txDetailsItemValue}>
              <TxAddress value={input.recipient} />
            </span>
          </li>

          <li className={styles.txDetailsItem}>
            <span className={styles.txDetailsItemLabel}>{t(i18n)`Amount`}</span>

            <span className={styles.txDetailsItemValue}>
              {input.amount.getTokens().toFormat()} {input.amount.asset.symbol}
            </span>
          </li>

          <li className={styles.txDetailsItem}>
            <span className={styles.txDetailsItemLabel}>{t(
              i18n,
            )`Network fee`}</span>

            <span className={styles.txDetailsItemValue}>
              {input.fee.toMoney().getTokens().toFormat()}{' '}
              {input.fee.toMoney().asset.symbol}
            </span>
          </li>
        </ul>
      );
    }
    case 'waves': {
      switch (input.type) {
        case 'invokeScript': {
          const feeAsset = input.fee.toMoney().asset;

          return (
            <ul className={styles.txDetails}>
              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`Sender`}</span>

                <span className={styles.txDetailsItemValue}>
                  <TxAddress
                    value={account.getWavesAddress(chainId).assertSome()}
                  />
                </span>
              </li>

              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`Payment`}</span>

                <span className={styles.txDetailsItemValue}>
                  <ul className={styles.txInvokePayments}>
                    {input.payments.map((payment, index) => (
                      <li key={index}>
                        {AsyncValue.allRecord({
                          dataServiceAssets,
                          wavesAssetsDetails,
                        }).match({
                          Pending: () => (
                            <Skeleton
                              key={index}
                              className={styles.txInvokePaymentsItemSkeleton}
                            />
                          ),
                          Ready: x => {
                            const assetDetails =
                              x.wavesAssetsDetails[payment.assetId];
                            invariant(assetDetails);

                            const amount = WavesMoney.fromCoins(
                              payment.amount,
                              assetDetails,
                            );

                            const dataServiceAsset = Maybe.fromNullable(
                              x.dataServiceAssets[amount.asset.assetId],
                            );

                            const logo = dataServiceAsset.flatMapSome(
                              assetValue => Maybe.fromNullable(assetValue.url),
                            );

                            const ticker = dataServiceAsset
                              .flatMapSome(assetValue =>
                                Maybe.fromNullable(assetValue.ticker),
                              )
                              .getOr(() => amount.asset.name);

                            return (
                              <div className={styles.txInvokePaymentsItem}>
                                <span>
                                  {amount.getTokens().toFormat()} {ticker}
                                </span>

                                <AssetLogo
                                  id={payment.assetId}
                                  logo={logo.toOptional()}
                                  ticker={ticker}
                                />
                              </div>
                            );
                          },
                        })}
                      </li>
                    ))}
                  </ul>
                </span>
              </li>

              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`To dApp`}</span>

                <span className={styles.txDetailsItemValue}>
                  <TxAddress value={input.dApp} />
                </span>
              </li>

              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`Network fee`}</span>

                <span className={styles.txDetailsItemValue}>
                  {input.fee.toMoney().getTokens().toFormat()}{' '}
                  {dataServiceAssets
                    .getReady()
                    .flatMapSome(x => Maybe.fromNullable(x[feeAsset.assetId]))
                    .flatMapSome(x => Maybe.fromNullable(x.ticker))
                    .getOr(() => feeAsset.name)}
                </span>
              </li>
            </ul>
          );
        }
        case 'transfer': {
          const feeAsset = input.fee.toMoney().asset;

          return (
            <ul className={styles.txDetails}>
              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`Recipient`}</span>

                <span className={styles.txDetailsItemValue}>
                  <TxAddress value={input.recipient} />
                </span>
              </li>

              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`Amount`}</span>

                <span className={styles.txDetailsItemValue}>
                  {input.amount.getTokens().toFormat()}{' '}
                  {dataServiceAssets
                    .getReady()
                    .flatMapSome(x =>
                      Maybe.fromNullable(x[input.amount.asset.assetId]),
                    )
                    .flatMapSome(x => Maybe.fromNullable(x.ticker))
                    .getOr(() => input.amount.asset.name)}
                </span>
              </li>

              <li className={styles.txDetailsItem}>
                <span className={styles.txDetailsItemLabel}>{t(
                  i18n,
                )`Network fee`}</span>

                <span className={styles.txDetailsItemValue}>
                  {input.fee.toMoney().getTokens().toFormat()}{' '}
                  {dataServiceAssets
                    .getReady()
                    .flatMapSome(x => Maybe.fromNullable(x[feeAsset.assetId]))
                    .flatMapSome(x => Maybe.fromNullable(x.ticker))
                    .getOr(() => feeAsset.name)}
                </span>
              </li>
            </ul>
          );
        }
      }
    }
  }
}

function TxAddress({ value }: { value: BlockchainAddress }) {
  return (
    <>
      <span className={styles.txAddress_lg}>{value.toString()}</span>

      <span className={styles.txAddress_sm}>
        {ellipsis(value.toString(), 10)}
      </span>
    </>
  );
}
