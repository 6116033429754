import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, boolean, type Infer, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const DataServiceLeasingInfo = type({
  address: string(),
  name: string(),
  description: string(),
  icon_url: string(),
  url: string(),
  deleted: boolean(),
});

type DataServiceLeasingInfo = Infer<typeof DataServiceLeasingInfo>;

export type DataServiceLeasingInfoByAddress = Partial<
  Record<string, DataServiceLeasingInfo>
>;

const reducer: Reducer<
  DataServiceLeasingInfoByAddress | null,
  {
    type: 'UPDATE_DATA_SERVICE_LEASES_INFO';
    payload: DataServiceLeasingInfo[];
  }
> = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_LEASES_INFO': {
      return Object.fromEntries(
        action.payload.map(info => [info.address, info]),
      );
    }
    default:
      return state;
  }
};

export default reducer;

export function useDataServiceLeasesInfo(): AsyncValue<
  Partial<Record<string, DataServiceLeasingInfo>>
> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    if (network !== Network.Mainnet) return;

    void fetch(new URL('/api/v1/lease', dataServiceUrl))
      .then(handleResponse(array(DataServiceLeasingInfo)))
      .then(leasesInfo => {
        dispatch({
          type: 'UPDATE_DATA_SERVICE_LEASES_INFO',
          payload: leasesInfo,
        });
      });
  }, [dataServiceUrl, dispatch, network]);

  const leasesInfo = useAppSelector(
    state => state.cache.dataService.leasesInfo,
  );

  return useMemo(
    () =>
      network === Network.Mainnet
        ? leasesInfo
          ? AsyncValue.Ready(leasesInfo)
          : AsyncValue.Pending
        : AsyncValue.Ready({}),
    [leasesInfo, network],
  );
}
