import clsx from 'clsx';
import { forwardRef } from 'react';
import { type Link, useHref, useLinkClickHandler } from 'react-router-dom';

import { Result } from '../_core/result';
import * as styles from './button.module.css';
import { None } from './maybe';

type ButtonSize = 'small';
type ButtonVariant = 'outlined' | 'text';

function getButtonClassName({
  block,
  className,
  disabled,
  error,
  iconOnly,
  size,
  variant,
}: {
  block?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  iconOnly: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
}) {
  return clsx(
    className,
    styles.root,
    {
      big: styles.root_size_big,
      small: styles.root_size_small,
    }[size ?? 'big'],
    {
      [styles.root_block]: block,
      [styles.root_iconOnly]: iconOnly,
    },
    disabled
      ? styles.root_state_disabled
      : variant === 'outlined'
      ? styles.root_state_outlined
      : variant === 'text'
      ? styles.root_state_text
      : styles.root_state_primary,
    error ? styles.root_error_on : styles.root_error_off,
  );
}

function ButtonContent({
  startIcon,
  text,
}: {
  startIcon?: React.ReactElement;
  text?: string;
}) {
  return (
    <>
      {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
      {text}
    </>
  );
}

export interface CommonButtonProps {
  block?: boolean;
  disabled?: boolean;
  error?: boolean;
  /**
   * @deprecated use `variant` prop instead
   */
  outlined?: boolean;
  startIcon?: React.ReactElement;
  size?: 'small';
  text?: string;
  variant?: ButtonVariant;
}

export const Button = forwardRef<
  HTMLButtonElement,
  CommonButtonProps &
    Pick<
      React.ComponentProps<'button'>,
      | 'className'
      | 'type'
      | 'onClick'
      | 'onMouseEnter'
      | 'onMouseLeave'
      | 'onTouchStart'
    >
>(function Button(
  {
    block,
    className,
    error,
    outlined,
    size,
    startIcon,
    text,
    type = 'button',
    variant = outlined ? 'outlined' : undefined,
    ...props
  },
  ref,
) {
  return (
    <button
      {...props}
      ref={ref}
      className={getButtonClassName({
        block,
        className,
        disabled: props.disabled,
        error,
        iconOnly: !text,
        size,
        variant,
      })}
      type={type}
    >
      <ButtonContent startIcon={startIcon} text={text} />
    </button>
  );
});

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  CommonButtonProps &
    Pick<
      React.ComponentProps<typeof Link>,
      'className' | 'target' | 'rel' | 'to' | 'onClick'
    >
>(function LinkButton(
  {
    block,
    className,
    disabled,
    error,
    outlined,
    size,
    startIcon,
    target,
    text,
    to,
    variant = outlined ? 'outlined' : undefined,
    onClick,
    ...props
  },
  ref,
) {
  const absoluteHref =
    typeof to === 'string'
      ? Result.try(() => new URL(to))
          .mapOk(url => url.toString())
          .getOk()
      : None;
  const href = useHref(to);

  const linkClickHandler = useLinkClickHandler(to, {
    target,
  });

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      {...props}
      ref={ref}
      className={getButtonClassName({
        block,
        className,
        disabled,
        error,
        iconOnly: !text,
        size,
        variant,
      })}
      href={disabled ? undefined : absoluteHref.getOr(() => href)}
      target={target}
      onClick={
        disabled
          ? undefined
          : event => {
              onClick?.(event);
              if (absoluteHref.isSome) return;
              linkClickHandler(event);
            }
      }
    >
      <ButtonContent startIcon={startIcon} text={text} />
    </a>
  );
});
