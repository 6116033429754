import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import { useMemo } from 'react';

import { AsyncValue } from '../../_core/asyncValue';
import { CoingeckoBadge } from '../../_core/coingeckoBadge';
import { useDataServiceAssets } from '../../cache/dataService/assets';
import { WAVES_ASSET } from '../../cache/wavesNode/assetsDetails';
import { ETHEREUM_ASSET } from '../../ethereum/constants';
import { PortfolioWallet } from '../../portfolio/wallet';
import { usePortfolioPageContext } from './portfolio';
import * as styles from './portfolio.module.css';

export function WalletPage() {
  const { i18n } = useLingui();

  const dataServiceAssets = useDataServiceAssets();

  const {
    wavesMoneys,
    ethereumMoneys,
    wavesUsdPrices,
    ethereumUsdPrices,
    walletWorth,
    investments,
  } = usePortfolioPageContext();

  const productAmountsByAssetId = investments.mapReady(investmentsValue =>
    investmentsValue.reduce<Record<string, BigNumber>>((acc, { amounts }) => {
      for (const amount of amounts) {
        const key = amount.asset.assetId;
        acc[key] ??= new BigNumber(0);
        acc[key] = acc[key].add(amount.getTokens());
      }

      return acc;
    }, {}),
  );

  const ethereumAssetItems = AsyncValue.allRecord({
    ethereumMoneys,
    ethereumUsdPrices,
  }).mapReady(x => {
    return x.ethereumMoneys.map(ethereumMoney => {
      const { asset } = ethereumMoney;

      const usdPrice = new BigNumber(x.ethereumUsdPrices[asset.address] ?? '0');

      const hasPrice = usdPrice.gt(0);

      return {
        blockchain: asset.blockchain,
        id: asset.address,
        ticker: asset.symbol,
        logo: asset.iconUrl,
        price: usdPrice ? usdPrice : undefined,
        available: ethereumMoney.getTokens(),
        worth: hasPrice ? ethereumMoney.getTokens().mul(usdPrice) : undefined,
      };
    });
  });

  const wavesAssetItems = AsyncValue.allRecord({
    wavesMoneys,
    wavesUsdPrices,
    productAmountsByAssetId,
    dataServiceAssets,
  }).mapReady(x =>
    x.wavesMoneys.map(balance => {
      const usdPrice = new BigNumber(
        x.wavesUsdPrices[balance.asset.assetId] ?? '0',
      );

      const hasPrice = usdPrice.gt(0);

      const productsAssetAmount =
        x.productAmountsByAssetId[balance.asset.assetId];

      const dataServiceAsset = x.dataServiceAssets[balance.asset.assetId];

      return {
        blockchain: balance.asset.blockchain,
        id: balance.asset.assetId,
        ticker: dataServiceAsset?.ticker || balance.asset.name,
        logo: dataServiceAsset?.url,
        price: hasPrice ? usdPrice : undefined,
        priceChange: undefined,
        available: balance.getTokens(),
        worth: hasPrice ? balance.getTokens().mul(usdPrice) : undefined,
        total: productsAssetAmount
          ? balance.getTokens().add(productsAssetAmount)
          : undefined,
      };
    }),
  );

  const assetBalances = useMemo(() => {
    return AsyncValue.allRecord({
      ethereumAssetItems,
      wavesAssetItems,
    }).mapReady(x =>
      [...x.ethereumAssetItems, ...x.wavesAssetItems].sort((a, b) => {
        const aWorth = new BigNumber(a.worth ?? 0);
        const bWorth = new BigNumber(b.worth ?? 0);

        if (a.id === WAVES_ASSET.assetId && b.id === ETHEREUM_ASSET.address) {
          return 1;
        }

        if ([ETHEREUM_ASSET.address, WAVES_ASSET.assetId].includes(a.id)) {
          return -1;
        }

        if ([ETHEREUM_ASSET.address, WAVES_ASSET.assetId].includes(b.id)) {
          return 1;
        }

        if (aWorth.gt(bWorth)) {
          return -1;
        }

        if (aWorth.lt(bWorth)) {
          return 1;
        }

        return a.ticker.localeCompare(b.ticker);
      }),
    );
  }, [ethereumAssetItems, wavesAssetItems]);

  if (
    AsyncValue.allRecord({ wavesMoneys, ethereumMoneys })
      .getReady()
      .mapSome(x => x.wavesMoneys.length === 0 && x.ethereumMoneys.length === 0)
      .toNullable()
  ) {
    return (
      <div className={styles.emptyMessage}>{t(i18n)`Your Wallet is empty`}</div>
    );
  }

  return (
    <>
      <PortfolioWallet assetBalances={assetBalances} worth={walletWorth} />

      <CoingeckoBadge />
    </>
  );
}
