import BigNumber from '@waves/bignumber';
import { deepEqual } from 'fast-equals';
import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { record, string } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { pollWhileUserIsActive } from '../../_core/polling';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const DataServiceUsdPrices = record(string(), string());

export type DataServiceUsdPrices = Partial<{
  [assetId: string]: string;
}>;

const reducer: Reducer<
  DataServiceUsdPrices,
  {
    type: 'UPDATE_DATA_SERVICE_USD_PRICES';
    payload: DataServiceUsdPrices;
  }
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_USD_PRICES': {
      const newState = { ...state, ...action.payload };

      return deepEqual(newState, state) ? state : newState;
    }
    default:
      return state;
  }
};

export default reducer;

export function useDataServiceUsdPrices({
  assetIds,
}: {
  assetIds: string[];
}): AsyncValue<DataServiceUsdPrices> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    if (network !== Network.Mainnet || assetIds.length === 0) return;

    return pollWhileUserIsActive(5000, signal =>
      fetch(new URL('/api/v1/rates', dataServiceUrl), {
        signal,
        method: 'POST',
        body: JSON.stringify({ ids: assetIds }),
      })
        .then(handleResponse(DataServiceUsdPrices))
        .then(usdPrices => {
          dispatch({
            type: 'UPDATE_DATA_SERVICE_USD_PRICES',
            payload: Object.fromEntries(
              Object.entries(usdPrices).map(([key, value]) => {
                const bn = new BigNumber(value);

                return [
                  key,
                  bn.isFinite() && bn.gt(0) ? bn.toString() : undefined,
                ];
              }),
            ),
          });
        }),
    );
  }, [dataServiceUrl, dispatch, network, assetIds]);

  const usdPrices = useAppSelector(state => state.cache.dataService.usdPrices);

  return useMemo(() => {
    if (network !== Network.Mainnet) return AsyncValue.Ready({});

    const result: DataServiceUsdPrices = {};

    for (const assetId of assetIds) {
      if (!(assetId in usdPrices)) return AsyncValue.Pending;
      result[assetId] = usdPrices[assetId];
    }

    return AsyncValue.Ready(result);
  }, [assetIds, network, usdPrices]);
}
