import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import BigNumber from '@waves/bignumber';
import type { SignedTx, Signer, SignerTx, UserData } from '@waves/signer';
import { TRANSACTION_TYPE } from '@waves/ts-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { number, type } from 'superstruct';
import invariant from 'tiny-invariant';

import { AsyncValue } from '../_core/asyncValue';
import { isErrorLike } from '../_core/errors';
import { FormHelperText } from '../_core/formHelperText';
import { handleResponse } from '../_core/handleResponse';
import { Maybe } from '../_core/maybe';
import { WavesMoney } from '../_core/money';
import { Spinner } from '../_core/spinner';
import type { KeeperExtensionAccount } from '../accounts/models/keeperExtensionAccount';
import type { KeeperMobileAccount } from '../accounts/models/keeperMobileAccount';
import type { MultichainAccount } from '../accounts/models/multichainAccount';
import type { WavesAccount } from '../accounts/models/wavesAccount';
import { useSignatureRequest } from '../accounts/signatureRequest';
import { importWavesSigner, useGetSigner } from '../accounts/utils';
import { WavesAddress } from '../blockchain/address';
import { WavesFee } from '../blockchain/fee/wavesFee';
import type { WavesTransactionInput } from '../blockchain/transaction/wavesTransaction';
import { WAVES_ASSET } from '../cache/wavesNode/assetsDetails';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import * as styles from './swapAssets.module.css';

const DEFAULT_FEE_IN_WAVES = '500000';

interface Props {
  account:
    | KeeperExtensionAccount
    | KeeperMobileAccount
    | MultichainAccount
    | WavesAccount;
}

export function SwapAssets({ account }: Props) {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const { chainId, nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  const wavesAddress = useMemo(
    () => account.getWavesAddress(chainId).assertSome(),
    [account, chainId],
  );

  const [swapWidget, setSwapWidget] = useState<KeeperSwapWidget.SwapWidget>();
  const swapWidgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let widget: KeeperSwapWidget.SwapWidget | undefined;
    let cancelled = false;

    const script = document.createElement('script');
    script.src = 'https://swap-widget.keeper-wallet.app/lib/swap-widget.umd.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (cancelled) return;

      widget = KeeperSwapWidget.create(swapWidgetRef.current, {
        useInternalSigner: false,
      });
      setSwapWidget(widget);
    };

    return () => {
      cancelled = true;

      setSwapWidget(undefined);
      widget?.destroy();

      document.body.removeChild(script);
    };
  }, []);

  const [signerError, setSignerError] = useState('');

  const getSigner = useGetSigner({ network });

  const signatureRequest = useSignatureRequest();

  const [wavesFee, setWavesFee] = useState<AsyncValue<WavesMoney>>(
    AsyncValue.Pending,
  );
  useEffect(() => {
    void fetch(
      new URL(`/addresses/scriptInfo/${wavesAddress.toString()}`, nodeUrl),
    )
      .then(handleResponse(type({ extraFee: number() })))
      .then(
        ({ extraFee }) => new BigNumber(DEFAULT_FEE_IN_WAVES).add(extraFee),
        () => new BigNumber(DEFAULT_FEE_IN_WAVES),
      )
      .then(coins => {
        setWavesFee(AsyncValue.Ready(WavesMoney.fromCoins(coins, WAVES_ASSET)));
      });
  }, [nodeUrl, wavesAddress]);

  useEffect(() => {
    if (!swapWidget) return;

    Maybe.allRecord({
      sendTransactionRequest: signatureRequest.sendTransactionRequest,
      wavesFee: wavesFee.getReady(),
    }).match({
      None: () => {},
      Some: async x => {
        try {
          const address = wavesAddress.toString();

          let signer: Signer;
          if (
            account.type === 'keeper-extension' ||
            account.type === 'keeper-mobile'
          ) {
            signer = await getSigner(account.type);
          } else {
            const { Signer } = await importWavesSigner();

            signer = new Signer({
              NODE_URL: nodeUrl,
            });

            const notImplemented = (): never => {
              throw new Error('NOT IMPLEMENTED');
            };

            const publicKey = account
              .getWavesPublicKey()
              .assertSome()
              .toString();

            const user: UserData = {
              address,
              publicKey,
            };

            await signer.setProvider({
              isSignAndBroadcastByProvider: true,
              user,

              logout: notImplemented,
              off: notImplemented,
              on: notImplemented,
              once: notImplemented,
              signMessage: notImplemented,
              signTypedData: notImplemented,

              connect: () => Promise.resolve(),
              login: () => Promise.resolve(user),

              sign: async (txs: SignerTx[]) => {
                invariant(txs.length === 1);

                const [tx] = txs;
                invariant(
                  tx.type === TRANSACTION_TYPE.INVOKE_SCRIPT &&
                    tx.call &&
                    tx.payment,
                );

                const { call, payment } = tx;
                const dApp = WavesAddress.fromString(tx.dApp).assertOk();

                const txInput: WavesTransactionInput = {
                  blockchain: 'waves',
                  type: 'invokeScript',
                  call,
                  dApp,
                  fee: new WavesFee(x.wavesFee),
                  network,
                  payments: payment.map(p => ({
                    amount: new BigNumber(p.amount),
                    assetId: p.assetId == null ? 'WAVES' : p.assetId,
                  })),
                };

                const responseResult = await x.sendTransactionRequest(
                  account.type === 'multichain'
                    ? {
                        account,
                        accountType: account.type,
                        input: txInput,
                      }
                    : {
                        account,
                        accountType: account.type,
                        input: txInput,
                      },
                );

                type SignedResult = SignedTx<typeof tx>;

                const fee = x.wavesFee.getCoins().toString();

                const feeAssetId =
                  x.wavesFee.asset.assetId === 'WAVES'
                    ? null
                    : x.wavesFee.asset.assetId;

                return responseResult.match({
                  Err: err => {
                    throw new Error(err.message ?? 'Unexpected error');
                  },
                  Ok: maybeResponse =>
                    maybeResponse.match({
                      None: () => {},
                      Some: response => {
                        invariant(response.blockchain === 'waves');

                        const signedTx: SignedResult = {
                          type: TRANSACTION_TYPE.INVOKE_SCRIPT,
                          call,
                          chainId,
                          dApp: dApp.toString(),
                          fee,
                          feeAssetId,
                          id: response.id,
                          payment,
                          proofs: [],
                          senderPublicKey: publicKey,
                          timestamp: Date.now(),
                          version: 2,
                        };

                        return signedTx;
                      },
                    }),
                });
              },
            });
          }

          swapWidget.setWallet({ address, signer });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);

          setSignerError(
            isErrorLike(err) ? err.message : t(i18n)`Unexpected error occurred`,
          );
        }
      },
    });
  }, [
    account,
    chainId,
    getSigner,
    i18n,
    network,
    nodeUrl,
    signatureRequest.sendTransactionRequest,
    swapWidget,
    wavesAddress,
    wavesFee,
  ]);

  return (
    <div className={styles.root}>
      {signerError && (
        <div className={styles.senderCard}>
          <FormHelperText hasError>{signerError}</FormHelperText>
        </div>
      )}

      <div ref={swapWidgetRef} className={styles.widgetCard}>
        {!swapWidget && <Spinner size={32} />}
      </div>
    </div>
  );
}
