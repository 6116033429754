import type { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { BigNumber } from '@waves/bignumber';

import { WavesMoney } from '../_core/money';
import type { DataServiceLeasingInfoByAddress } from '../cache/dataService/leasesInfo';
import type { DataServiceProduct } from '../cache/dataService/products';
import type { DataServiceUsdPrices } from '../cache/dataService/usdPrices';
import { WAVES_ASSET } from '../cache/wavesNode/assetsDetails';
import type {
  WavesLeasing,
  WavesLeasingResponse,
} from '../cache/wavesNode/leases';

export function calculateProductTotalWorth(
  usdPrices: DataServiceUsdPrices,
  product: Investment,
) {
  return product.amounts.reduce((amountsSum, amount) => {
    return amountsSum.add(
      amount.getTokens().mul(usdPrices[amount.asset.assetId] ?? '0'),
    );
  }, new BigNumber(0));
}

export function calculateProtocolTotalWorth(
  usdPrices: DataServiceUsdPrices,
  products: Investment[],
) {
  return products.reduce(
    (acc, current) => acc.add(calculateProductTotalWorth(usdPrices, current)),
    new BigNumber(0),
  );
}

export function getProductTypeName(i18n: I18n, type: string) {
  const productTypeNames: Record<string, string> = {
    staking: t(i18n)`Staking`,
    liquidity_pool: t(i18n)`Liquidity Pool`,
    lend: t(i18n)`Lend`,
    leasing: t(i18n)`Leasing`,
  };

  return productTypeNames[type] || type;
}

export interface Investment {
  product_id: string;
  protocol_id: string;
  address: string;
  type: string;
  name: string;
  url: string | null;
  icon_url: string | null;
  amounts: WavesMoney[];
}

export function mapWavesLeasingToInvestment(
  leasing: WavesLeasing,
  leasesInfo: DataServiceLeasingInfoByAddress,
): Investment {
  const leasingInfo = leasesInfo[leasing.recipient];

  return {
    product_id: leasing.id,
    protocol_id: 'waves',
    address: leasing.sender,
    type: 'leasing',
    name: leasingInfo?.name ?? leasing.recipient,
    url: leasingInfo?.url ?? null,
    icon_url: leasingInfo?.icon_url ?? null,
    amounts: [leasing.amount],
  };
}

export function mapDataServiceProductToInvestment(
  product: DataServiceProduct,
): Investment {
  return {
    ...product,
    product_id: product.product_id.toString(),
  };
}

export function mergeWavesLeasesByRecipient(leases: WavesLeasingResponse[]) {
  const mergedLeasesByRecipient = leases.reduce<Record<string, WavesLeasing>>(
    (acc, leasing) => {
      if (leasing == null) {
        return acc;
      }

      const mergedLeasing = (acc[leasing.recipient] ??= {
        ...leasing,
        amount: WavesMoney.fromCoins(0, WAVES_ASSET),
      });

      mergedLeasing.amount = WavesMoney.fromTokens(
        mergedLeasing.amount
          .getTokens()
          .add(WavesMoney.fromCoins(leasing.amount, WAVES_ASSET).getTokens()),
        WAVES_ASSET,
      );

      return acc;
    },
    {},
  );

  return Object.values(mergedLeasesByRecipient);
}
