import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import type BigNumber from '@waves/bignumber';
import { NumericFormat } from 'react-number-format';

import type { AsyncValue } from '../_core/asyncValue';
import { Button } from '../_core/button';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { FormControlProvider } from '../_core/formControl';
import { FormHelperText } from '../_core/formHelperText';
import { FormLabel } from '../_core/formLabel';
import { Input } from '../_core/input';
import { Maybe, None, Some } from '../_core/maybe';
import { type Result } from '../_core/result';
import { Spinner } from '../_core/spinner';
import { ellipsis } from '../_core/utils';
import type { BlockchainAddress } from '../blockchain/address';
import { WalletIcon } from '../icons/wallet';
import {
  SendAssetsAssetSelect,
  type SendAssetsAssetSelectOption,
} from './assetSelect';
import { SendAssetsFeeField, type SendAssetsFeeFieldData } from './feeField';
import * as styles from './sendAssets.module.css';

export interface SendAssetsFormValues {
  amount: Maybe<{ value: string; formattedValue: string }>;
  asset: Maybe<string>;
  feeOptionId: Maybe<string>;
  recipient: Maybe<string>;
}

export interface SendAssetsFormErrors {
  amount: Maybe<string>;
  recipient: Maybe<string>;
}

export type SendAssetsFormOnChange = (
  ...args: {
    [K in keyof SendAssetsFormValues]: [
      fieldName: K,
      newValue: SendAssetsFormValues[K],
    ];
  }[keyof SendAssetsFormValues]
) => void;

interface Props {
  amountInUsd: Maybe<BigNumber>;
  assetBalanceTokens: Maybe<BigNumber>;
  assetBalanceUsd: Maybe<BigNumber>;
  assetDecimals: Maybe<number>;
  assetSelectOptions: AsyncValue<SendAssetsAssetSelectOption[]>;
  errors: SendAssetsFormErrors;
  feeFieldData: Maybe<AsyncValue<Result<SendAssetsFeeFieldData, string>>>;
  formError: Maybe<string>;
  isResolvingRecipientAddress: boolean;
  maxAmountInAssetTokens: Maybe<BigNumber>;
  resolvedRecipientAddress: Maybe<BlockchainAddress>;
  values: SendAssetsFormValues;
  onChange: SendAssetsFormOnChange;
  onSubmit: Maybe<() => void>;
}

export function SendAssets({
  amountInUsd,
  assetBalanceTokens,
  assetBalanceUsd,
  assetDecimals,
  assetSelectOptions,
  errors,
  feeFieldData,
  formError,
  isResolvingRecipientAddress,
  maxAmountInAssetTokens,
  resolvedRecipientAddress,
  values,
  onChange,
  onSubmit,
}: Props) {
  const { i18n } = useLingui();

  return (
    <form
      className={styles.card}
      onSubmit={event => {
        event.preventDefault();
        onSubmit.mapSome(f => f());
      }}
    >
      <FormControlProvider>
        <div>
          <FormLabel>{t(i18n)`Asset`}</FormLabel>

          <SendAssetsAssetSelect
            disabled={assetSelectOptions.isPending}
            isLoading={assetSelectOptions.isPending}
            items={assetSelectOptions.getReady().getOr(() => [])}
            placeholder={t(i18n)`Choose asset`}
            value={values.asset.getOr(() => '')}
            onChange={newValue =>
              onChange('asset', Maybe.fromNullable(newValue || null))
            }
          />

          <FormHelperText className={styles.assetHelperText}>
            <span>{assetBalanceUsd.mapSome(formatUsdPrice).toNullable()}</span>

            {assetBalanceTokens
              .mapSome(tokens => (
                <span className={styles.walletBalanceLabel}>
                  <WalletIcon /> {tokens.toFormat()}
                </span>
              ))
              .toNullable()}
          </FormHelperText>
        </div>
      </FormControlProvider>

      <FormControlProvider error={errors.recipient.toOptional()}>
        <div>
          <div className={styles.recipientLabel}>
            <FormLabel>{t(i18n)`Recipient`}</FormLabel>

            {resolvedRecipientAddress
              .mapSome(address => (
                <span>{ellipsis(address.toString(), 5)}</span>
              ))
              .toNullable()}
          </div>

          <div className={styles.recipientInput}>
            <Input
              placeholder={t(i18n)`Address or domain`}
              name="recipient"
              value={values.recipient.getOr(() => '')}
              onChange={event => {
                onChange(
                  'recipient',
                  Maybe.fromNullable(event.currentTarget.value || null),
                );
              }}
            />

            {isResolvingRecipientAddress && (
              <Spinner className={styles.recipientSpinner} size={16} />
            )}
          </div>

          {errors.recipient
            .mapSome(error => <FormHelperText hasError>{error}</FormHelperText>)
            .toOptional()}
        </div>
      </FormControlProvider>

      <FormControlProvider hasError={errors.amount.isSome}>
        <div>
          <FormLabel>{t(i18n)`Amount`}</FormLabel>

          <NumericFormat
            allowLeadingZeros={false}
            customInput={Input}
            decimalScale={assetDecimals.toOptional()}
            name="amount"
            placeholder="0.0"
            thousandSeparator
            value={values.amount.match({
              None: () => '',
              Some: x => x.formattedValue,
            })}
            onValueChange={newValue => {
              onChange(
                'amount',
                Maybe.all([
                  Maybe.fromNullable(newValue.value || null),
                  Maybe.fromNullable(newValue.formattedValue || null),
                ]).mapSome(([value, formattedValue]) => ({
                  formattedValue,
                  value,
                })),
              );
            }}
          />

          <FormHelperText
            className={styles.amountHelperText}
            hasError={errors.amount.isSome}
          >
            <span>
              {errors.amount
                .or(() => amountInUsd.mapSome(formatUsdPrice))
                .toNullable()}
            </span>

            {maxAmountInAssetTokens
              .flatMapSome(maxAmountInAssetValue =>
                maxAmountInAssetValue.gt(0)
                  ? Some(maxAmountInAssetValue)
                  : None,
              )
              .mapSome(maxAmountInAssetValue => (
                <button
                  className={styles.amountUseMaxButton}
                  type="button"
                  onClick={() => {
                    onChange(
                      'amount',
                      Maybe.all([
                        Some(maxAmountInAssetValue.toFormat()),
                        Some(maxAmountInAssetValue.toString()),
                      ]).mapSome(([formattedValue, value]) => ({
                        formattedValue,
                        value,
                      })),
                    );
                  }}
                >{t(i18n)`Use max`}</button>
              ))
              .toNullable()}
          </FormHelperText>
        </div>
      </FormControlProvider>

      {feeFieldData
        .flatMapSome(feeFieldDataAsync => feeFieldDataAsync.getReady())
        .mapSome(data => (
          <SendAssetsFeeField
            data={data}
            onChange={newValue => {
              onChange('feeOptionId', Maybe.fromNullable(newValue || null));
            }}
          />
        ))
        .toNullable()}

      {formError
        .mapSome(formErrorValue => (
          <p className={styles.formError}>{formErrorValue}</p>
        ))
        .toNullable()}

      <Button
        disabled={onSubmit.isNone}
        text={t(i18n)`Continue`}
        type="submit"
      />
    </form>
  );
}
