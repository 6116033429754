import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type BigNumber } from '@waves/bignumber';

import { AssetLogo } from '../_core/assetLogo';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import * as styles from './assetList.module.css';

const NO_DATA_PLACEHOLDER = '–';

interface AssetItem {
  blockchain: 'waves' | 'ethereum';
  id: string;
  ticker: string;
  logo?: string;
  price?: BigNumber;
  priceChange?: number;
  available: BigNumber;
  worth?: BigNumber;
}

export function AssetList({
  items,
  className,
}: {
  items: AssetItem[];
  className?: string;
}) {
  const { i18n } = useLingui();

  return (
    <div className={className}>
      <div className={styles.assetListHeader}>
        <span>{t(i18n)`Asset`}</span>
        <span>{t(i18n)`Total`}</span>
      </div>

      {items.map(
        ({ blockchain, id, ticker, logo, price, available, worth }) => (
          <div className={styles.assetListItem} key={id}>
            <AssetLogo
              id={id}
              ticker={ticker}
              logo={logo}
              blockchain={blockchain}
            />

            <div className={styles.assetListItemContent}>
              <div className={styles.assetListItemContentTop}>
                <span>{ticker}</span>
                <span>{available.toFormat()}</span>
              </div>
              <div className={styles.assetListItemContentBottom}>
                <span>
                  {price ? formatUsdPrice(price) : `$${NO_DATA_PLACEHOLDER}`}
                </span>
                <span className={styles.assetListItemWorth}>
                  {worth ? formatUsdPrice(worth) : `$${NO_DATA_PLACEHOLDER}`}
                </span>
              </div>
            </div>
          </div>
        ),
      )}
    </div>
  );
}
