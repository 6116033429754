// extracted by mini-css-extract-plugin
export var betaBadge = "PzIm4V7vNo5_UsnHArfC";
export var currentAccountSelectContainer = "nvmw6TvjdHHI7blvfnA0";
export var divider = "JmyzJe5Um697qm3EjuTD";
export var header = "xJtsMAHYpbFjSOBUhuup";
export var headerContainer = "mHt_Lkxi_Mh5XBHKSGg5";
export var headerContent = "WouBxzssMz3GGkIawKp1";
export var headerLayout = "JlFwfRLq7VDnRXYfP7Jn";
export var headerPlaceholder = "_cZGJPlc9c9JUc3D7NRZ";
export var lockButton = "TKVzCsDQJ_Df5Li14ByT";
export var logo = "pomPxCVb1UYml5Hg_kyQ";
export var logoContainer = "AFSoP25ddIxNOKGNjV5V";
export var mobileMenuTrigger = "g2mfgjS2VmlBKv6JJC0i";
export var mobileMenuTriggerContainer = "xjkFHMuNxyf2iBpuOBhS";
export var rightControls = "lYnwqOtMxtpHy207slrn";