import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array, type Infer, optional, string, type } from 'superstruct';

import { AsyncValue } from '../../_core/asyncValue';
import { isAbortError } from '../../_core/errors';
import { handleResponse } from '../../_core/handleResponse';
import { useEntryContext } from '../../entry';
import { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';

const DataServiceAsset = type({
  id: string(),
  ticker: optional(string()),
  url: optional(string()),
});

type DataServiceAsset = Infer<typeof DataServiceAsset>;

export type DataServiceAssets = Partial<{
  [assetId: string]: DataServiceAsset;
}>;

const reducer: Reducer<
  DataServiceAssets | null,
  {
    type: 'UPDATE_DATA_SERVICE_ASSETS';
    payload: DataServiceAsset[];
  }
> = (state = null, action) => {
  switch (action.type) {
    case 'UPDATE_DATA_SERVICE_ASSETS':
      return Object.fromEntries(action.payload.map(asset => [asset.id, asset]));
    default:
      return state;
  }
};

export default reducer;

export function useDataServiceAssets(): AsyncValue<DataServiceAssets> {
  const { dataServiceUrl } = useEntryContext();

  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);

  useEffect(() => {
    if (network !== Network.Mainnet) return;

    const abortController = new AbortController();

    fetch(new URL('/api/v1/assets', dataServiceUrl), {
      signal: abortController.signal,
    })
      .then(handleResponse(array(DataServiceAsset)))
      .then(
        assets => {
          dispatch({
            type: 'UPDATE_DATA_SERVICE_ASSETS',
            payload: assets.map(asset => ({
              ...asset,
              url: asset.url || undefined,
              ticker: asset.ticker || undefined,
            })),
          });
        },
        err => {
          if (isAbortError(err)) return;

          throw err;
        },
      );

    return () => {
      abortController.abort();
    };
  }, [dataServiceUrl, dispatch, network]);

  const assets = useAppSelector(state => state.cache.dataService.assets);

  return useMemo(
    () =>
      network === Network.Mainnet
        ? assets
          ? AsyncValue.Ready(assets)
          : AsyncValue.Pending
        : AsyncValue.Ready({}),
    [assets, network],
  );
}
