import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { Button } from '../../_core/button';
import { FormControl } from '../../_core/formControl';
import { Input } from '../../_core/input';
import { useAppDispatch } from '../../store/react';
import {
  addAccount,
  type AddAccountInput,
  setPasswordForEmptyVault,
} from '../../vault/redux';
import { RequireUnlockedVault } from '../../vault/requireUnlockedVault';
import * as styles from './addAccount.module.css';

interface PasswordFormInputs {
  password: string;
  passwordConfirm: string;
}

function PasswordForm({
  onPassword,
}: {
  onPassword: (password: string) => void;
}) {
  const { i18n } = useLingui();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<PasswordFormInputs>({ mode: 'onTouched' });

  return (
    <>
      <h1 className={styles.passwordFormHeading}>
        <Trans>Protect your account</Trans>
      </h1>

      <form
        className={styles.passwordForm}
        onSubmit={handleSubmit(values => {
          onPassword(values.password);
        })}
      >
        <p className={styles.passwordFormDescription}>
          <Trans>
            Now choose a strong password to securely store your account
          </Trans>
        </p>

        <FormControl
          error={errors.password?.message}
          label={t(i18n)`Enter password`}
        >
          <Input
            autoComplete="new-password"
            autoFocus
            type="password"
            {...register('password', {
              required: t(i18n)`Required`,
              validate: value =>
                value.length >= 8
                  ? undefined
                  : t(i18n)`Password must be at least 8 characters long`,
            })}
          />
        </FormControl>

        <FormControl
          error={errors.passwordConfirm?.message}
          label={t(i18n)`Confirm password`}
        >
          <Input
            autoComplete="new-password"
            type="password"
            {...register('passwordConfirm', {
              deps: ['password'],
              required: t(i18n)`Required`,
              validate: (value, allValues) =>
                value === allValues.password
                  ? undefined
                  : t(i18n)`Passwords do not match`,
            })}
          />
        </FormControl>

        <Button
          className={styles.passwordFormButton}
          disabled={!isValid}
          text={t(i18n)`Start using Keeper`}
          type="submit"
        />

        <p className={styles.passwordFormTermsOfService}>
          <Trans>
            By continuing, I agree with{' '}
            <Link
              to="https://keeper-wallet.app/terms-of-use"
              target="_blank"
              rel="noopener"
            >
              Terms of use
            </Link>
          </Trans>
        </p>
      </form>
    </>
  );
}

interface AddAccountContextValue {
  tryToAddAnAccount: (input: AddAccountInput) => Promise<void>;
}

const AddAccountContext = createContext<AddAccountContextValue | null>(null);

export function useAddAccountPageContext() {
  const value = useContext(AddAccountContext);
  invariant(value);
  return value;
}

export function AddAccountPage() {
  const { i18n } = useLingui();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const [firstPendingAccount, setFirstPendingAccount] =
    useState<AddAccountInput>();

  const value = useMemo(
    (): AddAccountContextValue => ({
      tryToAddAnAccount: input =>
        dispatch(addAccount(input))
          .then(result =>
            result.match({
              Ok: () => {
                navigate(params.get('next') ?? '/accounts');
              },
              Err: err => {
                if (err.type === 'password-is-needed') {
                  setFirstPendingAccount(input);
                } else {
                  throw new Error('Failed to add an account');
                }
              },
            }),
          )
          .catch(() =>
            // eslint-disable-next-line no-alert
            alert(
              t(i18n)`Could not add an account. Unexpected error occurred.`,
            ),
          ),
    }),
    [dispatch, i18n, navigate, params],
  );

  return (
    <RequireUnlockedVault>
      {() => {
        if (firstPendingAccount) {
          return (
            <PasswordForm
              onPassword={async password => {
                try {
                  dispatch(setPasswordForEmptyVault(password));

                  (await dispatch(addAccount(firstPendingAccount))).assertOk();

                  navigate(params.get('next') ?? '/accounts');
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.error(err);

                  // eslint-disable-next-line no-alert
                  alert(
                    t(
                      i18n,
                    )`Could not add an account. Unexpected error occurred.`,
                  );
                }
              }}
            />
          );
        }

        return (
          <AddAccountContext.Provider value={value}>
            <Outlet />
          </AddAccountContext.Provider>
        );
      }}
    </RequireUnlockedVault>
  );
}
