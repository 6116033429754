import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { BigNumber } from '@waves/bignumber';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AsyncValue } from '../_core/asyncValue';
import { LinkButton } from '../_core/button';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Maybe } from '../_core/maybe';
import { WavesMoney } from '../_core/money';
import { isNotNull } from '../_core/predicates';
import { Skeleton } from '../_core/skeleton';
import { useAccounts } from '../accounts/requireAccounts';
import { Account } from '../accounts/types';
import type { EthereumPublicKey } from '../blockchain/publicKey';
import { useCoingeckoUsdPrices } from '../cache/coingecko/usdPrices';
import { useDataServiceEthereumBalances } from '../cache/dataService/ethereumBalances';
import { useDataServiceEthereumTokens } from '../cache/dataService/ethereumTokenList';
import { useDataServiceProducts } from '../cache/dataService/products';
import { useDataServiceUsdPrices } from '../cache/dataService/usdPrices';
import {
  useWavesAssetsDetails,
  WAVES_ASSET,
} from '../cache/wavesNode/assetsDetails';
import { useWavesBalances } from '../cache/wavesNode/balances';
import { ChevronRightIcon } from '../icons/chevronRight';
import { PlusIcon } from '../icons/plus';
import { Container } from '../layout/layout';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import { AccountAvatar } from '../vault/accountAvatar';
import type { InMemoryAccountJSON } from '../vault/types';
import * as styles from './accounts.module.css';

export function AccountsPage() {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);

  const accounts = useAccounts();

  const ethereumAddressesById = useMemo(() => {
    return accounts.reduce(
      (addressesById, account) => {
        const ethereumPublicKey = Account.fromInMemoryJSON(account)
          .assertOk()
          .getPublicKeys()
          .find((x): x is EthereumPublicKey => x.blockchain === 'ethereum');

        if (!ethereumPublicKey) return addressesById;

        addressesById[account.id] = ethereumPublicKey.getAddress().toString();

        return addressesById;
      },
      {} as { [id: string]: string },
    );
  }, [accounts]);

  const ethereumAddresses = useMemo(() => {
    return Object.values(ethereumAddressesById);
  }, [ethereumAddressesById]);

  const ethereumBalances = useDataServiceEthereumBalances({
    addresses: ethereumAddresses,
  });

  const ethereumAssetIds = useMemo(() => {
    return ethereumBalances.match({
      Pending: () => [],
      Ready: ethereumBalancesValue => {
        return Array.from(
          new Set(
            Object.values(ethereumBalancesValue)
              .filter(isNotNull)
              .flatMap(balance => Object.keys(balance.byAssetAddress)),
          ),
        );
      },
    });
  }, [ethereumBalances]);

  const ethereumUsdPrices = useCoingeckoUsdPrices({
    addresses: ethereumAssetIds,
  });
  const ethereumTokens = useDataServiceEthereumTokens();

  const usdBalancesByEthereumAddress = useMemo(() => {
    return AsyncValue.allRecord({
      ethereumBalances,
      ethereumUsdPrices,
      ethereumTokens,
    }).mapReady(x => {
      const result: Record<string, BigNumber> = {};

      for (const [address, balance] of Object.entries(x.ethereumBalances)) {
        if (!balance) continue;

        result[address] = Maybe.filterMap(
          Object.values(balance.byAssetAddress),
          Maybe.fromNullable,
        ).reduce((acc, balanceItem) => {
          const usdPrice = new BigNumber(
            x.ethereumUsdPrices[balanceItem.address] ?? '0',
          );
          const asset = x.ethereumTokens[balanceItem.address];

          if (usdPrice.eq(0) || asset == null) {
            return acc;
          }

          const worth = new BigNumber(balanceItem.balance)
            .mul(new BigNumber(10).pow(-asset.decimals))
            .mul(usdPrice);

          return acc.add(worth);
        }, new BigNumber(0));
      }

      return result;
    });
  }, [ethereumBalances, ethereumTokens, ethereumUsdPrices]);

  const wavesAddressesById = useMemo(() => {
    return accounts.reduce(
      (addressesById, account) => {
        const wavesPublicKey = Account.fromInMemoryJSON(account)
          .assertOk()
          .getPublicKeys()
          .find(x => x.blockchain === 'waves');

        if (!wavesPublicKey) return addressesById;

        addressesById[account.id] = wavesPublicKey
          .getAddress({
            chainId: WAVES_NETWORK_CONFIGS[network].chainId,
          })
          .toString();

        return addressesById;
      },
      {} as { [id: string]: string },
    );
  }, [accounts, network]);

  const wavesAddresses = useMemo(() => {
    return Object.values(wavesAddressesById);
  }, [wavesAddressesById]);

  const wavesBalances = useWavesBalances({
    addresses: wavesAddresses,
  });

  const dataServiceProducts = useDataServiceProducts({
    addresses: wavesAddresses,
  });

  const assetIds = useMemo((): string[] => {
    return AsyncValue.allRecord({ wavesBalances, dataServiceProducts }).match({
      Pending: () => [],
      Ready: x => {
        const assetIdsSet = new Set<string>();

        for (const address of wavesAddresses) {
          const accountBalances = x.wavesBalances[address];
          const accountProducts = x.dataServiceProducts[address];

          if (accountBalances) {
            for (const balance of accountBalances.list) {
              if (balance) {
                assetIdsSet.add(balance.assetId);
              }
            }
          }

          if (accountProducts) {
            for (const product of accountProducts) {
              for (const amount of product.amounts) {
                assetIdsSet.add(amount.asset_id);
              }
            }
          }
        }

        return Array.from(assetIdsSet);
      },
    });
  }, [wavesAddresses, dataServiceProducts, wavesBalances]);

  const wavesAssetsDetails = useWavesAssetsDetails({
    assetIds,
  });
  const usdPrices = useDataServiceUsdPrices({
    assetIds,
  });
  const usdBalancesByWavesAddress = useMemo(() => {
    return AsyncValue.allRecord({
      wavesAssetsDetails,
      wavesBalances,
      dataServiceProducts,
      usdPrices,
    }).mapReady(x => {
      function getTotalForAddress(address: string) {
        const accountBalances = x.wavesBalances[address];
        const accountProducts = x.dataServiceProducts[address];

        let total = new BigNumber(0);

        if (accountBalances) {
          for (const balance of accountBalances.list) {
            const assetDetails = x.wavesAssetsDetails[balance.assetId];
            if (!assetDetails) continue;

            const usdPrice = x.usdPrices[balance.assetId];
            if (!usdPrice) continue;

            const isWaves = balance.assetId === WAVES_ASSET.assetId;

            total = total.add(
              WavesMoney.fromCoins(
                isWaves
                  ? accountBalances.wavesBalance.regular
                  : balance.balance,
                assetDetails,
              )
                .getTokens()
                .mul(usdPrice),
            );
          }
        }

        if (accountProducts) {
          {
            for (const product of accountProducts) {
              for (const amount of product.amounts) {
                const assetDetails = x.wavesAssetsDetails[amount.asset_id];
                if (!assetDetails) continue;

                const usdPrice = x.usdPrices[assetDetails.assetId];
                if (!usdPrice) continue;

                total = total.add(
                  WavesMoney.fromCoins(amount.coins, assetDetails)
                    .getTokens()
                    .mul(usdPrice),
                );
              }
            }
          }
        }

        return total;
      }

      return Object.fromEntries(
        wavesAddresses.map(address => [address, getTotalForAddress(address)]),
      );
    });
  }, [
    wavesAddresses,
    wavesBalances,
    dataServiceProducts,
    wavesAssetsDetails,
    usdPrices,
  ]);

  return (
    <Container>
      <div className={styles.accountsPageHeader}>
        <h1 className={styles.accountsPageTitle}>
          <Trans>Manage Accounts</Trans>
        </h1>

        <div className={styles.accountsPageControls}>
          <LinkButton outlined text={t(i18n)`Add account`} to="/add-account" />
        </div>

        <LinkButton
          to="/add-account"
          outlined
          className={styles.mobileAddAccount}
          startIcon={<PlusIcon className={styles.mobileAddAccountIcon} />}
        />
      </div>

      {accounts.length === 0 ? (
        <p className={styles.noAccountsMessage}>
          <Trans>You have no saved accounts</Trans>
        </p>
      ) : (
        <ul className={styles.accountList}>
          {accounts.map(accountJSON => {
            const account = Account.fromInMemoryJSON(accountJSON).assertOk();

            const totalBalance = AsyncValue.allRecord({
              usdBalancesByWavesAddress,
              usdBalancesByEthereumAddress,
            }).mapReady(x => {
              const wavesBalance = new BigNumber(
                x.usdBalancesByWavesAddress[wavesAddressesById[account.id]] ??
                  '0',
              );
              const ethereumBalance = new BigNumber(
                x.usdBalancesByEthereumAddress[
                  ethereumAddressesById[account.id]
                ] ?? '0',
              );

              return wavesBalance.add(ethereumBalance);
            });

            return (
              <li key={account.id}>
                <AccountListItem
                  account={accountJSON}
                  balance={totalBalance}
                  name={account.name}
                />
              </li>
            );
          })}
        </ul>
      )}
    </Container>
  );
}

interface AccountListItemProps {
  account: InMemoryAccountJSON;
  balance: AsyncValue<BigNumber>;
  name: string;
}

function AccountListItem({ account, balance, name }: AccountListItemProps) {
  return (
    <Link to={`/accounts/${account.id}`}>
      <div className={styles.accountListItem}>
        <div className={styles.accountAvatarContainer}>
          <AccountAvatar
            account={Account.fromInMemoryJSON(account).assertOk()}
            className={styles.accountAvatar}
          />
        </div>

        <h3 className={styles.accountTitle}>{name}</h3>

        <span className={styles.accountBalance}>
          {balance.match({
            Pending: () => (
              <Skeleton className={styles.accountBalanceSkeleton} />
            ),
            Ready: balanceValue => <>{formatUsdPrice(balanceValue)}</>,
          })}
        </span>

        <ChevronRightIcon className={styles.chevronRightIcon} />
      </div>
    </Link>
  );
}
