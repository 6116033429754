import BigNumber from '@waves/bignumber';

import type { Asset, Balance } from '../blockchain/types';

function coinsToTokens(coins: BigNumber, decimals: number) {
  return coins.mul(new BigNumber(10).pow(0 - decimals));
}

function tokensToCoins(tokens: BigNumber, decimals: number) {
  return tokens.mul(new BigNumber(10).pow(decimals));
}

type BnInput = BigNumber | string | number;

function toBn(input: BnInput): BigNumber {
  return input instanceof BigNumber ? input : new BigNumber(input);
}

abstract class AbstractBlockchainMoney {
  #coins;
  #decimals;

  constructor(coins: BnInput, decimals: number) {
    this.#coins = toBn(coins);
    this.#decimals = decimals;
  }

  getCoins() {
    return this.#coins;
  }

  getTokens() {
    return coinsToTokens(this.#coins, this.#decimals);
  }
}

export class EthereumMoney extends AbstractBlockchainMoney {
  #asset;

  private constructor(asset: Asset<'ethereum'>, coins: BnInput) {
    super(coins, asset.decimals);
    this.#asset = asset;
  }

  static fromBalance(balance: Balance<'ethereum'>, asset: Asset<'ethereum'>) {
    return new this(asset, balance.balance);
  }

  static fromCoins(
    coins: BigNumber | number | string,
    asset: Asset<'ethereum'>,
  ) {
    return new this(asset, coins);
  }

  static fromTokens(
    tokens: BigNumber | number | string,
    asset: Asset<'ethereum'>,
  ) {
    return new this(asset, tokensToCoins(toBn(tokens), asset.decimals));
  }

  get blockchain() {
    return 'ethereum' as const;
  }

  get asset() {
    return this.#asset;
  }
}

export class WavesMoney extends AbstractBlockchainMoney {
  #asset;

  private constructor(asset: Asset<'waves'>, coins: BnInput) {
    super(coins, asset.decimals);
    this.#asset = asset;
  }

  static fromBalance(balance: Balance<'waves'>, asset: Asset<'waves'>) {
    return new this(asset, balance.balance);
  }

  static fromCoins(coins: BigNumber | number | string, asset: Asset<'waves'>) {
    return new this(asset, coins);
  }

  static fromTokens(
    tokens: BigNumber | number | string,
    asset: Asset<'waves'>,
  ) {
    return new this(asset, tokensToCoins(toBn(tokens), asset.decimals));
  }

  get blockchain() {
    return 'waves' as const;
  }

  get asset() {
    return this.#asset;
  }
}

export type BlockchainMoney = EthereumMoney | WavesMoney;
