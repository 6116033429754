import { Mnemonic } from 'ethers';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import invariant from 'tiny-invariant';

interface CreateAccountContextValue {
  generateRandomSeed: () => void;
  shuffleSeedIndices: () => void;
  seed: string | undefined;
  seedIndicesAfterShuffle: number[] | undefined;
  indicesToCheck: number[] | undefined;
}

const CreateAccountContext = createContext<CreateAccountContextValue | null>(
  null,
);

export function useCreateAccountPageContext() {
  const value = useContext(CreateAccountContext);
  invariant(value);
  return value;
}

export function CreateAccountPage() {
  const [seed, setSeed] = useState<string>();
  const [seedIndicesAfterShuffle, setSeedIndicesAfterShuffle] =
    useState<number[]>();
  const [indicesToCheck, setIndicesToCheck] = useState<number[]>();

  const generateRandomSeed = useCallback(() => {
    const mnemonic = Mnemonic.fromEntropy(
      crypto.getRandomValues(new Uint8Array(32)),
    );
    setSeed(mnemonic.phrase);
  }, []);

  const shuffleSeedIndices = useCallback(() => {
    invariant(seed);
    const words = seed.split(' ');

    const randomIndices: number[] = [];
    while (randomIndices.length !== 3) {
      const nextIndex = Math.floor(Math.random() * words.length);
      if (randomIndices.includes(nextIndex)) continue;
      randomIndices.push(nextIndex);
    }
    setIndicesToCheck(randomIndices.sort((a, b) => a - b));

    const indicesToShuffle = Array.from({ length: words.length }, (_, i) => i);
    const indicesAfterShuffle: number[] = [];

    while (indicesAfterShuffle.length !== words.length) {
      const randomIndex = Math.floor(Math.random() * indicesToShuffle.length);
      indicesAfterShuffle.push(...indicesToShuffle.splice(randomIndex, 1));
    }
    setSeedIndicesAfterShuffle(indicesAfterShuffle);
  }, [seed]);

  const value = useMemo(
    (): CreateAccountContextValue => ({
      generateRandomSeed,
      shuffleSeedIndices,
      seed,
      seedIndicesAfterShuffle,
      indicesToCheck,
    }),
    [
      generateRandomSeed,
      shuffleSeedIndices,
      seed,
      seedIndicesAfterShuffle,
      indicesToCheck,
    ],
  );

  return (
    <CreateAccountContext.Provider value={value}>
      <Outlet />
    </CreateAccountContext.Provider>
  );
}
