import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { AssetLogo } from '../_core/assetLogo';
import type { AsyncValue } from '../_core/asyncValue';
import { Button } from '../_core/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogContextConsumer,
} from '../_core/dialog';
import type { UnexpectedError } from '../_core/errors';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Maybe, None, Some } from '../_core/maybe';
import type { Result } from '../_core/result';
import { Spinner } from '../_core/spinner';
import type { BlockchainAddress } from '../blockchain/address';
import type {
  BlockchainTransactionRequest,
  BlockchainTransactionSendResponse,
} from '../blockchain/types';
import type { TransactionStatus } from '../blockchain/watchTransactionStatus';
import type { CoingeckoUsdPrices } from '../cache/coingecko/usdPrices';
import type { DataServiceAssets } from '../cache/dataService/assets';
import type { DataServiceUsdPrices } from '../cache/dataService/usdPrices';
import { CheckIcon } from '../icons/checkIcon';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import { useAppSelector } from '../store/react';
import * as styles from './sendStatusDialog.module.css';

interface Props {
  coingeckoUsdPrices: AsyncValue<CoingeckoUsdPrices>;
  dataServiceAssets: AsyncValue<DataServiceAssets>;
  dataServiceUsdPrices: AsyncValue<DataServiceUsdPrices>;
  isOpen: boolean;
  request: BlockchainTransactionRequest;
  sendResult: Result<BlockchainTransactionSendResponse, UnexpectedError>;
  transactionStatus: TransactionStatus;
  onIsOpenChange: (open: boolean) => void;
}

export function SendStatusDialog({
  coingeckoUsdPrices,
  dataServiceAssets,
  dataServiceUsdPrices,
  isOpen,
  request: { account, input },
  sendResult,
  transactionStatus,
  onIsOpenChange,
}: Props) {
  const { i18n } = useLingui();

  const network = useAppSelector(state => state.network);
  const { chainId } = WAVES_NETWORK_CONFIGS[network];

  let senderAddress: BlockchainAddress;

  if (input.blockchain === 'waves' && input.type === 'invokeScript') {
    throw new Error('NOT IMPLEMENTED');
  }

  switch (input.blockchain) {
    case 'ethereum':
      senderAddress = account.getEthereumAddress().assertSome();
      break;
    case 'waves':
      senderAddress = account.getWavesAddress(chainId).assertSome();
      break;
  }

  const { amount, fee, recipient } = input;

  const amountDataServiceAsset =
    amount.blockchain === 'waves'
      ? dataServiceAssets
          .getReady()
          .flatMapSome(dataServiceAssetsValue =>
            Maybe.fromNullable(dataServiceAssetsValue[amount.asset.assetId]),
          )
      : None;

  const amountAssetLabel =
    amount.blockchain === 'waves'
      ? amountDataServiceAsset
          .flatMapSome(x => Maybe.fromNullable(x.ticker))
          .getOr(() => amount.asset.name)
      : amount.asset.symbol;

  const amountAssetLogo =
    amount.blockchain === 'waves'
      ? amountDataServiceAsset
          .flatMapSome(x => Maybe.fromNullable(x.url))
          .toOptional()
      : amount.asset.iconUrl;

  const amountAssetObjectId =
    amount.blockchain === 'waves'
      ? amountDataServiceAsset.mapSome(x => x.id).toOptional()
      : amount.asset.address;

  const usdPrice =
    amount.blockchain === 'waves'
      ? dataServiceUsdPrices
          .getReady()
          .flatMapSome(dataServiceUsdPricesValue =>
            Maybe.fromNullable(dataServiceUsdPricesValue[amount.asset.assetId]),
          )
      : coingeckoUsdPrices
          .getReady()
          .flatMapSome(coingeckoUsdPricesValue =>
            Maybe.fromNullable(coingeckoUsdPricesValue[amount.asset.address]),
          );

  const feeAssetLabel =
    fee.blockchain === 'waves'
      ? dataServiceAssets
          .getReady()
          .flatMapSome(dataServiceAssetsValue =>
            Maybe.fromNullable(
              dataServiceAssetsValue[fee.toMoney().asset.assetId],
            ),
          )
          .flatMapSome(x => Maybe.fromNullable(x.ticker))
          .getOr(() => fee.toMoney().asset.name)
      : fee.asset.symbol;

  const explorerUrl = sendResult.match<Maybe<string>>({
    Err: () => None,
    Ok: sendResponse => {
      switch (sendResponse.blockchain) {
        case 'ethereum':
          return Some(
            {
              mainnet: `https://etherscan.io/tx/${sendResponse.hash}`,
              testnet: `https://sepolia.etherscan.io/tx/${sendResponse.hash}`,
            }[network],
          );
        case 'waves':
          return Some(
            {
              mainnet: `https://wavesexplorer.com/transactions/${sendResponse.id}?network=mainnet`,
              testnet: `https://wavesexplorer.com/transactions/${sendResponse.id}?network=testnet`,
            }[network],
          );
      }
    },
  });

  return (
    <Dialog isOpen={isOpen} onIsOpenChange={onIsOpenChange}>
      <DialogContent className={styles.root}>
        <DialogClose />

        <div>
          <img
            src={(transactionStatus.type === 'pending'
              ? new URL('./statusWaiting.webp', import.meta.url)
              : transactionStatus.type === 'applicationSuccess'
              ? new URL('./statusSucceed.webp', import.meta.url)
              : transactionStatus.type === 'applicationFailed' ||
                transactionStatus.type === 'broadcastError'
              ? new URL('./statusFailed.webp', import.meta.url)
              : new URL('./statusUnknown.webp', import.meta.url)
            ).toString()}
          />
        </div>

        <h3 className={styles.statusMessage}>
          {transactionStatus.type === 'pending'
            ? t(i18n)`Waiting for Send...`
            : transactionStatus.type === 'applicationSuccess'
            ? t(i18n)`Send succeeded`
            : transactionStatus.type === 'applicationFailed' ||
              transactionStatus.type === 'broadcastError'
            ? t(i18n)`Send failed`
            : t(i18n)`Network response time exceeded`}
        </h3>

        <div className={styles.plate}>
          <p className={styles.accountRow}>
            {account.name}
            <span className={styles.accountHelperText}>
              {senderAddress.toString()}
            </span>
          </p>

          {transactionStatus.type === 'pending' ||
          transactionStatus.type === 'applicationSuccess' ? (
            <div className={styles.statusCard}>
              <div className={styles.statusRow}>
                <div className={styles.assetItem}>
                  <div className={styles.assetIcon}>
                    <AssetLogo
                      blockchain={amount.blockchain}
                      id={amountAssetObjectId}
                      ticker={amountAssetLabel}
                      logo={amountAssetLogo}
                    />
                  </div>

                  {amountAssetLabel}
                </div>

                {transactionStatus.type === 'pending' ? (
                  <Spinner size={24} />
                ) : (
                  <CheckIcon className={styles.statusIcon} />
                )}
              </div>

              <div className={styles.statusRow}>
                <span className={styles.assetAmountInTokens}>
                  –{amount.getTokens().toFormat()}
                </span>

                {usdPrice
                  .mapSome(usdPriceValue => (
                    <span className={styles.assetAmountInUsd}>
                      {formatUsdPrice(amount.getTokens().mul(usdPriceValue))}
                    </span>
                  ))
                  .toNullable()}
              </div>

              <div className={styles.recipientRow}>
                <span className={styles.recipientLabel}>
                  {t(i18n)({ comment: 'Recipient', message: 'to' })}:
                </span>{' '}
                {recipient.toString()}
              </div>
            </div>
          ) : transactionStatus.type === 'applicationFailed' ||
            transactionStatus.type === 'broadcastError' ? (
            <>
              {transactionStatus.type === 'broadcastError' && (
                <div className={styles.statusCard}>
                  <p className={styles.statusErrorMessage}>
                    {transactionStatus.message}
                  </p>
                </div>
              )}

              <div className={styles.statusBox}>
                <p>
                  {t(i18n)({
                    message:
                      'Your assets ({amountFormatted} {amountAssetLabel}) has been returned to your balance',
                    values: {
                      amountFormatted: amount.getTokens().toFormat(),
                      amountAssetLabel,
                    },
                  })}
                </p>

                {transactionStatus.type === 'applicationFailed' && (
                  <p>
                    {t(i18n)({
                      message:
                        'Deducted fee is -{feeFormatted} {feeAssetLabel}',
                      values: {
                        feeFormatted: fee.toMoney().getTokens().toFormat(),
                        feeAssetLabel,
                      },
                    })}
                  </p>
                )}
              </div>
            </>
          ) : (
            <div className={styles.statusBox}>
              <p>{t(i18n)`We couldn’t find your transaction in blockchain`}</p>
            </div>
          )}
        </div>

        <div className={styles.transactionInfo}>
          {explorerUrl
            .mapSome(
              explorerUrlValue =>
                (transactionStatus.type === 'applicationSuccess' ||
                  transactionStatus.type === 'applicationFailed' ||
                  transactionStatus.type === 'transactionLost') && (
                  <a
                    className={styles.explorerUrl}
                    href={explorerUrlValue}
                    rel="noopener"
                    target="_blank"
                  >
                    {transactionStatus.type === 'transactionLost'
                      ? t(i18n)`Try to find transaction in Explorer`
                      : t(i18n)`View in Explorer`}
                  </a>
                ),
            )
            .toNullable()}
        </div>

        <DialogContextConsumer>
          {({ setOpen }) => (
            <Button
              text={t(i18n)`Close`}
              variant="outlined"
              onClick={() => setOpen(false)}
            />
          )}
        </DialogContextConsumer>
      </DialogContent>
    </Dialog>
  );
}
