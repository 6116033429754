import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { type BigNumber } from '@waves/bignumber';

import { type AsyncValue } from '../_core/asyncValue';
import { formatUsdPrice } from '../_core/formatUsdPrice';
import { Skeleton } from '../_core/skeleton';
import { Table } from '../_core/table';
import { AssetCell } from '../assets/assetCell';
import { AssetList } from '../assets/assetList';
import * as styles from './recentAssets.module.css';
import { DashboardWidget, DashboardWidgetEmptyMessage } from './widget';

interface AssetItem {
  blockchain: 'waves' | 'ethereum';
  id: string;
  ticker: string;
  logo?: string;
  price?: BigNumber;
  priceChange?: number;
  available: BigNumber;
  worth?: BigNumber;
}

const NO_DATA_PLACEHOLDER = '–';

interface Props {
  className?: string;
  items: AsyncValue<AssetItem[]>;
}

export function RecentAssets({ className, items }: Props) {
  const { i18n } = useLingui();

  const recentItems = items.mapReady(x => x.slice(0, 5));

  return (
    <DashboardWidget
      className={className}
      heading={t(i18n)`Wallet`}
      headerLink={{
        label: items.mapReady(itemsValue => itemsValue.length.toString()),
        to: '/portfolio',
      }}
    >
      {recentItems.match({
        Ready: recentItemsValue =>
          recentItemsValue.length === 0 ? (
            <DashboardWidgetEmptyMessage
              heading={t(i18n)`Your wallet is empty`}
              description={t(i18n)`You have no assets yet.`}
            />
          ) : (
            <>
              <Table
                className={styles.assetTable}
                columns={[
                  {
                    label: t(i18n)`Asset`,
                    render: ({ blockchain, ticker, logo, id }) => (
                      <AssetCell
                        blockchain={blockchain}
                        ticker={ticker}
                        logo={logo}
                        id={id}
                      />
                    ),
                  },
                  {
                    label: t(i18n)`Price`,
                    render: ({ price }) =>
                      price ? formatUsdPrice(price) : `$${NO_DATA_PLACEHOLDER}`,
                  },
                  {
                    label: t(i18n)`Balance`,
                    render: ({ available }) => available.toFormat(),
                    align: 'right',
                  },
                  {
                    label: t(i18n)`USD Value`,
                    render: ({ worth }) =>
                      worth ? formatUsdPrice(worth) : `$${NO_DATA_PLACEHOLDER}`,
                    align: 'right',
                  },
                ]}
                data={recentItemsValue}
                getKey={row => row.id}
              />

              <AssetList
                items={recentItemsValue}
                className={styles.assetList}
              />
            </>
          ),
        Pending: () => (
          <div className={styles.skeleton}>
            {new Array(5).fill(undefined).map((_, index) => (
              <Skeleton key={index} className={styles.skeletonItem} />
            ))}
          </div>
        ),
      })}
    </DashboardWidget>
  );
}
