import clsx from 'clsx';
import { create as createIdentityImg } from 'identity-img';
import { useMemo } from 'react';

import { Maybe } from '../_core/maybe';
import type { Account } from '../accounts/types';
import type { BlockchainPublicKey } from '../blockchain/publicKey';
import { ExtensionIcon } from '../icons/extension';
import { MobileAppIcon } from '../icons/mobileApp';
import { SeedIcon } from '../icons/seed';
import { WAVES_NETWORK_CONFIGS } from '../network/constants';
import type { Network } from '../network/types';
import { useAppSelector } from '../store/react';
import * as styles from './accountAvatar.module.css';

interface Props {
  account: Account;
  className?: string;
}

function getIdentityImgInputFromAccount(account: Account, network: Network) {
  const publicKeys = account.getPublicKeys();

  let publicKey: Maybe<BlockchainPublicKey>;
  switch (account.type) {
    case 'ethereum':
    case 'multichain':
      publicKey = Maybe.fromNullable(
        publicKeys.find(x => x.blockchain === 'ethereum'),
      );
      break;
    case 'keeper-extension':
    case 'keeper-mobile':
    case 'waves':
      publicKey = Maybe.fromNullable(
        publicKeys.find(x => x.blockchain === 'waves'),
      );
      break;
  }

  return publicKey
    .assertSome()
    .getAddress({
      chainId: WAVES_NETWORK_CONFIGS[network].chainId,
    })
    .toString();
}

export function AccountAvatarBase({ account, className }: Props) {
  const network = useAppSelector(state => state.network);

  const src = useMemo(
    () =>
      createIdentityImg(getIdentityImgInputFromAccount(account, network), {
        size: 44,
        rows: 8,
        cells: 8,
      }),
    [account, network],
  );

  return <img alt="" className={className} src={src} />;
}

function getAccountTypeIconComponent(account: Account) {
  switch (account.type) {
    case 'multichain':
    case 'ethereum':
    case 'waves': {
      return SeedIcon;
    }
    case 'keeper-extension': {
      return ExtensionIcon;
    }
    case 'keeper-mobile': {
      return MobileAppIcon;
    }
    default: {
      return null;
    }
  }
}

interface AccountTypeIconProps {
  account: Account;
  size?: 'large' | 'medium' | 'small';
  className?: string;
}

export function AccountAvatar({
  account,
  size,
  className,
}: AccountTypeIconProps) {
  const AccountTypeIcon = getAccountTypeIconComponent(account);

  return (
    <div
      className={clsx(styles.accountAvatarContainer, className)}
      style={
        size && {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          '--avatar-size': {
            large: '44px',
            medium: '32px',
            small: '20px',
          }[size],
        }
      }
    >
      <AccountAvatarBase account={account} className={styles.accountAvatar} />

      {AccountTypeIcon && (
        <AccountTypeIcon className={styles.accountAvatarBadge} />
      )}
    </div>
  );
}
