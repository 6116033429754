import { useEffect, useMemo } from 'react';
import type { Reducer } from 'redux';
import { array } from 'superstruct';
import invariant from 'tiny-invariant';

import { AsyncValue } from '../../_core/asyncValue';
import { handleResponse } from '../../_core/handleResponse';
import { pollWhileUserIsActive } from '../../_core/polling';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import type { Network } from '../../network/types';
import { useAppDispatch, useAppSelector } from '../../store/react';
import type { AppState } from '../../store/types';
import { WavesAsset } from './assetsDetails';

export type UpdateWavesNftsAction = {
  type: 'UPDATE_WAVES_NFTS';
  payload: {
    address: string;
    network: Network;
    nfts: WavesAsset[];
    requestedLimit: number;
  };
};

const reducer: Reducer<
  Partial<
    Record<
      Network,
      Partial<{
        [address: string]: {
          ids: string[];
          largestRequestedLimit: number;
        };
      }>
    >
  >,
  UpdateWavesNftsAction
> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_WAVES_NFTS': {
      let nfts = state[action.payload.network]?.[action.payload.address];

      if (!nfts) {
        nfts = {
          ids: action.payload.nfts.map(nft => nft.assetId),
          largestRequestedLimit: action.payload.requestedLimit,
        };
      } else {
        if (action.payload.requestedLimit > nfts.largestRequestedLimit) {
          nfts = {
            ...nfts,
            largestRequestedLimit: action.payload.requestedLimit,
          };
        }

        const nftIds = nfts.ids;

        if (
          action.payload.nfts.length > nfts.ids.length ||
          action.payload.nfts.some(
            (nft, index) => nft.assetId !== nftIds[index],
          )
        ) {
          nfts = {
            ...nfts,
            ids: action.payload.nfts.map(nft => nft.assetId),
          };
        }
      }

      return nfts === state[action.payload.network]?.[action.payload.address]
        ? state
        : {
            ...state,
            [action.payload.network]: {
              ...state[action.payload.network],
              [action.payload.address]: nfts,
            },
          };
    }
    default:
      return state;
  }
};

export function selectWavesNftById({
  id,
  network,
}: {
  id: string;
  network: Network;
}) {
  return (state: AppState): WavesAsset | undefined => {
    return state.cache.waves.assetsDetails[network][id];
  };
}

export default reducer;

type WavesNfts = Partial<{
  [address: string]: WavesAsset[];
}>;

export function useWavesNfts({
  addresses,
  limit,
}: {
  addresses: string[];
  limit: number;
}): AsyncValue<WavesNfts> {
  const dispatch = useAppDispatch();
  const network = useAppSelector(state => state.network);
  const { nodeUrl } = WAVES_NETWORK_CONFIGS[network];

  useEffect(() => {
    return pollWhileUserIsActive(5000, async signal => {
      await Promise.all(
        addresses.map(address =>
          fetch(new URL(`/assets/nft/${address}/limit/${limit}`, nodeUrl), {
            headers: {
              accept: 'application/json; large-significand-format=string',
            },
            signal,
          })
            .then(handleResponse(array(WavesAsset)))
            .then(nfts => {
              dispatch({
                type: 'UPDATE_WAVES_NFTS',
                payload: {
                  address,
                  network,
                  nfts,
                  requestedLimit: limit,
                },
              });
            }),
        ),
      );
    });
  }, [addresses, dispatch, limit, network, nodeUrl]);

  const assetsDetails = useAppSelector(
    state => state.cache.waves.assetsDetails[network],
  );

  const nftsByAddress = useAppSelector(
    state => state.cache.waves.nfts[network],
  );

  return useMemo(() => {
    const result: WavesNfts = {};

    for (const address of addresses) {
      const nfts = nftsByAddress?.[address];

      if (
        !nfts ||
        (nfts.largestRequestedLimit < limit && nfts.ids.length < limit)
      ) {
        return AsyncValue.Pending;
      }

      result[address] = nfts.ids.slice(0, limit).map(nftId => {
        const assetDetails = assetsDetails[nftId];
        invariant(assetDetails);
        return assetDetails;
      });
    }

    return AsyncValue.Ready(result);
  }, [addresses, assetsDetails, limit, nftsByAddress]);
}
