import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';

import { Button } from '../_core/button';
import { CopyToClipboard } from '../_core/copyToClipboard';
import { RevealableBox } from '../_core/revealableBox';
import { CheckIcon } from '../icons/checkIcon';
import { CopyIcon } from '../icons/copy';
import * as styles from './createAccount.module.css';
import { SeedWordList } from './seedWordList';

interface CreateAccountInputs {
  isChecked: boolean;
}

export function CreateAccount({
  seed,
  onSubmit,
}: {
  seed: string;
  onSubmit: (values: CreateAccountInputs) => void;
}) {
  const { i18n } = useLingui();

  const {
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<CreateAccountInputs>();

  return (
    <>
      <p className={styles.helpText}>
        <Trans>Copy or write down these words in the correct order</Trans>
      </p>

      <div className={styles.seedWrapper}>
        <RevealableBox revealButtonText={t(i18n)`Show phrase`}>
          <SeedWordList words={seed.split(' ')} />
        </RevealableBox>
      </div>

      <form
        className={styles.form}
        onSubmit={handleSubmit(values => onSubmit(values))}
      >
        <CopyToClipboard>
          {({ copy, isCopied }) => (
            <Button
              className={styles.copyButton}
              size="small"
              startIcon={isCopied ? <CheckIcon /> : <CopyIcon />}
              text={t(i18n)`Copy to clipboard`}
              variant="outlined"
              onClick={() => {
                void copy(seed);
              }}
            />
          )}
        </CopyToClipboard>

        <label className={styles.checkboxContainer}>
          <Trans>
            I understand that if I lose my secret phrase, I will not be able to
            access my account
          </Trans>

          <input
            className={styles.checkboxInput}
            type="checkbox"
            {...register('isChecked', { required: t(i18n)`Required` })}
          />

          <div className={styles.checkboxIconBorder}>
            <CheckIcon className={styles.checkboxIcon} />
          </div>
        </label>

        <Button
          block
          text={t(i18n)`Continue`}
          type="submit"
          disabled={!isValid}
        />
      </form>
    </>
  );
}
