// extracted by mini-css-extract-plugin
export var body = "X8gvXSg6I1ToL6FrtDf6";
export var confirmationError = "GeZDOqPsCbk0ItY388tN";
export var confirmationErrorMessage = "hKtabNUj7L5mSD0FwKK4";
export var header = "LfJELNa3SWlOyaPlbZvf";
export var headerCloseButton = "UpARZU478DhT99fZzcdn";
export var headerNetwork = "tgtIrn9fHCD_jw8gO19u";
export var headerNetworkIcon = "Kqge2iGdlkIzA6V3I_SP";
export var headerTop = "OnxwLuEMVCcaWSAUWjOQ";
export var root = "TNwpiRGX7W3GZBvJ9BL6";
export var txAddress_lg = "HkDBLQQRiOO1QP1HgZMs";
export var txAddress_sm = "L9BLYu8nnWJv46h52bo8";
export var txDetails = "AsaKjXvwUOrGlAo2Q_2u";
export var txDetailsItem = "g3DuDBs3JIxCbi5Prmad";
export var txDetailsItemLabel = "aFXn26Nt3NeGY6DWCioN";
export var txDetailsItemValue = "ChzG_f62NqEdl2IlyNCm";
export var txInvokePayments = "jhbQipY76IQnShQvnIsY";
export var txInvokePaymentsItem = "JRww4aVJipF2k_LDLBjk";
export var txInvokePaymentsItemSkeleton = "oJnNgwcnHuFuwQ6r4QYQ";
export var waitingConfirmationMessage = "ML4482QVrqJt8duFhQvA";