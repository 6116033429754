import { base58Encode, blake2b } from '@keeper-wallet/waves-crypto';
import BigNumber from '@waves/bignumber';
import create from '@waves/parse-json-bignumber';
import type { Signer } from '@waves/signer';
import { TRANSACTION_TYPE } from '@waves/ts-types';
import { makeTxBytes } from '@waves/waves-transactions';

import type { WavesMoney } from '../../_core/money';
import { WAVES_NETWORK_CONFIGS } from '../../network/constants';
import type { Network } from '../../network/types';
import type { WavesAddress } from '../address';
import type { WavesFee } from '../fee/wavesFee';
import type { WavesPublicKey } from '../publicKey';

type InvokeScriptArgScalar<Integer> =
  | { type: 'string'; value: string }
  | { type: 'binary'; value: string }
  | { type: 'boolean'; value: boolean }
  | { type: 'integer'; value: Integer };

type InvokeScriptArg<Integer> =
  | InvokeScriptArgScalar<Integer>
  | { type: 'list'; value: Array<InvokeScriptArgScalar<Integer>> };

export type WavesTransactionInput =
  | {
      blockchain: 'waves';
      type: 'transfer';
      amount: WavesMoney;
      fee: WavesFee;
      network: Network;
      recipient: WavesAddress;
    }
  | {
      blockchain: 'waves';
      type: 'invokeScript';
      call: {
        function: string;
        args: Array<InvokeScriptArg<string | number>>;
      };
      dApp: WavesAddress;
      fee: WavesFee;
      network: Network;
      payments: Array<{
        assetId: string;
        amount: BigNumber;
      }>;
    };

function normalizeWavesAssetId(money: WavesMoney) {
  return money.asset.assetId === 'WAVES' ? null : money.asset.assetId;
}

const JSONBigNumber = create({
  isInstance: BigNumber.isBigNumber,
  parse: v => {
    const bn = new BigNumber(v);

    return bn.gt(Number.MAX_SAFE_INTEGER) ? bn : Number(v);
  },
  strict: true,
  stringify: v => String(v),
});

function convertArgsIntegers<T, U>(
  args: Array<InvokeScriptArg<T>>,
  f: (value: T) => U,
): Array<InvokeScriptArg<U>> {
  return args.map(arg =>
    arg.type === 'integer'
      ? { type: 'integer', value: f(arg.value) }
      : arg.type === 'list'
      ? {
          type: 'list',
          value: arg.value.map(item =>
            item.type === 'integer'
              ? { type: 'integer', value: f(item.value) }
              : item,
          ),
        }
      : arg,
  );
}

export class WavesTransaction {
  readonly #data;

  private constructor(
    data:
      | {
          type: typeof TRANSACTION_TYPE.TRANSFER;
          amount: BigNumber;
          assetId: string | null;
          chainId: number;
          fee: BigNumber;
          feeAssetId: string | null;
          proofs: Uint8Array[];
          recipient: string;
          senderPublicKey: WavesPublicKey;
          timestamp: number;
          version: 3;
        }
      | {
          type: typeof TRANSACTION_TYPE.INVOKE_SCRIPT;
          call: { function: string; args: Array<InvokeScriptArg<BigNumber>> };
          chainId: number;
          dApp: string;
          fee: BigNumber;
          feeAssetId: string | null;
          payment: Array<{
            assetId: string | null;
            amount: BigNumber;
          }>;
          proofs: Uint8Array[];
          senderPublicKey: WavesPublicKey;
          timestamp: number;
          version: 3;
        },
  ) {
    this.#data = data;
  }

  static fromInput(
    input: WavesTransactionInput,
    senderPublicKey: WavesPublicKey,
  ): WavesTransaction {
    const { chainId } = WAVES_NETWORK_CONFIGS[input.network];

    switch (input.type) {
      case 'invokeScript':
        return new WavesTransaction({
          type: TRANSACTION_TYPE.INVOKE_SCRIPT,
          call: {
            ...input.call,
            args: convertArgsIntegers(
              input.call.args,
              value => new BigNumber(value),
            ),
          },
          chainId,
          dApp: input.dApp.toString(),
          fee: input.fee.toMoney().getCoins(),
          feeAssetId: normalizeWavesAssetId(input.fee.toMoney()),
          payment: input.payments.map(({ amount, assetId }) => ({
            amount,
            assetId: assetId === 'WAVES' ? null : assetId,
          })),
          proofs: [],
          senderPublicKey,
          timestamp: Date.now(),
          version: 3,
        });
      case 'transfer':
        return new WavesTransaction({
          type: TRANSACTION_TYPE.TRANSFER,
          amount: input.amount.getCoins(),
          assetId: normalizeWavesAssetId(input.amount),
          chainId,
          fee: input.fee.toMoney().getCoins(),
          feeAssetId: normalizeWavesAssetId(input.fee.toMoney()),
          proofs: [],
          recipient: input.recipient.toString(),
          senderPublicKey,
          timestamp: Date.now(),
          version: 3,
        });
    }
  }

  addSignature(signature: Uint8Array) {
    this.#data.proofs.push(signature);
  }

  toBytes(): Uint8Array {
    switch (this.#data.type) {
      case TRANSACTION_TYPE.INVOKE_SCRIPT:
        return makeTxBytes({
          ...this.#data,
          call: {
            ...this.#data.call,
            args: convertArgsIntegers(this.#data.call.args, value =>
              value.toString(),
            ),
          },
          fee: this.#data.fee.toString(),
          payment: this.#data.payment.map(p => ({
            amount: p.amount.toString(),
            assetId: p.assetId,
          })),
          senderPublicKey: this.#data.senderPublicKey.toString(),
        });
      case TRANSACTION_TYPE.TRANSFER:
        return makeTxBytes({
          ...this.#data,
          amount: this.#data.amount.toString(),
          fee: this.#data.fee.toString(),
          senderPublicKey: this.#data.senderPublicKey.toString(),
        });
    }
  }

  stringify(): string {
    return JSONBigNumber.stringify({
      ...this.#data,
      id: base58Encode(blake2b(this.toBytes())),
      proofs: this.#data.proofs.map(base58Encode),
      senderPublicKey: this.#data.senderPublicKey.toString(),
    });
  }

  broadcastWithSigner(signer: Signer) {
    switch (this.#data.type) {
      case TRANSACTION_TYPE.INVOKE_SCRIPT:
        return signer
          .invoke({
            call: {
              ...this.#data.call,
              args: convertArgsIntegers(this.#data.call.args, value =>
                value.toString(),
              ),
            },
            dApp: this.#data.dApp,
            fee: this.#data.fee.toString(),
            feeAssetId: this.#data.feeAssetId,
            payment: this.#data.payment.map(p => ({
              amount: p.amount.toString(),
              assetId: p.assetId,
            })),
            senderPublicKey: this.#data.senderPublicKey.toString(),
          })
          .broadcast();
      case TRANSACTION_TYPE.TRANSFER:
        return signer
          .transfer({
            amount: this.#data.amount.toString(),
            assetId: this.#data.assetId,
            fee: this.#data.fee.toString(),
            feeAssetId: this.#data.feeAssetId,
            recipient: this.#data.recipient,
            senderPublicKey: this.#data.senderPublicKey.toString(),
          })
          .broadcast();
    }
  }
}
