import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import clsx from 'clsx';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { merge, onEnd, pipe, subscribe, take, takeUntil } from 'wonka';

import { fromElementEvent } from '../_core/wonka';
import { CurrentAccountSelect } from '../accounts/currentAccountSelect';
import { useAppSelector } from '../store/react';
import * as styles from './mobileMenu.module.css';
import { Sidebar } from './sidebar';

export function MobileMenu({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { i18n } = useLingui();

  const vault = useAppSelector(state => state.vault);

  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={clsx(styles.mobileMenu, {
        [styles.mobileMenu_open]: isOpen,
      })}
      onPointerDown={event => {
        const { clientX, currentTarget, pointerId } = event;
        const draggableElement = menuRef.current;
        invariant(draggableElement);

        if (event.pointerType !== 'touch') {
          return;
        }

        currentTarget.setPointerCapture(pointerId);

        let moved = 0;

        pipe(
          fromElementEvent(event.currentTarget, 'pointermove'),
          takeUntil(
            pipe(
              merge([
                fromElementEvent(event.currentTarget, 'pointerup'),
                fromElementEvent(event.currentTarget, 'pointercancel'),
              ]),
              take(1),
            ),
          ),
          onEnd(() => {
            currentTarget.releasePointerCapture(pointerId);

            draggableElement.style.transition = '';
            draggableElement.style.transform = '';

            if (moved > 70) {
              onClose();
            }
          }),
          subscribe(pointerEvent => {
            pointerEvent.preventDefault();
            draggableElement.style.transition = 'unset';

            if (pointerEvent.clientX - clientX < 0) {
              moved = Math.abs(pointerEvent.clientX - clientX);

              draggableElement.style.transform = `translateX(${
                pointerEvent.clientX - clientX
              }px)`;
            }
          }),
        );
      }}
    >
      <div className={styles.mobileMenuBackdrop} onClick={onClose} />

      <div ref={menuRef} className={styles.mobileMenuSidebar}>
        <div className={styles.mobileMenuLogoContainer}>
          <Link
            className={styles.mobileMenuLogo}
            onClick={onClose}
            to="/"
            title={t(i18n)`Go to the home page`}
          >
            <img
              src={new URL('./images/logo.svg', import.meta.url).pathname}
              alt=""
              width={37}
              height={37}
            />
          </Link>

          <div className={styles.betaBadge}>Beta</div>
        </div>

        {vault.state === 'unlocked' && (
          <div className={styles.controls}>
            <CurrentAccountSelect
              accounts={vault.accounts}
              selectedAccountId={vault.selectedAccountId}
            />
          </div>
        )}

        <Sidebar closeMobileMenu={onClose} />
      </div>
    </div>
  );
}
